export default () => ({
  dialogContent: {
    padding: 0,
    position: 'relative',
  },
  showHideColumns: {
    width: '1%',
    padding: 0,
    margin: 0,
  },
})
