export const highchartsLang = {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  shortMonths: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ],
  weekdays: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado'
  ],
  loading: ['Carregando...'],
  contextButtonTitle: 'Exportar gráfico',
  drillUpText: '◁ Voltar para {series.name}',
  decimalPoint: ',',
  thousandsSep: '.',
  downloadJPEG: 'Baixar imagem JPEG',
  downloadPDF: 'Baixar arquivo PDF',
  downloadPNG: 'Baixar imagem PNG',
  downloadSVG: 'Baixar vetor SVG',
  printChart: 'Imprimir gráfico',
  rangeSelectorFrom: 'De',
  rangeSelectorTo: 'Para',
  rangeSelectorZoom: 'Zoom',
  resetZoom: 'Limpar Zoom',
  resetZoomTitle: 'Voltar Zoom para nível 1:1'
}
