import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

// import { Creators as QuizzeActions } from '~/store/ducks/quiz'
import { Creators as ObjectivesActions } from '~/store/ducks/objectives'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'
import CustomIcon from '~/components/CustomIcon'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'

import NewObjectiveDialog from './components/NewObjectiveDialog'

import styles from './styles'

const objectivesSelector = ({ objectives }) => objectives

const useStyles = makeStyles(styles)

function ObjectivesPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openConfirmDialog, setConfirmDialog] = useState(false)
  const [modeObjectiveDialog, setModeObjectiveDialog] = useState(null)
  const [objectiveId, setObjectiveId] = useState(null)
  const [objectiveDate, setObjectiveDate] = useState(null)

  // const [openDeparmentDialog, setOpenDepartmentDialog] = useState(false)
  const { data, options, loading } = useSelector(objectivesSelector)
  // const { sort, direction } = options
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(ObjectivesActions.getObjectivesRequest())
  }, [])

  //
  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(ObjectivesActions.setObjectivesPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(ObjectivesActions.setObjectivesPage(page, options.limit))
  }

  const handleEditObjective = (objective) => {
    setObjectiveDate({
      month: objective.month,
      year: objective.year,
      working_days: objective.working_days,
    })
    setObjectiveId(objective.id)
    setModeObjectiveDialog('edit')
  }

  const handleAddObjective = () => {
    setModeObjectiveDialog('new')
  }

  const handleCloseObjectiveDialog = () => {
    setModeObjectiveDialog(null)
    setObjectiveId(null)
    setObjectiveDate(null)
  }

  const handleRemoveObjective = (id) => {
    setObjectiveId(id)
    setConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setObjectiveId(null)
    setConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      ObjectivesActions.removeObjectiveRequest(objectiveId, () => {
        dispatch(ObjectivesActions.getObjectivesRequest())
        setObjectiveId(null)
        setConfirmDialog(false)
      }),
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.gridContainerButton}
        >
          <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleAddObjective}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Planejamento
            </Button>
          </Grid>
        </Grid>
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {!loading && data.length === 0 && (
              <EmptyList message="Não há dados para serem visualizados" />
            )}
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ano</TableCell>
                        <TableCell>Mês</TableCell>
                        <TableCell>Dias úteis</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                        >
                          <TableCell>{item.year || '-'}</TableCell>
                          <TableCell>{item.month || '-'}</TableCell>
                          <TableCell>{item.working_days || '-'}</TableCell>
                          <TableCell align="right">
                            <div className={classes.tableCellActions}>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleEditObjective(item)}
                              >
                                <CustomIcon pathComponent={mdiPencil} />
                              </IconButton>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => handleRemoveObjective(item.id)}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>
      <NewObjectiveDialog
        id={objectiveId}
        mode={modeObjectiveDialog}
        objectiveDate={objectiveDate}
        open={modeObjectiveDialog !== null}
        onClose={handleCloseObjectiveDialog}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este planejamento?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}

export default ObjectivesPage
