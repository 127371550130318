import { createActions, createReducer } from 'reduxsauce'
import {
  logout as logoutHelper,
  getUser,
  getTokenData,
  setLoginUser,
  login,
} from '../../helpers/session'

/**
 * Types
 */
export const { Types, Creators } = createActions({
  loginRequest: ['credentials'],
  loginSuccess: ['tokenData', 'user'],
  loginFailure: [],
  logout: [],
  updateProfileRequest: ['data', 'id', 'callback'],
  updateProfileFinish: [],
})

/**
 * Reducer handlers(manipulador)
 */

const { access_token: token } = getTokenData()
const INITIAL_STATE = {
  user: getUser(),
  loading: false,
  access_token: token,
}

const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const loginSuccess = (state = INITIAL_STATE, { user, tokenData }) => {
  login(tokenData)
  setLoginUser(user)
  return {
    ...state,
    user,
    access_token: tokenData,
    loading: false,
  }
}

const loginFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const logout = (state = INITIAL_STATE) => {
  logoutHelper()
  return {
    ...state,
    access_token: null,
    user: null,
  }
}

const updateProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateProfileFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_FINISH]: updateProfileFinish,
})
