export default (theme) => ({
  singleDate: {
    '& .SingleDatePicker': {
      '& .SingleDatePickerInput': {
        display: 'flex',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },

      '& .DateInput': {
        marginLeft: '0',
        '& .DateInput_input': {
          padding: '14px 11px 12px',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        },
      },

      '& .DateInput__block': {
        display: 'flex',
        flex: 1,
      },

      '& .SingleDatePickerInput_calendarIcon': {
        outline: '0',
      },

      '& .SingleDatePickerInput__withBorder': {
        borderRadius: '4px',
        border: '1px solid rgba(255,255,255,.23)',
      },
    },

    '& .SingleDatePicker_picker': {
      backgroundColor: 'transparent',
    },

    '& .CalendarMonth_caption': {
      color: theme.palette.text.primary,
    },

    '& .DayPicker': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
    },

    '& .DayPickerNavigation_button': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
    },

    '& .CalendarMonthGrid': {
      backgroundColor: 'transparent',
    },

    '& .CalendarMonth': {
      backgroundColor: theme.palette.background.paper,
    },

    '& .CalendarDay': {
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
      color: theme.palette.text.primary,

      '&:hover': {
        background: theme.palette.background.default,
      },

      '&__selected': {
        background: theme.palette.info.main,
        color: theme.palette.text.primary,

        '&:hover': {
          background: theme.palette.info.main,
          color: theme.palette.text.primary,
        },
      },

      '&__selected_span': {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,

        '&:hover': {
          background: theme.palette.info.main,
          color: theme.palette.text.primary,
        },
      },

      '&__blocked_out_of_range': {
        color: theme.palette.text.disabled,

        '&:hover': {
          background: theme.palette.background.paper,
        },
      },
    },

    '& .DateInput_fang': {
      bottom: '57px!important',
      '& path': {
        fill: theme.palette.background.paper,

        '&.DateInput_fangStroke': {
          stroke: theme.palette.border,
        },
      },
    },
  },

  rangeDate: {
    '& .CalendarMonth_caption': {
      color: theme.palette.text.primary,
    },

    '& .DayPickerNavigation_button': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid #DFE3E8`,
    },

    '& .DayPicker__withBorder': {
      boxShadow:
        '0 2px 6px rgb(255 255 255 / 5%), 0 0 0 1px rgb(255 255 255 / 7%)',

      '&>div': {
        backgroundColor: theme.palette.background.paper,
      },
    },

    '& .DateRangePickerInput': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.border,

      '& .DayPicker_focusRegion': {
        backgroundColor: theme.palette.background.paper,
      },

      '& .CalendarMonthGrid': {
        backgroundColor: theme.palette.background.paper,
      },

      '& .DayPicker_transitionContainer': {
        backgroundColor: theme.palette.background.paper,
      },

      '& .CalendarMonth': {
        backgroundColor: theme.palette.background.paper,
      },

      '& .CalendarDay__selected': {
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,

        '&:hover': {
          background: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        },

        '&_span': {
          background: theme.palette.background.default,
          color: theme.palette.text.primary,

          '&:hover': {
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          },
        },
      },

      '& .DateInput_fang path': {
        fill: theme.palette.background.paper,

        '&.DateInput_fangStroke': {
          stroke: theme.palette.border,
        },
      },

      '& .DayPickerKeyboardShortcuts_panel': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.border,
      },
    },

    '& .DateInput .DateInput_input': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },

    '& .CalendarDay': {
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
      color: theme.palette.text.primary,

      '&:hover': {
        background: theme.palette.background.default,
      },
    },
  },
})
