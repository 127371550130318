export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonNextCall: {
    marginLeft: 15,
  },
  iconCall: {
    marginRight: 8,
  },
  buttonCall: {
    marginLeft: 15,
  },
  grow: {
    flex: 1,
    minHeight: 500,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
    cursor: 'pointer',
  },
  statusChip: {
    color: '#FFFFFF',
    fontWeight: 500,
    minWidth: 150,
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconButton: {
    padding: 0,
  },
  iconAlert: {
    marginRight: theme.spacing(1),
  },
  filtersWrapper: {
    marginTop: theme.spacing(2),
  },
  filtersTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  filterIcon: {
    marginRight: theme.spacing(1 / 2),
  },
  showHideButton: {
    padding: 0,
    float: 'right',
  },
  formControlLabel: {
    minWidth: 292,
    maxHeight: 500,
  },
  menuList: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  popper: {
    zIndex: 5,
    left: -21,
  },
  showHideColumns: {
    width: '1%',
    padding: 0,
    margin: 0,
  },
})
