export default (theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    // minWidth: 800,
  },
  buttonForm: {
    justifyContent: 'space-between',
  },
  dialogContent: {
    height: 500,
    padding: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.divider,
    height: 2,
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  uploadIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxHeight: 500,
    },
  },
})
