import React, { useEffect, useState } from 'react'

import {
  Dialog,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  Table,
  TableRow,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  Button,
  DialogActions,
  Grid,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useMobileScreen } from '~/helpers/useMediaQuery'

import SearchInput from '~/components/SearchInput'
import ConfirmDialog from '~/components/ConfirmDialog'
import OverlayLoading from '~/components/OverlayLoading'
import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'
import { Creators as CallActions } from '~/store/ducks/call'
import { Creators as CallsActions } from '~/store/ducks/calls'

import styles from './styles'

const callSelector = ({ call }) => call.data
const callDuplicateSelector = ({ call }) => call.duplicateList

const useStyles = makeStyles(styles)

const CallDuplicateDialog = ({ open, onClose, onCallDialogClose }) => {
  const isMobileScreen = useMobileScreen()

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [callRefId, setCallRefId] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const call = useSelector(callSelector)
  const { data, loading } = useSelector(callDuplicateSelector)

  useEffect(() => {
    if (open && call.id) {
      dispatch(
        CallActions.getCallDuplicateListRequest(
          call.subject_id,
          call.status_id,
          call.id,
        ),
      )
    }
  }, [call.subject_id, call.status_id, call.id, open])

  const handleMarkAsDuplicate = (id) => {
    setOpenConfirmDialog(true)
    setCallRefId(id)
  }

  const handleRemoveCancel = () => {
    setOpenConfirmDialog(false)
    setCallRefId(null)
  }

  const handleRemoveAccept = () => {
    dispatch(
      CallActions.markAsDuplicateRequest(call.id, callRefId, () => {
        dispatch(CallsActions.getCallsRequest())
        onClose()
        onCallDialogClose()
      }),
    )
    setOpenConfirmDialog(false)
    setCallRefId(null)
  }

  const handleCancelMarkAsDuplicate = () => {
    onClose()
  }

  const handleSearchChange = (value) => {
    dispatch(
      CallActions.setDuplicateListSearch(
        value,
        call.subject_id,
        call.status_id,
        call.id,
      ),
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <Typography variant="h3">Selecione chamado de referência</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      {loading && <OverlayLoading />}
      {!loading && data.length > 0 && (
        <div>
          <Grid container spacing={2} className={classes.grid}>
            <Grid xs={12} md={6}>
              <SearchInput
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise por ID, assunto, solicitante e responsável..."
              />
            </Grid>
          </Grid>
          <DialogContent className={classes.dialogContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Departamento</TableCell>
                  <TableCell>Assunto</TableCell>
                  <TableCell>Solicitante</TableCell>
                  <TableCell>Responsável</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.id}
                    onClick={() => handleMarkAsDuplicate(item.id)}
                    hover
                    className={classes.tableRow}
                  >
                    <TableCell>{item.id} </TableCell>
                    <TableCell>{item.department} </TableCell>
                    <TableCell>{item.subject} </TableCell>
                    <TableCell>{item.requester_name} </TableCell>
                    <TableCell>{item.responsible_name} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
        </div>
      )}
      {!loading && data.length === 0 && (
        <Grid item xs={12} className={classes.emptyWrapper}>
          <Portlet className={classes.grow}>
            <EmptyList message="Não há dados para serem visualizadas" />
          </Portlet>
        </Grid>
      )}
      <DialogActions className={classes.buttonForm}>
        <Button
          variant="contained"
          onClick={handleCancelMarkAsDuplicate}
          className={classes.cancelButton}
        >
          Cancelar
        </Button>
      </DialogActions>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente marcar este chamado como duplicado?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
        variant="warning"
      />
    </Dialog>
  )
}

CallDuplicateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCallDialogClose: PropTypes.func.isRequired,
}

export default CallDuplicateDialog
