export default () => ({
  paper: {
    overflow: 'auto',
    maxHeight: '100%',
    margin: 16,
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#FCFCFC',
    },
  },
})
