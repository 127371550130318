import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'

import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as DealershipsActions } from '~/store/ducks/dealerships'
import { Creators as DealershipActions } from '~/store/ducks/dealership'

import Portlet from '~/components/Portlet'
import ConfirmDialog from '~/components/ConfirmDialog'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'
import CustomIcon from '~/components/CustomIcon'

import ShowHideColumns from '~/components/ShowHideColumns'
import DealershipsDialog from './components/DealershipsDialog'

import styles from './styles'

const useStyles = makeStyles(styles)

const delearshipsSelector = ({ dealerships }) => dealerships

export default function DealershipsPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, options, loading, showHideColumns } =
    useSelector(delearshipsSelector)
  const { sort, direction } = options
  const [openDealershipDialog, setOpenDealershipDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [dealershipId, setDealershipId] = useState(null)

  const optionsShowHideColumn = [
    { name: 'name', label: 'Nome', disabled: true },
    { name: 'address', label: 'Endereço' },
    { name: 'city', label: 'Cidade' },
    { name: 'automaker', label: 'Marca' },
    { name: 'group', label: 'Grupo' },
  ]

  useEffect(() => {
    dispatch(DealershipsActions.getDealershipsRequest())
  }, [])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }
    dispatch(DealershipsActions.setDealershipsSort(newSort, sortDirection))
  }

  const handleEditDealership = (id) => {
    setDealershipId(id)
    setOpenDealershipDialog(true)
  }

  const handleRemoveDealership = (id) => {
    setDealershipId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveDealershipCancel = () => {
    setDealershipId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveDealershipAccept = () => {
    dispatch(
      DealershipActions.removeDealershipRequest(dealershipId, () => {
        setDealershipId(null)
        setOpenConfirmDialog(false)
        dispatch(DealershipsActions.getDealershipsRequest())
      }),
    )
  }

  const handleAddDealership = () => {
    setOpenDealershipDialog(true)
  }
  const handleClose = () => {
    setOpenDealershipDialog(false)
    setDealershipId(null)
  }

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      DealershipsActions.setChangeShowHideColumnsDealerships(newColumnsTable),
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.gridContainerButton}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleAddDealership}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Concessionária
            </Button>
          </Grid>
        </Grid>
        {/* <QrReader delay={300}  /> */}

        <Portlet className={classes.grow}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há concessionárias para serem visualizadas" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <Table stickyHeader size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sort === 'name'}
                      onClick={() => handleSortChange('name')}
                      direction={direction}
                    >
                      Nome
                    </TableSortLabel>
                  </TableCell>
                  {showHideColumns.address && (
                    <TableCell align="left">
                      <TableSortLabel
                        active={sort === 'address'}
                        onClick={() => handleSortChange('address')}
                        direction={direction}
                      >
                        Endereço
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumns.city && (
                    <TableCell align="left">
                      <TableSortLabel
                        active={sort === 'city'}
                        onClick={() => handleSortChange('city')}
                        direction={direction}
                      >
                        Cidade
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumns.automaker && (
                    <TableCell align="left">Marca</TableCell>
                  )}
                  {showHideColumns.group && (
                    <TableCell align="left">Grupo</TableCell>
                  )}
                  <TableCell>
                    <ShowHideColumns
                      showHideColumns={showHideColumns}
                      optionsShowHideColumn={optionsShowHideColumn}
                      onChangeShowHideColumn={handleSetChangeShowHideColumn}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => {
                  return (
                    <TableRow hover key={item.id} className={classes.tableRow}>
                      <TableCell align="left">{item.name || '-'}</TableCell>
                      {showHideColumns.address && (
                        <TableCell align="left">
                          {item.address || '-'}
                        </TableCell>
                      )}
                      {showHideColumns.city && (
                        <TableCell align="left">{item.city || '-'}</TableCell>
                      )}
                      {showHideColumns.automaker && (
                        <TableCell align="left">
                          {item.automaker.name || '-'}
                        </TableCell>
                      )}
                      {showHideColumns.group && (
                        <TableCell align="left">
                          {item.group.name || '-'}
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <div className={classes.tableCellActions}>
                          <IconButton
                            className={classes.editIcon}
                            onClick={() => handleEditDealership(item.id)}
                          >
                            <CustomIcon pathComponent={mdiPencil} />
                          </IconButton>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleRemoveDealership(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Portlet>
      </div>

      <DealershipsDialog
        id={dealershipId}
        open={openDealershipDialog}
        onClose={handleClose}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir esta concessionária?"
        onCancel={handleRemoveDealershipCancel}
        onAccept={handleRemoveDealershipAccept}
      />
    </>
  )
}
