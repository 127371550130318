import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSuperiorRequest: ['userId'],
  getSuperiorSuccess: ['data'],
  getSuperiorFailure: [],
  updateSuperiorRequest: ['data', 'callback'],
  updateSuperiorFinish: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const getSuperiorRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSuperiorSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getSuperiorFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateSuperiorRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateSuperiorFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUPERIOR_REQUEST]: getSuperiorRequest,
  [Types.GET_SUPERIOR_SUCCESS]: getSuperiorSuccess,
  [Types.GET_SUPERIOR_FAILURE]: getSuperiorFailure,
  [Types.UPDATE_SUPERIOR_REQUEST]: updateSuperiorRequest,
  [Types.UPDATE_SUPERIOR_FINISH]: updateSuperiorFinish,
})
