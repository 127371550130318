export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    border: 'none',
    borderRadius: 4,
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.common.neutral,
  },
})
