export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    width: '100%',
  },
  newsBoardContainer: {
    height: '100%',
  },
  questionGrow: {
    flex: 1,
    width: '100%',
  },
  grow: {
    display: 'flex',
    height: 200,
    flex: 1,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  birthdaysPortlet: {
    width: '100%',
  },
  birthdaysTitle: {
    display: 'flex',
    textAlign: 'center',
    padding: '14px 0px',
  },
  titleWrapper: {
    paddingLeft: theme.spacing(2),
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 12,
    },
  },
  tableCell: {
    padding: '6px 0px 6px 16px',
  },
  postWrapper: {
    marginBottom: 24,
  },
  image: {
    height: 250,
    [theme.breakpoints.up('md')]: {
      height: 300,
    },
    [theme.breakpoints.up('lg')]: {
      height: 400,
    },
    cursor: 'pointer',
  },
  emptyPost: {
    width: '100%',
  },
  questionContainer: {
    padding: 16,
  },
  questionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
  },
  linkWrapper: {
    display: 'flex',
    textDecoration: 'underline',
  },
  linkIcon: {
    color: theme.palette.primary.main,
    width: 20,
    height: 20,
    marginLeft: 4,
  },
  birthdaysWrapper: {
    position: 'sticky',
    top: 80,
  },
  questionsQuizContainer: {
    marginTop: theme.spacing(2),
  },
  imgLogoContainer: {
    paddingBottom: theme.spacing(2),
  },
  tabsPortlet: {
    border: 'none',
    filter: 'drop-shadow(0px 4px 20px rgba(18, 28, 42, 0.08))',
  },
  communicationWrapper: {
    position: 'sticky',
    top: 80,
    marginBottom: theme.spacing(2),
  },
  textButton: {
    textDecoration: 'underline',
    fontWeight: 500,
    padding: '12px 0px',
  },
  buttonContainer: {
    margin: 0,
    padding: 0,
  },
})
