// Palette
import palette from '../palette'

export default {
  containedPrimary: {
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      opacity: '0.9',
    },
  },
  contained: {
    color: palette.text.main,
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.primary.main}`,
    '&:hover': {
      color: palette.text.main,
      backgroundColor: 'palette.button.containedHover',
      border: `1px solid ${palette.primary.main}`,
    },
  },
}
