export default (theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
  },
  buttonForm: {
    justifyContent: 'space-between',
  },
  dialogContent: {
    height: 200,
    padding: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.divider,
    height: 2,
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
})
