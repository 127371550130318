/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress } from '@material-ui/core'

function CustomAutocomplete({
  loading,
  label,
  options,
  name,
  value,
  onChange,
  className,
  onBlur,
  onInputChange,
  placeholder,
  error,
  helperText,
  InputProps,
  inputRef,
  ...rest
}) {
  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    if (value) {
      setInputValue(
        options
          .filter((option) => option.value === value)
          .map((option) => option.label)
          .join(''),
      )
    }
  }, [value, setInputValue, options])

  return (
    <Autocomplete
      ref={inputRef}
      options={options}
      autoComplete
      className={className}
      filterSelectedOptions
      noOptionsText="Sem opções.."
      value={value}
      onChange={(event, newValue) => {
        onChange(event, newValue)
      }}
      disabled={loading}
      inputValue={inputValue}
      getOptionSelected={(option, val) => {
        return option.value === val
      }}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue)
        onInputChange(event, newInputValue, reason)
      }}
      getOptionLabel={(opt) => {
        if (opt && opt.label) {
          return opt.label
        }
        if (opt && options.length) {
          return options
            .filter((option) => option.value === value)
            .map((option) => option.label)
            .join('')
        }
        return ''
      }}
      {...rest}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            inputRef={inputRef}
            fullWidth
            name={name}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  )
}

CustomAutocomplete.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
  ]),
  InputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
}

CustomAutocomplete.defaultProps = {
  label: 'label',
  name: '',
  loading: false,
  options: [],
  value: '',
  placeholder: '',
  className: '',
  error: false,
  helperText: '',
  onChange: () => {},
  onBlur: () => {},
  onInputChange: () => {},
  InputProps: {},
  inputRef: null,
}

export default CustomAutocomplete
