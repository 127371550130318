export default (theme) => ({
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 24px',
    marginBottom: 24,
  },
  textButton: {
    textDecoration: 'underline',
    fontWeight: 500,
    padding: '12px 0px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  divider: {
    width: '100%',
  },

  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#3A3B3F',
  },
  cardContent: {
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 200,
    marginBottom: theme.spacing(2),
  },
  communicationTitleWrapper: {
    width: '100%',
    background: ' #1B1464',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    padding: '14px 0px',
    height: '44px',
  },
  titleWrapper: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  emptyPost: {
    padding: '0 24px',
  },

  grow: {
    display: 'flex',
    height: 200,
    flex: 1,
    position: 'relative',
  },

  grid: {
    padding: theme.spacing(3),
  },
  cardHeader: {
    padding: '16px',
    marginBottom: '16px',
    background: 'white',
    borderRadius: '4px',
  },
  emptyWrapper: {
    display: 'flex',
    height: '85vh',
    flex: 1,
    position: 'relative',
    marginTop: theme.spacing(2),
  },
})
