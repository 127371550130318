import React from 'react'

import {
  makeStyles,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { useSelector } from 'react-redux'
import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'

import styles from './styles'

const userVwAttendantsSelector = ({ user }) => user.vwAttendants

const useStyles = makeStyles(styles)

export default function SubjectUserFormTab() {
  const classes = useStyles()
  const { data } = useSelector(userVwAttendantsSelector)

  return (
    <div className={classes.root}>
      {data?.length === 0 && (
        <EmptyList message="Não há assuntos para serem visualizados" />
      )}
      {data?.length > 0 && (
        <div className={classes.detailsWrapper}>
          <div className={classes.tableWrapper}>
            <Portlet>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Departamento</TableCell>
                    <TableCell align="right">Assunto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow className={classes.tableRow} key={item.id}>
                      <TableCell>{item.department_name || '-'}</TableCell>
                      <TableCell align="right">{item.subject || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Portlet>
          </div>
        </div>
      )}
    </div>
  )
}
