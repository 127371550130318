export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    overflow: 'auto',
  },
  paperTable: {
    height: '100%',
    overflow: 'auto',
    // padding: theme.spacing(2),
  },
  cardMetrics: {},
  card: {
    padding: theme.spacing(2),
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#FCFCFC',
    },
  },
  avatarIcon: {
    backgroundColor: '#fafafa',
    color: theme.palette.primary.main,
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },
})
