import React, { useEffect } from 'react'

import {
  Grid,
  Button,
  Typography,
  MenuItem,
  TextField,
  Avatar,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { useForm, Controller } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import history from '~/helpers/history'

import RhfTextField from '~/components/FormComponents/RhfTextField'

import moment from 'moment'
import { Creators as SessionActions } from '~/store/ducks/session'
import { Creators as ProfileActions } from '~/store/ducks/profile'
import OverlayLoading from '~/components/OverlayLoading'
import styles from './styles'

const SessionActionsSelector = ({ session }) => session

const useStyles = makeStyles(styles)

export default function DataFormTab({ dataUserProfile }) {
  const dispatch = useDispatch()
  const date = moment(new Date())
  const dtPrimeiroContrato = moment(dataUserProfile.dt_primeiro_contrato)
  const difference = moment.duration(date.diff(dtPrimeiroContrato))
  const companyYears = difference.asYears().toFixed(0)
  const companyMonths = Number(difference.asMonths().toFixed(0))

  const { user, loading } = useSelector(SessionActionsSelector)

  const userDealerships = user.dealerships

  const { reset } = useForm({
    defaultValues: dataUserProfile,
  })

  useEffect(() => {
    dispatch(
      ProfileActions.getUserProfileRequest(user.id, () =>
        reset(dataUserProfile),
      ),
    )
  }, [])

  // useEffect(() => {
  // }, [dataUserProfile])

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: dataUserProfile,
  })

  const watchPassword = watch('password')
  const hasPassword = !!watchPassword && watchPassword !== ''
  const maritalStatus = [
    { id: 1, name: 'Casado(a)' },
    { id: 2, name: 'Solteiro(a)' },
    { id: 3, name: 'Divorciado(a)' },
    { id: 4, name: 'Viuvo(a)' },
    { id: 5, name: 'União Estável' },
    { id: 6, name: 'Concubinato' },
  ]
  const classes = useStyles()

  function handleSubmitFormProfilePassword(data) {
    dispatch(
      SessionActions.updateProfileRequest(data, user.id, () => {
        history.push('/profile')
      }),
    )
  }

  function handleSubmitFormProfileUser(data) {
    const formattedData = {
      ...data,
      dt_primeiro_contrato:
        moment(data.dt_primeiro_contrato).format('YYYY-MM-DD') || null,
      dt_birthday: moment(data.dt_birthday).format('YYYY-MM-DD') || null,
      dealerships_id: data.dealerships?.map((d) => d.id),
      roles_id: data.roles_id?.map((r) => r.id),
    }

    if (!hasPassword) {
      delete formattedData.password
      delete formattedData.password_confirmation
    }
    dispatch(
      ProfileActions.updateUserProfileRequest(user.id, formattedData, () => {
        history.push('/profile')
      }),
    )
  }

  return (
    <>
      {loading && <OverlayLoading />}
      {!loading && (
        <Grid container xs={12} className={classes.root}>
          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.birthdayCard}>
                <Avatar
                  className={classes.avatar}
                  alt={user.name}
                  src={user.url_image}
                />
                <div>
                  <Typography variant="h5">{user.name}</Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 400 }}
                  >
                    {user.email}
                  </Typography>{' '}
                  <p>
                    <Typography variant="body2" style={{ color: '#3A3B3F' }}>
                      {user.function_name}
                    </Typography>{' '}
                  </p>
                  {dataUserProfile.dt_primeiro_contrato &&
                    companyMonths === 1 && (
                      <p>
                        <Typography
                          variant="body2"
                          style={{ color: '#3A3B3F' }}
                        >
                          1 mês de Grupo Nissul Gala
                        </Typography>
                      </p>
                    )}
                  {dataUserProfile.dt_primeiro_contrato &&
                    companyMonths > 0 &&
                    companyMonths < 12 &&
                    companyMonths !== 1 && (
                      <p>
                        <Typography
                          variant="body2"
                          style={{ color: '#3A3B3F' }}
                        >
                          {companyMonths} meses de Grupo Nissul Gala
                        </Typography>
                      </p>
                    )}
                  {dataUserProfile.dt_primeiro_contrato &&
                    companyYears === '1' && (
                      <p>
                        <Typography
                          variant="body2"
                          style={{ color: '#3A3B3F' }}
                        >
                          1 ano de Grupo Nissul Gala
                        </Typography>
                      </p>
                    )}
                  {dataUserProfile.dt_primeiro_contrato && companyYears > 1 && (
                    <p>
                      <Typography variant="body2" style={{ color: '#3A3B3F' }}>
                        {companyYears} anos de Grupo Nissul Gala
                      </Typography>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <form
              onSubmit={handleSubmit(handleSubmitFormProfileUser)}
              id="form-profile-user"
            >
              <Grid container spacing={2} className={classes.firstContainer}>
                <Grid item xs={8}>
                  <RhfTextField
                    fullWidth
                    control={control}
                    name="address"
                    label="Endereço"
                    helperText={errors && errors.name && 'Campo obrigatório'}
                    error={errors && !!errors.name}
                  />
                </Grid>{' '}
                {/* <Grid item xs={4}>
                      <RhfTextField
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        control={control}
                        name="cod_nbs"
                        label="Código NBS"
                        helperText={
                          errors && errors.email && 'Campo obrigatório'
                        }
                        error={errors && !!errors.email}
                        disabled
                      />
                    </Grid> */}
                <Grid item xs={4}>
                  <RhfTextField
                    fullWidth
                    type="text"
                    control={control}
                    helperText={
                      errors && errors.password && 'Campo obrigatório'
                    }
                    error={errors && !!errors.password}
                    name="phone"
                    label="Telefone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="estado_civil"
                    defaultValue={user.estado_civil}
                    render={({ field: { onChange, value, ...field } }) => (
                      <TextField
                        select
                        label="Selecione o estado civil *"
                        variant="outlined"
                        fullWidth
                        onChange={onChange}
                        value={value || ''}
                        {...field}
                        error={!!errors.estado_civil}
                        helperText={
                          !!errors.estado_civil && 'Campo obrigatório'
                        }
                      >
                        {maritalStatus.map((marital) => (
                          <MenuItem value={marital.id} key={marital.id}>
                            {marital.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RhfTextField
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    control={control}
                    helperText={
                      errors && errors.password && 'Campo obrigatório'
                    }
                    error={errors && !!errors.password}
                    name="cpf"
                    label="CPF"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <RhfTextField
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    control={control}
                    helperText={
                      errors && errors.password && 'Campo obrigatório'
                    }
                    error={errors && !!errors.password}
                    name="rg"
                    label="RG"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="dt_birthday"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value, ref, ...field } }) => (
                      <MuiPickersUtilsProvider
                        locale="pt-br"
                        utils={MomentUtils}
                      >
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          fullWidth
                          value={value || null}
                          label="Data de nascimento"
                          disabled
                          format="DD/MM/YYYY"
                          invalidDateMessage="Data inválida"
                          inputRef={ref}
                          onChange={(dateValue) => onChange(dateValue)}
                          {...field}
                          error={!!errors.dt_birthday}
                          helperText={
                            !!errors.dt_birthday && 'Campo obrigatório'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="dt_primeiro_contrato"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value, ref, ...field } }) => (
                      <MuiPickersUtilsProvider
                        locale="pt-br"
                        utils={MomentUtils}
                      >
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          fullWidth
                          value={value || null}
                          label="Data do primeiro vínculo"
                          disabled
                          format="DD/MM/YYYY"
                          invalidDateMessage="Data inválida"
                          inputRef={ref}
                          onChange={(dateValue) => dateValue}
                          {...field}
                          error={!!errors.dt_primeiro_contrato}
                          helperText={
                            !!errors.dt_primeiro_contrato && 'Campo obrigatório'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Accordion style={{ margin: 0, padding: 4 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Concessionárias
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <Controller
                          name="dealerships"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value, ...rest } }) => (
                            <Autocomplete
                              fullWidth
                              disabled
                              multiple
                              value={value || []}
                              onChange={(event, v) => {
                                onChange(v)
                              }}
                              disableCloseOnSelect
                              filterSelectedOptions
                              options={userDealerships}
                              {...rest}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue?.map((option, index) => (
                                  <Chip
                                    label={option.name}
                                    style={{
                                      backgroundColor: '#ED1E79',
                                      color: '#FFFFFF',
                                      // eslint-disable-next-line no-nested-ternary
                                    }}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.dealerships_id}
                                  helperText={
                                    !!errors.dealerships_id &&
                                    'Campo obrigatório'
                                  }
                                  label="Concessionárias"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid container item justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      form="form-profile-user"
                      // onClick={() => handleSubmitFormProfileUser()}
                      size="large"
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid item container justifyContent="space-between" spacing={2}>
              <Grid item xs={12} className={classes.accordionPassword}>
                <form
                  id="form-profile-password"
                  onSubmit={handleSubmit(handleSubmitFormProfilePassword)}
                >
                  <Accordion style={{ marginTop: 0, padding: 4 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Alterar Senha
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={4}>
                          <RhfTextField
                            fullWidth
                            type="password"
                            control={control}
                            helperText={
                              errors && errors.password && 'Campo obrigatório'
                            }
                            error={errors && !!errors.password}
                            name="password"
                            label="Informe a nova senha"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <RhfTextField
                            fullWidth
                            type="password"
                            control={control}
                            name="password_confirmation"
                            rules={{
                              required: hasPassword,
                              validate: (value) => {
                                if (!hasPassword) {
                                  return true
                                }
                                return (
                                  (watchPassword && value === watchPassword) ||
                                  'As duas senhas devem ser iguais'
                                )
                              },
                            }}
                            label="Confirme a nova senha"
                            helperText={
                              (errors &&
                                errors.password_confirmation?.message) ||
                              (errors &&
                                errors.password_confirmation?.type ===
                                  'required' &&
                                'Campo obrigatório')
                            }
                            error={errors && !!errors.password_confirmation}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            form="form-profile-password"
                            size="large"
                          >
                            Alterar Senha
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

DataFormTab.propTypes = {
  dataUserProfile: PropTypes.arrayOf(
    PropTypes.shape({
      dt_primeiro_contrato: PropTypes.string,
    }),
  ).isRequired,
}
