import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import { find } from 'lodash'
import moment from 'moment'
import configureApi from '~/helpers/api'

import {
  Types as UsersRamalTypes,
  Creators as UsersRamalActions,
} from '../ducks/usersRamal'

const optionsSelector = ({ usersRamal }) => usersRamal.options

function* getUsersRamal() {
  const options = yield select(optionsSelector)
  const getFilter = (property) => {
    const filterValue = find(options.filters, { name: property })
    return filterValue.value
  }

  try {
    const api = configureApi()
    const month = String(moment(getFilter('month')).month() + 1)
    const formattedMonth = month.padStart(2, '0')
    const monthNumber = Number(formattedMonth)
    const search = []
    if (options.search) {
      search.push(`ramal_next_ip,${options.search}`)
      search.push(`name,${options.search}`)
    }
    let formattedParams = {
      pagination: 1,
      page: options.page + 1,
      limit: options.limit,
      order: `${options.sort},${options.direction}`,
      search,
      'where[function_dealership_id]': getFilter('dealership_id'),
      'where[function_department_id]': getFilter('department_id'),
      'where[gender]': getFilter('gender'),
      'isnotnull[ramal_next_ip]': '',
    }

    if (getFilter('active')) {
      formattedParams['where[active]'] = getFilter('active')
    }

    if (monthNumber >= 1 && monthNumber <= 12) {
      formattedParams = {
        ...formattedParams,
        month: formattedMonth,
      }
    }

    const { data } = yield call(api.get, 'users', {
      params: formattedParams,
    })
    yield put(UsersRamalActions.getUsersRamalSuccess(data.data, data.total))
  } catch (e) {
    yield put(UsersRamalActions.getUsersRamalFailure())
  }
}

function* reloadUsersRamal() {
  yield put(UsersRamalActions.getUsersRamalRequest())
}

function* getUsersRamalFilterDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(UsersRamalActions.getUsersRamalFilterDealershipsSuccess(data))
  } catch (e) {
    yield put(UsersRamalActions.getUsersRamalFilterDealershipsFailure())
  }
}

function* getUsersRamalFilterDepartments() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'departments')
    yield put(
      UsersRamalActions.getUsersRamalFilterDepartmentsSuccess(data.data),
    )
  } catch (e) {
    yield put(UsersRamalActions.getUsersRamalFilterDepartmentsFailure())
  }
}

export default function* () {
  yield takeLatest(UsersRamalTypes.GET_USERS_RAMAL_REQUEST, getUsersRamal)
  yield takeLatest(UsersRamalTypes.SET_USERS_RAMAL_SORT, reloadUsersRamal)
  yield takeLatest(UsersRamalTypes.SET_USERS_RAMAL_PAGE, reloadUsersRamal)
  yield debounce(1000, UsersRamalTypes.SET_USERS_RAMAL_SEARCH, reloadUsersRamal)
  yield takeLatest(
    UsersRamalTypes.SET_USERS_RAMAL_PROPERTY_FILTER,
    reloadUsersRamal,
  )
  yield takeLatest(
    UsersRamalTypes.GET_USERS_RAMAL_FILTER_DEALERSHIPS_REQUEST,
    getUsersRamalFilterDealerships,
  )
  yield takeLatest(
    UsersRamalTypes.GET_USERS_RAMAL_FILTER_DEPARTMENTS_REQUEST,
    getUsersRamalFilterDepartments,
  )
}
