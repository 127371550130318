import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as StatusListTypes,
  Creators as StatusListActions,
} from '../ducks/statusList'

const optionsSelector = ({ statusList }) => statusList.options

function* getStatus() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'status', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
      },
    })

    yield put(StatusListActions.getStatusListSuccess(data.data, data.total))
  } catch (e) {
    yield put(StatusListActions.getStatusListFailure())
  }
}

function* reloadStatus() {
  yield put(StatusListActions.getStatusListRequest())
}

export default function* () {
  yield takeLatest(StatusListTypes.GET_STATUS_LIST_REQUEST, getStatus)
  yield takeLatest(StatusListTypes.SET_STATUS_SORT, reloadStatus)
  yield takeLatest(StatusListTypes.SET_STATUS_PAGE, reloadStatus)
}
