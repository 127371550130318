import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { Creators as AnalitycalActions } from '~/store/ducks/analitycal'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import { formatPrice } from '~/helpers/masks'
import ShowHideColumns from '~/components/ShowHideColumns'
import styles from './styles'

const analitycalSelector = ({ analitycal }) => analitycal

const useStyles = makeStyles(styles)

const AnalitycalDialog = ({ open, onClose, segment }) => {
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()
  const dispatch = useDispatch()
  const { dataDetails, showHideColumnsDialog } = useSelector(analitycalSelector)

  useEffect(() => {
    if (open) {
      dispatch(AnalitycalActions.getAnalitycalDetailsRequest(segment))
    }
  }, [segment])

  const getColorTableCellProfit = (item) => {
    return item.profit < 0 ? 'red' : 'black'
  }

  const getColorTableCellProfitPorcentage = (item) => {
    return item.profit_percentage < 0 ? 'red' : 'black'
  }

  const optionsShowHideColumnDialog = [
    { name: 'family', label: 'Família' },
    { name: 'amount', label: 'Quantidade' },
    { name: 'billing', label: 'Faturamento' },
    { name: 'cost', label: 'Custo' },
    { name: 'liquidValue', label: 'Lucro' },
    { name: 'porcentage', label: '%' },
  ]

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumnsDialog,
      [name]: value,
    }

    dispatch(
      AnalitycalActions.setChangeShowHideColumnsAnalitycalDialog(
        newColumnsTable,
      ),
    )
  }

  return (
    <Dialog
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Veículos faturados - {segment}</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.dialogContent}>
        <Table stickyHeader size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {showHideColumnsDialog.family && (
                <TableCell align="left">Família</TableCell>
              )}
              {showHideColumnsDialog.amount && (
                <TableCell align="left">Quantidade</TableCell>
              )}
              {showHideColumnsDialog.billing && (
                <TableCell align="left">Faturamento</TableCell>
              )}
              {showHideColumnsDialog.cost && (
                <TableCell align="center">Custo</TableCell>
              )}
              {showHideColumnsDialog.liquidValue && (
                <TableCell align="center">Lucro </TableCell>
              )}
              {showHideColumnsDialog.porcentage && (
                <TableCell align="center">%</TableCell>
              )}
              <TableCell>
                <ShowHideColumns
                  showHideColumns={showHideColumnsDialog}
                  optionsShowHideColumn={optionsShowHideColumnDialog}
                  onChangeShowHideColumn={handleSetChangeShowHideColumn}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDetails.map((item) => (
              <TableRow hover className={classes.tableRow} key={item.family}>
                {showHideColumnsDialog.family && (
                  <TableCell align="left">{item.family || '-'}</TableCell>
                )}
                {showHideColumnsDialog.amount && (
                  <TableCell align="center">{item.amount || '-'}</TableCell>
                )}
                {showHideColumnsDialog.billing && (
                  <TableCell align="left">
                    {formatPrice(Number(item.revenues))}
                  </TableCell>
                )}
                {showHideColumnsDialog.cost && (
                  <TableCell align="center">
                    {formatPrice(Number(item.cost))}
                  </TableCell>
                )}
                {showHideColumnsDialog.liquidValue && (
                  <TableCell
                    style={{
                      color: getColorTableCellProfit(item),
                    }}
                    align="center"
                  >
                    {formatPrice(Number(item.profit)) || '-'}
                  </TableCell>
                )}
                {showHideColumnsDialog.porcentage && (
                  <TableCell
                    align="center"
                    style={{
                      color: getColorTableCellProfitPorcentage(item),
                    }}
                  >
                    {`${parseFloat(item.profit_percentage || 0).toFixed(2)}%` ||
                      '-'}
                  </TableCell>
                )}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AnalitycalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  segment: PropTypes.string,
}

AnalitycalDialog.defaultProps = {
  segment: null,
}

export default AnalitycalDialog
