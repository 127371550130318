import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core'

// import { useDispatch, useSelector } from 'react-redux'

// import { Creators as IpcActions } from '~/store/ducks/ipc'

// import ListFooter from '~/components/ListFooter'

import styles from './styles'

// const IpcSelector = ({ ipc }) => ipc

const useStyles = makeStyles(styles)
function CallsTab({ data }) {
  // const dispatch = useDispatch()
  // const { options } = useSelector(IpcSelector)
  const classes = useStyles()

  // const handleChangeRowsPerPage = (ev) => {
  //   const {
  //     target: { value },
  //   } = ev
  //   dispatch(IpcActions.setIslandCallsPage(0, value))
  // }

  // const handleChangePage = (_, page) => {
  //   dispatch(IpcActions.setIslandCallsPage(page, options.limit))
  // }
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item style={{ margin: 8, width: '100%' }}>
          {data?.length > 0 && (
            <Grid item lg={12} className={classes.containerTable}>
              <Paper className={classes.paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ramal</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Duração</TableCell>
                      <TableCell align="center">Quantidade</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item) => {
                      return (
                        <TableRow
                          className={classes.tableRow}
                          key={item.callid}
                        >
                          <TableCell>{item.numero || '-'}</TableCell>
                          <TableCell>{item.nome || '-'}</TableCell>
                          <TableCell>{item.duracao || '-'}</TableCell>
                          <TableCell align="center">
                            {item.quantidade || '-'}
                          </TableCell>
                          <TableCell>{item.valor}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          )}
          {/* <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          /> */}
        </Grid>
      </Grid>
    </>
  )
}

CallsTab.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default CallsTab
