/* eslint-disable react/no-unused-state */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Badge,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import CustomIcon from '~/components/CustomIcon'
import { CloseMenu, MenuIcon } from '~/components/CustomIcon/Icons'
import { useMobileScreen } from '~/helpers/useMediaQuery'

import AccountButton from './components/AccountButton'
import NotificationsButton from './components/NotificationsButton'

import MonthCountdown from '../MonthCountdown'
import styles from './styles'

const useStyles = makeStyles(styles)

const notificationsUnreadSelector = ({ notifications }) => notifications.data

function Topbar({
  className,
  title,
  isSidebarOpen,
  onToggleSidebar,
  hasMonthCountdown,
}) {
  const classes = useStyles({ isSidebarOpen })
  const isMobileScreen = useMobileScreen()
  const rootClassName = classNames(classes.root, className)
  const { unread } = useSelector(notificationsUnreadSelector)

  return (
    <>
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            onClick={onToggleSidebar}
            variant="text"
          >
            {isSidebarOpen ? (
              <CustomIcon pathComponent={CloseMenu} />
            ) : (
              <CustomIcon pathComponent={MenuIcon} />
            )}
          </IconButton>
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        </Toolbar>
        {hasMonthCountdown && !isMobileScreen && <MonthCountdown />}
        <Badge color="secondary" badgeContent={unread} invisible={unread === 0}>
          <NotificationsButton />
        </Badge>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <AccountButton />
      </div>
    </>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string,
  hasMonthCountdown: PropTypes.bool,
}

Topbar.defaultProps = {
  className: '',
  isSidebarOpen: false,
  title: '',
  onToggleSidebar: () => {},
  hasMonthCountdown: false,
}

export default Topbar
