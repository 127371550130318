import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import { Types as BlipTypes, Creators as BlipActions } from '../ducks/blip'

function* reloadBlip() {
  yield put(BlipActions.getAttendantsRequest())
}

function* getOpenTickets() {
  try {
    const api = configureApi()
    const data = yield call(api.get, 'crm/open-tickets')
    yield put(BlipActions.getOpenTicketsSuccess(data))
  } catch (e) {
    yield put(BlipActions.getOpenTicketsFailure())
  }
}

function* getWaitingTickets() {
  try {
    const api = configureApi()
    const data = yield call(api.get, 'crm/waiting-tickets')
    yield put(BlipActions.getWaitingTicketsSuccess(data))
  } catch (e) {
    yield put(BlipActions.getWaitingTicketsFailure())
  }
}

const optionsSelector = ({ blip }) => blip.options

function* getAttendants() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const data = yield call(api.get, 'crm/attendants', {
      params: {
        dealership_id: options.dealershipId,
        department_id: options.departmentId,
      },
    })
    yield put(BlipActions.getAttendantsSuccess(data))
  } catch (e) {
    yield put(BlipActions.getAttendantsFailure())
  }
}

function* getTicketsPerHour() {
  try {
    const api = configureApi()
    const data = yield call(api.get, 'crm/tickets-per-hour')
    yield put(BlipActions.getTicketsPerHourSuccess(data))
  } catch (e) {
    yield put(BlipActions.getTicketsPerHourFailure())
  }
}
function* getTicketMetrics() {
  try {
    const api = configureApi()
    const data = yield call(api.get, 'crm/ticket-metrics')
    yield put(BlipActions.getTicketMetricsSuccess(data))
  } catch (e) {
    yield put(BlipActions.getTicketMetricsFailure())
  }
}

function* getDealershipsBlip() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        limit: 99999,
        order: 'name,asc',
      },
    })
    yield put(BlipActions.getDealershipsBlipSuccess(data.data))
  } catch (e) {
    yield put(BlipActions.getDealershipsBlipFailure())
  }
}

function* getDepartmentsBlip() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'departments', {
      params: {
        limit: 99999,
        order: 'name,asc',
      },
    })
    yield put(BlipActions.getDepartmentsBlipSuccess(data.data))
  } catch (e) {
    yield put(BlipActions.getDepartmentsBlipFailure())
  }
}

export default function* () {
  yield takeLatest(BlipTypes.GET_OPEN_TICKETS_REQUEST, getOpenTickets)
  yield takeLatest(BlipTypes.GET_WAITING_TICKETS_REQUEST, getWaitingTickets)
  yield takeLatest(BlipTypes.GET_ATTENDANTS_REQUEST, getAttendants)
  yield takeLatest(BlipTypes.GET_TICKETS_PER_HOUR_REQUEST, getTicketsPerHour)
  yield takeLatest(BlipTypes.GET_TICKET_METRICS_REQUEST, getTicketMetrics)
  yield takeLatest(BlipTypes.SET_BLIP_DEALERSHIP_FILTER, reloadBlip)
  yield takeLatest(BlipTypes.GET_DEALERSHIPS_BLIP_REQUEST, getDealershipsBlip)
  yield takeLatest(BlipTypes.GET_DEPARTMENTS_BLIP_REQUEST, getDepartmentsBlip)
  yield takeLatest(BlipTypes.SET_BLIP_DEPARTMENT_FILTER, reloadBlip)
}
