import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addDocumentRequest: ['data', 'callback'],
  addDocumentFinish: [],
  setReceiptFilter: ['property', 'value'],
  getReceiptsRequest: [],
  getReceiptsSuccess: ['data', 'total'],
  getReceiptsFailure: [],
  setReceiptsPage: ['page', 'limit'],
  getReceiptVisibilityRequest: ['id', 'callback'],
  getReceiptVisibilityFinish: [],
  removeDocumentRequest: ['id', 'callback'],
  removeDocumentFinish: [],
  removeReceiptUserRequest: ['id', 'callback'],
  removeReceiptUserFinish: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'created_at',
    page: 0,
    total: 0,
    limit: 20,
    loading: false,
    filters: [
      { name: 'date', value: new Date() },
      {
        name: 'users_id',
        value: null,
      },
    ],
    users: {
      data: [],
      loading: false,
    },
  },
  receiptsList: [],
  loadingReceipts: false,
}

const addDocumentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addDocumentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setReceiptFilter = (state = INITIAL_STATE, { property, value }) => {
  return {
    ...state,
    options: {
      ...state.options,
      filters: state.options.filters.map((filter) => {
        if (filter.name === property) {
          return {
            ...filter,
            value,
          }
        }
        return filter
      }),
    },
  }
}

const getReceiptsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingReceipts: true,
})

const getReceiptsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  receiptsList: data,
  loadingReceipts: false,
})

const getReceiptsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingReceipts: false,
})

const setReceiptsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const getReceiptVisibilityRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getReceiptVisibilityFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeDocumentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeDocumentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeReceiptUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeReceiptUserFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_DOCUMENT_REQUEST]: addDocumentRequest,
  [Types.ADD_DOCUMENT_FINISH]: addDocumentFinish,
  [Types.SET_RECEIPT_FILTER]: setReceiptFilter,
  [Types.GET_RECEIPTS_REQUEST]: getReceiptsRequest,
  [Types.GET_RECEIPTS_SUCCESS]: getReceiptsSuccess,
  [Types.GET_RECEIPTS_FAILURE]: getReceiptsFailure,
  [Types.SET_RECEIPTS_PAGE]: setReceiptsPage,
  [Types.GET_RECEIPT_VISIBILITY_REQUEST]: getReceiptVisibilityRequest,
  [Types.GET_RECEIPT_VISIBILITY_FINISH]: getReceiptVisibilityFinish,
  [Types.REMOVE_DOCUMENT_REQUEST]: removeDocumentRequest,
  [Types.REMOVE_DOCUMENT_FINISH]: removeDocumentFinish,
  [Types.REMOVE_RECEIPT_USER_REQUEST]: removeReceiptUserRequest,
  [Types.REMOVE_RECEIPT_USER_FINISH]: removeReceiptUserFinish,
})
