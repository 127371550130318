export default (theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    minHeight: 500,
  },
  button: {
    justifyContent: 'flex-start',
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
    cursor: 'pointer',
  },
  divider: {
    backgroundColor: theme.palette.divider,
    height: 2,
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  grid: {
    padding: theme.spacing(3),
  },
  buttonForm: {
    justifyContent: 'flex-start',
  },
  emptyWrapper: {
    display: 'flex',
    height: '85vh',
    flex: 1,
    position: 'relative',
    marginTop: theme.spacing(2),
  },
})
