import React, { useRef, useState, useEffect } from 'react'
// import ReactCrop from 'react-image-crop'

import PropTypes from 'prop-types'
import { makeStyles, Grid, Button, Typography } from '@material-ui/core'

import { Backup as UploadIcon } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import RhfTextField from '~/components/FormComponents/RhfTextField'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import history from '~/helpers/history'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'
// import { generateBlobImage } from '~/helpers/images'

// import 'react-image-crop/dist/ReactCrop.css'

import CustomMentionsInput from '~/components/CustomMentionsInput'

import styles from './styles'

const useStyles = makeStyles(styles)

const newsBoardSelector = ({ newsBoard }) => newsBoard

export default function EditNewsBoardPage({
  match: {
    params: { id },
  },
}) {
  const classes = useStyles()
  const fileInput = useRef(null)
  const [image, setImage] = useState('')

  // const [croppedCompleted, setCroppedCompleted] = useState(null)
  // const [selectImage, setSelectImage] = useState(false)
  const [imageUpload, setImageUpload] = useState('')
  const dispatch = useDispatch()
  const { news } = useSelector(newsBoardSelector)
  const { data, loading } = news
  const isEdit = id !== 'new'
  const [descriptionText, setDescriptionText] = useState('')

  // const [cropProps, setCropProps] = useState({
  //   aspect: 1 / 1,
  //   unit: 'px', // default, can be 'px' or '%'
  //   width: 250,
  //   height: 200,
  // })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (isEdit) {
      dispatch(NewsBoardActions.getNewsRequest(id))
    }
  }, [isEdit, id])

  useEffect(() => {
    reset(data)
    if (data.url_image) {
      setImage(data.url_image)
    }
    if (data.text !== null) {
      setDescriptionText(data.text)
    }
    return () => {
      reset({})
      setImage('')
      setDescriptionText('')
    }
  }, [reset, data])

  const handleUploadImage = (e) => {
    // setSelectImage(true)
    const allowedExtensions = {
      image: ['png', 'jpg', 'jpeg'],
    }
    const [file] = e.target.files
    if (!file) {
      return
    }
    const [fileType, ext] = file.type.split('/')

    if (
      !allowedExtensions[fileType] ||
      !allowedExtensions[fileType].includes(ext)
    ) {
      dispatch(
        FlashMessageActions.showMessage({
          message: 'Extensão não permitida',
          variant: 'error',
        }),
      )
      return
    }
    setImageUpload(file)

    const reader = new FileReader()
    reader.addEventListener('load', () => setImage(reader.result))
    reader.readAsDataURL(file)
    e.target.value = null
  }

  const getMentionsId = () => {
    if (!descriptionText) {
      return []
    }

    return [...descriptionText?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map((item) =>
      Number(item[2]),
    )
  }

  const handleSubmitNewsForm = (formData) => {
    const formattedData = {
      ...formData,
      type: 'news',
      mentions_id: getMentionsId(),
    }

    if (descriptionText) {
      formattedData.text = descriptionText
    }

    // if (image) {
    //   formattedData.image = await generateBlobImage(croppedCompleted, fileInput)
    // }

    if (image) {
      formattedData.image = imageUpload
    }

    if (!formData.url_video) {
      delete formattedData.url_video
    }

    if (isEdit) {
      if (descriptionText !== null) {
        formattedData.text = descriptionText
      }
      dispatch(NewsBoardActions.updateNewsRequest(id, formattedData))
    } else {
      dispatch(NewsBoardActions.addNewsRequest(formattedData))
    }
  }

  const getUsersOptions = async (search, callback) => {
    if (!search) {
      return
    }
    dispatch(
      NewsBoardActions.getNewsUsersRequest(search, (cbUsers) =>
        callback(cbUsers),
      ),
    )
  }

  // const onLoad = useCallback((img) => {
  //   fileInput.current = img
  // }, [])

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      <Portlet className={classes.grow}>
        {!loading && (
          <>
            <div className={classes.content}>
              <form
                className={classes.form}
                id="news-form"
                onSubmit={handleSubmit(handleSubmitNewsForm)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: 4,
                      }}
                    >
                      Título da Publicação
                    </Typography>
                    <RhfTextField
                      fullWidth
                      control={control}
                      name="title"
                      rules={{ required: true }}
                      helperText={errors && errors.title && 'Campo obrigatório'}
                      error={errors && !!errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: 4,
                      }}
                    >
                      Descrição
                    </Typography>
                    <CustomMentionsInput
                      value={descriptionText}
                      onChange={(e) => {
                        setDescriptionText(e.target.value)
                      }}
                      data={getUsersOptions}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: 4,
                      }}
                    >
                      URL do vídeo YouTube ou Vimeo
                    </Typography>
                    <RhfTextField
                      fullWidth
                      defaultValue=""
                      control={control}
                      name="url_video"
                      placeholder="https://www.youtube.com/watch?v=... ou https://vimeo.com/..."
                    />
                  </Grid>
                  {/*
                  {image && selectImage && (
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="center"
                      className={classes.cropWrapper}
                    >
                      <ReactCrop
                        src={image}
                        crop={cropProps}
                        keepSelection
                        onImageLoaded={onLoad}
                        onChange={(c) => setCropProps(c)}
                        onComplete={(c) => setCroppedCompleted(c)}
                        minWidth={200}
                        minHeight={200}
                      />
                    </Grid>
                  )} */}
                  {image && (
                    <Grid item xs={12} className={classes.imageWrapper}>
                      <img src={image} alt="imagem" />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      className={classes.uploadButton}
                      onClick={() => {
                        fileInput.current.click()
                      }}
                    >
                      <UploadIcon className={classes.uploadIcon} />
                      Selecionar imagem
                      <input
                        type="file"
                        multiple={false}
                        accept=".png,.jpg"
                        onClick={(e) => {
                          e.target.value = ''
                        }}
                        ref={fileInput}
                        style={{ display: 'none' }}
                        onChange={handleUploadImage}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Grid
              container
              justifyContent="space-between"
              className={classes.actionsWrapper}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => history.push('/news-board')}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="news-form"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Portlet>
    </div>
  )
}

EditNewsBoardPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
