export default () => ({
  leftGroupTableCells: {
    backgroundColor: '#FFEFBA',
  },
  rightGroupTableCells: {
    backgroundColor: '#e7ffe7',
  },
  tableRowOpen: {
    backgroundColor: '#f9f9f9',
  },
})
