export default () => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
  avatar: {
    marginRight: 8,
  },
})
