import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core'

import { Creators as NotificationsActions } from '~/store/ducks/notifications'

import history from '~/helpers/history'

import styles from './styles'

import CompanyAnniversaryDialog from './components/CompanyAnniversaryDialog'
import NotificationsList from './components/NotificationsList/index'

const SessionActionsSelector = ({ session }) => session

const useStyles = makeStyles(styles)

export default function GeneralList({
  data,
  onlyUnread,
  handleCloseCommunications,
}) {
  const { user } = useSelector(SessionActionsSelector)
  const [openCompanyAnniversaryDialog, setOpenCompanyAnniversaryDialog] =
    useState(false)
  const [numberCompanyAnniversary, setNumberCompanyAnniversary] =
    useState(false)
  const [nameCompanyAnniversary, setNameCompanyAnniversary] = useState(false)
  const dispatch = useDispatch()

  const readGeneral = (item) => {
    dispatch(
      NotificationsActions.handleReadNotificationRequest(item.id, () => {
        dispatch(NotificationsActions.getNotificationsRequest())
        const types = ['comment', 'reaction', 'news']
        if (types.includes(item.type)) {
          history.push(`/news-single-page/${item.context.id}`)
        }
      }),
    )

    if (item.type === 'mention') {
      history.push(`/news-single-page/${item.context.news_id}`)
    }

    if (item.type === 'company_anniversary') {
      if (user.name === item.context.name) {
        setOpenCompanyAnniversaryDialog(true)
        setNumberCompanyAnniversary(item?.context?.years)
        setNameCompanyAnniversary(item?.context?.name)
      } else {
        dispatch(
          NotificationsActions.getCongratulationsCompanyAnniversaryRequest(
            item.id,
          ),
        )
      }
    }

    if (item.type === 'birthday') {
      dispatch(NotificationsActions.getCongratulationsBirthdayRequest(item.id))
    }

    if (item.type === 'rh_document') {
      dispatch(
        NotificationsActions.handleReadNotificationRequest(item.id, () => {
          history.push('/profile')
        }),
      )
    }

    if (
      item.type !== 'congratulate_birthday' &&
      item.type !== 'company_anniversary'
    ) {
      handleCloseCommunications(null)
    } else if (user.name !== item?.context?.name) {
      handleCloseCommunications(true)
    }
  }

  const classes = useStyles()

  const unreadData = data?.filter((item) => item?.status === 'unread')

  const handleCloseCompanyAnniversaryDialog = () => {
    setOpenCompanyAnniversaryDialog(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <div className={classes.tableWrapper}>
          <div className={classes.listWrapper}>
            {onlyUnread
              ? unreadData?.map((item) => (
                  <NotificationsList item={item} readGeneral={readGeneral} />
                ))
              : data?.map((item) => (
                  <NotificationsList item={item} readGeneral={readGeneral} />
                ))}
          </div>
        </div>
      </div>
      <CompanyAnniversaryDialog
        handleCloseNotificationsDialog={handleCloseCommunications}
        open={openCompanyAnniversaryDialog}
        onClose={handleCloseCompanyAnniversaryDialog}
        number={numberCompanyAnniversary}
        name={nameCompanyAnniversary}
      />
    </div>
  )
}

GeneralList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onlyUnread: PropTypes.PropTypes.bool.isRequired,
  handleCloseCommunications: PropTypes.PropTypes.func.isRequired,
}
