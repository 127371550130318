export default (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  portlet: {
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    // position: 'relative',
    height: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
    height: 400,
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
})
