import { groupBy, replace } from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getSalesGoalsRequest: [],
  getSalesGoalsFailure: [],
  getSalesGoalsSuccess: ['data', 'total'],
  setSalesGoalsSort: ['sort', 'direction'],
  setSalesGoalsPage: ['page', 'limit'],
  getSalesGoalNewDataRequest: [],
  getSalesGoalNewDataSuccess: ['data'],
  getSalesGoalNewDataFailure: [],
  handleSalesGoalsCounterChange: ['id', 'value'],
  addSalesGoalRequest: ['data', 'callback'],
  addSalesGoalFinish: [],
  addSellersGoalsRequest: ['data', 'callback'],
  addSellersGoalsFinish: [],
  removeSalesGoalsRequest: ['id', 'callback'],
  removeSalesGoalsFinish: [],
  getSellerGoalsRequest: ['id'],
  getSellerGoalsSuccess: ['data'],
  getSellerGoalsFailure: [],
  updateSalesGoalRequest: ['id', 'data', 'callback'],
  updateSalesGoalFinish: [],
  getCopySalesGoalRequest: ['callback'],
  getCopySalesGoalFailure: [],
  getCopySalesGoalSuccess: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'id',
    direction: 'desc',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
  loadingCopy: false,
  dataCopy: {},
  salesNew: {
    data: [],
    loading: false,
  },
}

const addSellersGoalsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addSellersGoalsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addSalesGoalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addSalesGoalFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getSalesGoalsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSalesGoalsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getSalesGoalsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setSalesGoalsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    pagination: 0,
    sort,
    direction,
  },
})

const setSalesGoalsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const getSalesGoalNewDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  salesNew: {
    ...state.salesNew,
    loading: true,
  },
})

const getSalesGoalNewDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  salesNew: {
    ...state.salesNew,
    loading: false,
  },
})

const getSalesGoalNewDataSuccess = (state = INITIAL_STATE, { data }) => {
  const grouped = groupBy(data, 'dealership_name')
  const formattedDirectData =
    data.length > 0
      ? Object.keys(grouped)?.map((key) => {
          return {
            id: uniqueId(),
            dealership_name: grouped[key][0]?.dealership_name,
            data: grouped[key],
          }
        })
      : []
  return {
    ...state,
    salesNew: {
      ...state.salesNew,
      data: formattedDirectData,
      loading: false,
    },
  }
}

const handleSalesGoalsCounterChange = (
  state = INITIAL_STATE,
  { id, value },
) => {
  return {
    ...state,
    salesNew: {
      ...state.salesNew,
      data: state.salesNew?.data?.map((d) => {
        return {
          ...d,
          data: d.data?.map((item) => {
            if (item.seller_id === id) {
              return {
                ...item,
                counter: replace(value, 'R$', ''),
              }
            }
            return item
          }),
        }
      }),
    },
  }
}

const getSellerGoalsRequest = (state = INITIAL_STATE) => ({
  ...state,
  salesNews: {
    ...state.salesNew,
    loading: true,
  },
})

const getSellerGoalsSuccess = (state = INITIAL_STATE, { data }) => {
  const grouped = groupBy(data, 'dealership_name')
  const formattedDirectData =
    data.length > 0
      ? Object.keys(grouped)?.map((key) => {
          return {
            id: uniqueId(),
            dealership_name: grouped[key][0]?.dealership_name,
            data: grouped[key],
          }
        })
      : []
  return {
    ...state,
    salesNew: {
      ...state.salesNew,
      data: formattedDirectData,
      loading: false,
    },
  }
}

const getSellerGoalsFailure = (state = INITIAL_STATE) => ({
  ...state,
  salesNews: {
    ...state.salesNew,
    loading: false,
  },
})

const removeSalesGoalsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeSalesGoalsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateSalesGoalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateSalesGoalFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCopySalesGoalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: true,
})

const getCopySalesGoalFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: false,
})

const getCopySalesGoalSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SALES_GOALS_REQUEST]: getSalesGoalsRequest,
  [Types.GET_SALES_GOALS_FAILURE]: getSalesGoalsFailure,
  [Types.GET_SALES_GOALS_SUCCESS]: getSalesGoalsSuccess,
  [Types.SET_SALES_GOALS_SORT]: setSalesGoalsSort,
  [Types.SET_SALES_GOALS_PAGE]: setSalesGoalsPage,
  [Types.GET_SALES_GOAL_NEW_DATA_REQUEST]: getSalesGoalNewDataRequest,
  [Types.GET_SALES_GOAL_NEW_DATA_FAILURE]: getSalesGoalNewDataFailure,
  [Types.GET_SALES_GOAL_NEW_DATA_SUCCESS]: getSalesGoalNewDataSuccess,
  [Types.HANDLE_SALES_GOALS_COUNTER_CHANGE]: handleSalesGoalsCounterChange,
  [Types.ADD_SALES_GOAL_REQUEST]: addSalesGoalRequest,
  [Types.ADD_SALES_GOAL_FINISH]: addSalesGoalFinish,
  [Types.ADD_SELLERS_GOALS_REQUEST]: addSellersGoalsRequest,
  [Types.ADD_SELLERS_GOALS_FINISH]: addSellersGoalsFinish,
  [Types.REMOVE_SALES_GOALS_REQUEST]: removeSalesGoalsRequest,
  [Types.REMOVE_SALES_GOALS_FINISH]: removeSalesGoalsFinish,
  [Types.GET_SELLER_GOALS_REQUEST]: getSellerGoalsRequest,
  [Types.GET_SELLER_GOALS_FAILURE]: getSellerGoalsFailure,
  [Types.GET_SELLER_GOALS_SUCCESS]: getSellerGoalsSuccess,
  [Types.UPDATE_SALES_GOAL_REQUEST]: updateSalesGoalRequest,
  [Types.UPDATE_SALES_GOAL_FINISH]: updateSalesGoalFinish,
  [Types.GET_COPY_SALES_GOAL_REQUEST]: getCopySalesGoalRequest,
  [Types.GET_COPY_SALES_GOAL_FAILURE]: getCopySalesGoalFailure,
  [Types.GET_COPY_SALES_GOAL_SUCCESS]: getCopySalesGoalSuccess,
})
