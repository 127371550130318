import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as StatusTypes,
  Creators as StatusActions,
} from '../ducks/status'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* addStatus({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'status', data)

    if (callback) {
      yield call(callback)
    }

    yield put(StatusActions.addStatusFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Status cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(StatusActions.addStatusFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getStatus({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `status/${id}`)
    yield put(StatusActions.getStatusSuccess(data))
  } catch (error) {
    yield put(StatusActions.getStatusFailure())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateStatus({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `/status/${id}`, data)
    yield put(StatusActions.updateStatusFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Status editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(StatusActions.updateStatusFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeStatus({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/status/${id}`)

    if (callback) {
      yield call(callback)
    }
    yield put(StatusActions.removeStatusFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Status removido com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(StatusActions.removeStatusFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(StatusTypes.ADD_STATUS_REQUEST, addStatus)
  yield takeLatest(StatusTypes.GET_STATUS_REQUEST, getStatus)
  yield takeLatest(StatusTypes.UPDATE_STATUS_REQUEST, updateStatus)
  yield takeLatest(StatusTypes.REMOVE_STATUS_REQUEST, removeStatus)
}
