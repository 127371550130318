import React from 'react'

import { makeStyles, Grid } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

function CalendarPage() {
  const classes = useStyles()

  return (
    <div style={{ height: '100%' }}>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={12}>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=800&wkst=1&ctz=America%2FSao_Paulo&bgcolor=%23ffffff&title=CAID&showTitle=0&showTz=0&showPrint=0&src=NmNlZmVqYnBqcGFyZmlpdjgxOWdoYmY2bGw5czNkbTNAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&src=cGtnYWwxcTYwZ2cwOTVlNzJiOTVvZWFjMDRycnNyanNAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&src=MHA3bHYzcDZvMzdsbjN2NTFhbDBsOHJudjE5azhoNjNAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%239E69AF&color=%2333B679&color=%23F4511E"
            style={{ border: 'none' }}
            title="calendar"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CalendarPage
