import React from 'react'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useUnmount } from 'react-use'

import { Creators as RhDashboardActions } from '~/store/ducks/rhDashboard'

const rhDashboardSelector = ({ rhDashboard }) => rhDashboard

function RhDashboardFilters() {
  const { filtersData, filters } = useSelector(rhDashboardSelector)
  const dispatch = useDispatch()

  useUnmount(() => {
    dispatch(RhDashboardActions.resetRhDashboard())
  })

  function handleChangeFilter(property, value) {
    if (property === 'group') {
      dispatch(RhDashboardActions.setRhDashboardGroupFilter(value))
    } else {
      dispatch(RhDashboardActions.setRhDashboardFilter(property, value))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Grupos"
          fullWidth
          select
          value={filters.group}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {filtersData.groups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Departamentos"
          fullWidth
          select
          value={filters.department}
          onChange={(ev) => handleChangeFilter('department', ev.target.value)}
        >
          {filtersData.departments.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Vínculo"
          fullWidth
          select
          value={filters.bond}
          onChange={(ev) => handleChangeFilter('bond', ev.target.value)}
        >
          {filtersData.bonds.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

RhDashboardFilters.propTypes = {}

export default RhDashboardFilters
