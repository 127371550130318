export default (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  portlet: {
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  subjectButtonsWrapper: {
    marginTop: theme.spacing(1),
  },
})
