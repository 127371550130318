import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addQuizzeRequest: ['data', 'callback'],
  addQuizzeFailure: [],
  addQuestionRequest: ['data', 'callback'],
  addQuestionSuccess: [],
  addQuestionFailure: [],
  getQuizzeRequest: ['id'],
  getQuizzeSuccess: ['data'],
  getQuizzeFailure: [],
  addQuizzeSuccess: [],
  removeQuizzeRequest: ['id', 'callback'],
  removeQuizzeFinish: [],
  updateQuestionRequest: ['id', 'data', 'callback'],
  updateQuestionFinish: [],
  setQuizzeSort: ['sort', 'direction'],
  setQuizzePage: ['page', 'limit'],
  removeQuestionRequest: ['id', 'callback'],
  removeQuestionFinish: [],
  resetQuizzeData: [],
  updateQuizzeRequest: ['id', 'data', 'callback'],
  updateQuizzeFinish: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
  },
  loading: false,
  data: {
    users: [],
  },
}

const addQuizzeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addQuizzeFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addQuizzeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getQuizzeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getQuizzeSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data: {
    ...data,
    active: data.active ? 'true' : 'false',
  },
  loading: false,
})

const getQuizzeFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeQuizzeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeQuizzeFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addQuestionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addQuestionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addQuestionFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateQuestionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateQuestionFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setQuizzeSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setQuizzePage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})
const removeQuestionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeQuestionFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const resetQuizzeData = () => ({
  ...INITIAL_STATE,
})

const updateQuizzeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateQuizzeFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_QUIZZE_REQUEST]: addQuizzeRequest,
  [Types.ADD_QUIZZE_FAILURE]: addQuizzeFailure,
  [Types.ADD_QUIZZE_SUCCESS]: addQuizzeSuccess,
  [Types.GET_QUIZZE_REQUEST]: getQuizzeRequest,
  [Types.GET_QUIZZE_SUCCESS]: getQuizzeSuccess,
  [Types.GET_QUIZZE_FAILURE]: getQuizzeFailure,
  [Types.REMOVE_QUIZZE_REQUEST]: removeQuizzeRequest,
  [Types.REMOVE_QUIZZE_FINISH]: removeQuizzeFinish,
  [Types.ADD_QUESTION_REQUEST]: addQuestionRequest,
  [Types.ADD_QUESTION_SUCCESS]: addQuestionSuccess,
  [Types.ADD_QUESTION_FAILURE]: addQuestionFailure,
  [Types.UPDATE_QUESTION_REQUEST]: updateQuestionRequest,
  [Types.UPDATE_QUESTION_FINISH]: updateQuestionFinish,
  [Types.SET_QUIZZE_SORT]: setQuizzeSort,
  [Types.SET_QUIZZE_PAGE]: setQuizzePage,
  [Types.REMOVE_QUESTION_REQUEST]: removeQuestionRequest,
  [Types.REMOVE_QUESTION_FINISH]: removeQuestionFinish,
  [Types.RESET_QUIZZE_DATA]: resetQuizzeData,
  [Types.UPDATE_QUIZZE_REQUEST]: updateQuizzeRequest,
  [Types.UPDATE_QUIZZE_FINISH]: updateQuizzeFinish,
})
