import React from 'react'
import { find, sortBy } from 'lodash'
import { makeStyles, Grid } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { useDispatch, useSelector } from 'react-redux'
import SearchInput from '~/components/SearchInput'
import Autocomplete from '~/components/Autocomplete'
import { Creators as UsersActions } from '~/store/ducks/users'
import styles from './styles'

const dealershipsFilterSelector = ({ users }) => users.dealerships
const usersSelector = ({ users }) => users

const useStyles = makeStyles(styles)

function UsersFilters() {
  const { options } = useSelector(usersSelector)

  const classes = useStyles()
  const dispatch = useDispatch()

  const activeFilter = [
    { label: 'Sim', value: true },
    { label: 'Não', value: 'false' },
  ]

  const genderFilter = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' },
  ]

  const { data: dealershipsFilter, loading: loadingDealershipsFilter } =
    useSelector(dealershipsFilterSelector)

  const handlePropertyFilterChange = (property, value) => {
    dispatch(UsersActions.setUsersPropertyFilter(property, value))
  }

  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  const handleSearchChange = (value) => {
    dispatch(UsersActions.setUsersSearch(value))
  }

  return (
    <Grid container className={classes.gridContainerButton} spacing={2}>
      <Grid item md={6}>
        <SearchInput
          value={options.search}
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder="Pesquise por nome ou ramal..."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'dealership_id',
              option ? option.value : null,
            )
          }}
          options={sortBy(dealershipsFilter, (a) => a.name).map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="dealership_id"
          value={getFilterValue('dealership_id')}
          className={classes.autocomplete}
          label="Concessionária Vinculada"
          placeholder="Selecione uma Concessionária"
          loading={loadingDealershipsFilter}
          autoSelect={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange('active', option ? option.value : null)
          }}
          options={activeFilter}
          name="active"
          getOptionSelected={(option, val) => {
            return option.value === val
          }}
          value={getFilterValue('active')}
          className={classes.autocomplete}
          label="Ativo"
          placeholder="Ativo"
          autoSelect={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange('gender', option ? option.value : null)
          }}
          options={genderFilter}
          name="gender"
          value={getFilterValue('gender')}
          className={classes.autocomplete}
          label="Filtrar por Gênero"
          placeholder="Selecione um Gênero"
          autoSelect={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            fullWidth
            views={['month']}
            label="Filtrar aniversariantes de empresa por mês"
            invalidDateMessage="Data inválida"
            format="MM"
            openTo="month"
            value={getFilterValue('month')}
            onChange={(dValue) =>
              handlePropertyFilterChange('month', dValue || null)
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default UsersFilters
