export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterWropper: {
    display: 'flex',
  },
  emptyWrapper: {
    display: 'flex',
    height: '100%',
    marginTop: theme.spacing(3),
  },
  portlet: {
    flex: 1,
    position: 'relative',
    height: '100%',
    display: 'flex',
  },
})
