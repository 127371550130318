import { createActions, createReducer } from 'reduxsauce'
import { groupBy } from 'lodash'
import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getEquipmentDashboardFiltersRequest: [],
  getEquipmentDashboardFiltersFailure: [],
  getEquipmentDashboardFiltersSuccess: ['groups', 'departments'],
  getEquipmentDashboardDataRequest: [],
  getEquipmentDashboardDataFailure: [],
  getEquipmentDashboardDataSuccess: ['data'],
  setEquipmentDashboardFilter: ['property', 'value'],
  getEquipmentDashboardDealershipRequest: ['id'],
  getEquipmentDashboardDealershipSuccess: ['data'],
  getEquipmentDashboardDealershipFailure: [],
  resetEquipmentDashboard: [],
})

const INITIAL_STATE = {
  filters: {
    group: 0,
    department: 0,
    state: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
    ],
    states: [
      {
        name: 'Todos',
        value: 0,
      },
      {
        name: 'Bom',
        value: 1,
      },
      {
        name: 'Regular',
        value: 2,
      },
      {
        name: 'Ruim',
        value: 3,
      },
    ],
  },
  data: [],
  loadingFilters: false,
  loading: false,
  equipmentsDealership: {
    data: [],
    loading: false,
  },
}

const getEquipmentDashboardDealershipRequest = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsDealership: {
    ...state.equipmentsDealership,
    loading: true,
  },
})

const getEquipmentDashboardDealershipSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const grouppedByEquipmentTypeName = groupBy(data, 'equipment_type_name')
  const formattedData =
    data.length > 0
      ? Object.keys(grouppedByEquipmentTypeName)?.map((key) => {
          return {
            id: uniqueId(),
            equipment_type_name:
              grouppedByEquipmentTypeName[key][0]?.equipment_type_name,
            data: grouppedByEquipmentTypeName[key]?.map((d) => ({
              id: uniqueId(),
              equipment_type_name: d.equipment_type_name,
              equipment_description: d.equipment_description,
              equipment_type_id: d.equipment_type_id,
              user_name: d.user_name,
              equipment_brand: d.equipment_brand,
              cod_equipment: d.cod_equipment,
            })),
          }
        })
      : []

  return {
    ...state,
    loading: false,
    equipmentsDealership: {
      ...state.equipmentsDealership,
      data: formattedData,
      loading: false,
    },
  }
}

const getEquipmentDashboardDealershipFailure = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsDealership: {
    ...state.equipmentsDealership,
    loading: false,
  },
})

const getEquipmentDashboardFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getEquipmentDashboardFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getEquipmentDashboardFiltersSuccess = (
  state = INITIAL_STATE,
  { groups, departments },
) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
      ...groups,
    ],
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
      ...departments,
    ],
  },
  loadingFilters: false,
})

const getEquipmentDashboardDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentDashboardDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getEquipmentDashboardDataSuccess = (state = INITIAL_STATE, { data }) => {
  const grouppedByDealership = groupBy(data, 'dealership_name')
  const formattedData =
    data.length > 0
      ? Object.keys(grouppedByDealership)?.map((key) => {
          return {
            dealership_name: grouppedByDealership[key][0]?.dealership_name,
            dealership_id: grouppedByDealership[key][0]?.dealership_id,
            dealership_sort: grouppedByDealership[key][0]?.dealership_sort,
            data: grouppedByDealership[key]?.map((d) => ({
              equipment_type_name: d.equipment_type_name,
              equipment_type_id: d.equipment_type_id,
              total: d.total,
            })),
          }
        })
      : []

  return {
    ...state,
    data: formattedData,
    loading: false,
  }
}

const setEquipmentDashboardFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const resetEquipmentDashboard = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_EQUIPMENT_DASHBOARD_FILTERS_REQUEST]: getEquipmentDashboardFiltersRequest,
  [Types.GET_EQUIPMENT_DASHBOARD_FILTERS_FAILURE]: getEquipmentDashboardFiltersFailure,
  [Types.GET_EQUIPMENT_DASHBOARD_FILTERS_SUCCESS]: getEquipmentDashboardFiltersSuccess,
  [Types.GET_EQUIPMENT_DASHBOARD_DATA_REQUEST]: getEquipmentDashboardDataRequest,
  [Types.GET_EQUIPMENT_DASHBOARD_DATA_FAILURE]: getEquipmentDashboardDataFailure,
  [Types.GET_EQUIPMENT_DASHBOARD_DATA_SUCCESS]: getEquipmentDashboardDataSuccess,
  [Types.SET_EQUIPMENT_DASHBOARD_FILTER]: setEquipmentDashboardFilter,
  [Types.GET_EQUIPMENT_DASHBOARD_DEALERSHIP_REQUEST]: getEquipmentDashboardDealershipRequest,
  [Types.GET_EQUIPMENT_DASHBOARD_DEALERSHIP_SUCCESS]: getEquipmentDashboardDealershipSuccess,
  [Types.GET_EQUIPMENT_DASHBOARD_DEALERSHIP_FAILURE]: getEquipmentDashboardDealershipFailure,
  [Types.RESET_EQUIPMENT_DASHBOARD]: resetEquipmentDashboard,
})
