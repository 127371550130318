import React, { useRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import filesize from 'filesize'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  IconButton,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { sortBy } from 'lodash'

import {
  Backup as UploadIcon,
  InsertDriveFile as AttachmentIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons'

import { useSelector, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

import OverlayLoading from '~/components/OverlayLoading'
import RhfSelect from '~/components/FormComponents/RhfSelect'
import { Creators as CallActions } from '~/store/ducks/call'
import { Creators as CallsActions } from '~/store/ducks/calls'

import styles from './styles'

const useStyles = makeStyles(styles)
const callsSelector = ({ calls }) => calls

const userSelector = ({ session: { user } }) => user
const callSelector = ({ call }) => call

export default function CallFormTab({ onClose, id }) {
  const fileInput = useRef(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { data, loading } = useSelector(callSelector)
  const [attachment, setAttachment] = useState([])
  const {
    dealerships: { data: dealerships },
  } = useSelector(callsSelector)

  const isEdit = !!id
  const {
    control,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: isEdit ? data : {},
  })

  useEffect(() => {
    if (isEdit) {
      reset(data)
    }
  }, [reset, isEdit, data])

  const dealershipSelected = watch('dealership_id')
  const departmentSelected = watch('department_id')

  const allDepartments =
    dealershipSelected &&
    dealerships?.find((d) => d.id === dealershipSelected)?.departments

  const departments = allDepartments?.filter((d) => d.subjects.length > 0)

  const subjects = departments?.find(
    (department) => department.id === departmentSelected,
  )?.subjects

  function handleAddAttachment(e) {
    const file = e.target.files

    if (file) {
      setAttachment(file)
    }
  }

  const arrayFiles = Object.keys(attachment).map((item) => [attachment[item]])
  const formattedArrayFiles = arrayFiles.map((item) => item[0])

  function handleRemoveAttachment() {
    setAttachment([])
  }

  const reloadCallsPage = useCallback(() => {
    dispatch(CallsActions.getCallsRequest())
  }, [])

  function onSubmitForm(formData) {
    const formattedData = {
      ...formData,
      requester_id: user?.id,
      expected_date: formData.expected_date ? moment(formData.expected_date).format('YYYY-MM-DD') : null,
    }

    if (formattedData.expected_date === null)
      delete formattedData.expected_date

    if (attachment.length === 1)
      formattedData.file = formattedArrayFiles

    if (attachment.length > 1)
      formattedData.files = formattedArrayFiles

    dispatch(
      CallActions.addCallRequest(formattedData, () => {
        reloadCallsPage()
        onClose()
      }),
    )
  }

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      {!loading && (
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <RhfSelect
                name="dealership_id"
                control={control}
                label="Concessionária"
                variant="outlined"
                fullWidth
                rules={{ required: true }}
                // disabled={!!isEdit}
                error={!!errors.dealership_id}
              // helperText={!!errors.dealership_id && 'Campo obrigatório'}
              >
                {sortBy(dealerships, (a) => a.name).map((dealership) => (
                  <MenuItem value={dealership.id} key={dealership.id}>
                    {dealership.name}
                  </MenuItem>
                ))}
              </RhfSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="department_id"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, onBlur, ...field } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!!isEdit || !dealershipSelected}
                    select
                    onBlur={onBlur}
                    label="Departamento"
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value || ''}
                    {...field}
                    error={!!errors.department_id}
                    helperText={!!errors.department_id && 'Campo obrigatório'}
                  >
                    {sortBy(departments, (a) => a.name)?.map((department) => (
                      <MenuItem value={department.id} key={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                    {!departments?.length && (
                      <MenuItem disabled value="">
                        Sem opções
                      </MenuItem>
                    )}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="subject_id"
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { onChange, value, onBlur, ...field } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                    disabled={!!isEdit || !departmentSelected}
                    onBlur={onBlur}
                    label="Assunto"
                    variant="outlined"
                    placeholder="teste"
                    fullWidth
                    onChange={onChange}
                    value={value || ''}
                    error={!!errors.subject_id}
                    helperText={!!errors.subject_id && 'Campo obrigatório'}
                    {...field}
                  >
                    {sortBy(subjects, (s) => s.name)?.map((subject) => (
                      <MenuItem value={subject.id} key={subject.id}>
                        {subject.name}
                      </MenuItem>
                    ))}
                    {!subjects?.length && (
                      <MenuItem disabled value="">
                        Sem opções
                      </MenuItem>
                    )}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="expected_date"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref, ...field } }) => (
                  <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      fullWidth
                      value={value || null}
                      label="Concluir até:"
                      format="DD/MM/YYYY"
                      invalidDateMessage="Data inválida"
                      inputRef={ref}
                      onChange={(dateValue) => onChange(dateValue)}
                      {...field}
                      error={!!errors.expected_date}
                      helperText={!!errors.expected_date && 'Campo obrigatório'}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Descrição"
                    multiline
                    error={!!errors.description}
                    helperText={!!errors.description && 'Campo obrigatório'}
                    InputProps={{
                      readOnly: !!isEdit,
                    }}
                    rows={4}
                    placeholder="Informe a descrição do chamado"
                    value={value || ''}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>

            {!isEdit && (
              <>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      fileInput.current.click()
                    }}
                  >
                    <UploadIcon className={classes.uploadIcon} />
                    Selecionar arquivo
                    <input
                      ref={fileInput}
                      onClick={(e) => {
                        e.target.value = ''
                      }}
                      type="file"
                      multiple
                      style={{ display: 'none' }}
                      onChange={handleAddAttachment}
                    />
                  </Button>
                </Grid>

                {attachment && (
                  <>
                    <Grid item xs={12}>
                      {formattedArrayFiles.map((item) => (
                        <Card className={classes.upload}>
                          <CardContent className={classes.uploadContent}>
                            <AttachmentIcon />
                            <div className={classes.uploadDetail}>
                              <span>{item.name}</span>
                              <span className={classes.fileSize}>
                                {filesize(item.size, { round: 0 })}
                              </span>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                      {attachment.length !== 0 ? (
                        <>
                          <Typography color="textPrimary" variant="h7">
                            {' '}
                            Remover Anexos
                          </Typography>
                          <IconButton onClick={handleRemoveAttachment}>
                            <CancelIcon className={classes.cancelUploadIcon} />
                          </IconButton>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid container item justifyContent="flex-end">
              <Button
                disabled={!!isEdit || loading || !!Object.keys(errors).length}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Salvar chamado
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  )
}

CallFormTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  initialValues: PropTypes.shape({}),
}

CallFormTab.defaultProps = {
  id: null,
  initialValues: {},
}
