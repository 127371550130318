import React, { useRef, useEffect, useState } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  MenuItem,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import { Backup as UploadIcon } from '@material-ui/icons'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import { Creators as EquipmentActions } from '~/store/ducks/equipment'
import { Creators as EquipmentsActions } from '~/store/ducks/equipments'
import OverlayLoading from '~/components/OverlayLoading'
import Autocomplete from '~/components/Autocomplete'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'

import styles from './styles'

const useStyles = makeStyles(styles)

const equipmentSelector = ({ equipment }) => equipment
const equipmentsDealershipsSelector = ({ equipment: { dealerships } }) =>
  dealerships
const equipmentsUsersSelector = ({ equipment: { users } }) => users
const equipmentsEquipmentsTypesSelector = ({
  equipment: { equipmentsTypes },
}) => equipmentsTypes

const EquipmentsDialog = ({ open, onClose, id, codEquipment }) => {
  const [image, setImage] = useState('')
  const [imageUpload, setImageUpload] = useState('')
  const fileInput = useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()
  const { data: equipmentsData, loading } = useSelector(equipmentSelector)
  const isEdit = !!id
  const { data: dealershipsData } = useSelector(equipmentsDealershipsSelector)
  const { data: usersData, loading: usersLoading } = useSelector(
    equipmentsUsersSelector,
  )
  const {
    data: equipmentsTypesData,
    loading: equipmentsTypesLoading,
  } = useSelector(equipmentsEquipmentsTypesSelector)
  const [departmentsByDealership, setDepartmentsByDealhership] = useState([])

  const defaultValues = {
    brand: '',
    cod_equipment: '',
    conservation_state: '',
    dealership_id: '',
    department_id: '',
    description: '',
    equipment_type_id: '',
    model: '',
    price: '',
    purchased_at: null,
    responsible_id: '',
  }

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const dealershipValue = watch('dealership_id')

  const conservationsOptions = [
    { id: 1, name: 'Bom' },
    { id: 2, name: 'Regular' },
    { id: 3, name: 'Ruim' },
  ]

  useEffect(() => {
    if (isEdit) {
      dispatch(EquipmentActions.getEquipmentRequest(id))
    }
  }, [isEdit, id])

  useEffect(() => {
    if (isEdit) {
      reset(equipmentsData)
    }
  }, [isEdit, equipmentsData])

  useEffect(() => {
    if (open) {
      reset(defaultValues)
      dispatch(EquipmentActions.getEquipmentDealershipsRequest())
      dispatch(EquipmentActions.getEquipmentUsersRequest())
      dispatch(EquipmentActions.getEquipmentEquipmentsTypesRequest())
      setValue('cod_equipment', codEquipment)
    }
  }, [open, codEquipment])

  useEffect(() => {
    if (dealershipValue) {
      setDepartmentsByDealhership(
        dealershipsData.find((d) => d.id === dealershipValue)?.departments,
      )
    }
  }, [dealershipValue])

  const reloadEquipments = () => {
    dispatch(EquipmentsActions.getEquipmentsRequest())
  }

  const handleSubmitForm = (formData) => {
    let formattedData
    if (image) {
      formattedData = {
        ...formData,
        image: imageUpload,
      }
    } else {
      formattedData = formData
    }
    if (isEdit) {
      // update -> PUT
      dispatch(
        EquipmentActions.updateEquipmentRequest(id, formattedData, () =>
          reloadEquipments(),
        ),
      )
    } else {
      // create -> POST
      dispatch(
        EquipmentActions.addEquipmentRequest(formattedData, () => {
          reloadEquipments()
          onClose()
        }),
      )
    }
    onClose()
  }

  const handleUploadImage = (e) => {
    const allowedExtensions = {
      image: ['png', 'jpg', 'jpeg'],
    }
    const [file] = e.target.files
    if (!file) {
      return
    }
    const [fileType, ext] = file.type.split('/')

    if (
      !allowedExtensions[fileType] ||
      !allowedExtensions[fileType].includes(ext)
    ) {
      dispatch(
        FlashMessageActions.showMessage({
          id: 'extension_not_allowed',
          variant: 'error',
        }),
      )
      return
    }
    setImageUpload(file)
    const reader = new FileReader()
    reader.addEventListener('load', () => setImage(reader.result))
    reader.readAsDataURL(file)
    e.target.value = null
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      fullScreen={isMobileScreen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          Equipamento
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      {!isEdit && (
        <>
          <Grid container className={classes.subtitleWrapper}>
            <Grid item xs={12}>
              <Typography variant="h5">Novo Equipamento</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dividerBottom} />
        </>
      )}

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <form onSubmit={handleSubmit(handleSubmitForm)} id="form-equipment">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="cod_equipment"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Código Equipamanto"
                      placeholder="Informe o código do equipamento"
                      onChange={onChange}
                      value={value || ''}
                      error={!!errors?.cod_equipment}
                      helperText={
                        !!errors?.cod_equipment && 'Campo obrigatório'
                      }
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dealership_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Concessionária"
                      select
                      onChange={onChange}
                      value={value || ''}
                      error={!!errors?.dealership_id}
                      helperText={
                        !!errors?.dealership_id && 'Campo obrigatório'
                      }
                      fullWidth
                      {...field}
                    >
                      {dealershipsData.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="department_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Departamento"
                      select
                      disabled={!dealershipValue}
                      onChange={onChange}
                      value={value || ''}
                      error={!!errors?.department_id}
                      helperText={
                        !!errors?.department_id && 'Campo obrigatório'
                      }
                      fullWidth
                      {...field}
                    >
                      {departmentsByDealership?.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="responsible_id"
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Autocomplete
                      onChange={(_, option) => {
                        onChange(option ? option.value : null)
                      }}
                      options={usersData?.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      inputRef={ref}
                      value={value || null}
                      className={classes.autocomplete}
                      label="Colaborador"
                      placeholder="Selecione o responsável"
                      error={!!errors?.responsible_id}
                      helperText={errors?.responsible_id && 'Campo obrigatório'}
                      loading={usersLoading}
                      autoSelect={false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="equipment_type_id"
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Autocomplete
                      onChange={(_, option) => {
                        onChange(option ? option.value : null)
                      }}
                      options={equipmentsTypesData?.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      inputRef={ref}
                      value={value || null}
                      className={classes.autocomplete}
                      label="Tipo de equipamento"
                      placeholder="Selecione o responsável"
                      error={!!errors?.equipment_type_id}
                      helperText={
                        !!errors?.equipment_type_id && 'Campo obrigatório'
                      }
                      loading={equipmentsTypesLoading}
                      autoSelect={false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="brand"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Marca"
                      onChange={onChange}
                      value={value || ''}
                      // error={!!errors?.brand}
                      // helperText={!!errors?.brand && 'Campo obrigatório'}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="model"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Modelo"
                      onChange={onChange}
                      value={value || ''}
                      // error={!!errors?.model}
                      // helperText={!!errors?.model && 'Campo obrigatório'}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Descrição"
                      onChange={onChange}
                      value={value || ''}
                      multiline
                      rows={2}
                      // error={!!errors?.description}
                      // helperText={!!errors?.description && 'Campo obrigatório'}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="purchased_at"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, ...field } }) => (
                    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        label="Data de compra"
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data inválida"
                        onChange={(dateValue) => onChange(dateValue)}
                        {...field}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="price"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <NumberFormat
                      prefix="R$"
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale
                      customInput={TextField}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      fullWidth
                      label="Valor do equipamento"
                      name="productPrice"
                      onChange={onChange}
                      value={value || ''}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="conservation_state"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      variant="outlined"
                      label="Estado de conservação"
                      select
                      onChange={onChange}
                      value={value || ''}
                      fullWidth
                      {...field}
                    >
                      {conservationsOptions?.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {image && (
                <Grid item xs={12} className={classes.imageWrapper}>
                  <img src={image} alt="imagem" />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.uploadButton}
                  onClick={() => {
                    fileInput.current.click()
                  }}
                >
                  <UploadIcon className={classes.uploadIcon} />
                  Selecionar imagem
                  <input
                    type="file"
                    multiple={false}
                    accept=".png,.jpg"
                    onClick={(e) => {
                      e.target.value = ''
                    }}
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={handleUploadImage}
                  />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button
          color="primary"
          type="submit"
          form="form-equipment"
          variant="contained"
        >
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EquipmentsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  codEquipment: PropTypes.string,
}

EquipmentsDialog.defaultProps = {
  id: null,
  codEquipment: null,
}

export default EquipmentsDialog
