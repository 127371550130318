import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import MomentBusinessDays from 'moment-business-days'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function MonthCountdown() {
  const classes = useStyles()

  const [days, setDays] = useState('')
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')

  const getTimeUntil = (deadline) => {
    const businessDays = MomentBusinessDays().monthBusinessDays()
    const lastBusinessDay = moment(businessDays.pop())
    const lastBusinessDayUnix = lastBusinessDay.unix()
    const todayUnix = moment().unix()

    const diffTime = lastBusinessDayUnix - todayUnix
    let duration = moment.duration(diffTime * 1000, 'milliseconds')

    if (diffTime > 0) {
      duration = moment.duration(
        duration.asMilliseconds() - deadline,
        'milliseconds',
      )
      setDays(MomentBusinessDays().businessDiff(lastBusinessDay))
      setHours(moment.duration(duration).hours())
      setMinutes(moment.duration(duration).minutes())
      setSeconds(moment.duration(duration).seconds())
    }
  }

  useEffect(() => {
    moment.updateLocale('en', {
      workingWeekdays: [1, 2, 3, 4, 5, 6],
      holidays: [
        '01-01',
        '02-02',
        '04-03',
        '05-03',
        '06-03',
        '19-04',
        '21-04',
        '01-05',
        '20-06',
        '07-09',
        '20-09',
        '12-10',
        '15-10',
        '28-10',
        '02-11',
        '15-11',
        '24-12',
        '25-12',
        '31-12',
      ],
      holidayFormat: 'DD-MM',
    })
    const interval = 1000

    setInterval(() => getTimeUntil(interval), interval)

    return () => getTimeUntil(interval)
  }, [])

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item>
        {days !== '' && (
          <>
            <Typography color="primary" variant="h3">
              {days}
            </Typography>
            <Typography color="primary" variant="h5">
              D
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        {hours !== '' && (
          <>
            <Typography color="primary" variant="h3">
              {hours}
            </Typography>
            <Typography color="primary" variant="h5">
              H
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        {minutes !== '' && (
          <>
            <Typography color="primary" variant="h3">
              {minutes}
            </Typography>
            <Typography color="primary" variant="h5">
              M
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        {seconds !== '' && (
          <>
            <Typography color="primary" variant="h3">
              {seconds}
            </Typography>
            <Typography color="primary" variant="h5">
              S
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}
