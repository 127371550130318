import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getQuizzesRequest: [],
  getQuizzesFailure: [],
  getQuizzesSuccess: ['data', 'total'],
  setQuizzesSort: ['sort', 'direction'],
  setQuizzesPage: ['page', 'limit'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const getQuizzesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getQuizzesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getQuizzesSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setQuizzesSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setQuizzesPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_QUIZZES_REQUEST]: getQuizzesRequest,
  [Types.GET_QUIZZES_FAILURE]: getQuizzesFailure,
  [Types.GET_QUIZZES_SUCCESS]: getQuizzesSuccess,
  [Types.SET_QUIZZES_SORT]: setQuizzesSort,
  [Types.SET_QUIZZES_PAGE]: setQuizzesPage,
})
