import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getStatusListRequest: [],
  getStatusListFailure: [],
  getStatusListSuccess: ['data', 'total'],
  setStatusSort: ['sort', 'direction'],
  setStatusPage: ['page', 'limit'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const getStatusListRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getStatusListFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getStatusListSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setStatusSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setStatusPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_STATUS_LIST_REQUEST]: getStatusListRequest,
  [Types.GET_STATUS_LIST_FAILURE]: getStatusListFailure,
  [Types.GET_STATUS_LIST_SUCCESS]: getStatusListSuccess,
  [Types.SET_STATUS_SORT]: setStatusSort,
  [Types.SET_STATUS_PAGE]: setStatusPage,
})
