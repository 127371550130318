import React from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useDispatch, useSelector } from 'react-redux'

import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import { Grid, makeStyles, TextField, MenuItem } from '@material-ui/core'
import { Creators as RhMonthComparativeActions } from '~/store/ducks/rhMonthComparative'
import styles from './styles'

const rhMonthComparativeSelector = ({ rhMonthComparative }) =>
  rhMonthComparative

const useStyles = makeStyles(styles)

function RhMonthComparativeFilters() {
  const { filters, filtersData } = useSelector(rhMonthComparativeSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChangeFilter = (property, value) => {
    dispatch(
      RhMonthComparativeActions.setMonthComparativeFilter(property, value),
    )
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.header} spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              fullWidth
              views={['month', 'year']}
              label="Mês/Ano"
              invalidDateMessage="Data inválida"
              format="MM/yyyy"
              value={filters.date}
              onChange={(dValue) => handleChangeFilter('date', dValue)}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            variant="outlined"
            label="Grupo"
            fullWidth
            select
            value={filters.group}
            onChange={(ev) => handleChangeFilter('group', ev.target.value)}
          >
            {filtersData.groups.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            variant="outlined"
            label="Vínculo"
            fullWidth
            select
            value={filters.bond}
            onChange={(ev) => handleChangeFilter('bond', ev.target.value)}
          >
            {filtersData.bonds.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </div>
  )
}

export default RhMonthComparativeFilters
