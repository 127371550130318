import React, { useEffect } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as IslandsActions } from '~/store/ducks/islands'

import OverlayLoading from '~/components/OverlayLoading'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import styles from './styles'

const useStyles = makeStyles(styles)

const islandsSelector = ({ islands }) => islands

const IslandDialog = ({ open, onClose, id }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, loading } = useSelector(islandsSelector)

  const isMobileScreen = useMobileScreen()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (id) {
      dispatch(IslandsActions.getIslandRequest(id))
    }
  }, [id])

  const reloadIslandsList = () => {
    dispatch(IslandsActions.getIslandsRequest())
  }

  const onSubmit = (formData) => {
    dispatch(
      IslandsActions.updateIslandRequest(id, formData, () => {
        reloadIslandsList()
        onClose()
      }),
    )
  }

  useEffect(() => {
    reset(data)
  }, [data, reset])

  const handleResetForm = () => {
    reset({ name: '' })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
      open={open}
      onClose={(onClose, handleResetForm)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Editar Ramal</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <>
            <>
              <Divider className={classes.dividerBottom} />
            </>

            <form onSubmit={handleSubmit(onSubmit)} id="form-islands">
              <Grid container spacing={2} className={classes.contentWrapper}>
                <Grid item xs={12}>
                  <Controller
                    name="ramal"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <TextField
                        variant="outlined"
                        autoFocus
                        label="Ramal"
                        type="text"
                        onChange={onChange}
                        value={value || ''}
                        error={!!errors.name}
                        helperText={!!errors.name && 'Campo obrigatório'}
                        fullWidth
                        {...rest}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent="flex-end"
                style={{ marginTop: 16 }}
              >
                <Button
                  color="primary"
                  type="submit"
                  form="form-islands"
                  variant="contained"
                >
                  SALVAR RAMAL
                </Button>
              </Grid>
            </form>
          </>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IslandDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

IslandDialog.defaultProps = {
  id: null,
}

export default IslandDialog
