import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Card,
  CardHeader,
  Avatar,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Divider,
  makeStyles,
  Fade,
  Menu,
  Button,
  Tooltip,
  TextField,
  IconButton,
} from '@material-ui/core'

import CommentIcon from '@material-ui/icons/Comment'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { MdOutlineAddReaction as ReactionIcon } from 'react-icons/md'
import { find } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

import { ReactionBarSelector } from '~/components/ReactionBarSelector'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'
import CustomMentionsInput from '~/components/CustomMentionsInput'

import { reactions } from './helper/reactions'

import styles from './styles'

const sessionSelector = ({ session }) => session

const useStyles = makeStyles(styles)

const loadingNewsCommentsSelector = ({ newsBoard: { loadingComments } }) =>
  loadingComments

export default function NewsCard({ data, onOpenImageDialog }) {
  const { user: userSession } = useSelector(sessionSelector)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [commentIdDialog, setcommentIdDialog] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const loadingComments = useSelector(loadingNewsCommentsSelector)
  const [commentValueAdd, setCommentValueAdd] = useState('')
  const [commentValueUpdate, setCommentValueUpdate] = useState('')
  const [commentTextArea, setCommentTextArea] = useState(false)
  const [idComment, setIdComment] = useState(null)
  const [showComments, setShowComments] = useState(false)
  const el = React.useRef(null)

  const isEdit = idComment !== null

  const getEmbedId = (urlVideo) => {
    if (urlVideo.includes('youtube')) {
      return urlVideo?.split('v=')[1]
    }
    return urlVideo?.split('.com/')[1]
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const resetNews = () => {
    dispatch(NewsBoardActions.getNewsBoardRequestFeed('feed', true))
    dispatch(NewsBoardActions.getNewsBoardRequest('feed-communication', true))
  }

  const handleChangeReaction = (newsReaction) => {
    dispatch(NewsBoardActions.setNewsReactionRequest(data.id, newsReaction))
    handleClose()
    resetNews()
  }

  const openInputText = () => {
    setCommentTextArea(true)
    setCommentValueAdd('')
  }

  const getMentionsId = () => {
    const text = isEdit ? commentValueUpdate : commentValueAdd
    if (!text) {
      return []
    }

    return [...text?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map((item) =>
      Number(item[2]),
    )
  }

  const onSubmitForm = () => {
    if (isEdit) {
      const updateData = { comment: commentValueUpdate }
      dispatch(
        NewsBoardActions.updateNewsCommentRequest(idComment, updateData, () => {
          resetNews()
          setCommentValueUpdate('')
          setIdComment(null)
        }),
      )
      setCommentTextArea(false)
    } else {
      const formData = {
        news_id: data.id,
        comment: commentValueAdd,
        mentions_id: getMentionsId(),
      }
      setCommentTextArea(false)
      dispatch(
        NewsBoardActions.addNewsCommentRequest(formData, () => {
          resetNews()
          setCommentValueAdd('')
        }),
      )
    }
  }

  const handleSetComment = (comment) => {
    if (isEdit) {
      setCommentValueUpdate(comment)
    } else {
      setCommentValueAdd(comment)
    }
  }

  const handleCancelComment = () => {
    setCommentTextArea(false)
    setOpenConfirmDialog(false)
    setCommentValueAdd('')
    setCommentValueUpdate('')
    setIdComment(null)
  }
  const handleEditComment = (item) => {
    setCommentValueUpdate(item.comment)
    setIdComment(item.id)
  }

  const handleRemoveComment = () => {
    dispatch(
      NewsBoardActions.removeNewsCommentRequest(commentIdDialog, () => {
        resetNews()
      }),
    )
    setOpenConfirmDialog(false)
  }

  const handleOpenDialogRemoveComment = (commentId) => {
    setOpenConfirmDialog(true)
    setcommentIdDialog(commentId)
  }

  const getCurrentMention = (mention) => {
    // return `@[${mention.user.name}](${mention.user.id})`
    return `<span class="mention-text" data-id="mention-${mention.id}-user-${mention.user.id}" data-image=${mention.user.url_image}>@${mention.user.name}</span>`
  }

  const getUsersOptions = async (search, callback) => {
    if (!search) {
      return
    }
    dispatch(
      NewsBoardActions.getNewsUsersRequest(search, (cbUsers) =>
        callback(cbUsers),
      ),
    )
  }

  const getText = (str, mentions) => {
    const text = str || ''

    const usersMentions = [...text?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map(
      (item) => ({
        previous: item[0],
        current: getCurrentMention(
          mentions?.find((mention) => mention.user_id === Number(item[2])),
        ),
      }),
    )
    return usersMentions.reduce(
      (acc, item) => acc.replace(item.previous, item.current),
      str,
    )
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={<Avatar src={data.author.url_image} />}
          title={data.author.name}
          subheader={`${moment(data.created_at).format('L')} - ${moment(
            data.created_at,
          ).format('HH:mm')}`}
        />
        <CardContent>
          <Grid>
            {data.url_image && (
              <CardMedia
                onClick={() => onOpenImageDialog(data.url_image)}
                image={data.url_image}
                title={data.title}
                className={classes.image}
              />
            )}
            {data.url_video && data.url_video.includes('youtube') && (
              <div className={classes.videoWrapper}>
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${getEmbedId(
                    data?.url_video,
                  )}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
            {data.url_video && data.url_video.includes('vimeo') && (
              <div className={classes.videoWrapper}>
                <iframe
                  title="vimeo-player"
                  src={`https://player.vimeo.com/video/${getEmbedId(
                    data?.url_video,
                  )}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            )}
          </Grid>
          <div className={classes.titleWrapper}>
            <Typography variant="h5">{data.title}</Typography>
          </div>
          <Typography
            innerRef={el}
            variant="body1"
            className={classes.postWrapper}
            dangerouslySetInnerHTML={{
              __html: getText(data.text, data.mentions),
            }}
          />
        </CardContent>

        {(data?.reactions.length > 0 || data?.comments.length > 0) && (
          <>
            <Divider style={{ marginBottom: 4 }} />
            <Grid container className={classes.actionsWrapper}>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  container
                  spacing={1}
                  className={classes.reactionsContainer}
                >
                  {data.reactions.map(({ reaction, total, users }) => (
                    <React.Fragment key={reaction}>
                      <Tooltip
                        classes={{
                          popper: classes.tooltipPopper,
                        }}
                        title={users.map((user) => (
                          <div key={user}>{user}</div>
                        ))}
                        placement="top"
                      >
                        <Grid
                          item
                          onClick={() => handleChangeReaction(reaction)}
                        >
                          <div className={classes.reactionsCounter}>
                            <div className={classes.reactionCounterItem}>
                              {find(reactions, { label: reaction })?.node}
                            </div>
                            <Typography
                              variant="h6"
                              className={classes.reactionsTotalText}
                              color="primary"
                            >
                              {total}
                            </Typography>
                          </div>
                        </Grid>
                      </Tooltip>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Grid
                    item
                    className={classes.showCommentsButton}
                    onClick={() => setShowComments(!showComments)}
                  >
                    <Tooltip title="Ver comentários" placement="top">
                      <span>
                        {data.comments?.length > 0 && (
                          <>
                            {data.comments.length}
                            {data.comments.length > 1
                              ? ' comentários'
                              : ' comentário'}
                          </>
                        )}
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Divider style={{ marginTop: 8 }} />
        <Grid
          container
          className={classes.reactionsButtonWrapper}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              fullWidth
              id="fade-button"
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className={classes.reactionIcon}
            >
              <span style={{ marginRight: 8, display: 'flex' }}>
                <ReactionIcon fontSize={22} />
              </span>
              <Typography variant="h6">Reagir</Typography>
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              PaperProps={{
                style: {
                  borderRadius: '50px',
                  transform: 'translateY(-70%)',
                },
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              className={classes.menuList}
            >
              <div ref={anchorEl}>
                <ReactionBarSelector
                  reactions={reactions}
                  iconSize={18}
                  onSelect={handleChangeReaction}
                />
              </div>
            </Menu>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button fullWidth onClick={() => openInputText()}>
              <CommentIcon />
              <Typography variant="h6" style={{ marginLeft: 4 }}>
                COMENTAR
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>

      {loadingComments && <OverlayLoading size={40} />}
      {commentTextArea && (
        <Grid
          container
          className={classes.commentsWrapper}
          justifyContent="flex-end"
        >
          <div className={classes.commentArea}>
            <CardHeader
              style={{ padding: 0, marginBottom: '16px' }}
              avatar={<Avatar src={userSession.url_image} />}
              title={userSession.name}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault()
                onSubmitForm()
              }}
              id="form-comment-news-add"
            >
              <CustomMentionsInput
                value={commentValueAdd}
                onChange={(e) => {
                  handleSetComment(e.target.value)
                }}
                data={getUsersOptions}
                isComment
              />

              <Grid
                container
                justifyContent="space-between"
                className={classes.buttonsContainer}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => handleCancelComment()}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="form-comment-news-add"
                  disabled={commentValueAdd === '' && commentValueUpdate === ''}
                >
                  Enviar
                </Button>
              </Grid>
            </form>
          </div>
        </Grid>
      )}

      {!!data.comments.length &&
        showComments &&
        data.comments
          .sort((a, b) => b.id - a.id)
          .map((item) => {
            if (item.id === idComment) {
              return (
                <Grid
                  container
                  className={classes.commentsWrapper}
                  justifyContent="flex-end"
                  key={item.id}
                >
                  <div className={classes.commentedArea}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <CardHeader
                          style={{ padding: 0, marginBottom: '16px' }}
                          avatar={<Avatar src={item.user.url_image} />}
                          title={item.user.name}
                          subheader={`${moment(item.created_at).format(
                            'L',
                          )} - ${moment(item.created_at).format('HH:mm')}`}
                        />
                      </Grid>

                      {item.user.name === userSession.name && (
                        <Grid item>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleEditComment(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() =>
                              handleOpenDialogRemoveComment(item.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        onSubmitForm()
                      }}
                      id="form-comment-edit"
                    >
                      <TextField
                        fullWidth
                        placeholder="Escreva um comentário!"
                        multiline
                        variant="outlined"
                        onChange={(ev) => handleSetComment(ev.target.value)}
                        minRows={6}
                        defaultValue={commentValueUpdate}
                        autoFocus
                      />
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.buttonsContainer}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={() => handleCancelComment()}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          form="form-comment-edit"
                          disabled={
                            commentValueAdd === '' && commentValueUpdate === ''
                          }
                        >
                          Enviar
                        </Button>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              )
            }
            return (
              <Grid
                container
                key={item.id}
                className={classes.commentsWrapper}
                justifyContent="flex-end"
              >
                <div className={classes.commentedArea}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <CardHeader
                        style={{ padding: 0, marginBottom: '16px' }}
                        avatar={<Avatar src={item.user.url_image} />}
                        title={item.user.name}
                        subheader={`${moment(item.created_at).format(
                          'L',
                        )} - ${moment(item.created_at).format('HH:mm')}`}
                      />
                    </Grid>

                    {item.user.name === userSession.name && (
                      <Grid item>
                        {' '}
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleEditComment(item)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleOpenDialogRemoveComment(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: getText(item.comment, item.mentions),
                    }}
                  />
                </div>
              </Grid>
            )
          })}

      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este comentário?"
        onCancel={handleCancelComment}
        onAccept={handleRemoveComment}
      />
    </>
  )
}

NewsCard.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      url_image: PropTypes.string,
    }).isRequired,
    created_at: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    comment: PropTypes.string,
    id: PropTypes.number,
    text: PropTypes.string,
    user: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    url_image: PropTypes.string,
    url_video: PropTypes.string,
    reactions: PropTypes.arrayOf(PropTypes.shape({})),
    mentions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onOpenImageDialog: PropTypes.func.isRequired,
}
