/* eslint-disable no-dupe-keys */
import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import jsonToFormData from '~/helpers/jsonToFormData'

import { Types as CallTypes, Creators as CallActions } from '../ducks/call'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()

function* addCall({ data, callback }) {
  try {
    const formData = jsonToFormData(data)

    if (data?.files) {
      data?.files.map((file) => formData.append('files[]', file))
    }

    if (data?.file) {
      data?.file.map((file) => formData.append('files[]', file))
    }

    yield call(api.post, '/calls', formData)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Chamado criado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(CallActions.addCallFinish())
  } catch (e) {
    yield put(CallActions.addCallFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCall({ id }) {
  try {
    const { data } = yield call(api.get, `/calls/${id}`)

    yield put(CallActions.getCallSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCallAttachments({ id }) {
  try {
    const { data } = yield call(api.get, '/call_attachments', {
      params: {
        pagination: 0,
        order: 'created_at,asc',
        'where[call_id]': id,
      },
    })

    yield put(CallActions.getCallAttachmentsSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallAttachmentsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* uploadCallAttachment({ data, callback }) {
  const formData = jsonToFormData(data)
  if (data?.files) {
    data?.files.map((file) => formData.append('files[]', file))
  }

  if (data?.file) {
    data?.file.map((file) => formData.append('files[]', file))
  }
  try {
    yield call(api.post, '/call_attachments', formData)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Anexo adicionado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(CallActions.uploadCallAttachmentFinish())
  } catch (e) {
    yield put(CallActions.uploadCallAttachmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeCallAttachment({ id, callback }) {
  try {
    yield call(api.delete, `call_attachments/${id}`)

    yield put(
      FlashMessageActions.showMessage({
        message: 'Anexo removido com sucesso!',
        variant: 'success',
      }),
    )

    if (callback) {
      yield call(callback)
    }

    yield put(CallActions.removeCallAttachmentFinish())
  } catch (e) {
    yield put(CallActions.removeCallAttachmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCallActions() {
  try {
    const { data } = yield call(api.get, 'actions', {
      params: {
        pagination: 0,
        order: 'description,asc',
        'wherenotin[id]': 1,
      },
    })

    yield put(CallActions.getCallActionsSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallActionsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCallHistories({ id }) {
  try {
    const { data } = yield call(api.get, 'call_histories', {
      params: {
        pagination: 0,
        order: 'created_at,asc',
        'where[call_id]]': id,
      },
    })

    yield put(CallActions.getCallHistoriesSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallHistoriesFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCallAttendants({ departmentId, subjectId }) {
  try {
    const { data } = yield call(api.get, 'attendants', {
      params: {
        pagination: 0,
        order: 'created_at,asc',
        'where[department_id]]': departmentId,
        'where[subject_id]': subjectId,
      },
    })

    yield put(CallActions.getCallAttendantsSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallAttendantsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}
function* addCallActions({ data, callback }) {
  try {
    yield call(api.post, 'call_histories', data)

    yield put(CallActions.addCallActionsFinish())
  } catch (e) {
    yield put(CallActions.addCallActionsFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  }
}

function* getCallDealerships() {
  try {
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })

    yield put(CallActions.getCallDealershipsSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallDealershipsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

const optionsSelector = ({ call: { duplicateList } }) => duplicateList.options

function* getCallDuplicateList({ subjectId, id }) {
  const options = yield select(optionsSelector)

  try {
    const search = []
    if (options.search) {
      search.push(`requester_name,${options.search}`)
      search.push(`id,${options.search}`)
      search.push(`responsible_name,${options.search}`)
      search.push(`subject,${options.search}`)
    }

    const { data } = yield call(api.get, 'vw_calls', {
      params: {
        pagination: 0,
        order: 'id,desc',
        'where[subject_id]': subjectId,
        'wherenotin[id]': id,
        status_id: 5,
        search,
      },
    })

    yield put(CallActions.getCallDuplicateListSuccess(data))
  } catch (e) {
    yield put(CallActions.getCallDuplicateListFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadDuplicateList({ subjectId, statusId, id }) {
  yield getCallDuplicateList({ subjectId, statusId, id })
}

function* markAsDuplicate({ callRefId, callDupId, callback }) {
  try {
    yield call(api.get, 'call_duplicated', {
      params: {
        call_ref_id: callRefId,
        call_dup_id: callDupId,
      },
    })

    yield put(CallActions.markAsDuplicateSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Chamado marcado como duplicado com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(CallActions.markAsDuplicateFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

// function* getDownloadFile({ id, callback }) {
//   yield call(api.get, `download/${id}`)
//   if (callback) {
//     yield call(callback)
//   }
// yield put(CallActions.getDownloadFileSuccess(data))
// }

export default function* () {
  yield takeLatest(CallTypes.ADD_CALL_REQUEST, addCall)
  yield takeLatest(CallTypes.GET_CALL_REQUEST, getCall)
  yield takeLatest(CallTypes.GET_CALL_ATTACHMENTS_REQUEST, getCallAttachments)
  yield takeLatest(
    CallTypes.UPLOAD_CALL_ATTACHMENT_REQUEST,
    uploadCallAttachment,
  )
  yield takeLatest(
    CallTypes.REMOVE_CALL_ATTACHMENT_REQUEST,
    removeCallAttachment,
  )
  yield takeLatest(CallTypes.GET_CALL_ACTIONS_REQUEST, getCallActions)
  yield takeLatest(CallTypes.GET_CALL_HISTORIES_REQUEST, getCallHistories)
  yield takeLatest(CallTypes.GET_CALL_ATTENDANTS_REQUEST, getCallAttendants)
  yield takeLatest(CallTypes.ADD_CALL_ACTIONS_REQUEST, addCallActions)
  yield takeLatest(CallTypes.GET_CALL_DEALERSHIPS_REQUEST, getCallDealerships)
  yield takeLatest(
    CallTypes.GET_CALL_DUPLICATE_LIST_REQUEST,
    getCallDuplicateList,
  )
  yield takeLatest(CallTypes.MARK_AS_DUPLICATE_REQUEST, markAsDuplicate)
  yield debounce(1000, CallTypes.SET_DUPLICATE_LIST_SEARCH, reloadDuplicateList)
  // yield takeLatest(CallTypes.GET_DOWNLOAD_FILE_REQUEST, getDownloadFile)
}
