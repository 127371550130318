import axios from 'axios'
import config from './config'
import { getTokenData, isSessionExpired, sessionExpiredLogout } from './session'

export default () => {
  const { url } = config
  const api = axios.create({ baseURL: url })

  api.interceptors.request.use(async (reqConfig) => {
    const { access_token: token } = getTokenData()

    const clone = { ...reqConfig }
    if (token) {
      if (isSessionExpired()) {
        sessionExpiredLogout()
        return new Promise(() => {})
      }
      clone.headers.Authorization = `Bearer ${token}`
    }

    return clone
  })

  return api
}
