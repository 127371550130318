import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addCallRequest: ['data', 'callback'],
  addCallFinish: [],
  uploadCallAttachmentRequest: ['data', 'callback'],
  uploadCallAttachmentFinish: [],
  removeCallAttachmentRequest: ['id', 'callback'],
  removeCallAttachmentFinish: [],
  getCallRequest: ['id'],
  getCallSuccess: ['data'],
  getCallFailure: [],
  getCallAttachmentsRequest: ['id'],
  getCallAttachmentsSuccess: ['data'],
  getCallAttachmentsFailure: [],
  getCallActionsRequest: [],
  getCallActionsSuccess: ['data'],
  getCallActionsFailure: [],
  getCallHistoriesRequest: ['id'],
  getCallHistoriesSuccess: ['data'],
  getCallHistoriesFailure: [],
  getCallAttendantsRequest: ['departmentId', 'subjectId'],
  getCallAttendantsSuccess: ['data'],
  getCallAttendantsFailure: [],
  addCallActionsRequest: ['data', 'callback'],
  addCallActionsFinish: [],
  getCallDealershipsRequest: [],
  getCallDealershipsSuccess: ['data'],
  getCallDealershipsFailure: [],
  resetCall: [],
  getCallDuplicateListRequest: ['subjectId', 'statusId', 'id'],
  getCallDuplicateListSuccess: ['data'],
  getCallDuplicateListFailure: [],
  markAsDuplicateRequest: ['callDupId', 'callRefId', 'callback'],
  markAsDuplicateSuccess: [],
  markAsDuplicateFailure: [],
  setDuplicateListSearch: ['search', 'subjectId', 'statusId', 'id'],
  // getDownloadFileRequest: ['id', 'callback'],
  // getDownloadFileSuccess: ['data'],
})

const INITIAL_STATE = {
  data: {
    branch_contact: '',
    dealership_id: null,
    deparment_id: null,
    description: '',
    ip_number: '',
    observers: '',
    requester_id: null,
    subject_id: null,
    status_id: null,
    id: null,
  },
  loading: false,
  attachments: {
    data: [],
    loading: false,
  },
  actions: {
    data: [],
    loading: false,
  },
  histories: {
    data: [],
    loading: false,
  },
  attendants: {
    data: [],
    loading: false,
  },
  dealerships: {
    data: [],
    loading: false,
  },
  duplicateList: {
    data: [],
    loading: false,
    options: {
      search: '',
    },
  },
  dataFile: '',
}

const addCallRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addCallFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const uploadCallAttachmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: true,
  },
})

const uploadCallAttachmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: false,
  },
})

const removeCallAttachmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: true,
  },
})

const removeCallAttachmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: false,
  },
})

const getCallRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getCallSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getCallFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCallAttachmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: true,
  },
})

const getCallAttachmentsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  attachments: {
    ...state.attachments,
    data,
    loading: false,
  },
})

const getCallAttachmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  attachments: {
    ...state.attachments,
    loading: false,
  },
})

const getCallActionsRequest = (state = INITIAL_STATE) => ({
  ...state,
  actions: {
    ...state.actions,
    loading: true,
  },
})

const getCallActionsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  actions: {
    ...state.actions,
    data,
    loading: false,
  },
})

const getCallActionsFailure = (state = INITIAL_STATE) => ({
  ...state,
  actions: {
    ...state.actions,
    loading: false,
  },
})

const getCallHistoriesRequest = (state = INITIAL_STATE) => ({
  ...state,
  histories: {
    ...state.histories,
    loading: true,
  },
})

const getCallHistoriesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  histories: {
    ...state.histories,
    data,
    loading: false,
  },
})

const getCallHistoriesFailure = (state = INITIAL_STATE) => ({
  ...state,
  histories: {
    ...state.histories,
    loading: false,
  },
})

const getCallAttendantsRequest = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: true,
  },
})

const getCallAttendantsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  attendants: {
    ...state.attendants,
    data,
    loading: false,
  },
})

const getCallAttendantsFailure = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: false,
  },
})

const addCallActionsRequest = (state = INITIAL_STATE) => ({
  ...state,
  histories: {
    ...state.histories,
    loading: true,
  },
})

const addCallActionsFinish = (state = INITIAL_STATE) => ({
  ...state,
  histories: {
    ...state.histories,
    loading: false,
  },
})

const getCallDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getCallDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getCallDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const resetCall = () => ({
  ...INITIAL_STATE,
})

const getCallDuplicateListRequest = (state = INITIAL_STATE) => ({
  ...state,
  duplicateList: {
    ...state.duplicateList,
    loading: true,
  },
})

const getCallDuplicateListSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  duplicateList: {
    ...state.duplicateList,
    data,
    loading: false,
  },
})

const getCallDuplicateListFailure = (state = INITIAL_STATE) => ({
  ...state,
  duplicateList: {
    ...state.duplicateList,
    loading: false,
  },
})

const markAsDuplicateRequest = (state = INITIAL_STATE) => ({
  ...state,
  duplicateList: {
    ...state.duplicateList,
    loading: true,
  },
})

const markAsDuplicateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  duplicateList: {
    ...state.markAsDuplicate,
    loading: false,
  },
})

const markAsDuplicateFailure = (state = INITIAL_STATE) => ({
  ...state,
  duplicateList: {
    ...state.markAsDuplicate,
    loading: false,
  },
})

const setDuplicateListSearch = (state = INITIAL_STATE, { search }) => ({
  ...state,
  duplicateList: {
    ...state.duplicateList,
    options: {
      ...state.duplicateList.options,
      search,
    },
  },
})

// const getDownloadFileRequest = (state = INITIAL_STATE) => ({
//   ...state,
//   loading: false,
// })

// const getDownloadFileSuccess = (state = INITIAL_STATE, { data }) => ({
//   ...state,
//   loading: false,
//   dataFile: data,
// })

export default createReducer(INITIAL_STATE, {
  [Types.ADD_CALL_REQUEST]: addCallRequest,
  [Types.ADD_CALL_FINISH]: addCallFinish,
  [Types.UPLOAD_CALL_ATTACHMENT_REQUEST]: uploadCallAttachmentRequest,
  [Types.UPLOAD_CALL_ATTACHMENT_FINISH]: uploadCallAttachmentFinish,
  [Types.REMOVE_CALL_ATTACHMENT_REQUEST]: removeCallAttachmentRequest,
  [Types.REMOVE_CALL_ATTACHMENT_FINISH]: removeCallAttachmentFinish,
  [Types.GET_CALL_REQUEST]: getCallRequest,
  [Types.GET_CALL_SUCCESS]: getCallSuccess,
  [Types.GET_CALL_FAILURE]: getCallFailure,
  [Types.GET_CALL_ATTACHMENTS_REQUEST]: getCallAttachmentsRequest,
  [Types.GET_CALL_ATTACHMENTS_SUCCESS]: getCallAttachmentsSuccess,
  [Types.GET_CALL_ATTACHMENTS_FAILURE]: getCallAttachmentsFailure,
  [Types.GET_CALL_ACTIONS_REQUEST]: getCallActionsRequest,
  [Types.GET_CALL_ACTIONS_SUCCESS]: getCallActionsSuccess,
  [Types.GET_CALL_ACTIONS_FAILURE]: getCallActionsFailure,
  [Types.GET_CALL_HISTORIES_REQUEST]: getCallHistoriesRequest,
  [Types.GET_CALL_HISTORIES_SUCCESS]: getCallHistoriesSuccess,
  [Types.GET_CALL_HISTORIES_FAILURE]: getCallHistoriesFailure,
  [Types.GET_CALL_ATTENDANTS_REQUEST]: getCallAttendantsRequest,
  [Types.GET_CALL_ATTENDANTS_SUCCESS]: getCallAttendantsSuccess,
  [Types.GET_CALL_ATTENDANTS_FAILURE]: getCallAttendantsFailure,
  [Types.ADD_CALL_ACTIONS_REQUEST]: addCallActionsRequest,
  [Types.ADD_CALL_ACTIONS_FINISH]: addCallActionsFinish,
  [Types.GET_CALL_DEALERSHIPS_REQUEST]: getCallDealershipsRequest,
  [Types.GET_CALL_DEALERSHIPS_SUCCESS]: getCallDealershipsSuccess,
  [Types.GET_CALL_DEALERSHIPS_FAILURE]: getCallDealershipsFailure,
  [Types.RESET_CALL]: resetCall,
  [Types.GET_CALL_DUPLICATE_LIST_REQUEST]: getCallDuplicateListRequest,
  [Types.GET_CALL_DUPLICATE_LIST_SUCCESS]: getCallDuplicateListSuccess,
  [Types.GET_CALL_DUPLICATE_LIST_FAILURE]: getCallDuplicateListFailure,
  [Types.MARK_AS_DUPLICATE_REQUEST]: markAsDuplicateRequest,
  [Types.MARK_AS_DUPLICATE_SUCCESS]: markAsDuplicateSuccess,
  [Types.MARK_AS_DUPLICATE_FAILURE]: markAsDuplicateFailure,
  [Types.SET_DUPLICATE_LIST_SEARCH]: setDuplicateListSearch,
  // [Types.GET_DOWNLOAD_FILE_REQUEST]: getDownloadFileRequest,
  // [Types.GET_DOWNLOAD_FILE_SUCCESS]: getDownloadFileSuccess,
})
