import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  TableBody,
  TextField,
  makeStyles,
} from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useDispatch } from 'react-redux'

import { Creators as ObjectiveActions } from '~/store/ducks/objective'

import styles from './styles'

const useStyles = makeStyles(styles)

function CustomRow({ row }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleInputChange = (property, id, dealershipId, value) => {
    dispatch(
      ObjectiveActions.handleObjectiveTypeChange(
        property,
        id,
        dealershipId,
        value,
      ),
    )
  }

  return (
    <>
      <TableRow>
        <TableCell align="left" className={classes.smallCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.dealership}</TableCell>
      </TableRow>
      {open && row?.data.length > 0 && (
        <TableRow>
          <TableCell className={classes.tableWrapper} colSpan={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Objetivos</TableCell>
                  <TableCell align="center">Carteira</TableCell>
                  <TableCell align="center">Vendas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.data.map((item) => (
                  <TableRow key={item.objective_type}>
                    <TableCell>{item.objective_type}</TableCell>
                    <TableCell align="right">
                      <TextField
                        name="wallet"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="standard"
                        onChange={(e) =>
                          handleInputChange(
                            'wallet',
                            item.objective_type_id,
                            item.dealership_id,
                            e.target.value,
                          )
                        }
                        value={item.wallet || ''}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        name="sales"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="standard"
                        onChange={(e) =>
                          handleInputChange(
                            'sales',
                            item.objective_type_id,
                            item.dealership_id,
                            e.target.value,
                          )
                        }
                        value={item.sales || ''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

CustomRow.propTypes = {
  row: PropTypes.shape({
    dealership: PropTypes.string,
    dealership_name: PropTypes.string,
    seller_id: PropTypes.number,
    seller_name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default React.memo(CustomRow)
