import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import OverlayLoading from '~/components/OverlayLoading'
import { cpfMask } from '~/helpers/masks'
import logoImg from '~/assets/images/logo-login.png'
import logoMobile from '~/assets/images/logo-sidebar.png'

import { Creators as SessionActions } from '~/store/ducks/session'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'

import FlashMessage from '~/components/FlashMessage'

import styles from './styles'

const useStyles = makeStyles(styles)
function LoginPage({
  match: {
    params: { reason },
  },
}) {
  const loading = useSelector((state) => state.session.loading)
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm()

  const usernameWatch = watch('username')
  const passwordWatch = watch('password')

  const onSubmit = (credentials) => {
    dispatch(SessionActions.loginRequest(credentials))
  }

  useEffect(() => {
    if (reason === 'session-expired') {
      dispatch(
        FlashMessageActions.showMessage({
          message: 'Sua sessão expirou, por favor faça login novamente.',
          variant: 'warning',
        }),
      )
    }
  }, [reason])

  return (
    <>
      <FlashMessage />
      <div className={classes.root}>
        <Grid container className={classes.logoContainer}>
          <div className={classes.logoWrapper}>
            <img src={logoImg} alt="logo" className={classes.logoImg} />
          </div>
        </Grid>
        <Grid container className={classes.loginContainer}>
          <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              className={classes.formWrapper}
            >
              <Grid item xs={12} className={classes.logoMobile}>
                <img src={logoMobile} alt="logo" />
              </Grid>
              <Grid item>
                <Typography variant="h4" className={classes.title}>
                  Bem-vindo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="username"
                  variant="outlined"
                  label="CPF"
                  error={!!errors.username}
                  helperText={!!errors.username && 'Campo obrigatório'}
                  {...register('username', {
                    required: true,
                  })}
                  onChange={(e) => {
                    e.target.value = cpfMask(e.target.value)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  variant="outlined"
                  type="password"
                  label="Senha"
                  error={!!errors.password}
                  helperText={!!errors.password && 'Campo obrigatório'}
                  {...register('password', {
                    required: true,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!usernameWatch && !passwordWatch}
                >
                  {loading && <OverlayLoading size={20} />}
                  ENTRAR
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </div>
    </>
  )
}

LoginPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reason: PropTypes.string,
    }),
  }),
}

LoginPage.defaultProps = {
  match: { reason: null },
}

export default LoginPage
