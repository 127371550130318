import React, { useState, useEffect } from 'react'

import {
  Grid,
  Tabs,
  Tab,
  Button,
  makeStyles,
  Badge,
  Divider,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import history from '~/helpers/history'

import TabPanel from '~/components/TabPanel'

import { Creators as QuizzeActions } from '~/store/ducks/quiz'
import Portlet from '~/components/Portlet'

import QuizzeFormTab from './components/QuizFormTab'
import QuestionsFormTab from './components/QuestionsFormTab'
import AwnsersFormTab from './components/AwnsersFormTab'

import styles from './styles'

const quizzesSelector = ({ quiz }) => quiz

const useStyles = makeStyles(styles)

export default function NewQuizPage({ match }) {
  const { id } = match.params
  const classes = useStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(0)
  const isEdit = id !== 'new'
  const { data } = useSelector(quizzesSelector)

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }
  const totalResponses = data?.users?.length

  useEffect(() => {
    if (isEdit) {
      dispatch(QuizzeActions.getQuizzeRequest(id))
    }
    return () => {
      dispatch(QuizzeActions.resetQuizzeData())
    }
  }, [isEdit, id])

  return (
    <div className={classes.root}>
      <Portlet className={classes.grow}>
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={handleTabsChange}
        >
          <Tab label="Questionário" />
          <Tab label="Perguntas" disabled={!isEdit} />
          <Tab
            disabled={!isEdit}
            label={
              <Badge
                color="primary"
                showZero
                badgeContent={totalResponses || 0}
                max={999}
              >
                Respostas
              </Badge>
            }
          />
        </Tabs>

        <Grid container className={classes.container}>
          <div className={classes.tabsWrapper}>
            <TabPanel index={0} value={currentTab}>
              <QuizzeFormTab id={id} />
            </TabPanel>

            <TabPanel index={1} value={currentTab}>
              <QuestionsFormTab id={id} />
            </TabPanel>

            <TabPanel index={2} value={currentTab}>
              <AwnsersFormTab />
            </TabPanel>
          </div>
        </Grid>
        <Divider />

        <Grid
          container
          justifyContent="space-between"
          className={classes.actionsWrapper}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => history.push('/quizzes')}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Portlet>
    </div>
  )
}

NewQuizPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
