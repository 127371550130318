import React, { useEffect, useState, useRef } from 'react'

import {
  Typography,
  Grid,
  Button,
  makeStyles,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  IconButton,
  Divider,
  TextField,
  Chip,
} from '@material-ui/core'

import filesize from 'filesize'

import {
  ExpandMore as ExpandMoreIcon,
  Backup as UploadIcon,
  Cancel as CancelIcon,
  InsertDriveFile as AttachmentIcon,
} from '@material-ui/icons'

import { useForm, Controller } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import history from '~/helpers/history'

import Portlet from '~/components/Portlet'

import { Creators as PopsActions } from '~/store/ducks/pops'

import RhfTextField from '~/components/FormComponents/RhfTextField'
import RhfSelect from '~/components/FormComponents/RhfSelect'

import OverlayLoading from '~/components/OverlayLoading'

import styles from './styles'

const loadingSelector = ({ pops: { loading } }) => loading

const useStyles = makeStyles(styles)

const popSelector = ({ pops }) => pops.dataPopId
const popFunctions = ({ pops }) => pops.dataFunctions

export default function NewPopPage({ match }) {
  const { id } = match.params
  const fileInput = useRef(null)

  const isEdit = id !== 'new'
  const loading = useSelector(loadingSelector)
  const dispatch = useDispatch()
  const classes = useStyles()
  const popData = useSelector(popSelector)
  const functions = useSelector(popFunctions)
  const [attachment, setAttachment] = useState('')

  const linkDownload = popData?.url_pdf_file

  function handleUploadAttachment(e) {
    const file = e.target.files[0]
    if (file) {
      setAttachment(file)
    }
  }

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (isEdit) {
      reset(popData)
    } else {
      reset({})
    }
  }, [isEdit, popData])

  useEffect(() => {
    dispatch(PopsActions.getPopFunctionsRequest())
    if (isEdit) {
      dispatch(PopsActions.getPopRequest(id))
    }
    return () => {
      dispatch(PopsActions.resetPopData())
    }
  }, [isEdit, id])

  const handleFormPopSubmit = (data) => {
    const formattedData = {
      ...data,
      active: data.active === 'true',
      type: 'pop',
    }

    if (attachment) {
      formattedData.file = attachment
    }

    if (isEdit) {
      dispatch(
        PopsActions.updatePopRequest(id, formattedData, () =>
          history.push('/pop'),
        ),
      )
    } else {
      dispatch(
        PopsActions.addPopRequest(formattedData, () => history.push('/pop')),
      )
    }
  }

  return (
    <div className={classes.root}>
      <Portlet className={classes.grow} style={{ padding: 8 }}>
        <Grid container className={classes.container}>
          <div className={classes.tabsWrapper}>
            <div className={classes.root}>
              <div>
                {loading && <OverlayLoading />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(handleFormPopSubmit)}
                    id="form-pop"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <RhfTextField
                          label="Nome"
                          name="name"
                          control={control}
                          fullWidth
                          rules={{ required: true }}
                          error={!!errors.name}
                          helperText={!!errors.name && 'Campo obrigatório'}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <RhfTextField
                          label="Descrição"
                          name="description"
                          control={control}
                          fullWidth
                          rules={{ required: true }}
                          error={!!errors.description}
                          helperText={
                            !!errors.description && 'Campo obrigatório'
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RhfSelect
                          name="active"
                          label="Ativo"
                          variant="outlined"
                          fullWidth
                          control={control}
                          defaultValue=""
                        >
                          <MenuItem value="true">Sim</MenuItem>
                          <MenuItem value="false">Não</MenuItem>
                        </RhfSelect>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="functions"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value, ...rest } }) => (
                            <Autocomplete
                              fullWidth
                              multiple
                              value={value || []}
                              onChange={(event, v) => {
                                onChange(v)
                              }}
                              disableCloseOnSelect
                              filterSelectedOptions
                              options={functions}
                              {...rest}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue?.map((option, index) => (
                                  <Chip
                                    label={option.name}
                                    style={{
                                      backgroundColor: '#ED1E79',
                                      color: '#FFFFFF',
                                      // eslint-disable-next-line no-nested-ternary
                                    }}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.functions}
                                  helperText={
                                    !!errors.functions && 'Campo obrigatório'
                                  }
                                  label="Funções"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <div className={classes.detailsWrapper}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="caption">Vídeo</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <RhfTextField
                                  name="title_video"
                                  label="Título do Vídeo"
                                  placeholder="Informe o título do vídeo"
                                  control={control}
                                  fullWidth
                                  helperText={
                                    !!errors.title_video && 'Campo obrigatório'
                                  }
                                />
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <RhfTextField
                                  name="url_link"
                                  label="Link do Vídeo"
                                  placeholder="Exemplo: https://www.youtube.com/watch?v=3CZ4JSdsa"
                                  control={control}
                                  fullWidth
                                  helperText={
                                    !!errors.title_video && 'Campo obrigatório'
                                  }
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <div className={classes.detailsWrapper}>
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="caption">
                              Apresentação (PDF)
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  onClick={() => {
                                    fileInput.current.click()
                                  }}
                                >
                                  <UploadIcon className={classes.uploadIcon} />
                                  Selecionar arquivo
                                  <input
                                    type="file"
                                    multiple={false}
                                    ref={fileInput}
                                    onClick={(e) => {
                                      e.target.value = ''
                                    }}
                                    style={{ display: 'none' }}
                                    onChange={handleUploadAttachment}
                                  />
                                </Button>
                              </Grid>
                              {attachment && (
                                <Grid item xs={12}>
                                  <Card className={classes.upload}>
                                    <CardContent
                                      className={classes.uploadContent}
                                    >
                                      <AttachmentIcon
                                        className={classes.uploadIcon}
                                      />
                                      <div className={classes.uploadDetail}>
                                        <span>{attachment.name}</span>
                                        <span className={classes.fileSize}>
                                          {filesize(attachment.size, {
                                            round: 0,
                                          })}
                                        </span>
                                      </div>
                                      <IconButton
                                        onClick={() => setAttachment('')}
                                      >
                                        <CancelIcon
                                          className={classes.cancelUploadIcon}
                                        />
                                      </IconButton>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                              {!attachment && !!linkDownload && (
                                <Button
                                  className={classes.buttonDonwload}
                                  // color="primary"
                                  // variant="outlined"
                                >
                                  <a
                                    className={classes.link}
                                    id="anchorId"
                                    href={linkDownload}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Baixar Arquivo
                                  </a>
                                </Button>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </form>
                )}
              </div>
              <Grid
                container
                className={classes.buttonForm}
                justifyContent="flex-end"
              >
                <Button
                  color="primary"
                  type="submit"
                  form="form-pop"
                  variant="contained"
                >
                  SALVAR
                </Button>
              </Grid>
            </div>
          </div>
        </Grid>
        <Divider />

        <Grid
          container
          justifyContent="space-between"
          className={classes.actionsWrapper}
        >
          <Grid item>
            <Button variant="contained" onClick={() => history.push('/pop')}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Portlet>
    </div>
  )
}

NewPopPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
