import React from 'react'

import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'

import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

const optionsNewsBoardSelector = ({ newsBoard }) => newsBoard.options

export default function NewsBoardListHeader() {
  const dispatch = useDispatch()
  const options = useSelector(optionsNewsBoardSelector)
  const { sort, direction } = options

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(NewsBoardActions.setNewsBoardSort(newSort, sortDirection))
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ padding: 0 }} />
        <TableCell>
          {/* <TableSortLabel
            active={sort === 'author_id'}
            onClick={() => handleSortChange('author_id')}
            direction={direction}
          > */}
          Autor
          {/* </TableSortLabel> */}
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sort === 'title'}
            onClick={() => handleSortChange('title')}
            direction={direction}
          >
            Título
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={sort === 'text'}
            onClick={() => handleSortChange('text')}
            direction={direction}
          >
            Descrição
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={sort === 'created_at'}
            onClick={() => handleSortChange('created_at')}
            direction={direction}
          >
            Data de Publicação
          </TableSortLabel>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
