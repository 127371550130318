import React, { useRef, useState } from 'react'
import filesize from 'filesize'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles,
  Typography,
} from '@material-ui/core'

import {
  Backup as UploadIcon,
  InsertDriveFile as AttachmentIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons'

import { useSelector, useDispatch } from 'react-redux'

import { Creators as CallActions } from '~/store/ducks/call'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import styles from './styles'

const callSelector = ({ call }) => call

const useStyles = makeStyles(styles)

const userSelector = ({ session: { user } }) => user
const loadingSelector = ({ call: { attachments } }) => attachments.loading

// const fileSelector = ({ call: { dataFile } }) => dataFile

export default function CallAttachmentsFormTab({ id }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { attachments } = useSelector(callSelector)
  const loading = useSelector(loadingSelector)
  // const fileDownload = useSelector(fileSelector)
  const fileInput = useRef(null)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [attachment, setAttachment] = useState([])
  const [attachmentId, setAttachmentId] = useState(null)

  function handleUploadAttachment(e) {
    const file = e.target.files
    if (file) {
      setAttachment(file)
    }
  }

  const arrayFiles = Object.keys(attachment).map((item) => [attachment[item]])
  const formattedArrayFiles = arrayFiles.map((item) => item[0])

  function reloadCallAttachments() {
    dispatch(CallActions.getCallAttachmentsRequest(id))
  }

  function handleRemoveAttachment(idToRemove) {
    setOpenConfirmDialog(true)
    setAttachmentId(idToRemove)
  }

  function handleRemoveFiles() {
    setAttachment([])
  }

  function handleRemoveCallAttachmentCancel() {
    setOpenConfirmDialog(false)
    setAttachmentId(null)
  }

  function handleRemoveCallAttachmentAccept() {
    dispatch(
      CallActions.removeCallAttachmentRequest(attachmentId, () => {
        setOpenConfirmDialog(false)
        setAttachmentId(null)
        reloadCallAttachments()
      }),
    )
  }

  function handleAddAttachment() {
    let formData = {}
    if (attachment.length === 1) {
      formData = {
        call_id: id,
        user_id: user?.id,
        file: formattedArrayFiles,
      }
    } else if (attachment.length > 1) {
      formData = {
        call_id: id,
        user_id: user?.id,
        files: formattedArrayFiles,
      }
    }

    dispatch(
      CallActions.uploadCallAttachmentRequest(formData, () => {
        setAttachment('')
        reloadCallAttachments()
      }),
    )
  }

  const downloadFile = (item) => {
    const apiUrl = process.env.REACT_APP_API_URL
    window.location.href = `${apiUrl}download/${item.id}`
  }

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      {!loading && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  fileInput.current.click()
                }}
              >
                <UploadIcon className={classes.uploadIcon} />
                Selecionar arquivo
                <input
                  ref={fileInput}
                  onClick={(e) => {
                    e.target.value = ''
                  }}
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  onChange={handleUploadAttachment}
                />
              </Button>
            </Grid>
            {attachment && (
              <>
                <Grid item xs={12}>
                  {formattedArrayFiles.map((item) => (
                    <Card className={classes.upload}>
                      <CardContent className={classes.uploadContent}>
                        <AttachmentIcon />
                        <div className={classes.uploadDetail}>
                          <span>{item.name}</span>
                          <span className={classes.fileSize}>
                            {filesize(item.size, { round: 0 })}
                          </span>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                  {attachment.length !== 0 ? (
                    <>
                      <Typography color="textPrimary" variant="h7">
                        {' '}
                        Remover Anexos
                      </Typography>
                      <IconButton onClick={handleRemoveFiles}>
                        <CancelIcon className={classes.cancelUploadIcon} />
                      </IconButton>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </>
            )}
            <Grid container item justifyContent="flex-end">
              <Button
                disabled={arrayFiles.length === 0}
                variant="outlined"
                color="primary"
                onClick={handleAddAttachment}
              >
                Salvar anexo
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            {!!attachments?.data.length && (
              <Portlet className={classes.portlet}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Arquivo</TableCell>
                      <TableCell>Colaborador</TableCell>
                      <TableCell>Dt. cadastro</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attachments?.data.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.original_name}</TableCell>
                        <TableCell>{item.user.name}</TableCell>
                        <TableCell>
                          {moment(item.created_at).format('L')}
                          {' - '}
                          {moment(item.created_at).format('LT')}
                        </TableCell>
                        <TableCell align="right">
                          <div className={classes.actionsWrapper}>
                            <IconButton
                              // onClick={() => {
                              //   window.open(item.url_file, '_blank')
                              // }}
                              onClick={() => downloadFile(item)}
                            >
                              <GetAppIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleRemoveAttachment(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Portlet>
            )}
          </Grid>
        </>
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este anexo?"
        onCancel={handleRemoveCallAttachmentCancel}
        onAccept={handleRemoveCallAttachmentAccept}
      />
    </div>
  )
}

CallAttachmentsFormTab.propTypes = {
  id: PropTypes.number,
}

CallAttachmentsFormTab.defaultProps = {
  id: null,
}
