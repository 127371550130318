/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { MentionsInput, Mention } from 'react-mentions'
import { defaultStyles, commentStyles } from './defaultStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function CustomMentionsInput({
  value,
  data,
  onChange,
  onAdd,
  isComment,
  ...rest
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MentionsInput
        value={value}
        onChange={onChange}
        style={isComment ? commentStyles : defaultStyles}
        className={classes.mentioInput}
        placeholder="Para mencionar use '@'"
        a11ySuggestionsListLabel={'Suggested mentions'}
        {...rest}
      >
        <Mention
          markup="@@[__display__](__id__)"
          data={data}
          onAdd={onAdd}
          displayTransform={(id, display) => `@${display}`}
          // style={
          //   {
          //     backgroundColor: '#EDECF0',
          //   }
          // }
          {...rest}
        />
      </MentionsInput>
    </div>
  )
}

CustomMentionsInput.propTypes = {
  value: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.func,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  isComment: PropTypes.bool,
}

CustomMentionsInput.defaultProps = {
  value: '',
  isComment: false,
  onAdd: () => {},
}
