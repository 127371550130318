export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    '& .BrainhubCarousel__arrows': {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    '& .BrainhubCarousel__arrows:hover:enabled': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .BrainhubCarousel__arrows:disabled': {
      backgroundColor: '#ccc',
    },
  },
  carouselWrapper: {
    position: 'relative',
  },
  carouselImage: {
    height: '540px',
    maxHeight: '540px',
    [theme.breakpoints.down('md')]: {
      height: '600px',
      maxHeight: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '600px',
      width: '600px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '330px',
      maxHeight: '330px',
      width: '260px',
    },
  },
  grow: {
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  questionsWrapper: {
    padding: theme.spacing(0, 4),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },
  alternativeCorrect: {
    color: 'green !important',
  },
  alternativeWrong: {
    color: 'red !important',
  },
  header: {
    position: 'absolute',
    top: 10,
    right: 15,
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    right: 15,
  },
  button: {
    marginBottom: theme.spacing(1),
  },
})
