import { createActions, createReducer } from 'reduxsauce'

import moment from 'moment'

export const { Types, Creators } = createActions({
  getIslandSummaryRequest: [],
  getIslandSummaryFailure: [],
  getIslandSummarySuccess: ['data'],
  getIslandCallsRequest: [],
  getIslandCallsFailure: [],
  getIslandCallsSuccess: ['data'],
  getRankingRequest: [],
  getRankingFailure: [],
  getRankingSuccess: ['data'],
  setRankingDate: ['start', 'end'],
  setIslandCallsPage: ['page', 'limit'],
})

const INITIAL_STATE = {
  dataIslandSummary: [],
  dataIslandCalls: [],
  dataRanking: [],
  loading: false,
  filters: {
    startDate: moment().subtract(30, 'days').format(),
    endDate: moment().format(),
  },
  options: {
    page: 0,
    total: 0,
    limit: 20,
  },
}

const getIslandSummaryRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getIslandSummaryFailure = (state = INITIAL_STATE) => ({
  ...state,

  // loading: false,
})

const getIslandSummarySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataIslandSummary: data,
  // loading: false,
})
const getIslandCallsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getIslandCallsFailure = (state = INITIAL_STATE) => ({
  ...state,
  // loading: false,
})

const getIslandCallsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataIslandCalls: data,
  // loading: false,
})
const getRankingRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getRankingFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getRankingSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataRanking: data,
  loading: false,
})

const setRankingDate = (state = INITIAL_STATE, { start, end }) => ({
  ...state,
  filters: {
    ...state.filters,
    startDate: start,
    endDate: end,
  },
})

const setIslandCallsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_ISLAND_SUMMARY_REQUEST]: getIslandSummaryRequest,
  [Types.GET_ISLAND_SUMMARY_FAILURE]: getIslandSummaryFailure,
  [Types.GET_ISLAND_SUMMARY_SUCCESS]: getIslandSummarySuccess,
  [Types.GET_ISLAND_CALLS_REQUEST]: getIslandCallsRequest,
  [Types.GET_ISLAND_CALLS_FAILURE]: getIslandCallsFailure,
  [Types.GET_ISLAND_CALLS_SUCCESS]: getIslandCallsSuccess,
  [Types.GET_RANKING_REQUEST]: getRankingRequest,
  [Types.GET_RANKING_FAILURE]: getRankingFailure,
  [Types.GET_RANKING_SUCCESS]: getRankingSuccess,
  [Types.SET_RANKING_DATE]: setRankingDate,
  [Types.SET_ISLAND_CALLS_PAGE]: setIslandCallsPage,
})
