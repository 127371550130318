import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withWidth, Drawer, makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { Creators as NotificationsActions } from '~/store/ducks/notifications'
import FlashMessage from '~/components/FlashMessage'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'

import styles from './styles'

const useStyles = makeStyles(styles)

function DashboardContainer(props) {
  const { width, title, children, noSidebar, hasMonthCountdown } = props
  const {
    props: {
      location: { search },
    },
  } = children
  const titleFromQueryParams = decodeURI(search.replace('?name=', ''))
  const classes = useStyles()
  const isMobile = ['xs', 'sm', 'md'].includes(width)
  const [isOpen, setIsOpen] = useState(!isMobile)
  const dispatch = useDispatch()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleToggleOpen = () => {
    setIsOpen((prevState) => !prevState)

    // necessary for redrawing charts when opening and closing sidebar menu
    // eslint-disable-next-line no-undef
    setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
  }

  const shiftTopbar = isOpen && !isMobile && !noSidebar
  const shiftContent = isOpen && !isMobile && !noSidebar

  const caidTitle = titleFromQueryParams || title

  document.title = caidTitle === '' ? 'Caid' : `${caidTitle} - Caid`

  useEffect(() => {
    dispatch(NotificationsActions.getNotificationsRequest())
  }, [])

  useEffect(() => {
    const timeToGetNotifications = 1000 * 60 * 1 // 1 minute
    setInterval(
      () => dispatch(NotificationsActions.getNotificationsRequest()),
      timeToGetNotifications,
    )

    return () => {
      clearInterval()
    }
  }, [])

  return (
    <>
      <Topbar
        className={classNames(classes.topbar, {
          [classes.topbarShift]: shiftTopbar,
        })}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={caidTitle}
        noSidebar={noSidebar}
        hasMonthCountdown={hasMonthCountdown}
      />
      {!noSidebar && (
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={handleClose}
          open={isOpen}
          variant={isMobile ? 'temporary' : 'persistent'}
        >
          <Sidebar
            className={classes.sidebar}
            isMobile={isMobile}
            handleDrawerClose={handleClose}
          />
        </Drawer>
      )}
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent,
        })}
      >
        <FlashMessage />
        {children}
      </main>
    </>
  )
}

DashboardContainer.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  noSidebar: PropTypes.bool,
  width: PropTypes.string,
  hasMonthCountdown: PropTypes.bool,
}

DashboardContainer.defaultProps = {
  children: <></>,
  title: '',
  width: '',
  noSidebar: false,
  hasMonthCountdown: false,
}

export default compose(withWidth())(DashboardContainer)
