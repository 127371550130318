import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart,
  HighchartsChart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highcharts'
// import { Typography } from '@material-ui/core'

function PieChart({ data, color, labels, title, plotOptions }) {
  return (
    <>
      {/* <Typography variant="h5">{title}</Typography> */}
      <HighchartsChart colors={color} plotOptions={plotOptions}>
        <Tooltip />
        <Chart />
        <Title y={30}>{title}</Title>
        <XAxis type="category" />
        <YAxis>
          <PieSeries
            name="Chamados"
            data={data.map((y, i) => ({ name: labels[i], y }))}
          />
        </YAxis>
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      </HighchartsChart>
    </>
  )
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  plotOptions: PropTypes.shape({
    pie: PropTypes.shape({
      shadow: PropTypes.bool,
      innerSize: PropTypes.string,
      dataLabels: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      showInLegend: PropTypes.bool,
    }),
  }),
  color: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
}

PieChart.defaultProps = {
  plotOptions: {
    pie: {
      shadow: false,
      // center: ['50%', '50%'],
      innerSize: '50%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  data: [],
  color: [],
  title: 'Title',
  labels: [],
}

export default PieChart
