import palette from '../palette'

export default {
  root: {
    boxShadow: 'none',
    border: `1px solid ${palette.border}`,
    margin: '16px 0',
  },
  rounded: {
    borderRadius: '4px',
  },
}
