import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
  Divider,
  Grid,
  Chip,
  TextField,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { Inbox as InboxIcon } from '@material-ui/icons'

import { Controller, useForm } from 'react-hook-form'
import { Creators as DailyEmailsActions } from '~/store/ducks/dailyEmails'
import { useDispatch, useSelector } from 'react-redux'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import OverlayLoading from '~/components/OverlayLoading'
import styles from './styles'

const useStyles = makeStyles(styles)

const DailyEmailsDialog = ({ open, onClose, id, name }) => {
  const [empty, setEmpty] = useState(true)
  const classes = useStyles()
  const dispatch = useDispatch()

  const usersDailyEmailsSelector = ({ dailyEmails }) => dailyEmails

  const { dataUsers, dataType, loadingType } = useSelector(
    usersDailyEmailsSelector,
  )

  const isMobileScreen = useMobileScreen()

  const dataReset = dataType.data

  const usersEdit = dataReset?.recipients

  const dataEdit = {
    users_id: usersEdit,
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (open) {
      dispatch(DailyEmailsActions.getTypeEmailRequest(id))
    }
  }, [open])

  useEffect(() => {
    if (open && !loadingType && id) {
      reset(dataEdit)
    }
  }, [loadingType])

  const closeModal = () => {
    reset({})
    dispatch(DailyEmailsActions.getTypesEmailRequest())
    onClose()
  }

  const handleSubmitUsersForm = (formData) => {
    dispatch(
      DailyEmailsActions.updateUsersTypesEmailRequest(id, formData, () => {
        dispatch(DailyEmailsActions.getTypesEmailRequest(), closeModal())
      }),
    )
  }

  const emptyList = dataEdit?.users_id?.length === 0

  const addUsers = () => {
    setEmpty(false)
  }

  return (
    <>
      {loadingType && <OverlayLoading />}
      {!loadingType && (
        <Dialog
          fullScreen={isMobileScreen}
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={closeModal}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle disableTypography>
            <Typography variant="h3">Usuários - {name}</Typography>
          </DialogTitle>
          <Divider className={classes.divider} />
          <form
            id="users-form"
            onSubmit={handleSubmit(handleSubmitUsersForm)}
            className={classes.form}
          >
            {!emptyList && !empty ? <Divider /> : ''}

            <Grid
              container
              spacing={2}
              className={classes.container}
              justifyContent="space-between"
            >
              {emptyList && empty ? (
                ''
              ) : (
                <Grid item xs={12} className={classes.usersSelect}>
                  <Controller
                    name="users_id"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Autocomplete
                        fullWidth
                        multiple
                        limitTags={20}
                        value={value || []}
                        onChange={(event, v) => {
                          onChange(v)
                        }}
                        disableCloseOnSelect
                        filterSelectedOptions
                        options={dataUsers}
                        {...rest}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, val) => {
                          return option.id === val.id
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue?.map((option, index) => (
                            <Chip
                              label={option.name}
                              style={{
                                backgroundColor: '#ED1E79',
                                color: '#FFFFFF',
                                // eslint-disable-next-line no-nested-ternary
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.dealerships_id}
                            helperText={
                              !!errors.dealerships_id && 'Campo obrigatório'
                            }
                            label="Selecione os usuários que receberão o relatório*"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
          {emptyList && empty ? (
            <div className={classes.wrapper}>
              <div className={classes.root}>
                <InboxIcon className={classes.emptyIcon} />
                <Typography variant="h3">
                  Não há usuários cadastrados para receber este e-mail
                </Typography>
              </div>
            </div>
          ) : (
            ''
          )}
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Button
                onClick={() => closeModal()}
                variant="contained"
                size="small"
              >
                CANCELAR
              </Button>
              <div>
                {emptyList && empty ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addUsers()}
                  >
                    Cadastrar usuários
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="users-form"
                  className={classes.saveButton}
                >
                  Salvar
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

DailyEmailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  name: PropTypes.string,
}

DailyEmailsDialog.defaultProps = {
  name: '',
  id: null,
}

export default DailyEmailsDialog
