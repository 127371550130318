export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
  },
  grow: {
    flex: 1,
    position: 'relative',
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '70Vh',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    // position: 'absolute',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  backButton: {
    marginTop: theme.spacing(3),
  },
})
