export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
  },

  grow: {
    flex: 1,
  },
})
