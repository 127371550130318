import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'
import { useUnmount } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as EquipmentDashboardActions } from '~/store/ducks/equipmentDashboard'

import { useMobileScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'

import CustomRow from './components/CustomRow'

import styles from './styles'

const useStyles = makeStyles(styles)

const equipmentsDealershipSelector = ({
  equipmentDashboard: { equipmentsDealership },
}) => equipmentsDealership

export default function EquipmentsDashboardDialog({
  open,
  onClose,
  dealership,
}) {
  const isMobileScreen = useMobileScreen()
  const classes = useStyles(isMobileScreen)
  const dispatch = useDispatch()
  const { data, loading } = useSelector(equipmentsDealershipSelector)

  useEffect(() => {
    if (open && dealership?.id) {
      dispatch(
        EquipmentDashboardActions.getEquipmentDashboardDealershipRequest(
          dealership?.id,
        ),
      )
    }
  }, [open, dealership?.id])

  useUnmount(() => {
    dispatch(EquipmentDashboardActions.resetEquipmentDashboard())
  })

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      fullScreen={isMobileScreen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Equipamentos - {dealership?.name}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Table>
          <TableBody>
            {!loading &&
              data.length > 0 &&
              data?.map((item) => <CustomRow row={item} key={item.id} />)}
          </TableBody>
        </Table>
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EquipmentsDashboardDialog.propTypes = {
  dealership: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

EquipmentsDashboardDialog.defaultProps = {
  dealership: null,
  open: false,
  onClose: () => {},
}
