import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  TableBody,
  TextField,
  makeStyles,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useDispatch } from 'react-redux'

import { removeMaskReal } from '~/helpers/masks'

import { Creators as SalesGoalsActions } from '~/store/ducks/salesGoals'

import styles from './styles'

const useStyles = makeStyles(styles)

function CustomRow({ row }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleCounterChange = (sellerId, value) => {
    const valueFormatted = removeMaskReal(value)
    dispatch(
      SalesGoalsActions.handleSalesGoalsCounterChange(sellerId, valueFormatted),
    )
  }

  return (
    <>
      <TableRow>
        <TableCell align="left" className={classes.smallCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.dealership_name}</TableCell>
      </TableRow>
      {open && row?.data.length > 0 && (
        <TableRow>
          <TableCell className={classes.tableWrapper} colSpan={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Vendedor</TableCell>
                  <TableCell>Objetivo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.data.map((item) => (
                  <TableRow key={item.seller_name}>
                    <TableCell>{item.seller_name}</TableCell>
                    <TableCell>
                      <NumberFormat
                        prefix="R$"
                        name="counter"
                        thousandSeparator="."
                        decimalSeparator=","
                        // fixedDecimalScale
                        decimalScale={2}
                        customInput={TextField}
                        fullWidth
                        variant="standard"
                        label="(R$) Real"
                        onChange={(e) =>
                          handleCounterChange(item.seller_id, e.target.value)
                        }
                        value={item.counter || ''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

CustomRow.propTypes = {
  row: PropTypes.shape({
    dealership_name: PropTypes.string,
    seller_id: PropTypes.number,
    seller_name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default React.memo(CustomRow)
