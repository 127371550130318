export default (theme) => ({
  root: {
    height: 1000,
  },
  titleDivider: {
    margin: theme.spacing(2, 0, 2, 0),
  },

  iconButton: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  table: {
    marginTop: 16,
    border: 'solid 1px #DFE3E8',
    borderRadius: '4px',
  },
})
