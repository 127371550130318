import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getQuizzesProfileRequest: [],
  getQuizzesProfileFailure: [],
  getQuizzesProfileSuccess: ['data', 'total'],
  updateUserProfileRequest: ['id', 'data', 'callback'],
  updateUserProfileFinish: [],
  getUserProfileRequest: ['id', 'callback'],
  getUserProfileSuccess: ['data'],
  getUserProfileFailure: [],
  getUserSalesProfileRequest: ['id', 'date'],
  getUserSalesProfileSuccess: ['data'],
  getUserSalesProfileFailure: [],
  setProfilePageDate: ['value'],
  getPopsProfileRequest: [],
  getPopsProfileFailure: [],
  getPopsProfileSuccess: ['data', 'total'],
  getReceiptsProfileRequest: [],
  getReceiptsProfileFailure: [],
  getReceiptsProfileSuccess: ['data', 'total'],
  setReceiptsPageProfile: ['page', 'limit'],
})

const INITIAL_STATE = {
  dataProfile: [],
  dataSales: [],
  dataUserProfile: {},
  dataPops: [],
  dataReceipts: [],
  loading: false,
  options: {
    total: 10,
    dateValueSales: new Date(),
    sort: 'name',
    direction: 'asc',
    page: 0,
    limit: 20,
    pagination: 1,
  },
}

const getQuizzesProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getQuizzesProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getQuizzesProfileSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataProfile: data,
  loading: false,
})

const updateUserProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateUserProfileFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUserProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUserProfileSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataUserProfile: data,
  loading: false,
})

const getUserProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUserSalesProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUserSalesProfileSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataSales: data,
  loading: false,
})

const getUserSalesProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setProfilePageDate = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    dateValueSales: value,
  },
})

const getPopsProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getPopsProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getPopsProfileSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataPops: data,
  loading: false,
})
const getReceiptsProfileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getReceiptsProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getReceiptsProfileSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  dataReceipts: data,
  loading: false,
})

const setReceiptsPageProfile = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_QUIZZES_PROFILE_REQUEST]: getQuizzesProfileRequest,
  [Types.GET_QUIZZES_PROFILE_FAILURE]: getQuizzesProfileFailure,
  [Types.GET_QUIZZES_PROFILE_SUCCESS]: getQuizzesProfileSuccess,
  [Types.UPDATE_USER_PROFILE_REQUEST]: updateUserProfileRequest,
  [Types.UPDATE_USER_PROFILE_FINISH]: updateUserProfileFinish,
  [Types.GET_USER_PROFILE_REQUEST]: getUserProfileRequest,
  [Types.GET_USER_PROFILE_SUCCESS]: getUserProfileSuccess,
  [Types.GET_USER_PROFILE_FAILURE]: getUserProfileFailure,
  [Types.GET_USER_SALES_PROFILE_REQUEST]: getUserSalesProfileRequest,
  [Types.GET_USER_SALES_PROFILE_SUCCESS]: getUserSalesProfileSuccess,
  [Types.GET_USER_SALES_PROFILE_FAILURE]: getUserSalesProfileFailure,
  [Types.SET_PROFILE_PAGE_DATE]: setProfilePageDate,
  [Types.GET_POPS_PROFILE_REQUEST]: getPopsProfileRequest,
  [Types.GET_POPS_PROFILE_FAILURE]: getPopsProfileFailure,
  [Types.GET_POPS_PROFILE_SUCCESS]: getPopsProfileSuccess,
  [Types.GET_RECEIPTS_PROFILE_REQUEST]: getReceiptsProfileRequest,
  [Types.GET_RECEIPTS_PROFILE_FAILURE]: getReceiptsProfileFailure,
  [Types.GET_RECEIPTS_PROFILE_SUCCESS]: getReceiptsProfileSuccess,
  [Types.SET_RECEIPTS_PAGE_PROFILE]: setReceiptsPageProfile,
})
