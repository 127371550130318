export default (theme) => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  buttonsWrapper: {
    marginBottom: theme.spacing(2),
  },
  uploadIcon: {
    marginRight: theme.spacing(1),
  },
  cropWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  gridButton: {
    marginTop: theme.spacing(4),
  },
  acordeon: {
    display: 'block',
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  firstContainer: {
    marginBottom: theme.spacing(1),
  },
  detailsWrapper: {
    width: '100%',
    padding: theme.spacing(0),
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },
  tableCellActions: {
    paddingRight: 0,
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },
})
