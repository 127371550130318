import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import history from '~/helpers/history'
import configureApi from '~/helpers/api'

import { Types as UserTypes, Creators as UserActions } from '../ducks/user'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const userOptionsSelector = ({ user }) => user.options

function* addUser({ data }) {
  try {
    const api = configureApi()
    const response = yield call(api.post, 'users', data)
    if (response?.data?.id) {
      history.push(`/users/${response.data.id}/edit`)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Colaborador cadastado com sucesso!',
        variant: 'success',
      }),
    )
    yield put(UserActions.addUserFinish())
  } catch (e) {
    yield put(UserActions.addUserFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUser({ id, callback }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `users/${id}`)
    const formattedData = {
      ...data,
      dealerships_id: data.dealerships,
      roles_id: data.roles,
    }
    yield put(UserActions.getUserSuccess(formattedData))
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.getUserFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateUser({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `/users/${id}`, data)
    yield put(UserActions.updateUserFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Colaborador editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(UserActions.updateUserFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeUser({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/users/${id}`)

    yield put(UserActions.removeUserFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Colaborador removiada com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.removeUserFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })

    yield put(UserActions.getUserDealershipsSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserDealershipsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserRoles() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'roles', {
      params: {
        pagination: 0,
        order: 'display_name,asc',
      },
    })

    yield put(UserActions.getUserRolesSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserRolesFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserBanks() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'banks', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })

    yield put(UserActions.getUserBankSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserBankFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserFunction() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'functions', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })

    yield put(UserActions.getUserFunctionSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserFunctionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserFunctionsList({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'user_functions', {
      params: {
        pagination: 0,
        'where[user_id]': id,
        order: 'effective_date,desc',
      },
    })

    yield put(UserActions.getUserFunctionsListSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserFunctionsListFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserVwAttendants({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'vw_attendants', {
      params: {
        pagination: 0,
        'where[user_id]': id,
      },
    })

    yield put(UserActions.getUserVwAttendantsSuccess(data))
  } catch (e) {
    yield put(UserActions.getUserVwAttendantsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addUserFunction({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'user_functions', data)

    if (callback) {
      yield call(callback)
    }

    yield put(UserActions.addUserFunctionFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Contrato cadastado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(UserActions.addUserFunctionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateUserFunction({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `user_functions/${id}`, data)

    if (callback) {
      yield call(callback)
    }

    yield put(UserActions.updateUserFunctionFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Contrato editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(UserActions.updateUserFunctionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* announceHiring({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `users/announceHiring/${id}`)

    yield put(UserActions.announceHiringSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Contratação anunciada com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.announceHiringFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* announceResignation({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `users/announceResignation/${id}`)

    yield put(UserActions.announceResignationSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Contratação anunciada com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.announceResignationFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* announceNewFunction({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `user_functions/${id}/announceNewFunction`)

    yield put(UserActions.announceNewFunctionSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Anúncio de nova função realizado com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.announceNewFunctionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeUserFunction({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `user_functions/${id}`)

    yield put(UserActions.removeUserFunctionSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Função removida com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(UserActions.removeUserFunctionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getReceiptsUsers({ id }) {
  const options = yield select(userOptionsSelector)

  try {
    const api = configureApi()
    const { data } = yield call(api.get, `user-receipts`, {
      params: {
        'where[user_id]': id,
        pagination: 1,
        page: options.page + 1,
        limit: options.limit,
        order: options.sort,
      },
    })

    yield put(UserActions.getReceiptsUsersSuccess(data, data.total))
  } catch (e) {
    yield put(UserActions.getReceiptsUsersFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getReceiptsUsersPage({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `user-receipts`, {
      params: {
        'where[user_id]': id,
      },
    })

    yield put(UserActions.getReceiptsUsersSuccess(data, data.total))
  } catch (e) {
    yield put(UserActions.getReceiptsUsersFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* setReceiptsUserTab({ idUser }) {
  const options = yield select(userOptionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `user-receipts`, {
      params: {
        'where[user_id]': idUser,
        pagination: 1,
        page: options.page + 1,
        limit: options.limit,
        order: options.sort,
      },
    })

    yield put(UserActions.getReceiptsUsersSuccess(data, data.total))
  } catch (e) {
    yield put(UserActions.getReceiptsUsersFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(UserTypes.ADD_USER_REQUEST, addUser)
  yield takeLatest(UserTypes.GET_USER_REQUEST, getUser)
  yield takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUser)
  yield takeLatest(UserTypes.REMOVE_USER_REQUEST, removeUser)
  yield takeLatest(UserTypes.GET_USER_DEALERSHIPS_REQUEST, getUserDealerships)
  yield takeLatest(UserTypes.GET_USER_ROLES_REQUEST, getUserRoles)
  yield takeLatest(UserTypes.GET_USER_BANK_REQUEST, getUserBanks)
  yield takeLatest(UserTypes.GET_USER_FUNCTION_REQUEST, getUserFunction)
  yield takeLatest(
    UserTypes.GET_USER_FUNCTIONS_LIST_REQUEST,
    getUserFunctionsList,
  )
  yield takeLatest(
    UserTypes.GET_USER_VW_ATTENDANTS_REQUEST,
    getUserVwAttendants,
  )
  yield takeLatest(UserTypes.ADD_USER_FUNCTION_REQUEST, addUserFunction)
  yield takeLatest(UserTypes.UPDATE_USER_FUNCTION_REQUEST, updateUserFunction)
  yield takeLatest(UserTypes.ANNOUNCE_HIRING_REQUEST, announceHiring)
  yield takeLatest(UserTypes.ANNOUNCE_RESIGNATION_REQUEST, announceResignation)
  yield takeLatest(UserTypes.ANNOUNCE_NEW_FUNCTION_REQUEST, announceNewFunction)
  yield takeLatest(UserTypes.REMOVE_USER_FUNCTION_REQUEST, removeUserFunction)
  yield takeLatest(UserTypes.GET_RECEIPTS_USERS_REQUEST, getReceiptsUsers)
  yield takeLatest(
    UserTypes.GET_RECEIPTS_USERS_REQUEST_PAGE,
    getReceiptsUsersPage,
  )
  yield takeLatest(UserTypes.SET_RECEIPTS_USER_TAB, setReceiptsUserTab)
}
