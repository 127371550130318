import React, { useState } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'
import { useSelector } from 'react-redux'
import Portlet from '~/components/Portlet'
import PieChart from '~/components/PieChart'
import OverlayLoading from '~/components/OverlayLoading'

import { useSmallScreen } from '~/helpers/useMediaQuery'

import styles from './styles'
import HelpdeskDashboardFilters from './components/HelpdeskDashboardFilters'
import CallsDialog from './components/CallsDialog'

const helpdeskSelector = ({ helpdeskDashboard }) => helpdeskDashboard

const useStyles = makeStyles(styles)

function HelpdeskDashboardPage() {
  const { data, loading } = useSelector(helpdeskSelector)
  const [callsSubject, setCallsSubject] = useState(null)
  const isSmallScreen = useSmallScreen()
  const classes = useStyles()

  function handleCloseDialog() {
    setCallsSubject(null)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        {isSmallScreen && (
          <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filtersTitle}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <HelpdeskDashboardFilters />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && (
          <Grid item xs={12}>
            <HelpdeskDashboardFilters />
          </Grid>
        )}
      </Grid>

      {!loading && (
        <Grid container spacing={2} className={classes.container}>
          {data.map((item) => (
            <Grid key={item.title} item md={3} sm={6} xs={12}>
              <Portlet>
                <PieChart {...item} />

                <Grid container justifyContent="flex-end">
                  <Button
                    disabled={!item.total || item.total <= 0}
                    onClick={() => setCallsSubject(item.title)}
                    variant="outlined"
                    className={classes.button}
                  >
                    {item.total} {item.total > 1 ? 'CHAMADOS' : 'CHAMADO'}
                  </Button>
                </Grid>
              </Portlet>
            </Grid>
          ))}
        </Grid>
      )}

      {loading && <OverlayLoading />}

      <CallsDialog
        open={!!callsSubject}
        subject={callsSubject}
        onClose={handleCloseDialog}
      />
    </div>
  )
}

export default HelpdeskDashboardPage
