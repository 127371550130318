import { combineReducers } from 'redux'

import session from './session'
import dealerships from './dealerships'
import dealership from './dealership'
import dailySales from './dailySales'
import departments from './departments'
import department from './department'
import flashMessage from './flashMessage'
import functions from './functions'
import superiors from './superiors'
import superior from './superior'
import statusList from './statusList'
import status from './status'
import equipmentsTypes from './equipmentsTypes'
import equipmentType from './equipmentType'
import equipments from './equipments'
import equipment from './equipment'
import functionDuck from './function'
import calls from './calls'
import users from './users'
import user from './user'
import call from './call'
import quizzes from './quizzes'
import quiz from './quiz'
import salesGoals from './salesGoals'
import objectives from './objectives'
import objective from './objective'
import helpdeskDashboard from './helpdeskDashboard'
import sellers from './sellers'
import rhMonthComparative from './rhMonthComparative'
import biDashboard from './biDashboard'
import newsBoard from './newsBoard'
import dashboard from './dashboard'
import billed from './billed'
import biSellersDashboard from './biSellersDashboard'
import organogram from './organogram'
import rhDashboard from './rhDashboard'
import equipmentDashboard from './equipmentDashboard'
import surveys from './surveys'
import billedMonthComparative from './billedMonthComparative'
import analitycal from './analitycal'
import analitycalParts from './analitycalParts'
import analitycalDashboard from './analitycalDashboard'
import dailyEmails from './dailyEmails'
import newsSingle from './newsSingle'
import notifications from './notifications'
import profile from './profile'
import blip from './blip'
import ipc from './ipc'
import pops from './pops'
import receipts from './receipts'
import usersRamal from './usersRamal'
import islands from './islands'

export default combineReducers({
  session,
  dealerships,
  dealership,
  flashMessage,
  departments,
  department,
  dailySales,
  functions,
  superiors,
  superior,
  status,
  statusList,
  equipmentsTypes,
  equipmentType,
  equipments,
  equipment,
  functionDuck,
  calls,
  users,
  user,
  call,
  quizzes,
  salesGoals,
  objectives,
  objective,
  helpdeskDashboard,
  sellers,
  quiz,
  rhMonthComparative,
  biDashboard,
  newsBoard,
  dashboard,
  billed,
  biSellersDashboard,
  organogram,
  rhDashboard,
  equipmentDashboard,
  surveys,
  billedMonthComparative,
  analitycal,
  analitycalParts,
  analitycalDashboard,
  dailyEmails,
  newsSingle,
  notifications,
  profile,
  blip,
  ipc,
  pops,
  receipts,
  usersRamal,
  islands,
})
