import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as SuperiorsActions } from '~/store/ducks/superiors'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import CustomIcon from '~/components/CustomIcon'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'
import SuperiorDialog from './components/SuperiorDialog'
import SuperiorsFilter from './components/SuperiorDialog/SuperiorsFilter'
import styles from './styles'

const superiorsSelector = ({ superiors }) => superiors

const useStyles = makeStyles(styles)

function SuperiorsPage() {
  const isSmallScreen = useSmallScreen()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data, options, loading, showHideColumns } =
    useSelector(superiorsSelector)
  const { sort, direction } = options
  const [openDialog, setOpenDialog] = useState(false)

  const [currentSuperior, setCurrentSuperior] = useState({})

  useEffect(() => {
    dispatch(SuperiorsActions.getSuperiorsRequest())
    dispatch(SuperiorsActions.getFunctionsSuperiorsRequest())

    return () => {
      dispatch(SuperiorsActions.resetSuperiorsList())
    }
  }, [])

  const optionsShowHideColumn = [
    { name: 'name', label: 'Nome', disabled: true },
    { name: 'function', label: 'Função' },
    { name: 'department', label: 'Departamento' },
    { name: 'parent', label: 'Superior' },
  ]

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(SuperiorsActions.setSuperiorsSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(SuperiorsActions.setSuperiorsPage(0, value))
  }

  const handleChangePage = (ev, page) => {
    dispatch(SuperiorsActions.setSuperiorsPage(page, options.limit))
  }

  const handleEditSuperior = (superior) => {
    setCurrentSuperior(superior)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setCurrentSuperior(null)
  }

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      SuperiorsActions.setChangeShowHideColumnsSuperiors(newColumnsTable),
    )
  }

  return (
    <div className={classes.root}>
      {isSmallScreen && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.filterWropper}>
              <FilterIcon color="primary" className={classes.filterIcon} />
              <Typography variant="h5" color="primary">
                Filtros
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <SuperiorsFilter />
          </AccordionDetails>
        </Accordion>
      )}
      {!isSmallScreen && <SuperiorsFilter />}
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há superiores para serem visualizados" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              {data.length > 0 && (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {showHideColumns.name && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'name'}
                            onClick={() => handleSortChange('name')}
                            direction={direction}
                          >
                            Nome
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.function && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'function_name'}
                            onClick={() => handleSortChange('function_name')}
                            direction={direction}
                          >
                            Função
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.department && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'function_department_name'}
                            onClick={() =>
                              handleSortChange('function_department_name')
                            }
                            direction={direction}
                          >
                            Departamento
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.parent && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'parent_name'}
                            onClick={() => handleSortChange('parent_name')}
                            direction={direction}
                          >
                            Superior
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell>
                        <ShowHideColumns
                          showHideColumns={showHideColumns}
                          optionsShowHideColumn={optionsShowHideColumn}
                          onChangeShowHideColumn={handleSetChangeShowHideColumn}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        {showHideColumns.name && (
                          <TableCell>{item.name || '-'}</TableCell>
                        )}
                        {showHideColumns.function && (
                          <TableCell>{item.function_name || '-'}</TableCell>
                        )}
                        {showHideColumns.department && (
                          <TableCell>
                            {item.function_department_name || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.parent && (
                          <TableCell>{item.parent_name || '-'}</TableCell>
                        )}
                        <TableCell align="right">
                          <IconButton onClick={() => handleEditSuperior(item)}>
                            <CustomIcon pathComponent={mdiPencil} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </div>

        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>
      <SuperiorDialog
        open={openDialog}
        onClose={handleClose}
        superior={currentSuperior}
      />
    </div>
  )
}

export default SuperiorsPage
