import { createActions, createReducer } from 'reduxsauce'
import { sortBy } from 'lodash'

export const { Types, Creators } = createActions({
  getMonthComparativeDataRequest: [],
  getMonthComparativeDataSuccess: ['data'],
  getMonthComparativeDataFailure: [],
  getMonthComparativeFiltersRequest: [],
  getMonthComparativeFiltersFailure: [],
  getMonthComparativeFiltersSuccess: ['groups'],
  setMonthComparativeFilter: ['property', 'value'],
  resetMonthComparative: [],
})

const INITIAL_STATE = {
  data: [],
  filters: {
    date: new Date(),
    group: 0,
    bond: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    bonds: [
      {
        name: 'Todos os contratos',
        value: 0,
      },
      {
        name: 'CLT',
        value: 1,
      },
      {
        name: 'Terceiro',
        value: 2,
      },
      {
        name: 'Estágio',
        value: 3,
      },
    ],
  },
  loadingFilters: false,
  loading: false,
}

const getMonthComparativeDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getMonthComparativeDataSuccess = (state = INITIAL_STATE, { data }) => {
  const formattedDatasets = (datasets) => {
    const totals = []
    datasets.forEach((dataset) => {
      dataset.data.forEach((item, index) => {
        totals[index] = totals[index] ? totals[index] + item : item
      })
    })

    const total = {
      label: 'Total',
      data: totals,
      fill: false,
      backgroundColor: '#f99000',
      borderColor: '#f99000',
    }
    return [...datasets, total]
  }

  const formattedData = data.map((item) => ({
    ...item,
    datasets:
      item?.datasets.length > 0 ? formattedDatasets(item?.datasets) : [],
  }))

  return {
    ...state,
    loading: false,
    data: formattedData,
  }
}

const getMonthComparativeDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getMonthComparativeFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getMonthComparativeFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getMonthComparativeFiltersSuccess = (
  state = INITIAL_STATE,
  { groups },
) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
      ...sortBy(groups, (a) => a.name),
    ],
  },
  loadingFilters: false,
})

const setMonthComparativeFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const resetMonthComparative = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_MONTH_COMPARATIVE_DATA_REQUEST]: getMonthComparativeDataRequest,
  [Types.GET_MONTH_COMPARATIVE_DATA_SUCCESS]: getMonthComparativeDataSuccess,
  [Types.GET_MONTH_COMPARATIVE_DATA_FAILURE]: getMonthComparativeDataFailure,
  [Types.GET_MONTH_COMPARATIVE_FILTERS_REQUEST]: getMonthComparativeFiltersRequest,
  [Types.GET_MONTH_COMPARATIVE_FILTERS_FAILURE]: getMonthComparativeFiltersFailure,
  [Types.GET_MONTH_COMPARATIVE_FILTERS_SUCCESS]: getMonthComparativeFiltersSuccess,
  [Types.SET_MONTH_COMPARATIVE_FILTER]: setMonthComparativeFilter,
  [Types.RESET_MONTH_COMPARATIVE]: resetMonthComparative,
})
