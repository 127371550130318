import React, { useEffect, useState } from 'react'

import { Tabs, Tab, Grid } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as IpcActions } from '~/store/ducks/ipc'

import TabPanel from '~/components/TabPanel'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'

import MetricsTab from './components/MetricsTab'
import CallsTab from './components/CallsTab'
import RankingTab from './components/RankingTab'

const ipcSelector = ({ ipc }) => ipc

function Ipc() {
  const [currentTab, setCurrentTab] = useState(0)

  const dispatch = useDispatch()

  const { dataIslandSummary, dataIslandCalls, dataRanking, loading } =
    useSelector(ipcSelector)

  useEffect(() => {
    dispatch(IpcActions.getIslandSummaryRequest())
    dispatch(IpcActions.getIslandCallsRequest())
    dispatch(IpcActions.getRankingRequest())
  }, [])

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  return (
    <>
      {loading && <OverlayLoading />}
      {dataIslandCalls?.data &&
        dataIslandSummary?.data?.length > 0 &&
        dataRanking?.data?.ranking.length > 0 && (
          <Portlet style={{ margin: 16 }}>
            <Tabs
              variant="fullWidth"
              value={currentTab}
              onChange={handleTabsChange}
            >
              <Tab label="Ligações" />
              <Tab label="Métricas" />
              <Tab label="Ranking de ligações" />
            </Tabs>
            <Grid>
              <TabPanel
                index={0}
                value={currentTab}
                style={{ margin: 0, padding: 0, overflowX: 'auto' }}
              >
                <CallsTab data={dataIslandCalls.data} />
              </TabPanel>
            </Grid>
            <Grid>
              <TabPanel
                index={1}
                value={currentTab}
                style={{ margin: 0, padding: 0, overflowX: 'auto' }}
              >
                <MetricsTab data={dataIslandSummary.data[0]} />
              </TabPanel>
            </Grid>
            <Grid>
              <TabPanel
                index={2}
                value={currentTab}
                style={{ margin: 0, padding: 0, overflowX: 'auto' }}
              >
                <RankingTab
                  data={dataRanking.data.ranking}
                  period={dataRanking.data.period}
                />
              </TabPanel>
            </Grid>
          </Portlet>
        )}
    </>
  )
}

export default Ipc
