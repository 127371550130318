import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as RhDashboardTypes,
  Creators as RhDashboardActions,
} from '../ducks/rhDashboard'

const filterSelector = ({ rhDashboard }) => rhDashboard.filters

const api = configureApi()

function* getRhDashboardFilters() {
  try {
    const { groups, departments } = yield all({
      groups: call(api.get, 'groups', {
        params: {
          pagination: 0,
          order: 'sort,asc',
        },
      }),
      departments: call(api.get, 'departments', {
        params: {
          pagination: 0,
          order: 'name,asc',
        },
      }),
    })

    const groupsData = groups.data.map((a) => ({
      name: a.name,
      value: a.id,
    }))

    const departmentsData = departments.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      RhDashboardActions.getRhDashboardFiltersSuccess(
        groupsData,
        departmentsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(RhDashboardActions.getRhDashboardFiltersFailure())
  }
}

function* getRhDashboardData() {
  try {
    const filters = yield select(filterSelector)
    const { data } = yield call(api.get, 'bi-rh/dashboard/pie_charts', {
      params: {
        group_id: filters.group,
        department_id: filters.department,
        type_bond_id: filters.bond,
      },
    })
    yield put(
      RhDashboardActions.getRhDashboardDataSuccess(
        data.sort((a, b) => b.total - a.total),
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(RhDashboardActions.getRhDashboardDataFailure())
  }
}

function* getRhDashboardUsers({ dealershipId }) {
  try {
    const filters = yield select(filterSelector)
    const { data } = yield call(api.get, 'bi-rh/dashboard/users_departments', {
      params: {
        group_id: filters.group,
        department_id: filters.department,
        type_bond_id: filters.bond,
        dealership_id: dealershipId,
      },
    })
    yield put(RhDashboardActions.getRhDashboardUsersSuccess(data))
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(RhDashboardActions.getRhDashboardUsersFailure())
  }
}

function* reloadRhDashboardData() {
  yield put(RhDashboardActions.getRhDashboardDataRequest())
}

export default function* () {
  yield takeLatest(
    RhDashboardTypes.GET_RH_DASHBOARD_DATA_REQUEST,
    getRhDashboardData,
  )
  yield debounce(
    400,
    RhDashboardTypes.SET_RH_DASHBOARD_FILTER,
    reloadRhDashboardData,
  )
  yield takeLatest(
    RhDashboardTypes.GET_RH_DASHBOARD_FILTERS_REQUEST,
    getRhDashboardFilters,
  )

  yield takeLatest(
    RhDashboardTypes.GET_RH_DASHBOARD_USERS_REQUEST,
    getRhDashboardUsers,
  )
}
