import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as SuperiorsTypes,
  Creators as SuperiorsActions,
} from '../ducks/superiors'

const optionsSelector = ({ superiors }) => superiors.options

function* getSuperiors() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const response = yield call(api.get, 'parent/get_parents', {
      params: {
        pagination: 1,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        limit: options.limit,
        search: options.search ? `name,${options.search}` : null,
        'where[function_id]': options.functionId,
      },
    })
    yield put(
      SuperiorsActions.getSuperiorsSuccess(
        response.data.data,
        response.data.total,
      ),
    )
  } catch (e) {
    yield put(SuperiorsActions.getSuperiorsFailure())
  }
}

function* reloadSuperiors() {
  yield put(SuperiorsActions.getSuperiorsRequest())
}

function* getFunctionsSuperiors() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'functions', {
      params: {
        limit: 99999,
        order: 'name,asc',
      },
    })
    yield put(SuperiorsActions.getFunctionsSuperiorsSuccess(data.data))
  } catch (e) {
    yield put(SuperiorsActions.getFunctionsSuperiorsFailure())
  }
}

export default function* () {
  yield takeLatest(SuperiorsTypes.GET_SUPERIORS_REQUEST, getSuperiors)
  yield takeLatest(SuperiorsTypes.SET_SUPERIORS_SORT, reloadSuperiors)
  yield takeLatest(
    SuperiorsTypes.GET_FUNCTIONS_SUPERIORS_REQUEST,
    getFunctionsSuperiors,
  )
  yield debounce(1000, SuperiorsTypes.SET_SUPERIORS_SEARCH, reloadSuperiors)
  yield takeLatest(SuperiorsTypes.SET_SUPERIORS_PAGE, reloadSuperiors)
  yield takeLatest(
    SuperiorsTypes.SET_SUPERIORS_FUNCTION_FILTER,
    reloadSuperiors,
  )
}
