export const jsonUserToFormData = (data) => {
  const formData = new FormData()
  const codNbs = data.cod_nbs === null ? [] : data.cod_nbs.split(',')
  const address = data.address === null ? '' : data.address
  const jobRole = data.job_role === null ? '' : data.job_role
  const { email } = data

  if (email === undefined || email === null) {
    formData.append('email', '')
  } else {
    formData.append('email', data.email)
  }
  formData.append('name', String(data.name))
  codNbs.map((item) => formData.append('nbs_users[]', String(item)))
  formData.append('cod_nbs', codNbs)
  formData.append('username', String(data.username))
  formData.append('gender', String(data.gender))
  formData.append('address', address)

  formData.append('job_role', jobRole)

  if (data.rh_code) formData.append('rh_code', String(data.rh_code))
  if (data.department_id)
    formData.append('department_id', String(data.department_id))
  if (data.admission_date)
    formData.append('admission_date', String(data.admission_date))
  if (data.resignation_date)
    formData.append('resignation_date', String(data.resignation_date))
  if (data.linked_dealership_id)
    formData.append('linked_dealership_id', String(data.linked_dealership_id))
  if (data.dt_birthday) formData.append('dt_birthday', String(data.dt_birthday))
  if (data.dt_primeiro_contrato)
    formData.append('dt_primeiro_contrato', String(data.dt_primeiro_contrato))
  if (data.phone) formData.append('phone', String(data.phone))
  if (data.cpf) formData.append('cpf', String(data.cpf))
  if (data.rg) formData.append('rg', String(data.rg))
  if (data.ramal_next_ip)
    formData.append('ramal_next_ip', String(data.ramal_next_ip))
  if (data.image) formData.append('image', data.image)

  if (data.ctps) formData.append('ctps', String(data.ctps))
  if (data.serie) formData.append('serie', String(data.serie))
  if (data.pis) formData.append('pis', String(data.pis))
  if (data.titulo_eleitor)
    formData.append('titulo_eleitor', String(data.titulo_eleitor))
  if (data.zona) formData.append('zona', String(data.zona))
  if (data.seccao) formData.append('seccao', String(data.seccao))
  if (data.estado_civil)
    formData.append('estado_civil', String(data.estado_civil))
  if (data.grau_escolaridade)
    formData.append('grau_escolaridade', String(data.grau_escolaridade))
  if (data.bank_id) formData.append('bank_id', String(data.bank_id))
  if (data.agencia) formData.append('agencia', String(data.agencia))
  if (data.conta) formData.append('conta', String(data.conta))

  if (data.password) formData.append('password', String(data.password))

  if (data.dealerships_id) {
    data.dealerships_id.map((i) =>
      formData.append('dealerships_id[]', String(i)),
    )
  }
  if (data.roles_id) {
    data.roles_id.map((i) => formData.append('roles_id[]', String(i)))
  }

  return formData
}
