import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getUsersRequest: ['ramal'],
  getUsersFailure: [],
  getUsersSuccess: ['data', 'total', 'ramal'],
  setUsersSort: ['sort', 'direction', 'ramal'],
  setUsersPage: ['page', 'limit', 'ramal'],
  resetUsers: [],
  setUsersSearch: ['value', 'ramal'],
  setUsersPropertyFilter: ['property', 'value', 'ramal'],
  getUsersFilterDealershipsRequest: [],
  getUsersFilterDealershipsSuccess: ['data'],
  getUsersFilterDealershipsFailure: [],
  resetUsersFilters: [],
  setChangeShowHideColumnsUsers: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
    filters: [
      {
        name: 'dealership_id',
        value: null,
      },
      {
        name: 'active',
        value: true,
      },
      {
        name: 'gender',
        value: null,
      },
      {
        name: 'month',
        value: null,
      },
    ],
  },
  data: [],
  loading: false,
  dealerships: {
    data: [],
    loading: false,
  },
  active: {
    data: [],
    loading: false,
  },
  gender: {
    data: [],
    loading: false,
  },
  showHideColumns: {
    name: true,
    function: true,
    department: true,
    dealership: true,
    active: true,
    firstBond: true,
  },
}

const resetUsersFilters = () => ({
  ...INITIAL_STATE,
})

const setUsersPropertyFilter = (state = INITIAL_STATE, { property, value }) => {
  return {
    ...state,
    options: {
      ...state.options,
      filters: state.options.filters.map((filter) => {
        if (filter.name === property) {
          return {
            ...filter,
            value,
          }
        }
        return filter
      }),
    },
  }
}

const getUsersFilterDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getUsersFilterDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getUsersFilterDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUsersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUsersSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setUsersSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setUsersPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setUsersSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

const resetUsers = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsUsers = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableUsers',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.SET_USERS_SORT]: setUsersSort,
  [Types.SET_USERS_PAGE]: setUsersPage,
  [Types.RESET_USERS]: resetUsers,
  [Types.SET_USERS_SEARCH]: setUsersSearch,
  [Types.SET_USERS_PROPERTY_FILTER]: setUsersPropertyFilter,
  [Types.GET_USERS_FILTER_DEALERSHIPS_REQUEST]:
    getUsersFilterDealershipsRequest,
  [Types.GET_USERS_FILTER_DEALERSHIPS_SUCCESS]:
    getUsersFilterDealershipsSuccess,
  [Types.GET_USERS_FILTER_DEALERSHIPS_FAILURE]:
    getUsersFilterDealershipsFailure,
  [Types.RESET_USERS_FILTERS]: resetUsersFilters,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_USERS]: setChangeShowHideColumnsUsers,
})
