export default (theme) => ({
  iconButton: {
    padding: 0,
  },

  showHideButton: {
    padding: 0,
    float: 'right',
  },
  formControlLabel: {
    minWidth: 292,
    maxHeight: 500,
  },
  menuList: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  popper: {
    zIndex: 5,
    left: -21,
  },
})
