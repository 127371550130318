import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  makeStyles,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import { Close as CloseIcon } from '@material-ui/icons'

import LogosNissulDialog from './assets/LogosNissulDialog.png'
import ImageCompanyAnniversary from './assets/ImageCompanyAnniversary.png'
import styles from './styles'

const useStyles = makeStyles(styles)
function CommunicationDialogNotification({
  handleCloseNotificationsDialog,
  open,
  onClose,
  number,
  name,
}) {
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()

  const handleClose = () => {
    onClose()
    handleCloseNotificationsDialog(null)
  }

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        fullScreen={isMobileScreen}
        classes={{ paper: classes.paper }}
      >
        <DialogContent className={classes.dialog}>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <img
                src={LogosNissulDialog}
                alt="Logo Nissul Gala"
                className={classes.logo}
              />
            </Grid>
            <Grid item>
              <p className={classes.textTitle}>
                <Typography variant="h1" style={{ color: '#FFFF' }}>
                  {number} anos de Nissul Gala! 🎉
                </Typography>
              </p>
              <div className={classes.divText}>
                <p className={classes.text}>
                  <Typography
                    variant="h5"
                    style={{ color: '#FFFF', fontWeight: 400 }}
                  >
                    O Grupo Nissul Gala parabeniza o colaborador{' '}
                    <span style={{ color: '#ED1E79' }}>{name}</span> pelo
                    aniversário de empresa.
                  </Typography>
                </p>
              </div>
            </Grid>
            <Grid item>
              <img
                style={{ marginTop: '24px' }}
                src={ImageCompanyAnniversary}
                alt="desenho de pessoas comemorando"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

CommunicationDialogNotification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleCloseNotificationsDialog: PropTypes.func,
  number: PropTypes.string,
  name: PropTypes.string,
}

CommunicationDialogNotification.defaultProps = {
  open: true,
  onClose: () => {},
  handleCloseNotificationsDialog: () => {},
  number: '',
  name: '',
}

export default CommunicationDialogNotification
