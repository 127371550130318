/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DashboardContainer from '../containers/DashboardContainer'

const sessionSelector = ({ session }) => session

const PrivateRoute = ({
  component: Component,
  title,
  hasMonthCountdown,
  ...rest
}) => {
  const { access_token: token } = useSelector(sessionSelector)
  const loggedIn = !!token
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <DashboardContainer
            title={title}
            hasMonthCountdown={hasMonthCountdown}
          >
            <Component {...props} />
          </DashboardContainer>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string,
  hasMonthCountdown: PropTypes.bool,
}

PrivateRoute.defaultProps = {
  title: '',
  hasMonthCountdown: false,
}

export default PrivateRoute
