import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import {
  filter,
  // find
} from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  makeStyles,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import {
  Chart,
  HighchartsChart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highcharts'

import { Creators as BiDashboardActions } from '~/store/ducks/biDashboard'
import Portlet from '~/components/Portlet'
// import PieChart from '~/components/PieChart'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import SalesDialog from './components/SalesDialog'
import BiDashboardFilters from './components/BiDashboardFilters'
import BiDashboardTable from './components/BiDashboardTable'

import styles from './styles'

const biDashboardDataSelector = ({ biDashboard }) => biDashboard

const useStyles = makeStyles(styles)

export default function BiDashboardPage() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [openSalesDialog, setOpenSalesDialog] = useState(false)
  const [dealershipId, setDealershipId] = useState(null)
  const [dealershipName, setDealershipName] = useState('')
  const isSmallScreen = useSmallScreen()
  const {
    data: biDashboardData,
    loading,
    filters,
  } = useSelector(biDashboardDataSelector)

  useEffectOnce(() => {
    dispatch(BiDashboardActions.getBiDashboardTableDataRequest())
    dispatch(BiDashboardActions.getBiDashboardDataRequest())
  })

  const getFilteredData = (data) => {
    let filteredData = data
    if (filters.group !== 0) {
      const dataFilteredByGroup = filter(data, { group_id: filters.group })
      const grandTotalFiltered = {
        nome: 'Total Geral',
        news: dataFilteredByGroup.reduce((acc, curr) => acc + curr.news, 0),
        semiNews: dataFilteredByGroup.reduce(
          (acc, curr) => acc + curr.semiNews,
          0,
        ),
        direct: dataFilteredByGroup.reduce((acc, curr) => acc + curr.direct, 0),
        totalSales: dataFilteredByGroup.reduce(
          (acc, curr) => acc + curr.totalSales,
          0,
        ),
      }
      filteredData = [...dataFilteredByGroup, grandTotalFiltered]
    }
    return filteredData
  }

  const plotOptions = {
    pie: {
      shadow: false,
      innerSize: '50%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  }

  const colors = ['#1b1464', '#cb175b', '#ffa600']

  const handleOpenSalesDialog = (id, name) => {
    setOpenSalesDialog(true)
    setDealershipId(id)
    setDealershipName(name)
  }

  const handleCloseSalesDialog = () => {
    setOpenSalesDialog(false)
    setDealershipId(null)
    setDealershipName('')
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.header}>
        {isSmallScreen && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filterWropper}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <BiDashboardFilters />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && (
          <Grid item xs={12}>
            <BiDashboardFilters />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {loading && <OverlayLoading />}

        {!loading &&
          getFilteredData(biDashboardData)?.map((item) => {
            return (
              <Grid key={item.nome} item xs={12} sm={6} md={3}>
                <Portlet>
                  <HighchartsChart colors={colors} plotOptions={plotOptions}>
                    <Tooltip />
                    <Chart />
                    <Title y={30}>{item.nome}</Title>
                    <XAxis type="category" />
                    <YAxis>
                      <PieSeries
                        name="Vendas"
                        data={[
                          { name: 'Novos', y: item.news },
                          { name: 'Usados', y: item.semiNews },
                          { name: 'Venda Direta', y: item.direct },
                        ]}
                      />
                    </YAxis>
                    <Legend
                      layout="horizontal"
                      align="center"
                      verticalAlign="bottom"
                    />
                  </HighchartsChart>
                  <Grid container justifyContent="flex-end">
                    <Button
                      onClick={() =>
                        handleOpenSalesDialog(item.dealership_id, item.nome)
                      }
                      variant="outlined"
                      className={classes.button}
                      disabled={
                        item.nome.toLowerCase() === 'total geral' ||
                        item.totalSales === 0
                      }
                    >
                      {item.totalSales}
                      {item.totalSales > 1 ? ' VENDAS' : ' VENDA'}
                    </Button>
                  </Grid>
                </Portlet>
              </Grid>
            )
          })}
      </Grid>
      <Grid container item xs={12} />
      <SalesDialog
        open={openSalesDialog}
        dealershipId={dealershipId}
        dealershipName={dealershipName}
        onClose={handleCloseSalesDialog}
      />
      {!loading && (
        <Grid container>
          <Grid item xs={12}>
            <BiDashboardTable variant="news" />
          </Grid>
          <Grid item xs={12}>
            <BiDashboardTable variant="semiNews" />
          </Grid>
          <Grid item xs={12}>
            <BiDashboardTable variant="direct" />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
