import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { UpdateOutlined as TimerIcon } from '@material-ui/icons'
import { Menu, MenuItem, IconButton } from '@material-ui/core'

import styles from './styles'

const timerOptions = [
  { label: 'Últimos 15 dias', value: 15 },
  { label: 'Últimos 30 dias', value: 30 },
  { label: 'Últimos 60 dias', value: 60 },
  { label: 'Últimos 90 dias', value: 90 },
  { label: 'Últimos 120 dias', value: 120 },
]

const useStyles = makeStyles(styles)

const RangeTimeSelect = ({ onSelect, customOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleSelect = (option, options = {}) => {
    if (options.callback) {
      options.callback()
    }

    if (options.ignoreSelect !== true) {
      onSelect(option)
    }
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        className={classes.root}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <TimerIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {timerOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleSelect(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
        {customOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() =>
              handleSelect(option.value, {
                callback: option.callback,
                ignoreSelect: option.ignoreSelect,
              })
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

RangeTimeSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  customOptions: PropTypes.arrayOf(PropTypes.shape({})),
}

RangeTimeSelect.defaultProps = {
  customOptions: [],
}

export default RangeTimeSelect
