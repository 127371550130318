import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'

// import { mdiArrowUp, mdiArrowDown, mdiArrowLeftRight } from '@mdi/js'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function MetricsTab({ data }) {
  const classes = useStyles()

  return (
    <>
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid item style={{ margin: 8 }}>
          {/* <Card className={classes.card} style={{ padding: 8 }}>
                <Typography
                  variant="h6"
                  sttle={{ letterSpacing: -0.04 }}
                  color="primary"
                >
                  {`${item.nomeilha} ${item.nrilha}`}
                </Typography>

                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon
                    pathComponent={mdiArrowUp}
                    style={{ color: '#E53935' }}
                  />
                  {`Maior Duração: `} <b>{item.maior_duracao}</b>
                </Typography>

                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon pathComponent={mdiArrowLeftRight} />{' '}
                  {`Média de Duração: `} <b>{item.media_duracao}</b>
                </Typography>
                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon
                    pathComponent={mdiArrowDown}
                    style={{ color: '#348137' }}
                  />
                  {`Menor Duração: `} <b>{item.menor_duracao}</b>
                  <Divider style={{ marginBottom: 8, marginTop: 8 }} />
                </Typography>
                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon
                    pathComponent={mdiArrowUp}
                    style={{ color: '#E53935' }}
                  />{' '}
                  {`Maior Espera: `} <b>{item.maior_espera}</b>
                </Typography>
                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon pathComponent={mdiArrowLeftRight} />{' '}
                  {`Média de Espera: `}
                  <b>{item.media_espera}</b>
                </Typography>
                <Typography variant="h6" style={{ letterSpacing: 0.2 }}>
                  <CustomIcon
                    pathComponent={mdiArrowDown}
                    style={{ color: '#348137' }}
                  />{' '}
                  {`Menor Espera: `} <b>{item.menor_espera}</b>
                </Typography>
              </Card> */}
          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ margin: 160 }}>Ilha</TableCell>
                  <TableCell style={{ padding: 8 }}>Maior Duração</TableCell>
                  <TableCell style={{ padding: 8 }}>Média de Duração</TableCell>
                  <TableCell style={{ padding: 8 }}>Menor Duração</TableCell>
                  <TableCell style={{ padding: 8 }}>Maior Espera</TableCell>
                  <TableCell style={{ padding: 8 }}>Média de Espera</TableCell>
                  <TableCell style={{ padding: 8 }}>Menor Espera</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => {
                  return (
                    <TableRow className={classes.tableRow} key={item.nrilha}>
                      <TableCell>
                        {' '}
                        {`${item.nomeilha} ${item.nrilha}`}
                      </TableCell>
                      <TableCell align="center">{item.maior_duracao}</TableCell>
                      <TableCell align="center">{item.media_duracao}</TableCell>
                      <TableCell align="center">{item.menor_duracao}</TableCell>
                      <TableCell align="center">{item.maior_espera}</TableCell>
                      <TableCell align="center">{item.media_espera}</TableCell>
                      <TableCell align="center">{item.menor_espera}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

MetricsTab.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
