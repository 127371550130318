import { call, takeLatest, put, select } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as DashboardTypes,
  Creators as DashboardActions,
} from '../ducks/dashboard'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ dashboard }) => dashboard.options

const api = configureApi()

function* getDashboardBirthdays() {
  try {
    const { data } = yield call(api.get, 'bi-rh/birthdays', {
      params: {
        pagination: 0,
        'where[year]': moment().year(),
        'where[month]': moment().month() + 1,
      },
    })
    yield put(DashboardActions.getDashboardBirthdaysSuccess(data))
  } catch (e) {
    yield put(DashboardActions.getDashboardBirthdaysFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getQuizzesDashboard() {
  const options = yield select(optionsSelector)
  try {
    const { data } = yield call(api.get, 'quizzes', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        'where[active]': true,
      },
    })

    yield put(DashboardActions.getQuizzesDashboardSuccess(data.data))
  } catch (e) {
    yield put(DashboardActions.getQuizzesDashboardFailure())
  }
}

export default function* () {
  yield takeLatest(
    DashboardTypes.GET_DASHBOARD_BIRTHDAYS_REQUEST,
    getDashboardBirthdays,
  )
  yield takeLatest(
    DashboardTypes.GET_QUIZZES_DASHBOARD_REQUEST,
    getQuizzesDashboard,
  )
}
