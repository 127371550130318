import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

function RhfTextField({
  name,
  label,
  control,
  defaultValue,
  variant,
  rules,
  ...rest
}) {
  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <TextField
          label={label}
          variant={variant}
          value={value || ''}
          onChange={onChange}
          inputRef={ref}
          {...props}
          {...rest}
        />
      )}
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

RhfTextField.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  rules: PropTypes.objectOf(PropTypes.bool),
  // renderOption: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
}

RhfTextField.defaultProps = {
  defaultValue: '',
  // renderOption: () => {},
  variant: 'outlined',
  rules: {},
}

export default RhfTextField
