import { createActions, createReducer } from 'reduxsauce'
import { groupBy, sortBy } from 'lodash'
import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getRhDashboardFiltersRequest: [],
  getRhDashboardFiltersFailure: [],
  getRhDashboardFiltersSuccess: ['groups', 'departments'],
  getRhDashboardDataRequest: [],
  getRhDashboardDataFailure: [],
  getRhDashboardDataSuccess: ['data'],
  setRhDashboardFilter: ['property', 'value'],
  getRhDashboardUsersRequest: ['dealershipId'],
  getRhDashboardUsersFailure: [],
  getRhDashboardUsersSuccess: ['data'],
  setRhDashboardGroupFilter: ['value'],
  resetRhDashboard: [],
})

const INITIAL_STATE = {
  filters: {
    group: 0,
    department: 0,
    bond: 0,
  },
  filtersData: {
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
    ],
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    bonds: [
      {
        name: 'Todos os contratos',
        value: 0,
      },
      {
        name: 'CLT',
        value: 1,
      },
      {
        name: 'Terceiro',
        value: 2,
      },
      {
        name: 'Estágio',
        value: 3,
      },
    ],
  },
  users: {
    data: [],
    loading: true,
  },
  data: [],
  loadingFilters: false,
  loading: false,
}

const getRhDashboardFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getRhDashboardFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getRhDashboardFiltersSuccess = (
  state = INITIAL_STATE,
  { groups, departments },
) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
      ...departments,
    ],
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
      ...sortBy(groups, (a) => a.name),
    ],
  },
  loadingFilters: false,
})

const getRhDashboardDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getRhDashboardDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getRhDashboardDataSuccess = (state = INITIAL_STATE, { data }) => {
  const grouped = groupBy(data, 'dealership')
  const formattedData =
    data.length > 0
      ? Object.keys(grouped)?.map((key) => {
          return {
            id: uniqueId(),
            dealership_name: grouped[key][0]?.dealership,
            dealership_id: grouped[key][0]?.dealership_id,
            group_id: grouped[key][0]?.group_id,
            data: grouped[key],
          }
        })
      : []

  return {
    ...state,
    data: formattedData,
    loading: false,
  }
}

const setRhDashboardFilter = (state = INITIAL_STATE, { property, value }) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const setRhDashboardGroupFilter = (state = INITIAL_STATE, { value }) => ({
  ...state,
  filters: {
    ...state.filters,
    group: value,
  },
})

const getRhDashboardUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
  users: {
    ...state.users,
    loading: true,
  },
})

const getRhDashboardUsersFailure = (state = INITIAL_STATE) => ({
  ...state,
  users: {
    ...state.users,
    loading: false,
  },
})

const getRhDashboardUsersSuccess = (state = INITIAL_STATE, { data }) => {
  const userGrouped = groupBy(data, 'department')
  const formattedData =
    data.length > 0
      ? Object.keys(userGrouped)?.map((key) => {
          return {
            id: uniqueId(),
            data: userGrouped[key],
            department_name: userGrouped[key][0].department,
            total_away: userGrouped[key][0].total_away,
          }
        })
      : []
  return {
    ...state,
    users: {
      ...state.users,
      data: formattedData,
      loading: false,
    },
  }
}

const resetRhDashboard = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_RH_DASHBOARD_FILTERS_REQUEST]: getRhDashboardFiltersRequest,
  [Types.GET_RH_DASHBOARD_FILTERS_FAILURE]: getRhDashboardFiltersFailure,
  [Types.GET_RH_DASHBOARD_FILTERS_SUCCESS]: getRhDashboardFiltersSuccess,
  [Types.GET_RH_DASHBOARD_DATA_REQUEST]: getRhDashboardDataRequest,
  [Types.GET_RH_DASHBOARD_DATA_FAILURE]: getRhDashboardDataFailure,
  [Types.GET_RH_DASHBOARD_DATA_SUCCESS]: getRhDashboardDataSuccess,
  [Types.SET_RH_DASHBOARD_FILTER]: setRhDashboardFilter,
  [Types.GET_RH_DASHBOARD_USERS_REQUEST]: getRhDashboardUsersRequest,
  [Types.GET_RH_DASHBOARD_USERS_FAILURE]: getRhDashboardUsersFailure,
  [Types.GET_RH_DASHBOARD_USERS_SUCCESS]: getRhDashboardUsersSuccess,
  [Types.SET_RH_DASHBOARD_GROUP_FILTER]: setRhDashboardGroupFilter,
  [Types.RESET_RH_DASHBOARD]: resetRhDashboard,
})
