import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import classNames from 'classnames'

import styles from './styles'

const ConfirmDialog = ({
  classes,
  open,
  title,
  description,
  acceptLabel,
  cancelLabel,
  variant,
  onAccept,
  onCancel,
}) => (
  <Dialog open={open} onClose={onCancel} classes={{ paper: classes.paper }}>
    <div
      className={classNames(
        classes.variantHeader,
        {
          [classes.variantSaveHeader]: variant === 'save',
        },
        {
          [classes.variantWarningHeader]: variant === 'warning',
        },
      )}
    >
      {variant === 'save' && (
        <CheckIcon className={classes.variantHeaderIcon} />
      )}
      {variant === 'delete' && (
        <DeleteIcon className={classes.variantHeaderIcon} />
      )}
      {variant === 'warning' && (
        <WarningIcon className={classes.variantHeaderIcon} />
      )}
    </div>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {typeof description === 'string' ? (
        <DialogContentText>{description}</DialogContentText>
      ) : (
        description
      )}
    </DialogContent>
    <DialogActions className={classes.buttonContent}>
      {variant === 'warning' || variant === 'delete' ? (
        <>
          <Button onClick={onCancel} variant="contained" autoFocus>
            {cancelLabel}
          </Button>
          <Button
            className={classes.acceptButton}
            onClick={onAccept}
            variant="contained"
            color="primary"
          >
            {acceptLabel}
          </Button>
        </>
      ) : (
        <>
          <Button
            className={classes.cancelButton}
            onClick={onCancel}
            variant="contained"
          >
            {cancelLabel}
          </Button>
          <Button onClick={onAccept} variant="contained" autoFocus>
            {acceptLabel}
          </Button>
        </>
      )}
    </DialogActions>
  </Dialog>
)

ConfirmDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  variant: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  open: false,
  cancelLabel: 'não',
  acceptLabel: 'sim',
  variant: 'delete',
}

export default withStyles(styles)(ConfirmDialog)
