export default () => ({
  paper: {
    borderRadius: 12,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    // minWidth: 600,
  },
  dialogContent: {
    padding: 0,
    height: '100%',
    display: 'flex',
    flex: 1,
    minHeight: 400,
  },
  tableWrapper: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
})
