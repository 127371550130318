/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import green from '@material-ui/core/colors/green'

import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  ArrowDownward as ArrowDownIcon,
  ArrowUpward as ArrowUpIcon,
  // Money as MoneyIcon,
} from '@material-ui/icons'
import { formatPrice } from '~/helpers/masks'

const AnalitycalCard = ({ data, variant }) => {
  const isCost = variant === 'cost'
  const isProfitPercentage = variant === 'profit_percentage'

  const percentage =
    data?.previousValue &&
    parseFloat(
      ((data?.value - data?.previousValue || 0) / data?.previousValue) * 100,
    )?.toFixed(2)

  const removeSignal = (value) => {
    return String(value).replace('-', '')
  }

  const getTextColor = () => {
    let color = '#E53935'
    if (
      (!isCost && data?.value >= data?.previousValue) ||
      (isCost && percentage < 0)
    ) {
      color = green[600]
    }
    return color
  }

  return (
    <Card style={{ height: '100%' }}>
      <CardContent style={{ margin: 8, padding: 5 }}>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'space-between', padding: 0 }}
        >
          <Grid container spacing={3} justifyContent="flex-end">
            {/* <Grid item>
              <Avatar
                style={{
                  backgroundColor: '#1B1464',
                  height: 38,
                  width: 38,
                }}
              >
                {icon || <MoneyIcon />}
              </Avatar>
            </Grid> */}
          </Grid>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              {data?.name}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {isProfitPercentage ? (
                <>{parseFloat(data?.value).toFixed(2)}%</>
              ) : (
                <>
                  {isNumber(data?.value)
                    ? formatPrice(data.value || 0)
                    : data?.value}
                </>
              )}
            </Typography>
            {data?.previousValue && (
              <Typography color="textPrimary" variant="body2">
                Período anterior:{' '}
                {isProfitPercentage ? (
                  <>{parseFloat(data?.previousValue).toFixed(2)}%</>
                ) : (
                  <>
                    {isNumber(data?.previousValue)
                      ? formatPrice(data.previousValue || 0)
                      : data?.previousValue}
                  </>
                )}
              </Typography>
            )}
          </Grid>
          {/* <Grid item>
            <Avatar
              style={{
                backgroundColor: '#1B1464',
                height: 38,
                width: 38,
              }}
            >
              {icon || <MoneyIcon />}
            </Avatar>
          </Grid> */}
        </Grid>
        {percentage && (
          <Box
            style={{
              paddingTop: 8,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isCost && (
              <>
                {percentage >= 0 ? (
                  <ArrowDownIcon
                    color="error"
                    style={{ margin: 0, padding: 0 }}
                  />
                ) : (
                  <ArrowUpIcon
                    style={{ color: green[600], margin: 0, padding: 0 }}
                  />
                )}
              </>
            )}
            {!isCost && (
              <>
                {data?.value >= data?.previousValue ? (
                  <ArrowUpIcon style={{ color: green[600] }} />
                ) : (
                  <ArrowDownIcon color="error" />
                )}
              </>
            )}
            <Typography
              style={{
                marginRight: 2,
                marginLeft: 2,
                color: getTextColor(),
              }}
              variant="body2"
            >
              {isProfitPercentage && (
                <>
                  {removeSignal(
                    parseFloat(data.value - data.previousValue).toFixed(2),
                  )}
                  %
                </>
              )}
              {!isProfitPercentage && <>{removeSignal(percentage)}%</>}
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
              style={{ fontSize: '11px' }}
            >
              Em relação ao período anterior
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

AnalitycalCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    previousValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  variant: PropTypes.string.isRequired,
  // icon: PropTypes.element,
}

AnalitycalCard.defaultProps = {
  data: {
    name: '',
    value: null,
    previousValue: null,
  },
  // icon: null,
}

export default AnalitycalCard
