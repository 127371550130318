import React from 'react'
import PropTypes from 'prop-types'
import { ReactionBarSelectorEmoji } from './ReactionBarSelectorEmoji'

export const ReactionBarSelector = ({ iconSize, reactions, onSelect }) => {
  const emojiStyle = React.useMemo(() => {
    return {
      width: `${iconSize + 10}px`,
      height: `${iconSize + 10}px`,
      display: 'flex',
      alignItems: 'center',
      fontSize: iconSize,
    }
  }, [iconSize])

  const wrapStyle = {
    backgroundColor: '#fff',
    borderRadius: '50px',
    padding: '4px',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, .05), 0 1px 2px rgba(0, 0, 0, .15)',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  }

  return (
    <div style={wrapStyle}>
      {reactions.map((reaction) => {
        return (
          <div style={emojiStyle} key={reaction.label}>
            <ReactionBarSelectorEmoji reaction={reaction} onSelect={onSelect} />
          </div>
        )
      })}
    </div>
  )
}

ReactionBarSelector.propTypes = {
  iconSize: PropTypes.number,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      node: PropTypes.node,
    }),
  ),
  onSelect: PropTypes.func,
}

ReactionBarSelector.defaultProps = {
  iconSize: 38,
  reactions: [
    { node: <div>👍</div>, label: 'like' },
    { node: <div>❤️</div>, label: 'love' },
    { node: <div>😆</div>, label: 'haha' },
    { node: <div>😮</div>, label: 'wow' },
    { node: <div>😢</div>, label: 'sad' },
    { node: <div>😡</div>, label: 'angry' },
  ],
  onSelect: (label) => {
    console.log(label)
  },
}

export default ReactionBarSelector
