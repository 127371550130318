export default (theme) => ({
  paper: {
    maxWidth: '650px',
    maxHeight: '650px',
    background: '#1B1464;',
    padding: theme.spacing(3),
    margin: 0,
  },
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  textTitle: {
    margin: theme.spacing(2),
  },
  divText: {
    width: '468px',
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
})
