import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as BilledTypes,
  Creators as BilledActions,
} from '../ducks/billed'

const filterSelector = ({ billed }) => billed.filters

const api = configureApi()

function* getBilledFilters() {
  try {
    const { data: groups } = yield call(api.get, 'groups', {
      params: { pagination: 0 },
    })

    const groupsData = groups.map((a) => ({
      name: a.name,
      value: a.id,
    }))

    yield put(BilledActions.getBilledFiltersSuccess(groupsData))
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(BilledActions.getBilledFiltersFailure())
  }
}

function* getBilledData() {
  try {
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }
    const { data } = yield call(api.get, 'table_data_split', {
      params: {
        'where[year]': mDate.year(),
        'where[month]': mDate.month() + 1,
        'where[type]': filters.type,
        'where[with_total]': 'N',
      },
    })

    const billedData = data
      .filter(item => !filters.group || filters.group === 'all' || item.group_id === filters.group)
      .map((item) => ({
        id: item.dealership_id,
        title: item.dealership_name,
        total: item.total_faturados,
        goal: item.sales,
        sort: item.dealership_sort,
        data: [Math.floor((100 * item.total_faturados) / item.sales) || 0],
      }))
      .sort((a, b) => b.data.sort - a.data.sort)
    yield put(BilledActions.getBilledDataSuccess(billedData))
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(BilledActions.getBilledDataFailure())
  }
}

function* getBillingsData({ id }) {
  try {
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }

    const { data } = yield call(api.get, 'billings', {
      params: {
        'where[dealership_id]': id,
        'where[year]': mDate.year(),
        'where[month]': mDate.month() + 1,
        'where[type]': filters.type,
      },
    })

    yield put(BilledActions.getBillingsDataSuccess(data))
  } catch(e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(BilledActions.getBillingsDataFailure())

  }
}

function* reloadBilledData() {
  yield put(BilledActions.getBilledDataRequest())
}

export default function* () {
  yield takeLatest(BilledTypes.GET_BILLINGS_DATA_REQUEST, getBillingsData)
  yield takeLatest(BilledTypes.GET_BILLED_DATA_REQUEST, getBilledData)
  yield debounce(400, BilledTypes.SET_BILLED_FILTER, reloadBilledData)
  yield takeLatest(BilledTypes.GET_BILLED_FILTERS_REQUEST, getBilledFilters)
}
