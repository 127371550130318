import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Grid, makeStyles, Typography, Avatar } from '@material-ui/core'

import {
  BsNewspaper as NewspaperIcon,
  BsCircleFill as CircleIcon,
} from 'react-icons/bs'

import { Creators as NotificationsActions } from '~/store/ducks/notifications'
import styles from './styles'
import CommunicationDialogNotification from './components/CommunicationDialogNotification'

const useStyles = makeStyles(styles)

export default function CommucationsList({
  data,
  onlyUnread,
  handleCloseCommunications,
}) {
  const [openCommunicationDialog, setOpenCommunicationDialog] = useState(false)
  const [communicationTitle, setCommunicationTitle] = useState(null)
  const [communicationText, setCommunicationText] = useState(null)
  const [communicationImageUrl, setCommunicationImageUrl] = useState(null)

  const dispatch = useDispatch()

  const readCommunication = (item) => {
    setOpenCommunicationDialog(true)
    setCommunicationTitle(item.context.title)
    setCommunicationText(item.context.text)
    setCommunicationImageUrl(item.context.url_image)

    if (item.status === 'unread') {
      dispatch(
        NotificationsActions.handleReadNotificationRequest(item.id, () => {
          dispatch(NotificationsActions.getNotificationsRequest())
        }),
      )
    }
  }

  const handleCloseCommunicationDialog = () => {
    setOpenCommunicationDialog(false)
  }

  const unreadData = data?.filter((item) => item?.status === 'unread')

  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.grow}>
          <div className={classes.tableWrapper}>
            <div className={classes.listWrapper}>
              {onlyUnread
                ? unreadData?.map((item) => (
                    <Grid
                      container
                      spacing={3}
                      key={item.id}
                      onClick={() => readCommunication(item)}
                      className={classes.container}
                    >
                      <Grid item xs={12}>
                        <div className={classes.content}>
                          <Avatar>
                            <NewspaperIcon size={20} />
                          </Avatar>
                          <div className={classes.contentText}>
                            <div className={classes.titleWrapper}>
                              <div className={classes.textWrapper}>
                                <Typography
                                  variant="h5"
                                  className={classes.text}
                                >
                                  {item.context.title}
                                </Typography>
                              </div>
                              <div className={classes.circleWrapper}>
                                <CircleIcon
                                  size={4}
                                  color={
                                    item.status === 'unread'
                                      ? '#ED1E79'
                                      : '#B3B3B3'
                                  }
                                />
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: 8 }}
                                >
                                  {moment(
                                    moment(item.created_at).format(
                                      'YYYY-MM-DD',
                                    ),
                                  ).isSame(
                                    moment(new Date()).format('YYYY-MM-DD'),
                                    'day',
                                  )
                                    ? moment(item.created_at).format('HH:mm')
                                    : moment(item.created_at).format('DD/MM')}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.textWrapper}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                {item.context.text}
                              </Typography>
                            </div>
                          </div>
                          <div>
                            {item.context.url_image && (
                              <img
                                src={item.context.url_image}
                                alt="imagem da publicação"
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ))
                : data?.map((item) => (
                    <Grid
                      container
                      spacing={3}
                      key={item.id}
                      onClick={() => readCommunication(item)}
                      className={classes.container}
                    >
                      <Grid item xs={12}>
                        <div className={classes.content}>
                          <Avatar>
                            <NewspaperIcon size={20} />
                          </Avatar>
                          <div className={classes.contentText}>
                            <div className={classes.titleWrapper}>
                              <div className={classes.textWrapper}>
                                <Typography
                                  variant="h5"
                                  className={classes.text}
                                >
                                  {item.context.title}
                                </Typography>
                              </div>
                              <div className={classes.circleWrapper}>
                                {item.status === 'unread' ? (
                                  <CircleIcon size={4} color="#ED1E79" />
                                ) : (
                                  ''
                                )}
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: 8 }}
                                >
                                  {moment(
                                    moment(item.created_at).format(
                                      'YYYY-MM-DD',
                                    ),
                                  ).isSame(
                                    moment(new Date()).format('YYYY-MM-DD'),
                                    'day',
                                  )
                                    ? moment(item.created_at).format('HH:mm')
                                    : moment(item.created_at).format('DD/MM')}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.textWrapper}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                {item.context.text}
                              </Typography>
                            </div>
                          </div>
                          <div>
                            {item.url_image && (
                              <img
                                src={item.url_image}
                                alt="imagem da publicação"
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <CommunicationDialogNotification
        handleCloseNotificationsDialog={handleCloseCommunications}
        open={openCommunicationDialog}
        onClose={handleCloseCommunicationDialog}
        title={communicationTitle}
        text={communicationText}
        image={communicationImageUrl}
      />
    </>
  )
}

CommucationsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onlyUnread: PropTypes.PropTypes.bool.isRequired,
  handleCloseCommunications: PropTypes.PropTypes.func.isRequired,
}
