import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addFunctionRequest: ['data', 'callback'],
  addFunctionFinish: [],
  getFunctionRequest: ['id'],
  getFunctionSuccess: ['data'],
  getFunctionFailure: [],
  updateFunctionRequest: ['id', 'data', 'callback'],
  updateFunctionFinish: [],
  removeFunctionRequest: ['id', 'callback'],
  removeFunctionFinish: [],
})

const INITIAL_STATE = {
  data: {
    name: '',
  },
  loading: false,
}

const addFunctionRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  }
}

const addFunctionFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getFunctionSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getFunctionFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateFunctionFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeFunctionFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_FUNCTION_REQUEST]: addFunctionRequest,
  [Types.ADD_FUNCTION_FINISH]: addFunctionFinish,
  [Types.GET_FUNCTION_REQUEST]: getFunctionRequest,
  [Types.GET_FUNCTION_SUCCESS]: getFunctionSuccess,
  [Types.GET_FUNCTION_FAILURE]: getFunctionFailure,
  [Types.UPDATE_FUNCTION_REQUEST]: updateFunctionRequest,
  [Types.UPDATE_FUNCTION_FINISH]: updateFunctionFinish,
  [Types.REMOVE_FUNCTION_REQUEST]: removeFunctionRequest,
  [Types.REMOVE_FUNCTION_FINISH]: removeFunctionFinish,
})
