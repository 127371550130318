import React from 'react'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useUnmount } from 'react-use'

import { Creators as EquipmentDashboardActions } from '~/store/ducks/equipmentDashboard'

const equipmentDashboardSelector = ({ equipmentDashboard }) =>
  equipmentDashboard

function EquipmentsDashboardFilters() {
  const { filtersData, filters } = useSelector(equipmentDashboardSelector)
  const dispatch = useDispatch()

  useUnmount(() => {
    dispatch(EquipmentDashboardActions.resetEquipmentDashboard())
  })

  function handleChangeFilter(property, value) {
    dispatch(
      EquipmentDashboardActions.setEquipmentDashboardFilter(property, value),
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Grupos"
          fullWidth
          select
          value={filters.group}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {filtersData.groups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Estado"
          fullWidth
          select
          value={filters.state}
          onChange={(ev) => handleChangeFilter('state', ev.target.value)}
        >
          {filtersData.states.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Departamentos"
          fullWidth
          select
          value={filters.department}
          onChange={(ev) => handleChangeFilter('department', ev.target.value)}
        >
          {filtersData.departments.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

EquipmentsDashboardFilters.propTypes = {}

export default EquipmentsDashboardFilters
