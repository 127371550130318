export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  accountButton: {
    borderRadius: 4,
  },
  arrowIcon: {
    width: 20,
    height: 20,
    color: '#fff',
    marginLeft: 4,
  },
  avatar: {
    width: '36px',
    height: '36px',
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  dropdownIcon: {
    minWidth: 0,
    marginRight: 0,
  },
  accountMenu: {
    '& .MuiMenuItem-root': {
      minWidth: '314px',
      minHeight: '68px',
      borderRadius: 4,
    },
    '& button:first-child': {
      border: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'initial',
      },
    },
    '& li': {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#e4e7eb',
    },
  },
  menuList: {
    padding: 0,
  },
  avatarMenu: {
    padding: 0,
    width: 36,
    height: 36,
    color: theme.palette.primary.main,
    position: 'relative',
  },
  avatarImage: {
    border: '1px solid #ED1E79',
  },
  accountName: {
    marginLeft: 10,
    color: theme.palette.text.main,
    fontWeight: 500,
  },
  position: {
    marginLeft: 10,
  },
  avatarIcon: {
    backgroundColor: '#fafafa',
    color: theme.palette.primary.main,
  },
  avatarIconUser: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  avatarMenuWrapper: {
    backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
  },
  avatarWrapper: {
    position: 'absolute',
    width: 25,
    height: 28,
    backgroundColor: theme.palette.common.white,
    left: 14,
    borderRadius: '50%',
    zIndex: 0,
  },
})
