export default (theme) => ({
  root: {
    width: '100%',
    minWidth: 400,
  },
  notificationIcon: {
    color: '#fff',
    padding: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: '24px 20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0px',
  },
  tabNotifications: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
