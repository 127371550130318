import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as EquipmentsTypesTypes,
  Creators as EquipmentsTypesActions,
} from '../ducks/equipmentsTypes'

const optionsSelector = ({ equipmentsTypes }) => equipmentsTypes.options

function* getEquipments() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'equipment_types', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        search: options.search ? `name,${options.search}` : null,
      },
    })
    yield put(
      EquipmentsTypesActions.getEquipmentsTypesSuccess(data.data, data.total),
    )
  } catch (e) {
    yield put(EquipmentsTypesActions.getEquipmentsTypesFailure())
  }
}

function* reloadEquipments() {
  yield put(EquipmentsTypesActions.getEquipmentsTypesRequest())
}

export default function* () {
  yield takeLatest(
    EquipmentsTypesTypes.GET_EQUIPMENTS_TYPES_REQUEST,
    getEquipments,
  )
  yield takeLatest(
    EquipmentsTypesTypes.SET_EQUIPMENTS_TYPES_SORT,
    reloadEquipments,
  )
  yield takeLatest(
    EquipmentsTypesTypes.SET_EQUIPMENTS_TYPES_PAGE,
    reloadEquipments,
  )
  yield debounce(
    1000,
    EquipmentsTypesTypes.SET_EQUIPMENTS_TYPES_SEARCH,
    reloadEquipments,
  )
}
