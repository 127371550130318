import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import SearchInput from '~/components/SearchInput'
import Autocomplete from '~/components/Autocomplete'
import { Creators as SuperiorsActions } from '~/store/ducks/superiors'

import styles from './styles'

const functionsSelector = ({ superiors }) => superiors.functions
const useStyles = makeStyles(styles)
const superiorsSelector = ({ superiors }) => superiors

function SuperiorsFilter() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: dataFunctions, loading: loadingFunctions } = useSelector(
    functionsSelector,
  )
  const { options } = useSelector(superiorsSelector)

  const handleSearchChange = (value) => {
    dispatch(SuperiorsActions.setSuperiorsSearch(value))
  }

  const handleFunctionFilterChange = (optionId) => {
    dispatch(SuperiorsActions.setSuperiorsFunctionFilter(optionId))
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        <SearchInput
          className={classes.search}
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder="Pesquise por nome..."
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          onChange={(_, option) => {
            handleFunctionFilterChange(option ? option.value : null)
          }}
          options={dataFunctions.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="function"
          value={options.functionId}
          className={classes.autocomplete}
          label="Filtrar por função"
          placeholder="Selecione uma função"
          loading={loadingFunctions}
          autoSelect={false}
        />
      </Grid>
    </Grid>
  )
}

export default SuperiorsFilter
