import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, makeStyles, IconButton, Grid } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import styles from './styles'

const useStyles = makeStyles(styles)
function ImageDialog({ open, onClose, imageUrl }) {
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth="md"
        fullScreen={isMobileScreen}
        classes={{ paper: classes.paper }}
      >
        {isMobileScreen ? (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton onClick={onClose} className={classes.iconButton}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <img src={imageUrl} alt="imagem da notícia" className={classes.image} />
      </Dialog>
    </>
  )
}

ImageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imageUrl: PropTypes.string,
}

ImageDialog.defaultProps = {
  open: true,
  onClose: () => {},
  imageUrl: '',
}

export default ImageDialog
