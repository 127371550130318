import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  Badge,
} from '@material-ui/core'

import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'

// import { mdiArrowUp, mdiArrowDown, mdiArrowLeftRight } from '@mdi/js'

import { Creators as IpcActions } from '~/store/ducks/ipc'

import RangeTimeSelect from '~/components/RangeTimeSelect'
import DateRangePicker from '~/components/DateRangePicker'
import OverlayLoading from '~/components/OverlayLoading'

import styles from './styles'

const useStyles = makeStyles(styles)

const IpcSelector = ({ ipc }) => ipc

export default function RankingTab({ data, period }) {
  const { filters, loading } = useSelector(IpcSelector)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleDatesChange = (dates) => {
    const { startDate, endDate } = dates
    dispatch(IpcActions.setRankingDate(startDate, endDate))
  }

  const handleTimerChange = (value) => {
    const from = moment().subtract(value, 'd')
    const to = moment()
    dispatch(IpcActions.setRankingDate(from, to))
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid
        container
        justifyContent="space-between"
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginLeft: 15, marginTop: 16, marginBottom: 26 }}
        >
          <DateRangePicker
            startDate={moment(filters.startDate)}
            startDateId="ranking-start"
            endDate={moment(filters.endDate)}
            isOutsideRange={() => false}
            endDateId="ranking-end"
            onDatesChange={handleDatesChange}
          />
          <RangeTimeSelect onSelect={handleTimerChange} />
        </Grid>
        <Paper className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Tooltip placement="top" title="Total geral">
                  <TableCell style={{ cursor: 'pointer' }}>Total</TableCell>
                </Tooltip>
                <TableCell>Loja</TableCell>
                <Tooltip
                  style={{ cursor: 'pointer' }}
                  placement="top"
                  title="Ligações da loja"
                >
                  <TableCell>Ligações</TableCell>
                </Tooltip>
                <Tooltip
                  style={{ cursor: 'pointer' }}
                  placement="top"
                  title="Porcentagem em relação ao total"
                >
                  <TableCell>%</TableCell>
                </Tooltip>
                <TableCell>Ligações por dia</TableCell>
                <Tooltip
                  style={{ cursor: 'pointer' }}
                  placement="top"
                  title="Colaborador que mais ligou"
                >
                  <TableCell>Mais ligou</TableCell>
                </Tooltip>
                <Tooltip
                  style={{ cursor: 'pointer' }}
                  placement="top"
                  title="Colaborador que menos ligou"
                >
                  <TableCell>Menos ligou</TableCell>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => {
                return (
                  <TableRow className={classes.tableRow} key={item.percent}>
                    <TableCell>{period.calls}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.calls}</TableCell>
                    <TableCell>{item.percent.toFixed(2)}</TableCell>
                    <TableCell>{item.calls_by_day.toFixed(2)}</TableCell>
                    <TableCell>
                      <Badge
                        color="secondary"
                        max="1000"
                        showZero
                        overlap="rectangular"
                        badgeContent={item.top_caller_count}
                      >
                        <span
                          style={{ marginTop: 20, marginLeft: 12 }}
                        >{`${item.top_caller.name}`}</span>
                      </Badge>
                    </TableCell>

                    {/* <Tooltip
                      style={{ cursor: 'pointer' }}
                      placement="top"
                      title={item.bottom_caller_count}
                    > */}

                    <TableCell>
                      <Badge
                        color="secondary"
                        max="1000"
                        showZero
                        overlap="rectangular"
                        badgeContent={item.bottom_caller_count}
                      >
                        <span
                          style={{ marginTop: 20, marginLeft: 12 }}
                        >{`${item.bottom_caller.name}`}</span>
                      </Badge>
                    </TableCell>
                    {/* </Tooltip> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </>
  )
}

RankingTab.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  period: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
