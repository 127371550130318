import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as SuperiorTypes,
  Creators as SuperiorActions,
} from '../ducks/superior'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* getSuperior({ userId }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'parent/get_superiors/', {
      params: {
        user_id: userId,
      },
    })
    yield put(SuperiorActions.getSuperiorSuccess(data))
  } catch (e) {
    yield put(SuperiorActions.getSuperiorFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateSuperior({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, 'parent/save', data)
    yield put(SuperiorActions.updateSuperiorFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Superior editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(SuperiorActions.updateSuperiorFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(SuperiorTypes.GET_SUPERIOR_REQUEST, getSuperior)
  yield takeLatest(SuperiorTypes.UPDATE_SUPERIOR_REQUEST, updateSuperior)
}
