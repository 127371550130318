import React from 'react'
import PropTypes from 'prop-types'
import { HoverContext } from './useHover'

export const Hover = ({ hoverStyle = {}, children, style, ...rest }) => {
  const [isHovered, setHovered] = React.useState(false)

  return (
    <HoverContext.Provider value={isHovered}>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...rest}
        style={{ ...style, ...(isHovered ? hoverStyle : {}) }}
      >
        {children}
      </div>
    </HoverContext.Provider>
  )
}

Hover.propTypes = {
  hoverStyle: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.string).isRequired,
}

Hover.defaultProps = {
  hoverStyle: null,
}

export default Hover
