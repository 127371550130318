import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Typography, IconButton } from '@material-ui/core'

import { Add as AddIcon, Remove as SubtractIcon } from '@material-ui/icons'

import { useDispatch } from 'react-redux'

import { Creators as DailySalesActions } from '~/store/ducks/dailySales'

import Portlet from '~/components/Portlet'

import styles from './styles'

const useStyles = makeStyles(styles)

function DailySalesCard({ data }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const reloadDailySalesList = () =>
    dispatch(DailySalesActions.getDailySalesRequest())

  const handleAddDailySale = (id, currentDailySale) => {
    dispatch(
      DailySalesActions.updateDailySaleRequest(id, currentDailySale + 1, () => {
        reloadDailySalesList()
      }),
    )
  }

  const handleRemoveDailySale = (id, currentDailySale) => {
    dispatch(
      DailySalesActions.updateDailySaleRequest(id, currentDailySale - 1, () => {
        reloadDailySalesList()
      }),
    )
  }
  return (
    <Portlet className={classes.root}>
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="h4">{data[0].dealership}</Typography>
      </Grid>
      <Grid container className={classes.cardContent}>
        {data.map((dailySale) => (
          <Grid
            container
            key={dailySale.id}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item xs={6}>
              <Typography variant="h6">{dailySale.objective_type}</Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              item
              xs={6}
            >
              <Grid item>
                <IconButton
                  className={classes.iconButton}
                  disabled={dailySale.daily_sales <= 0}
                  onClick={() =>
                    handleRemoveDailySale(dailySale.id, dailySale.daily_sales)
                  }
                >
                  <SubtractIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{dailySale.daily_sales}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={classes.iconButton}
                  onClick={() =>
                    handleAddDailySale(dailySale.id, dailySale.daily_sales)
                  }
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Portlet>
  )
}

DailySalesCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      daily_sales: PropTypes.number,
      dealership: PropTypes.string,
      objective_type: PropTypes.string,
    }),
  ).isRequired,
}

export default React.memo(DailySalesCard)
