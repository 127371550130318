import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@material-ui/core'

export default function TabPanel(props) {
  const { children, value, index, noPadding, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ padding: noPadding && 0 }}>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  noPadding: PropTypes.bool,
}

TabPanel.defaultProps = {
  noPadding: false,
}
