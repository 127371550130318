import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as QuizzesTypes,
  Creators as QuizzesActions,
} from '../ducks/quizzes'

const optionsSelector = ({ quizzes }) => quizzes.options

function* getQuizzes() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'quizzes', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        'where[type]': 'quiz',
      },
    })

    yield put(QuizzesActions.getQuizzesSuccess(data.data, data.total))
  } catch (e) {
    yield put(QuizzesActions.getQuizzesFailure())
  }
}

function* reloadQuizzes() {
  yield put(QuizzesActions.getQuizzesRequest())
}

export default function* () {
  yield takeLatest(QuizzesTypes.GET_QUIZZES_REQUEST, getQuizzes)
  yield takeLatest(QuizzesTypes.SET_QUIZZES_SORT, reloadQuizzes)
  yield takeLatest(QuizzesTypes.SET_QUIZZES_PAGE, reloadQuizzes)
}
