import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  makeStyles,
} from '@material-ui/core'

import LaunchIcon from '@material-ui/icons/Launch'

import history from '~/helpers/history'

import styles from './styles'

const useStyles = makeStyles(styles)

function ELearningTab({ data }) {
  const classes = useStyles()
  const quizzeLink = (item) => {
    history.push(`/surveys/${item.id}`)
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Table className={classes.table}>
          <TableBody>
            {data?.map((item) => {
              return (
                <TableRow hover key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => quizzeLink(item)}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider />
      </Grid>
    </>
  )
}

ELearningTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
}

export default ELearningTab
