import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Inbox as InboxIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import styles from './styles'

const useStyles = makeStyles(styles)

const EmptyList = ({ message, icon: Icon, button, variant }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Icon className={classes.emptyIcon} />
        <Typography variant={variant}>{message}</Typography>
        {button}
      </div>
    </div>
  )
}

EmptyList.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.shape({}),
  button: PropTypes.element,
}

EmptyList.defaultProps = {
  message: 'Não há itens para serem visualizados',
  variant: 'h5',
  icon: InboxIcon,
  button: <></>,
}

export default EmptyList
