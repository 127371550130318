export default (theme) => ({
  image: {
    maxHeight: 750,
    maxWidth: 620,
    objectFit: 'contain',
    [theme.breakpoints.down('lg')]: {
      maxHeight: 460,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 860,
    },
  },

  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 620,
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
      margin: '0 auto',
      alignContent: 'center',
    },
    height: '100%',
    margin: 'auto',
  },
})
