export default (theme) => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 12,
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    },
  },
  divider: {
    marginBottom: 2,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  dialogContent: {
    height: 660,
    padding: 0,
    '& .MuiBadge-colorPrimary': {},
  },
})
