export default (theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 0,
  },

  showHideButton: {
    padding: 0,
    float: 'right',
  },
  formControlLabel: {
    minWidth: 292,
    maxHeight: 500,
  },
  menuList: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  popper: {
    zIndex: 5,
    left: -21,
  },
})
