export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    backgroundColor: '#ffff',
    height: '100%',
    position: 'relative',
    overflowX: 'auto',
    flex: 1,
  },
  tabsWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  actionsWrapper: {
    padding: theme.spacing(2),
    // backgroundColor: '#ffff',
    // borderTop: '1px solid #E4E7EB',
    position: 'aboslute',
    bottom: 0,
  },
  grow: {
    flex: 1,
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  Icon: {
    marginRight: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'space-around',
  },
  announce: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  announceResignation: {
    // marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})
