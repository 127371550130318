import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import {
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Divider,
} from '@material-ui/core'
import { BsCircleFill as CircleIcon } from 'react-icons/bs'

import history from '~/helpers/history'
import { Creators as NotificationsActions } from '~/store/ducks/notifications'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function CallsList({ data, onlyUnread, handleClose }) {
  const dispatch = useDispatch()

  const readCalls = (item) => {
    if (item.status === 'unread') {
      dispatch(
        NotificationsActions.handleReadNotificationRequest(item.id, () => {
          dispatch(NotificationsActions.getNotificationsRequest())
        }),
      )
      history.push(`/calls/${item.context.id}`)
    } else {
      history.push(`/calls/${item.context.id}`)
    }
    handleClose(null)
  }

  const classes = useStyles()

  const unreadData = data?.filter((item) => item?.status === 'unread')

  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <div className={classes.tableWrapper}>
          <div className={classes.listWrapper}>
            {onlyUnread
              ? unreadData?.map((item) => (
                  <>
                    <Grid
                      container
                      key={item.id}
                      className={classes.container}
                      onClick={() => readCalls(item.id)}
                    >
                      <Grid item xs={12}>
                        <div className={classes.content}>
                          <Avatar
                            className={classes.avatar}
                            src={item?.sender?.url_image}
                            alt={item?.sender?.name}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginRight: 8 }}
                              >
                                {item?.sender?.name}
                              </Typography>
                              <CircleIcon
                                size={4}
                                color={
                                  item.status === 'unread'
                                    ? '#ED1E79'
                                    : '#B3B3B3'
                                }
                              />
                              <Typography
                                variant="body2"
                                style={{ marginLeft: 8 }}
                              >
                                {moment(
                                  moment(item.created_at).format('YYYY-MM-DD'),
                                ).isSame(
                                  moment(new Date()).format('YYYY-MM-DD'),
                                  'day',
                                )
                                  ? moment(item.created_at).format('HH:mm')
                                  : moment(item.created_at).format('DD/MM')}
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html: item.message,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))
              : data?.map((item) => (
                  <>
                    <Grid
                      container
                      key={item.id}
                      className={classes.container}
                      onClick={() => readCalls(item)}
                    >
                      <Grid item xs={12}>
                        <div className={classes.content}>
                          <Avatar
                            className={classes.avatar}
                            src={item?.sender?.url_image}
                            alt={item?.sender?.name}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginRight: 8 }}
                              >
                                {item?.sender?.name}
                              </Typography>
                              {item.status === 'unread' ? (
                                <CircleIcon size={4} color="#ED1E79" />
                              ) : (
                                ''
                              )}
                              <Typography
                                variant="body2"
                                style={{ marginLeft: 8 }}
                              >
                                {moment(
                                  moment(item.created_at).format('YYYY-MM-DD'),
                                ).isSame(
                                  moment(new Date()).format('YYYY-MM-DD'),
                                  'day',
                                )
                                  ? moment(item.created_at).format('HH:mm')
                                  : moment(item.created_at).format('DD/MM')}
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html: item.message,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))}
          </div>
        </div>
      </div>
    </div>
  )
}

CallsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onlyUnread: PropTypes.PropTypes.bool.isRequired,
  handleClose: PropTypes.PropTypes.func.isRequired,
}
