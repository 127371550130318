export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  gridContainerButton: {
    marginBottom: 24,
  },
  buttonAdd: {
    minWidth: 259,
    minHeight: 40,
  },
  iconAdd: {
    marginRight: 8,
  },
  grow: {
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },
})
