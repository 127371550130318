import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getDashboardBirthdaysRequest: [],
  getDashboardBirthdaysFailure: [],
  getDashboardBirthdaysSuccess: ['data'],
  getQuizzesDashboardRequest: [],
  getQuizzesDashboardFailure: [],
  getQuizzesDashboardSuccess: ['data', 'total'],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  dataDashboard: [],
  birthdays: {
    loading: false,
    data: [],
  },
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
}

const getDashboardBirthdaysRequest = (state = INITIAL_STATE) => ({
  ...state,
  birthdays: {
    ...state.birthdays,
    loading: true,
  },
})

const getDashboardBirthdaysFailure = (state = INITIAL_STATE) => ({
  ...state,
  birthdays: {
    ...state.birthdays,
    loading: false,
  },
})

const getDashboardBirthdaysSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  birthdays: {
    ...state.birthdays,
    loading: false,
    data,
  },
})

const getQuizzesDashboardRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getQuizzesDashboardFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getQuizzesDashboardSuccess = (
  state = INITIAL_STATE,
  { data, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  dataDashboard: data,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_DASHBOARD_BIRTHDAYS_REQUEST]: getDashboardBirthdaysRequest,
  [Types.GET_DASHBOARD_BIRTHDAYS_FAILURE]: getDashboardBirthdaysFailure,
  [Types.GET_DASHBOARD_BIRTHDAYS_SUCCESS]: getDashboardBirthdaysSuccess,
  [Types.GET_QUIZZES_DASHBOARD_REQUEST]: getQuizzesDashboardRequest,
  [Types.GET_QUIZZES_DASHBOARD_FAILURE]: getQuizzesDashboardFailure,
  [Types.GET_QUIZZES_DASHBOARD_SUCCESS]: getQuizzesDashboardSuccess,
})
