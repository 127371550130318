import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'

import Toastr from './components/Toastr'

const flashMessageSelector = ({ flashMessage }) => flashMessage

function FlashMessage() {
  const { open, message, variant } = useSelector(flashMessageSelector)
  const dispatch = useDispatch()
  function handleClose() {
    dispatch(FlashMessageActions.hideMessage())
  }

  return (
    message && (
      <Toastr
        open={open}
        onClose={handleClose}
        message={message}
        variant={variant}
      />
    )
  )
}

export default FlashMessage
