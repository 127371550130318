import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core'

import { useMobileScreen } from '~/helpers/useMediaQuery'

const ListFooter = ({
  className,
  options,
  onChangeRowsPerPage,
  onChangePage,
  rowsPerPageOptions,
  countDown,
  hasCountDown,
}) => {
  const isMobileScreen = useMobileScreen()
  return (
    <Table size="small">
      <TableFooter>
        <TableRow>
          {hasCountDown && (
            <TableCell>Atualizando em {countDown} segundos</TableCell>
          )}
          <TableCell className={className}>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              labelRowsPerPage={!isMobileScreen && 'Linhas por página'}
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} de ${count !== -1 ? count : to}`
              }
              onRowsPerPageChange={onChangeRowsPerPage}
              count={options.total}
              rowsPerPage={options.limit}
              page={options.page}
              onPageChange={onChangePage}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

ListFooter.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  countDown: PropTypes.number,
  hasCountDown: PropTypes.bool,
}

ListFooter.defaultProps = {
  className: '',
  rowsPerPageOptions: [5, 10, 20, 50],
  countDown: null,
  hasCountDown: false,
}

export default ListFooter
