import React, { useState } from 'react'
import moment from 'moment'
import { useEffectOnce, useUnmount } from 'react-use'
import {
  makeStyles,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'

import SearchInput from '~/components/SearchInput'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'
import EmptyList from '~/components/EmptyList'

import OverlayLoading from '~/components/OverlayLoading'

import history from '~/helpers/history'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

import CommunicationBoardListHeader from './components/CommunicationBoardListHeader'

import styles from './styles'

const useStyles = makeStyles(styles)

const newsBoardSelector = ({ newsBoard }) => newsBoard

export default function NewsBoardPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSmallScreen = useSmallScreen()
  const { data, loading, options } = useSelector(newsBoardSelector)
  const [newsId, setNewsId] = useState(null)
  const [communicationIdMail, setCommunicationIdMail] = useState(null)
  const [openConfirmDialogCommunication, setOpenConfirmDialogCommunication] =
    useState(false)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffectOnce(() => {
    dispatch(NewsBoardActions.getNewsBoardRequest('communication', false))
  })

  useUnmount(() => {
    dispatch(NewsBoardActions.resetNews())
  })

  const handleSearchChange = (value) => {
    dispatch(NewsBoardActions.setNewsBoardSearch(value))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(NewsBoardActions.setNewsBoardCommunicationPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(
      NewsBoardActions.setNewsBoardCommunicationPage(page, options.limit),
    )
  }

  const handleRemoveCancel = () => {
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      NewsBoardActions.removeNewsRequest(newsId, 'communication', () => {
        setNewsId(null)
        setOpenConfirmDialog(false)
        dispatch(NewsBoardActions.getNewsBoardRequest('communication', false))
      }),
    )
  }
  const handleDeleteNewBoard = (id) => {
    setNewsId(id)
    setOpenConfirmDialog(true)
  }

  const handleSwitchChange = (item) => {
    const formattedData = {
      ...item,
      visible: Number(!item.visible),

      dealerships_id: item.dealerships,
    }

    dispatch(
      NewsBoardActions.updateNewsRequest(item.id, formattedData, () => {
        dispatch(NewsBoardActions.getNewsBoardRequest('communication', false))
      }),
    )
  }

  const handleSendMail = (id) => {
    setOpenConfirmDialogCommunication(true)
    setCommunicationIdMail(id)
  }
  const handleCancelMail = () => {
    setOpenConfirmDialogCommunication(false)
    setCommunicationIdMail(null)
  }

  const handleAcceptMail = () => {
    dispatch(NewsBoardActions.sendMailNewsRequest(communicationIdMail))
    setOpenConfirmDialogCommunication(false)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.gridContainerButton}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <SearchInput
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise pela descrição..."
              />
            </Grid>
            <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAdd}
                onClick={() => history.push('/communication-board/new')}
              >
                <AddIcon className={classes.iconAdd} /> Adicionar Comunicado
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há comunicados para serem visualizados" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              <Table size="small">
                <CommunicationBoardListHeader />
                <TableBody>
                  {data.length > 0 &&
                    data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell align="center">
                          <div className={classes.avatar}>
                            <Avatar
                              alt={item?.author?.name}
                              src={item?.author?.url_image}
                            />
                          </div>
                        </TableCell>
                        <TableCell>{item?.author?.name}</TableCell>
                        <TableCell>{item?.title}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.descriptionCell}
                        >
                          {item.text}
                        </TableCell>
                        <TableCell align="center">
                          {moment(item.created_at).format('L')}
                          {' - '}
                          {moment(item.created_at).format('HH:mm')}
                        </TableCell>
                        <TableCell className={classes.actionsCell}>
                          <div className={classes.actionsWrapper}>
                            <IconButton
                              className={classes.editIcon}
                              onClick={() =>
                                history.push(`/communication-board/${item.id}`)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              className={classes.icon}
                              onClick={() => handleDeleteNewBoard(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Tooltip
                              placement="top"
                              title={`${
                                item.visible ? 'Vísivel' : 'Não vísivel'
                              }`}
                            >
                              <Switch
                                size="small"
                                checked={item.visible}
                                color="primary"
                                onChange={() => handleSwitchChange(item)}
                              />
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              title="Enviar comunicado por e-mail"
                            >
                              <IconButton
                                className={classes.icon}
                                onClick={() => handleSendMail(item.id)}
                              >
                                {' '}
                                <MailIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este comunicado?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
      <ConfirmDialog
        open={openConfirmDialogCommunication}
        title="Atenção"
        description="Deseja realmente enviar este comunicado?"
        variant="warning"
        onCancel={handleCancelMail}
        onAccept={handleAcceptMail}
      />
    </div>
  )
}
