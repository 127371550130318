export default (theme) => ({
  paper: {
    minWidth: '60%',
    minHeigth: '80%',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },
  image: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  buttonClose: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
})
