import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as FunctionsTypes,
  Creators as FunctionsActions,
} from '../ducks/functions'

const optionsSelector = ({ functions }) => functions.options

function* getFunctions() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'functions', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        search: options.search ? `name,${options.search}` : null,
      },
    })

    yield put(FunctionsActions.getFunctionsSuccess(data.data, data.total))
  } catch (e) {
    yield put(FunctionsActions.getFunctionsFailure())
  }
}

function* reloadFunctions() {
  yield put(FunctionsActions.getFunctionsRequest())
}

export default function* () {
  yield takeLatest(FunctionsTypes.GET_FUNCTIONS_REQUEST, getFunctions)
  yield takeLatest(FunctionsTypes.SET_FUNCTIONS_SORT, reloadFunctions)
  yield takeLatest(FunctionsTypes.SET_FUNCTIONS_PAGE, reloadFunctions)
  yield debounce(1000, FunctionsTypes.SET_FUNCTIONS_SEARCH, reloadFunctions)
}
