import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'

import configureApi from '~/helpers/api'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

import {
  Types as DailyEmailsTypes,
  Creators as DailyEmailsActions,
} from '../ducks/dailyEmails'

const optionsSelector = ({ dailyEmails }) => dailyEmails.options

function* getUsersDailyEmails() {
  const options = yield select(optionsSelector)

  try {
    const api = configureApi()
    const formattedParams = {
      pagination: 1,
      page: options.page + 1,
      limit: options.limit,
      order: `${options.sort},${options.direction}`,
    }
    const { data } = yield call(api.get, 'users', {
      params: formattedParams,
    })
    yield put(
      DailyEmailsActions.getUsersDailyEmailsSuccess(data.data, data.total),
    )
  } catch (e) {
    yield put(DailyEmailsActions.getUsersDailyEmailsFailure())
  }
}

function* getTypesEmail() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `email-types`)

    yield put(DailyEmailsActions.getTypesEmailSuccess(data.data))
  } catch (e) {
    yield put(DailyEmailsActions.getTypesEmailFailure())
  }
}

function* getTypeEmail({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `email-types/${id}`)

    yield put(DailyEmailsActions.getTypeEmailSuccess(data))
  } catch (e) {
    yield put(DailyEmailsActions.getTypeEmailFailure())
  }
}

// function* addUsersTypeEmail({ data }) {
//   const ids = data.users_id.map((item) => item.id)

//   const formattedData = {
//     ...data,
//     users_id: ids,
//   }

//   try {
//     const api = configureApi()
//     yield call(api.post, 'email-recipients', formattedData)

//     yield put(
//       FlashMessageActions.showMessage({
//         message: 'Usuários adicionados com sucesso!',
//         variant: 'success',
//       }),
//     )
//     yield put(DailyEmailsActions.addUsersTypesEmailFinish())
//   } catch (e) {
//     yield put(DailyEmailsActions.addUsersTypesEmailFinish())
//     const error = get(e, 'response.data.error[0]')
//     yield put(
//       FlashMessageActions.showMessage({
//         message: error || 'Ops! Algo deu errado. Tente novamente',
//         variant: 'error',
//       }),
//     )
//   }
// }

function* updateUsersTypesEmail({ id, data, callback }) {
  const ids = data.users_id.map((item) => item.id)

  const formattedData = {
    ...data,
    users_id: ids,
  }

  try {
    const api = configureApi()
    yield call(api.put, `email-recipients/${id}`, formattedData)
    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Usuários editados com sucesso!',
        variant: 'success',
      }),
    )
    yield put(DailyEmailsActions.updateUsersTypesEmailFinish())
  } catch (e) {
    yield put(DailyEmailsActions.updateUsersTypesEmailFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(
    DailyEmailsTypes.GET_USERS_DAILY_EMAILS_REQUEST,
    getUsersDailyEmails,
  )

  yield takeLatest(DailyEmailsTypes.GET_TYPES_EMAIL_REQUEST, getTypesEmail)
  yield takeLatest(DailyEmailsTypes.GET_TYPE_EMAIL_REQUEST, getTypeEmail)
  // yield takeLatest(
  //   DailyEmailsTypes.ADD_USERS_TYPES_EMAIL_REQUEST,
  //   addUsersTypeEmail,
  // )
  yield takeLatest(
    DailyEmailsTypes.UPDATE_USERS_TYPES_EMAIL_REQUEST,
    updateUsersTypesEmail,
  )
}
