import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Button,
  Tooltip,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons'
// import { Autocomplete } from '@material-ui/lab'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Creators as UserActions } from '~/store/ducks/user'

import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import RhfAutocomplete from '~/components/FormComponents/RhfAutocomplete'

import styles from './styles'

const useStyles = makeStyles(styles)

const userDealershipSelector = ({ user }) => user.dealerships
const userFunctionSelector = ({ user }) => user.function
const userFunctionsListSelector = ({ user }) => user.functionsList

export default function ContractsFormTab({ id, mode }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isView = !!id && mode === 'view'

  const { data: userDealerships } = useSelector(userDealershipSelector)
  const formattedUserDealerships = userDealerships.map((user) => ({
    label: user.name,
    value: user.id,
  }))
  const userFunctions = useSelector(userFunctionSelector)
  const userFunctionsList = useSelector(userFunctionsListSelector)
  const [userFunctionId, setUserFunctionId] = useState(null)
  const { data: dataFunctionList, loading } = userFunctionsList
  const [announceId, setAnnounceId] = useState(null)
  const [removeId, setRemoveId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openConfirmDialogRemove, setOpenConfirmDialogRemove] = useState(false)

  const initialValues = {
    effective_date: null,
    end_date: null,
    dealership: '',
    department: '',
    function: '',
    type_bond: '',
    away: '',
    current: false,
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: initialValues,
  })

  const dealershipSelected = watch('dealership')
  const departments = dealershipSelected
    ? userDealerships
        ?.find((dealership) => dealership.id === dealershipSelected)
        ?.departments?.sort((a, b) => a.name.localeCompare(b.name))
    : ''

  const reloadUserFunctionsList = () =>
    dispatch(UserActions.getUserFunctionsListRequest(id))

  const handleClearContractForm = () => {
    reset({
      effective_date: null,
      end_date: null,
      dealership: '',
      department: '',
      function: '',
      type_bond: '',
      away: '',
      current: false,
    })
  }

  const handleSubmitUserContractForm = (data) => {
    const isEditUserFunction = !!userFunctionId
    let formattedData = {
      ...data,
      away: data.away || 0,
      dealership_id: data.dealership,
      department_id: data.department,
      function_id: data.function,
    }

    if (isEditUserFunction) {
      formattedData = {
        ...formattedData,
        effective_date: data.effective_date
          ? moment(data.effective_date).format('YYYY-MM-DD')
          : null,
        end_date: data.end_date
          ? moment(data.end_date).format('YYYY-MM-DD')
          : null,
      }
      dispatch(
        UserActions.updateUserFunctionRequest(
          userFunctionId,
          formattedData,
          () => {
            reloadUserFunctionsList()
            handleClearContractForm()
            setUserFunctionId(null)
          },
        ),
      )
    } else {
      formattedData = {
        ...formattedData,
        user_id: id,
        effective_date: data.effective_date
          ? moment(data.effective_date).format('YYYY-MM-DD')
          : null,
        end_date: data.end_date
          ? moment(data.end_date).format('YYYY-MM-DD')
          : null,
      }
      dispatch(
        UserActions.addUserFunctionRequest(formattedData, () => {
          reloadUserFunctionsList()
          handleClearContractForm()
        }),
      )
    }
  }

  const handleEditUserFunction = (userFunction) => {
    setUserFunctionId(userFunction.id)
    const formattedUserFunction = {
      ...userFunction,
      dealership: userFunction?.dealership_id,
      department: userFunction?.department?.id,
      function: userFunction.function_id,
      end_date: userFunction.end_date
        ? moment(userFunction.end_date).format()
        : '',
      effective_date: userFunction.effective_date
        ? moment(userFunction.effective_date).format()
        : '',
    }
    reset(formattedUserFunction)
  }

  const removal = [
    { value: 0, name: 'Nenhum' },
    { value: 1, name: 'Doença ou acidente' },
    { value: 2, name: 'Licença Maternidade' },
  ]

  const bonds = [
    { value: 1, name: 'CLT' },
    { value: 2, name: 'Terceiro' },
    { value: 3, name: 'Estágio' },
  ]

  const announceNewFunction = (idAnnounce) => {
    setAnnounceId(idAnnounce)
    setOpenConfirmDialog(true)
  }

  const removeUserFunction = (idRemove) => {
    setRemoveId(idRemove)
    setOpenConfirmDialogRemove(true)
  }

  const handleRemoveCancel = () => {
    setRemoveId(null)
    setOpenConfirmDialogRemove(false)
  }

  const handleAnnounceCancel = () => {
    setAnnounceId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      UserActions.removeUserFunctionRequest(removeId, () => {
        dispatch(UserActions.getUserFunctionsListRequest(id))
        setRemoveId(null)
        setOpenConfirmDialogRemove(false)
      }),
    )
  }
  const handleAnnounceAccept = () => {
    dispatch(
      UserActions.announceNewFunctionRequest(announceId, () => {
        setAnnounceId(null)
        setOpenConfirmDialog(false)
      }),
    )
  }

  return (
    <div className={classes.root}>
      <form
        onSubmit={handleSubmit(handleSubmitUserContractForm)}
        id="form-user-contract"
      >
        <Grid container spacing={2} className={classes.firstContainer}>
          <Grid item xs={12} md={6}>
            <Controller
              name="effective_date"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref, ...rest } }) => (
                <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                  <KeyboardDatePicker
                    inputVariant="outlined"
                    fullWidth
                    inputRef={ref}
                    value={value || null}
                    disabled={isView}
                    label="Data de efetivação*"
                    format="DD/MM/YYYY"
                    invalidDateMessage="Data inválida"
                    onChange={(dateValue) => onChange(dateValue)}
                    {...rest}
                    error={!!errors.effective_date}
                    helperText={!!errors.effective_date && 'Campo obrigatório'}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="end_date"
              control={control}
              // rules={{ required: true }}
              defaultValue={null}
              render={({ field: { onChange, value, ref, ...rest } }) => (
                <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                  <KeyboardDatePicker
                    inputVariant="outlined"
                    fullWidth
                    inputRef={ref}
                    value={value || null}
                    disabled={isView}
                    label="Data de encerramento"
                    format="DD/MM/YYYY"
                    invalidDateMessage="Data inválida"
                    onChange={(dateValue) => onChange(dateValue)}
                    {...rest}
                    error={!!errors.end_date}
                    helperText={!!errors.end_date && 'Campo obrigatório'}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RhfAutocomplete
              control={control}
              name="dealership"
              defaultValue=""
              disabled={isView}
              label="Concessionária"
              options={formattedUserDealerships}
              placeholder="Selecione a Concessionária"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RhfAutocomplete
              name="department"
              control={control}
              disabled={isView}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              rules={{ required: true }}
              options={
                departments.length > 0
                  ? departments?.map((option) => ({
                      label: option.name,
                      value: option.id,
                    }))
                  : []
              }
              label="Departamento*"
              placeholder="Selecione o departamento do colaborador"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RhfAutocomplete
              control={control}
              disabled={isView}
              fullWidth
              rules={{ required: true }}
              InputLabelProps={{
                shrink: true,
              }}
              options={
                userFunctions.data
                  ? userFunctions.data?.map((option) => ({
                      label: option.name,
                      value: option.id,
                    }))
                  : []
              }
              name="function"
              label="Função*"
              placeholder="Selecione a função"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="type_bond"
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  select
                  label="Vínculo*"
                  disabled={isView}
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value || ''}
                  {...rest}
                  error={!!errors.type_bond}
                  helperText={!!errors.type_bond && 'Campo obrigatório'}
                >
                  {bonds?.map((bond) => (
                    <MenuItem value={bond.value} key={bond.value}>
                      {bond.name}
                    </MenuItem>
                  ))}
                  {!bonds?.length && (
                    <MenuItem disabled value="">
                      Sem opções
                    </MenuItem>
                  )}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="away"
              defaultValue=""
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  select
                  label="Afastamento"
                  disabled={isView}
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value || ''}
                  {...rest}
                  error={!!errors.away}
                  helperText={!!errors.away && 'Campo obrigatório'}
                >
                  {removal.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel component="legend">Função atual</FormLabel>
            <FormControlLabel
              label="Sim"
              disabled={isView}
              control={
                <Controller
                  name="current"
                  disabled={isView}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Checkbox
                      checked={value}
                      disabled={isView}
                      onChange={(e) => onChange(e.target.checked)}
                      {...rest}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          className={classes.buttonsWrapper}
        >
          <Grid item>
            <Button
              disabled={isView}
              variant="contained"
              onClick={() => handleClearContractForm()}
            >
              Limpar
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isView}
              color="primary"
              variant="contained"
              type="submit"
              form="form-user-contract"
            >
              Salvar contrato
            </Button>
          </Grid>
        </Grid>
        <div className={classes.detailsWrapper}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="caption">Funções do colaborador</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading && <OverlayLoading />}
              <div className={classes.tableWrapper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dt. efetivação</TableCell>
                      <TableCell>Dt. encerramento</TableCell>
                      <TableCell>Função</TableCell>
                      <TableCell>Concessionária</TableCell>
                      <TableCell>Departamento</TableCell>
                      <TableCell>Função atual</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataFunctionList.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell>
                          {item.effective_date
                            ? moment(item.effective_date).format('DD/MM/YYYY')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {item.end_date
                            ? moment(item.end_date).format('DD/MM/YYYY')
                            : '-'}
                        </TableCell>
                        <TableCell>{item.function?.name}</TableCell>
                        <TableCell>{item.dealership?.name}</TableCell>
                        <TableCell>{item.department?.name}</TableCell>
                        <TableCell>{item.current ? 'Sim' : 'Não'}</TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableCellActions}
                        >
                          <div>
                            <IconButton
                              disabled={isView}
                              className={classes.editIcon}
                              onClick={() => handleEditUserFunction(item)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              disabled={isView}
                              className={classes.deleteIcon}
                              onClick={() => removeUserFunction(item.id)}
                            >
                              <DeleteIcon className={classes.deleteIcon} />
                            </IconButton>
                            {item.current && (
                              <Tooltip
                                title="Anunciar nova função"
                                placement="top"
                              >
                                <IconButton
                                  disabled={isView}
                                  className={classes.iconButton}
                                  onClick={() => announceNewFunction(item.id)}
                                >
                                  <MailOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </form>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente anunciar a nova função?"
        onCancel={handleAnnounceCancel}
        onAccept={handleAnnounceAccept}
      />
      <ConfirmDialog
        open={openConfirmDialogRemove}
        title="Atenção"
        description="Deseja realmente excluir esta função?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </div>
  )
}

ContractsFormTab.propTypes = {
  id: PropTypes.string,
  mode: PropTypes.string,
}

ContractsFormTab.defaultProps = {
  id: null,
  mode: null,
}
