import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSuperiorsRequest: [],
  getSuperiorsFailure: [],
  getSuperiorsSuccess: ['data', 'total'],
  setSuperiorsSort: ['sort', 'direction'],
  setSuperiorsPage: ['page', 'limit'],
  setSuperiorsSearch: ['search'],
  getFunctionsSuperiorsRequest: [],
  getFunctionsSuperiorsSuccess: ['data'],
  getFunctionsSuperiorsFailure: [],
  setSuperiorsFunctionFilter: ['functionId'],
  resetSuperiorsList: [],
  updateSuperiorsRequest: ['id', 'data', 'callback'],
  updateSuperiorsFinish: [],
  setChangeShowHideColumnsSuperiors: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
    search: '',
    functionId: null,
  },
  functions: {
    data: [],
    loading: false,
  },
  data: [],
  loading: false,
  showHideColumns: {
    name: true,
    function: true,
    department: true,
    parent: true,
  },
}

const resetSuperiorsList = () => ({
  ...INITIAL_STATE,
})

const getFunctionsSuperiorsRequest = (state = INITIAL_STATE) => ({
  ...state,
  functions: {
    ...state.functions,
    loading: true,
  },
})
const getFunctionsSuperiorsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  functions: {
    ...state.functions,
    data,
    loading: false,
  },
})

const getFunctionsSuperiorsFailure = (state = INITIAL_STATE) => ({
  ...state,
  functions: {
    ...state.functions,
    loading: false,
  },
})

const getSuperiorsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSuperiorsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getSuperiorsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setSuperiorsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setSuperiorsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setSuperiorsSearch = (state = INITIAL_STATE, { search }) => ({
  ...state,
  options: {
    ...state.options,
    search,
    page: 0,
  },
})

const setSuperiorsFunctionFilter = (state = INITIAL_STATE, { functionId }) => ({
  ...state,
  options: {
    ...state.options,
    functionId,
  },
})

const updateSuperiorsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateSuperiorsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setChangeShowHideColumnsSuperiors = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableSuperiors',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUPERIORS_REQUEST]: getSuperiorsRequest,
  [Types.GET_SUPERIORS_FAILURE]: getSuperiorsFailure,
  [Types.GET_SUPERIORS_SUCCESS]: getSuperiorsSuccess,
  [Types.SET_SUPERIORS_SORT]: setSuperiorsSort,
  [Types.SET_SUPERIORS_PAGE]: setSuperiorsPage,
  [Types.SET_SUPERIORS_SEARCH]: setSuperiorsSearch,
  [Types.GET_FUNCTIONS_SUPERIORS_REQUEST]: getFunctionsSuperiorsRequest,
  [Types.GET_FUNCTIONS_SUPERIORS_SUCCESS]: getFunctionsSuperiorsSuccess,
  [Types.GET_FUNCTIONS_SUPERIORS_FAILURE]: getFunctionsSuperiorsFailure,
  [Types.SET_SUPERIORS_FUNCTION_FILTER]: setSuperiorsFunctionFilter,
  [Types.RESET_SUPERIORS_LIST]: resetSuperiorsList,
  [Types.UPDATE_SUPERIORS_REQUEST]: updateSuperiorsRequest,
  [Types.UPDATE_SUPERIORS_FINISH]: updateSuperiorsFinish,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_SUPERIORS]:
    setChangeShowHideColumnsSuperiors,
})
