import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { DateRangePicker } from 'react-dates'
import {
  DateRange as DateIcon,
  ArrowRightAlt as ArrowIcon,
} from '@material-ui/icons'
import datePickerStyles from '~/assets/styles/DatePickerStyles'
import styles from './styles'

const CustomDateRangePicker = ({ classes, onDatesChange, ...rest }) => {
  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <div className={[classes.dateRangeWrapper, classes.rangeDate].join(' ')}>
      <DateRangePicker
        regular
        focusedInput={focusedInput}
        onFocusChange={(fI) => setFocusedInput(fI)}
        customArrowIcon={<ArrowIcon className={classes.arrowIcon} />}
        customInputIcon={<DateIcon className={classes.inputIcon} />}
        minimumNights={0}
        onDatesChange={(dates) => {
          const { startDate, endDate } = dates

          if (moment(startDate).isValid() && moment(endDate).isValid()) {
            onDatesChange(dates)
          }
        }}
        {...rest}
      />
    </div>
  )
}

CustomDateRangePicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onDatesChange: PropTypes.func,
}

CustomDateRangePicker.defaultProps = {
  onDatesChange: () => {},
}

export default withStyles((theme) => ({
  ...styles(theme),
  ...datePickerStyles(theme),
}))(CustomDateRangePicker)
