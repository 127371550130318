import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as EquipmentDashboardTypes,
  Creators as EquipmentDashboardActions,
} from '../ducks/equipmentDashboard'

const filterSelector = ({ equipmentDashboard }) => equipmentDashboard.filters

const api = configureApi()

function* getEquipmentDashboardFilters() {
  try {
    const { groups, departments } = yield all({
      groups: call(api.get, 'groups', {
        params: {
          pagination: 0,
          order: 'sort,asc',
        },
      }),
      departments: call(api.get, 'departments'),
    })

    const groupsData = groups.data.map((a) => ({
      name: a.name,
      value: a.id,
    }))

    const departmentsData = departments.data?.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      EquipmentDashboardActions.getEquipmentDashboardFiltersSuccess(
        groupsData,
        departmentsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(EquipmentDashboardActions.getEquipmentDashboardFiltersFailure())
  }
}

function* getEquipmentDashboardData() {
  try {
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }
    const { data } = yield call(api.get, '/get_dealership_equipments', {
      params: {
        group_id: filters.group,
        department_id: filters.department,
        state_id: filters.state,
      },
    })
    yield put(
      EquipmentDashboardActions.getEquipmentDashboardDataSuccess(
        data.sort((a, b) => b.total - a.total),
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(EquipmentDashboardActions.getEquipmentDashboardDataFailure())
  }
}

function* getEquipmentDashboardDealership({ id }) {
  try {
    const { data } = yield call(api.get, '/get_equipments', {
      params: {
        dealership_id: id,
      },
    })
    yield put(
      EquipmentDashboardActions.getEquipmentDashboardDealershipSuccess(data),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(
      EquipmentDashboardActions.getEquipmentDashboardDealershipFailure(),
    )
  }
}

function* reloadEquipmentDashboardData() {
  yield put(EquipmentDashboardActions.getEquipmentDashboardDataRequest())
}

export default function* () {
  yield takeLatest(
    EquipmentDashboardTypes.GET_EQUIPMENT_DASHBOARD_DATA_REQUEST,
    getEquipmentDashboardData,
  )
  yield debounce(
    400,
    EquipmentDashboardTypes.SET_EQUIPMENT_DASHBOARD_FILTER,
    reloadEquipmentDashboardData,
  )
  yield takeLatest(
    EquipmentDashboardTypes.GET_EQUIPMENT_DASHBOARD_FILTERS_REQUEST,
    getEquipmentDashboardFilters,
  )
  yield takeLatest(
    EquipmentDashboardTypes.GET_EQUIPMENT_DASHBOARD_DEALERSHIP_REQUEST,
    getEquipmentDashboardDealership,
  )
}
