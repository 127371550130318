export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 0,
  },
  tableWrapper: {
    overflowY: 'auto',
    height: '100%',
    position: 'relative',
    minHeight: 400,
    width: '100%',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  grow: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
  avatar: {
    marginRight: 8,
  },
  container: {
    cursor: 'pointer',
  },
})
