import React from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useEffectOnce, useUnmount } from 'react-use'
import { Creators as BilledActions } from '~/store/ducks/billed'

// import PropTypes from 'prop-types'

const billedSelector = ({ billed }) => billed

function DashboardHelpdeskFilters() {
  const { filtersData, filters } = useSelector(billedSelector)
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(BilledActions.getBilledFiltersRequest())
    dispatch(BilledActions.getBilledDataRequest())
  })

  useUnmount(() => {
    dispatch(BilledActions.resetBilled())
  })

  function handleChangeFilter(property, value) {
    dispatch(BilledActions.setBilledFilter(property, value))
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            fullWidth
            views={['month', 'year']}
            label="Mês/Ano"
            invalidDateMessage="Data inválida"
            format="MM/yyyy"
            value={filters.date}
            onChange={(dValue) => handleChangeFilter('date', dValue)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Segmento"
          fullWidth
          select
          value={filters.type}
          onChange={(ev) => handleChangeFilter('type', ev.target.value)}
        >
          {filtersData.types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Grupo"
          fullWidth
          select
          value={filters.group}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {filtersData.groups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

DashboardHelpdeskFilters.propTypes = {}

export default DashboardHelpdeskFilters
