import React from 'react'
import { Grid, makeStyles, TextField, MenuItem } from '@material-ui/core'
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'

import 'moment/locale/pt-br'
import { Creators as AnalitycalPartsActions } from '~/store/ducks/analitycalParts'
import Autocomplete from '~/components/Autocomplete'
import RangeTimeSelect from '~/components/RangeTimeSelect'
import DateRangePicker from '~/components/DateRangePicker'
import styles from './styles'

const filtersSelector = ({ analitycalParts }) => analitycalParts.filtersData

const useStyles = makeStyles(styles)
const analitycalPartsSelector = ({ analitycalParts }) => analitycalParts

function AnalitycalPartsFilters() {
  const { filters } = useSelector(analitycalPartsSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const { dealerships: dataDealerships, groups: dataGroups } =
    useSelector(filtersSelector)

  const handleDatesChange = (dates) => {
    const { startDate, endDate } = dates
    dispatch(AnalitycalPartsActions.setAnalitycalPartsDate(startDate, endDate))
  }

  const handleTimerChange = (value) => {
    const from = moment().subtract(value, 'd')
    const to = moment()
    dispatch(AnalitycalPartsActions.setAnalitycalPartsDate(from, to))
  }

  const handleChangeFilter = (property, value) => {
    dispatch(
      AnalitycalPartsActions.setAnalitycalPartsChangeFilter(property, value),
    )
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.gridContainerButton}
      spacing={2}
    >
      <Grid item xs={12} sm={6} md>
        <DateRangePicker
          startDate={moment(filters?.startDate)}
          startDateId="analitycal-parts-start"
          endDate={moment(filters?.endDate)}
          isOutsideRange={() => false}
          endDateId="analitycal-parts-end"
          onDatesChange={handleDatesChange}
        />
        <RangeTimeSelect onSelect={handleTimerChange} />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Grupo"
          fullWidth
          size="small"
          select
          value={filters.groups}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {dataGroups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
          onChange={(_, option) =>
            handleChangeFilter('dealership', option ? option.value : null)
          }
          size="small"
          options={dataDealerships
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((option) => ({
              label: option.name,
              value: option.value,
            }))}
          name="dealership"
          value={filters.dealership}
          className={classes.autocomplete}
          label="Filtrar por concessionária"
          placeholder="Selecione uma concessionária"
          // loading={loadingDealerships}
          autoSelect={false}
        />
      </Grid>
    </Grid>
  )
}

export default AnalitycalPartsFilters
