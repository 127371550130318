export default (theme) => ({
  image: {
    height: 'auto',
    minHeight: 400,
    width: '100%',
    objectFit: 'contain',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      minHeight: 240,
    },
  },
  reactionIcon: {
    position: 'relative',
  },
  titleWrapper: {
    marginBottom: theme.spacing(2),
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  menuList: {
    padding: 0,
    '& .MuiList-padding': {
      padding: 0,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  commentButton: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  postWrapper: {
    whiteSpace: 'pre-wrap',
  },
  reactionsButtonWrapper: {
    padding: theme.spacing(1, 0),
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  reactionsCounter: {
    display: 'flex',
    flexDiretion: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    borderRadius: '50px',
    width: 'fit-content',
    padding: '4px 8px',
    '&:hover': {
      transform: 'scale(1.3)',
      transition: 'transform 0.2s ease-in-out',
    },
  },
  reactionCounterItem: {
    marginRight: -8,
    fontSize: theme.spacing(2),
  },
  tooltipPopper: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentArea: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: 4,
    minHeight: 276,
    width: '99.5%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  commentedArea: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: 4,
    heigh: 'auto',
    width: '99.5%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
  },
  reactionsTotalText: {
    marginLeft: 12,
  },
  commentsWrapper: {
    paddingLeft: theme.spacing(2),
  },
  showCommentsButton: {
    paddingRight: theme.spacing(2),

    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  videoWrapper: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,

    '& iframe': {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
  },
})
