import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as SalesGoalsActions } from '~/store/ducks/salesGoals'
import { useMobileScreen, useSmallScreen } from '~/helpers/useMediaQuery'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'
import CustomIcon from '~/components/CustomIcon'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'

import NewSalesGoalDialog from './components/NewSalesGoalDialog'
import styles from './styles'

const salesGoalsSelector = ({ salesGoals }) => salesGoals

const useStyles = makeStyles(styles)

function SalesGoalsPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [modeSalesGoalsDialog, setModeSalesGoalsDialog] = useState(null)

  const [openConfirmDialog, setConfirmDialog] = useState(false)
  const [salesGoalDate, setSalesGoalDate] = useState(null)
  const [salesGoalId, setSalesGoalId] = useState(null)
  const { data, options, loading } = useSelector(salesGoalsSelector)
  const isMobileScreen = useMobileScreen()
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(SalesGoalsActions.getSalesGoalsRequest())
  }, [])

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(SalesGoalsActions.setSalesGoalsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(SalesGoalsActions.setSalesGoalsPage(page, options.limit))
  }

  const handleAddSalesGoal = () => {
    setModeSalesGoalsDialog('new')
  }

  const handleCloseNewsSalesGolsDialog = () => {
    setModeSalesGoalsDialog(null)
    setSalesGoalId(null)
  }

  const handleRemoveSalesGoals = (id) => {
    setSalesGoalId(id)
    setConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setSalesGoalId(null)
    setConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      SalesGoalsActions.removeSalesGoalsRequest(salesGoalId, () => {
        dispatch(SalesGoalsActions.getSalesGoalsRequest())
        setSalesGoalId(null)
        setConfirmDialog(false)
      }),
    )
  }

  const handleEditSalesGoals = (sGoals) => {
    setSalesGoalDate({
      month: sGoals.month,
      year: sGoals.year,
      working_days: sGoals.working_days,
    })
    setSalesGoalId(sGoals.id)
    setModeSalesGoalsDialog('edit')
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.gridContainerButton}
        >
          <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleAddSalesGoal}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Planejamento
            </Button>
          </Grid>
        </Grid>
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {!loading && data.length === 0 && (
              <EmptyList message="Não há dados para serem visualizados" />
            )}
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ano</TableCell>
                        <TableCell>Mês</TableCell>
                        <TableCell>Dias úteis</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                        >
                          <TableCell>{item.year || '-'}</TableCell>
                          <TableCell>{item.month || '-'}</TableCell>
                          <TableCell>{item.working_days || '-'}</TableCell>
                          <TableCell align="right">
                            <div className={classes.tableCellActions}>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleEditSalesGoals(item)}
                              >
                                <CustomIcon pathComponent={mdiPencil} />
                              </IconButton>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => handleRemoveSalesGoals(item.id)}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>
      <NewSalesGoalDialog
        fullScreen={isMobileScreen}
        id={salesGoalId}
        onClose={handleCloseNewsSalesGolsDialog}
        open={modeSalesGoalsDialog !== null}
        mode={modeSalesGoalsDialog}
        salesGoalDate={salesGoalDate}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este planejamento?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}

export default SalesGoalsPage
