export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    backgroundColor: '#ffff',
    height: '100%',
    position: 'relative',
    overflowX: 'auto',
    flex: 1,
  },
  tabsWrapper: {
    width: '99%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  actionsWrapper: {
    padding: theme.spacing(2),
    // backgroundColor: '#ffff',
    // borderTop: '1px solid #E4E7EB',
    position: 'aboslute',
    bottom: 0,
  },
  grow: {
    flex: 1,
  },

  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },

  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  gridContainerButton: {
    marginBottom: 24,
  },
  iconAdd: {
    marginRight: 8,
  },
  buttonAdd: {
    minWidth: 259,
    minHeight: 40,
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },

  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  detailsWrapper: {
    width: '100%',
    padding: theme.spacing(1),
  },
  div: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buttonForm: {
    marginTop: theme.spacing(2),
  },
  uploadIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  upload: {
    height: 56,
    padding: 10,
    borderRadius: 0,
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    '&:last-child': {
      padding: 0,
    },
  },
  uploadDetail: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  fileSize: {
    fontSize: 10,
  },
  cancelUploadIcon: {
    width: 20,
    height: 20,
    color: '#adadad',
  },
  buttonDonwload: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
})
