import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getDealershipsRequest: [],
  getDealershipsFailure: [],
  getDealershipsSuccess: ['data'],
  setDealershipsSort: ['sort', 'direction'],
  setChangeShowHideColumnsDealerships: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
  },
  data: [],
  loading: false,
  showHideColumns: {
    name: true,
    address: true,
    city: true,
    automaker: true,
    group: true,
  },
}

const getDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const setDealershipsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setChangeShowHideColumnsDealerships = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableDealerships',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_DEALERSHIPS_REQUEST]: getDealershipsRequest,
  [Types.GET_DEALERSHIPS_FAILURE]: getDealershipsFailure,
  [Types.GET_DEALERSHIPS_SUCCESS]: getDealershipsSuccess,
  [Types.SET_DEALERSHIPS_SORT]: setDealershipsSort,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_DEALERSHIPS]:
    setChangeShowHideColumnsDealerships,
})
