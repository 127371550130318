import { groupBy } from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import { formatPrice } from '~/helpers/masks'
import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getBiSellersDashboardPageRequest: [],
  getBiSellersDashboardPageFailure: [],
  getBiSellersDashboardPageSuccess: ['data'],
  setBiSellersDashboardPageDate: ['value'],
  getBiSellersDashboardPageSellerRequest: ['id', 'codNbs'],
  getBiSellersDashboardPageSellerSuccess: ['data'],
  getBiSellersDashboardPageSellerFailure: [],
  setChangeShowHideColumnsBiSellersDashboard: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    page: 0,
    total: 0,
    limit: 20,
    dateValue: new Date(),
  },
  data: [],
  loading: false,
  sellers: {
    data: [],
    loading: false,
  },
  showHideColumns: {
    seller: true,
    sale: true,
    devolution: true,
    dailyGoal: true,
    goal: true,
    total: true,
  },
}

const getBiSellersDashboardPageRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getBiSellersDashboardPageFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getBiSellersDashboardPageSellerRequest = (state = INITIAL_STATE) => ({
  ...state,
  sellers: {
    ...state.sellers,
    loading: true,
  },
})

const getBiSellersDashboardPageSellerFailure = (state = INITIAL_STATE) => ({
  ...state,
  sellers: {
    ...state.sellers,
    loading: false,
  },
})

const getBiSellersDashboardPageSellerSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  sellers: {
    data,
    loading: false,
  },
})

const getBiSellersDashboardPageSuccess = (
  state = INITIAL_STATE,
  { data, total },
) => {
  const totalsGroupped = groupBy(data.totals, 'group_name')
  const formattedTotals =
    data.totals.length > 0
      ? Object.keys(totalsGroupped)?.map((key) => {
          return {
            group_name: totalsGroupped[key][0]?.group_name,
            id: uniqueId(),
            data: totalsGroupped[key],
          }
        })
      : []

  const getSellers = (dealershipId, dealershipCity) => {
    const sellers = data.sellers.filter(
      (s) =>
        s.dealership_id === dealershipId &&
        s.dealership_city === dealershipCity,
    )
    return sellers
  }
  return {
    ...state,
    options: {
      ...state.options,
      total,
    },
    data: formattedTotals.map((item) => {
      const totalCounter = item.data.reduce(
        (totalSale, sale) => Number(totalSale + Number(sale.counter)),
        0,
      )
      const workingDays = item?.data[0]?.working_days
      return {
        ...item,
        total_vendas: formatPrice(
          item.data.reduce(
            (totalSale, sale) => Number(totalSale + Number(sale.total_vendas)),
            0,
          ),
        ),
        total_devolucao: formatPrice(
          item.data.reduce(
            (totalSale, sale) =>
              Number(totalSale + Number(sale.total_devolucao)),
            0,
          ),
        ),
        total: formatPrice(
          item.data.reduce(
            (totalSale, sale) => Number(totalSale + Number(sale.total)),
            0,
          ),
        ),
        counter: formatPrice(totalCounter),
        dailyGoal: formatPrice(totalCounter / workingDays),
        data: item.data.map((d) => ({
          ...d,
          sellers: getSellers(d.dealership_id, d.dealership_city),
        })),
      }
    }),
    loading: false,
  }
}

const setBiSellersDashboardPageDate = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    dateValue: value,
  },
})

const setChangeShowHideColumnsBiSellerDashboard = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableBiSellersDashboard',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_REQUEST]:
    getBiSellersDashboardPageRequest,
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_FAILURE]:
    getBiSellersDashboardPageFailure,
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_SUCCESS]:
    getBiSellersDashboardPageSuccess,
  [Types.SET_BI_SELLERS_DASHBOARD_PAGE_DATE]: setBiSellersDashboardPageDate,
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_SELLER_REQUEST]:
    getBiSellersDashboardPageSellerRequest,
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_SELLER_FAILURE]:
    getBiSellersDashboardPageSellerFailure,
  [Types.GET_BI_SELLERS_DASHBOARD_PAGE_SELLER_SUCCESS]:
    getBiSellersDashboardPageSellerSuccess,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_BI_SELLERS_DASHBOARD]:
    setChangeShowHideColumnsBiSellerDashboard,
})
