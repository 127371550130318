import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as FunctionTypes,
  Creators as FunctionActions,
} from '../ducks/function'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* addFunction({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'functions', data)

    if (callback) {
      yield call(callback)
    }

    yield put(FunctionActions.addFunctionFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Função cadastrada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(FunctionActions.addFunctionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getFunction({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `functions/${id}`)
    yield put(FunctionActions.getFunctionSuccess(data))
  } catch (e) {
    yield put(FunctionActions.getFunctionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateFunction({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `/functions/${id}`, data)
    yield put(FunctionActions.updateFunctionFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Função editada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(FunctionActions.updateFunctionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeFunction({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/functions/${id}`)

    yield put(FunctionActions.removeFunctionFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Função removiada com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(FunctionActions.removeFunctionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(FunctionTypes.ADD_FUNCTION_REQUEST, addFunction)
  yield takeLatest(FunctionTypes.GET_FUNCTION_REQUEST, getFunction)
  yield takeLatest(FunctionTypes.UPDATE_FUNCTION_REQUEST, updateFunction)
  yield takeLatest(FunctionTypes.REMOVE_FUNCTION_REQUEST, removeFunction)
}
