import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useEffectOnce } from 'react-use'
import { Grid, makeStyles, Button } from '@material-ui/core'

import {
  Chart,
  HighchartsChart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highcharts'

import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import { Creators as EquipmentDashbaordActions } from '~/store/ducks/equipmentDashboard'

import EquipmentsDashboardFilters from './components/EquipmentsDashboardFilters'
import EquipmentsDashboardDialog from './components/EquipmentsDashboardDialog'

import styles from './styles'

const equipmentsDashboardSelector = ({ equipmentDashboard }) =>
  equipmentDashboard

const useStyles = makeStyles(styles)

export default function EquipmentDashboardPage() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [dealership, setDealership] = useState(null)
  const { data, loading } = useSelector(equipmentsDashboardSelector)

  useEffectOnce(() => {
    dispatch(EquipmentDashbaordActions.getEquipmentDashboardDataRequest())
    dispatch(EquipmentDashbaordActions.getEquipmentDashboardFiltersRequest())
  })

  const formattedData = data.map((item) => {
    const othersData = item.data.slice(5)
    const totalOthers = othersData.reduce(
      (total, other) => Number(total + other.total),
      0,
    )
    const others = {
      equipment_type_name: 'Outros',
      total: totalOthers,
    }

    const allTotal = item.data.reduce(
      (total, d) => Number(total + Number(d.total)),
      0,
    )

    return {
      ...item,
      allTotal,
      data: [...item.data.slice(0, 5), others],
    }
  })

  const handleOpenEquipmentsDialog = (dId, dName) => {
    setDealership({
      id: dId,
      name: dName,
    })
  }

  const colors = [
    '#1b1464',
    '#71056c',
    '#b10064',
    '#e0334f',
    '#fb6c33',
    '#ffa600',
  ]

  const plotOptions = {
    pie: {
      shadow: false,
      innerSize: '50%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  }

  const handleCloseDialog = () => {
    setDealership(null)
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <EquipmentsDashboardFilters />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {loading && <OverlayLoading />}

          {formattedData &&
            !loading &&
            formattedData?.map((equipment) => (
              <Grid key={equipment.dealership_id} item xs={12} sm={6} md={3}>
                <Portlet>
                  <HighchartsChart colors={colors} plotOptions={plotOptions}>
                    <Tooltip />
                    <Chart />
                    <Title y={30}>{equipment.dealership_name}</Title>
                    <XAxis type="category" />
                    <YAxis>
                      <PieSeries
                        name="Total"
                        data={equipment?.data.map((item) => ({
                          name: item.equipment_type_name,
                          y: item.total,
                        }))}
                      />
                    </YAxis>
                    <Legend
                      layout="horizontal"
                      align="center"
                      verticalAlign="top"
                    />
                  </HighchartsChart>
                  <Grid container justifyContent="flex-end">
                    <Button
                      disabled={!equipment.allTotal || equipment.allTotal <= 0}
                      onClick={() =>
                        handleOpenEquipmentsDialog(
                          equipment.dealership_id,
                          equipment.dealership_name,
                        )
                      }
                      variant="outlined"
                      className={classes.button}
                    >
                      {equipment.allTotal}
                      {equipment.allTotal > 1
                        ? ' EQUIPAMENTOS'
                        : ' EQUIPAMENTO'}
                    </Button>
                  </Grid>
                </Portlet>
              </Grid>
            ))}
        </Grid>
      </div>
      <EquipmentsDashboardDialog
        open={!!dealership?.id}
        dealership={dealership}
        onClose={handleCloseDialog}
      />
    </>
  )
}
