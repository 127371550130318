export default (theme) => ({
  root: {
    height: 1000,
  },
  titleDivider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  titleCard: {
    margin: theme.spacing(3, 0, 0, 3),
  },
  sellersMedia: {
    border: 'solid 1px #B8BAB7',
    borderRadius: theme.spacing(0.5),
    minHeight: 60,
    padding: 8,
    marginTop: 32,
    marginBottom: 16,
    alignItems: 'center',
  },
  grow: {
    flex: 1,
    overflowX: 'auto',
  },
  tableSales: {
    flex: 1,
    overflowX: 'auto',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#FCFCFC',
    },
  },
  growEmpty: {
    flex: 1,
  },
  emptyWrapper: {
    display: 'flex',
    height: '85vh',
    flex: 1,
    position: 'relative',
    marginTop: theme.spacing(2),
  },
})
