import { call, takeLatest, put, select } from 'redux-saga/effects'
import moment from 'moment'
import { get, pickBy } from 'lodash'
import configureApi from '~/helpers/api'
import { removeMaskReal } from '~/helpers/masks'

import jsonToFormData from '~/helpers/jsonToFormData'

import {
  Types as EquipmentTypes,
  Creators as EquipmentActions,
} from '../ducks/equipment'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const userSelector = ({ session: { user } }) => user

function* addEquipment({ data, callback }) {
  const normalizeData = (obj) => pickBy(obj, null)
  const user = yield select(userSelector)
  const formattedData = {
    ...data,
    user_id: user?.id,
    price: data.price ? removeMaskReal(data.price) : null,
    purchased_at: data.purchased_at
      ? moment(data.purchased_at).format('YYYY-MM-DD')
      : null,
  }
  const formData = jsonToFormData(normalizeData(formattedData))
  try {
    const api = configureApi()
    yield call(api.post, 'equipments', formData)

    if (callback) {
      yield call(callback)
    }

    yield put(EquipmentActions.addEquipmentFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Equipamento cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(EquipmentActions.addEquipmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getEquipment({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `equipments/${id}`)
    const formattedData = {
      ...data,
      purchased_at: data.purchased_at ? moment(data.purchased_at) : null,
    }
    yield put(EquipmentActions.getEquipmentSuccess(formattedData))
  } catch (e) {
    yield put(EquipmentActions.getEquipmentFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateEquipment({ id, data, callback }) {
  const formattedData = {
    ...data,
    price: data.price ? removeMaskReal(data.price) : null,
    purchased_at: data.purchased_at
      ? moment(data.purchased_at).format('YYYY-MM-DD', 'en')
      : null,
  }
  try {
    const api = configureApi()
    yield call(api.put, `/equipments/${id}`, formattedData)
    yield put(EquipmentActions.updateEquipmentFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Equipamento editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(EquipmentActions.updateEquipmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeEquipment({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/equipments/${id}`)

    yield put(EquipmentActions.removeEquipmentFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Equipamento removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(EquipmentActions.removeEquipmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getEquipmentDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(EquipmentActions.getEquipmentDealershipsSuccess(data))
  } catch (e) {
    yield put(EquipmentActions.getEquipmentDealershipsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getEquipmentUsers() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'users', {
      params: {
        pagination: 0,
        order: 'name,asc',
        'where[active]': true,
      },
    })
    yield put(EquipmentActions.getEquipmentUsersSuccess(data))
  } catch (e) {
    yield put(EquipmentActions.getEquipmentUsersFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getEquipmentEquipmentsTypes() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'equipment_types', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(EquipmentActions.getEquipmentEquipmentsTypesSuccess(data))
  } catch (e) {
    yield put(EquipmentActions.getEquipmentEquipmentsTypesFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(EquipmentTypes.ADD_EQUIPMENT_REQUEST, addEquipment)
  yield takeLatest(EquipmentTypes.GET_EQUIPMENT_REQUEST, getEquipment)
  yield takeLatest(EquipmentTypes.UPDATE_EQUIPMENT_REQUEST, updateEquipment)
  yield takeLatest(EquipmentTypes.REMOVE_EQUIPMENT_REQUEST, removeEquipment)
  yield takeLatest(
    EquipmentTypes.GET_EQUIPMENT_DEALERSHIPS_REQUEST,
    getEquipmentDealerships,
  )
  yield takeLatest(
    EquipmentTypes.GET_EQUIPMENT_USERS_REQUEST,
    getEquipmentUsers,
  )
  yield takeLatest(
    EquipmentTypes.GET_EQUIPMENT_EQUIPMENTS_TYPES_REQUEST,
    getEquipmentEquipmentsTypes,
  )
}
