export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  grow: {
    height: '100%',
    position: 'relative',
    paddingBottom: theme.spacing(9),
  },

  container: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  editIcon: {
    padding: 0,
  },

  tableRow: {
    cursor: 'pointer',
  },
})
