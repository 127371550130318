import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getDepartmentsRequest: [],
  getDepartmentsFailure: [],
  getDepartmentsSuccess: ['data', 'total'],
  setDepartmentsSort: ['sort', 'direction'],
  setDepartmentsPage: ['page', 'limit'],
  setDepartmentsSearch: ['value'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const getDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDepartmentsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setDepartmentsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setDepartmentsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setDepartmentsSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_DEPARTMENTS_REQUEST]: getDepartmentsRequest,
  [Types.GET_DEPARTMENTS_FAILURE]: getDepartmentsFailure,
  [Types.GET_DEPARTMENTS_SUCCESS]: getDepartmentsSuccess,
  [Types.SET_DEPARTMENTS_SORT]: setDepartmentsSort,
  [Types.SET_DEPARTMENTS_PAGE]: setDepartmentsPage,
  [Types.SET_DEPARTMENTS_SEARCH]: setDepartmentsSearch,
})
