import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import 'moment/locale/pt-br'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import AppContainer from './containers/AppContainer'
import configureStore from './store'
import './assets/scss/index.scss'

const store = configureStore()

moment.locale('pt-br')

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root'),
)
