export default () => ({
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 20,
    alignItems: 'center'
  },
  emptyIcon: {
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    height: 100,
    color: '#e4e4e4'
  }
});
