import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getOrganogramDataRequest: [],
  getOrganogramDataFailure: [],
  getOrganogramDataSuccess: ['data'],
})

const INITIAL_STATE = {
  data: {},
  filter: {
    dealership_id: 0,
  },
  loading: false,
}

const getOrganogramDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getOrganogramDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getOrganogramDataSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_ORGANOGRAM_DATA_REQUEST]: getOrganogramDataRequest,
  [Types.GET_ORGANOGRAM_DATA_FAILURE]: getOrganogramDataFailure,
  [Types.GET_ORGANOGRAM_DATA_SUCCESS]: getOrganogramDataSuccess,
})
