import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
  // AttachMoney as RevenuesIcon,
  // Money as CostIcon,
  // TrendingUp as ProfitIcon,
} from '@material-ui/icons'

// import { mdiPercent } from '@mdi/js'

import { last } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as AnalitycalActions } from '~/store/ducks/analitycal'
import { formatPrice } from '~/helpers/masks'
// import CustomIcon from '~/components/CustomIcon'
import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import AnalitycalCard from '~/components/AnalitycalCard'

import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import AnalitycalFilters from './components/AnalyticalFilters'
import styles from './styles'
import AnalitycalDialog from './components/AnalitycalDialog'

const analitycalSelector = ({ analitycal }) => analitycal

const useStyles = makeStyles(styles)

function AnalitycalPage() {
  const isSmallScreen = useSmallScreen()
  const [openAnalitycalDialog, setOpenAnalitycalDialog] = useState(false)
  const [segmentDialog, setSegmentDialog] = useState(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, showHideColumns, loading, dataPreviousMonth } =
    useSelector(analitycalSelector)

  const optionsShowHideColumn = [
    { name: 'type', label: 'Segmento' },
    { name: 'billing', label: 'Faturamento' },
    { name: 'quantity', label: 'Quantidade' },
    { name: 'cost', label: 'Custo' },
    { name: 'liquidValue', label: 'Lucro líquido' },
    { name: 'porcentage', label: '%' },
  ]

  useEffect(() => {
    dispatch(AnalitycalActions.getAnalitycalRequest())
    dispatch(AnalitycalActions.getFiltersAnalitycalRequest())
    return () => {
      dispatch(AnalitycalActions.resetAnalitycal())
    }
  }, [])

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      AnalitycalActions.setChangeShowHideColumnsAnalitycal(newColumnsTable),
    )
  }

  const getColorTableCellProfit = (item) => {
    return item.profit < 0 ? 'red' : 'black'
  }

  const getColorTableCellProfitPorcentage = (item) => {
    return item.profit_percentage < 0 ? 'red' : 'black'
  }

  const getFontTotal = (segment) => {
    return segment === 'Total' ? 'totalRow' : ''
  }

  const openDetails = (segment) => {
    setOpenAnalitycalDialog(true)
    setSegmentDialog(segment)
  }

  const handleClose = () => {
    setOpenAnalitycalDialog(false)
    setSegmentDialog(null)
  }

  const currentData = last(data) || {}
  return (
    <>
      <div className={classes.root}>
        {isSmallScreen && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filterWropper}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <AnalitycalFilters />
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && <AnalitycalFilters />}
        {!loading && (
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="revenues"
                // icon={<RevenuesIcon />}
                data={{
                  name: 'Faturamento',
                  value: currentData?.revenues,
                  previousValue: dataPreviousMonth?.revenues,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="cost"
                // icon={<CostIcon />}
                data={{
                  name: 'Custo',
                  value: currentData?.cost,
                  previousValue: dataPreviousMonth?.cost,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="profit"
                // icon={<ProfitIcon />}
                data={{
                  name: 'Lucro Líquido',
                  value: currentData?.profit,
                  previousValue: dataPreviousMonth?.profit,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="profit_percentage"
                // icon={<CustomIcon pathComponent={mdiPercent} />}
                data={{
                  name: 'Porcentagem de Lucro',
                  value: currentData?.profit_percentage,
                  previousValue: dataPreviousMonth?.profit_percentage,
                }}
              />
            </Grid>
          </Grid>
        )}
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {showHideColumns.type && (
                        <TableCell align="left">Segmento</TableCell>
                      )}
                      {showHideColumns.billing && (
                        <TableCell align="center">Faturamento</TableCell>
                      )}
                      {showHideColumns.quantity && (
                        <TableCell align="center">Quantidade</TableCell>
                      )}
                      {showHideColumns.cost && (
                        <TableCell align="center">Custo</TableCell>
                      )}
                      {showHideColumns.liquidValue && (
                        <TableCell align="center">Lucro Líquido</TableCell>
                      )}
                      {showHideColumns.porcentage && (
                        <TableCell align="center">%</TableCell>
                      )}
                      <TableCell className={classes.showHideColumns}>
                        <ShowHideColumns
                          showHideColumns={showHideColumns}
                          optionsShowHideColumn={optionsShowHideColumn}
                          onChangeShowHideColumn={handleSetChangeShowHideColumn}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item) => (
                      <TableRow
                        hover
                        disabled={item.segment === 'Total'}
                        key={item.segment}
                        className={[
                          classes.tableRow,
                          item.segment !== 'Total' && classes.tableRowClicked,
                        ].join(' ')}
                        onClick={() => {
                          if (item.segment !== 'Total') {
                            openDetails(item.segment)
                          }
                        }}
                      >
                        {showHideColumns.type && (
                          <TableCell
                            align="left"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {item.segment || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.billing && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {formatPrice(Number(item.revenues)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.quantity && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {item.amount || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.cost && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {formatPrice(Number(item.cost)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.liquidValue && (
                          <TableCell
                            className={classes[getFontTotal(item.segment)]}
                            style={{
                              color: getColorTableCellProfit(item),
                            }}
                            align="center"
                          >
                            {formatPrice(Number(item.profit)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.porcentage && (
                          <TableCell
                            className={classes[getFontTotal(item.segment)]}
                            align="center"
                            style={{
                              color: getColorTableCellProfitPorcentage(item),
                            }}
                          >
                            {`${parseFloat(item.profit_percentage || 0).toFixed(
                              2,
                            )}%` || '-'}
                          </TableCell>
                        )}
                        <TableCell className={classes.showHideColumns} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </Portlet>
      </div>
      <AnalitycalDialog
        segment={segmentDialog}
        open={openAnalitycalDialog}
        onClose={handleClose}
      />
    </>
  )
}

export default AnalitycalPage
