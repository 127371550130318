import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getHelpdeskDashboardFiltersRequest: [],
  getHelpdeskDashboardFiltersFailure: [],
  getHelpdeskDashboardFiltersSuccess: ['departments', 'attendants'],
  getHelpdeskDashboardDataRequest: [],
  getHelpdeskDashboardDataFailure: [],
  getHelpdeskDashboardDataSuccess: ['data'],
  setHelpdeskDashboardFilter: ['property', 'value'],
  getHelpdeskCallsDataRequest: ['subject'],
  getHelpdeskCallsDataSuccess: ['data'],
  getHelpdeskCallsDataFailure: [],
  resetHelpdeskDashboard: [],
  setChangeShowHideColumnsHelpdeskDashboard: ['newTableColumns'],
})

const INITIAL_STATE = {
  filters: {
    date: new Date(),
    department: 0,
    attendant: 0,
    delay: 0,
  },
  calls: {
    loading: false,
    currentSubject: null,
    data: [],
    dataChart: [],
  },
  filtersData: {
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
    ],
    attendants: [
      {
        name: 'Todos os atendentes',
        value: 0,
      },
    ],
    delays: [
      {
        name: 'Tudo',
        value: 0,
      },
      {
        name: 'Menos de 1 dia',
        value: 1,
      },
      {
        name: '1 dia',
        value: 2,
      },
      {
        name: 'Mais de 2 dias',
        value: 3,
      },
    ],
  },
  data: [],
  loadingFilters: false,
  loading: false,
  showHideColumns: {
    id: true,
    status: true,
    subject: true,
    requester: true,
    responsible: true,
  },
}

const getHelpdeskDashboardFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getHelpdeskDashboardFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getHelpdeskDashboardFiltersSuccess = (
  state = INITIAL_STATE,
  { departments, attendants },
) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    departments: [
      {
        name: 'Todos os departamentos',
        value: 0,
      },
      ...departments,
    ],
    attendants: [
      {
        name: 'Todos os atendentes',
        value: 0,
      },
      ...attendants,
    ],
  },
  loadingFilters: false,
})

const getHelpdeskDashboardDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getHelpdeskDashboardDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getHelpdeskDashboardDataSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const setHelpdeskDashboardFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const getHelpdeskCallsDataRequest = (state = INITIAL_STATE, { subject }) => ({
  ...state,
  calls: {
    ...state.calls,
    loading: true,
    currentSubject: subject,
  },
})

const getHelpdeskCallsDataSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  calls: {
    ...state.calls,
    loading: false,
    data: data.data,
    dataChart: data.chart,
  },
})

const getHelpdeskCallsDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  calls: {
    ...state.calls,
    loading: false,
  },
})

const resetHelpdeskDashboard = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsHelpdeskDashboard = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableHelpdeskDashboard',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_HELPDESK_DASHBOARD_FILTERS_REQUEST]:
    getHelpdeskDashboardFiltersRequest,
  [Types.GET_HELPDESK_DASHBOARD_FILTERS_FAILURE]:
    getHelpdeskDashboardFiltersFailure,
  [Types.GET_HELPDESK_DASHBOARD_FILTERS_SUCCESS]:
    getHelpdeskDashboardFiltersSuccess,
  [Types.GET_HELPDESK_DASHBOARD_DATA_REQUEST]: getHelpdeskDashboardDataRequest,
  [Types.GET_HELPDESK_DASHBOARD_DATA_FAILURE]: getHelpdeskDashboardDataFailure,
  [Types.GET_HELPDESK_DASHBOARD_DATA_SUCCESS]: getHelpdeskDashboardDataSuccess,
  [Types.SET_HELPDESK_DASHBOARD_FILTER]: setHelpdeskDashboardFilter,
  [Types.GET_HELPDESK_CALLS_DATA_REQUEST]: getHelpdeskCallsDataRequest,
  [Types.GET_HELPDESK_CALLS_DATA_SUCCESS]: getHelpdeskCallsDataSuccess,
  [Types.GET_HELPDESK_CALLS_DATA_FAILURE]: getHelpdeskCallsDataFailure,
  [Types.RESET_HELPDESK_DASHBOARD]: resetHelpdeskDashboard,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_HELPDESK_DASHBOARD]:
    setChangeShowHideColumnsHelpdeskDashboard,
})
