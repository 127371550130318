import palette from '../palette'

export default {
  root: {
    position: 'relative',
    '& $notchedOutline': {
      borderColor: '#B3B3B3',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: '#B3B3B3',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderColor: '#B3B3B3',
      },
    },
    '&$focused $notchedOutline': {
      borderColor: '#B3B3B3',
      color: '#B3B3B3',
    },
    floatingLabelFocusStyle: {
      color: palette.primary.main,
      fontWeight: 500,
    },
  },
}
