import React, { useEffect, useRef, useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  IconButton,
} from '@material-ui/core'

import filesize from 'filesize'

import {
  ExpandMore as ExpandMoreIcon,
  Backup as UploadIcon,
  Cancel as CancelIcon,
  InsertDriveFile as AttachmentIcon,
} from '@material-ui/icons'

import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import history from '~/helpers/history'
import { Creators as QuizzeActions } from '~/store/ducks/quiz'
import OverlayLoading from '~/components/OverlayLoading'

import RhfTextField from '~/components/FormComponents/RhfTextField'
import RhfSelect from '~/components/FormComponents/RhfSelect'
import styles from './styles'

const useStyles = makeStyles(styles)

const quizzeSelector = ({ quiz }) => quiz.data
const loadingSelector = ({ quiz: { loading } }) => loading

const QuizzeFormTab = ({ id }) => {
  const fileInput = useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const quizzeData = useSelector(quizzeSelector)
  const isEdit = id !== 'new'
  const loading = useSelector(loadingSelector)
  const [attachment, setAttachment] = useState('')

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm()

  const handleFormQuizzeSubmit = (data) => {
    let formattedData = data

    formattedData = {
      ...data,
      active: data.active === 'true',
      type: 'quiz',
    }

    if (attachment) {
      formattedData.file = attachment
    }

    if (isEdit) {
      dispatch(
        QuizzeActions.updateQuizzeRequest(id, formattedData, () =>
          history.push('/quizzes'),
        ),
      )
    } else {
      dispatch(
        QuizzeActions.addQuizzeRequest(formattedData, () =>
          history.push('/quizzes'),
        ),
      )
    }
  }
  function handleUploadAttachment(e) {
    const file = e.target.files[0]
    if (file) {
      setAttachment(file)
    }
  }

  useEffect(() => {
    if (isEdit) {
      reset(quizzeData)
    } else {
      reset({})
    }
  }, [isEdit, quizzeData])

  const linkDownload = quizzeData?.url_pdf_file

  return (
    <div className={classes.root}>
      <div>
        {loading && <OverlayLoading />}
        {!loading && (
          <form onSubmit={handleSubmit(handleFormQuizzeSubmit)} id="form-quiz">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RhfTextField
                  label="Nome"
                  name="name"
                  control={control}
                  fullWidth
                  rules={{ required: true }}
                  error={!!errors.name}
                  helperText={!!errors.name && 'Campo obrigatório'}
                />
              </Grid>

              <Grid item xs={12}>
                <RhfTextField
                  label="Descrição"
                  name="description"
                  control={control}
                  fullWidth
                  rules={{ required: true }}
                  error={!!errors.description}
                  helperText={!!errors.description && 'Campo obrigatório'}
                />
              </Grid>
              <Grid item xs={12}>
                <RhfSelect
                  name="active"
                  label="Ativo"
                  variant="outlined"
                  fullWidth
                  control={control}
                  defaultValue=""
                >
                  <MenuItem value="true">Sim</MenuItem>
                  <MenuItem value="false">Não</MenuItem>
                </RhfSelect>
              </Grid>
              <div className={classes.detailsWrapper}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="caption">Vídeo</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <RhfTextField
                          name="title_video"
                          label="Título do Vídeo"
                          placeholder="Informe o título do vídeo"
                          control={control}
                          fullWidth
                          helperText={
                            !!errors.title_video && 'Campo obrigatório'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <RhfTextField
                          name="url_link"
                          label="Link do Vídeo"
                          placeholder="Exemplo: https://www.youtube.com/watch?v=3CZ4JSdsa"
                          control={control}
                          fullWidth
                          helperText={
                            !!errors.title_video && 'Campo obrigatório'
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={classes.detailsWrapper}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="caption">
                      Apresentação (PDF)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => {
                            fileInput.current.click()
                          }}
                        >
                          <UploadIcon className={classes.uploadIcon} />
                          Selecionar arquivo
                          <input
                            type="file"
                            multiple={false}
                            ref={fileInput}
                            onClick={(e) => {
                              e.target.value = ''
                            }}
                            style={{ display: 'none' }}
                            onChange={handleUploadAttachment}
                          />
                        </Button>
                      </Grid>
                      {attachment && (
                        <Grid item xs={12}>
                          <Card className={classes.upload}>
                            <CardContent className={classes.uploadContent}>
                              <AttachmentIcon className={classes.uploadIcon} />
                              <div className={classes.uploadDetail}>
                                <span>{attachment.name}</span>
                                <span className={classes.fileSize}>
                                  {filesize(attachment.size, { round: 0 })}
                                </span>
                              </div>
                              <IconButton onClick={() => setAttachment('')}>
                                <CancelIcon
                                  className={classes.cancelUploadIcon}
                                />
                              </IconButton>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {!attachment && !!linkDownload && (
                        <Button
                          className={classes.buttonDonwload}
                        // color="primary"
                        // variant="outlined"
                        >
                          <a
                            className={classes.link}
                            id="anchorId"
                            href={linkDownload}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Baixar Arquivo
                          </a>
                        </Button>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </form>
        )}
      </div>
      <Grid container className={classes.buttonForm} justifyContent="flex-end">
        <Button
          color="primary"
          type="submit"
          form="form-quiz"
          variant="contained"
        >
          SALVAR
        </Button>
      </Grid>
    </div>
  )
}

QuizzeFormTab.propTypes = {
  id: PropTypes.string,
}

QuizzeFormTab.defaultProps = {
  id: null,
}

export default QuizzeFormTab
