export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',

    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'flex-end',
      color: theme.palette.primary.main,

      '& h3': {
        marginRight: 2,
      },
    },
  },
})
