import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getUsersDailyEmailsRequest: [],
  getUsersDailyEmailsFailure: [],
  getUsersDailyEmailsSuccess: ['data', 'total'],
  getTypesEmailRequest: [],
  getTypesEmailFailure: [],
  getTypesEmailSuccess: ['data'],
  getTypeEmailRequest: ['id'],
  getTypeEmailFailure: [],
  getTypeEmailSuccess: ['data'],
  // addUsersTypesEmailRequest: ['data'],
  // addUsersTypesEmailFinish: [],
  updateUsersTypesEmailRequest: ['id', 'data', 'callback'],
  updateUsersTypesEmailFinish: [],
})

const INITIAL_STATE = {
  dataUsers: [],
  dataTypes: [],
  dataType: [],
  loading: false,
  loadingTypes: false,
  loadingType: false,
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 1000,
  },
}

const getUsersDailyEmailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUsersDailyEmailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUsersDailyEmailsSuccess = (
  state = INITIAL_STATE,
  { data, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  dataUsers: data,
  loading: false,
})

const getTypesEmailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingTypes: true,
})

const getTypesEmailFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingTypes: false,
})
const getTypesEmailSuccess = (state = INITIAL_STATE, data) => ({
  ...state,
  loadingTypes: false,
  dataTypes: data,
})

const getTypeEmailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingType: true,
})

const getTypeEmailFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingType: false,
})
const getTypeEmailSuccess = (state = INITIAL_STATE, data) => ({
  ...state,
  loadingType: false,
  dataType: data,
})

// const addUsersTypesEmailRequest = (state = INITIAL_STATE) => ({
//   ...state,
//   loading: true,
// })

// const addUsersTypesEmailFinish = (state = INITIAL_STATE) => ({
//   ...state,
//   loading: false,
// })

const updateUsersTypesEmailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateUsersTypesEmailFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_USERS_DAILY_EMAILS_REQUEST]: getUsersDailyEmailsRequest,
  [Types.GET_USERS_DAILY_EMAILS_FAILURE]: getUsersDailyEmailsFailure,
  [Types.GET_USERS_DAILY_EMAILS_SUCCESS]: getUsersDailyEmailsSuccess,
  [Types.GET_TYPES_EMAIL_REQUEST]: getTypesEmailRequest,
  [Types.GET_TYPES_EMAIL_FAILURE]: getTypesEmailFailure,
  [Types.GET_TYPES_EMAIL_SUCCESS]: getTypesEmailSuccess,
  [Types.GET_TYPE_EMAIL_REQUEST]: getTypeEmailRequest,
  [Types.GET_TYPE_EMAIL_FAILURE]: getTypeEmailFailure,
  [Types.GET_TYPE_EMAIL_SUCCESS]: getTypeEmailSuccess,
  // [Types.ADD_USERS_TYPES_EMAIL_REQUEST]: addUsersTypesEmailRequest,
  // [Types.ADD_USERS_TYPES_EMAIL_FINISH]: addUsersTypesEmailFinish,
  [Types.UPDATE_USERS_TYPES_EMAIL_REQUEST]: updateUsersTypesEmailRequest,
  [Types.UPDATE_USERS_TYPES_EMAIL_FINISH]: updateUsersTypesEmailFinish,
})
