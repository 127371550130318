import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { mdiPencil } from '@mdi/js'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import history from '~/helpers/history'

import { Creators as QuizzeActions } from '~/store/ducks/quiz'
import { Creators as QuizzesActions } from '~/store/ducks/quizzes'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'
import CustomIcon from '~/components/CustomIcon'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'
import styles from './styles'

const quizzesSelector = ({ quizzes }) => quizzes

const useStyles = makeStyles(styles)

function QuizzesPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openConfirmDialog, setConfirmDialog] = useState(false)

  const [quizzeId, setQuizzeId] = useState(null)
  const { data, options, loading } = useSelector(quizzesSelector)

  const isSmallScreen = useSmallScreen()
  useEffect(() => {
    dispatch(QuizzesActions.getQuizzesRequest())
  }, [])

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(QuizzesActions.setQuizzesPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(QuizzesActions.setQuizzesPage(page, options.limit))
  }

  const handleRemoveQuizze = (id) => {
    setQuizzeId(id)
    setConfirmDialog(true)
  }

  const handleEditQuizze = (id) => {
    history.push(`/quizzes/${id}`)
  }

  const handleRemoveCancel = () => {
    setQuizzeId(null)
    setConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      QuizzeActions.removeQuizzeRequest(quizzeId, () => {
        dispatch(QuizzesActions.getQuizzesRequest())
        setQuizzeId(null)
        setConfirmDialog(false)
      }),
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.gridContainerButton}
        >
          <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={() => history.push('quizzes/new')}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Questionário
            </Button>
          </Grid>
        </Grid>
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {!loading && data.length === 0 && (
              <EmptyList message="Não há questionários para serem visualizados" />
            )}
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Data de cadastro</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                        >
                          <TableCell>{item.name || '-'}</TableCell>
                          <TableCell>{item.description || '-'}</TableCell>
                          <TableCell>
                            {moment(item.created_at || '-').format(
                              'DD/MM/YYYY HH:mm',
                              'HH/MM',
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <div className={classes.tableCellActions}>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleEditQuizze(item.id)}
                              >
                                <CustomIcon pathComponent={mdiPencil} />
                              </IconButton>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => handleRemoveQuizze(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>

      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este questionário?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}

export default QuizzesPage
