export default (theme) => {
  return {
    inputIcon: {
      width: 20,
      height: 20,
      color: theme.palette.text.primary,
    },
    arrowIcon: {
      width: 18,
      height: 18,
      verticalAlign: 'bottom',
      color: theme.palette.text.primary,
    },
    dateRangeWrapper: {
      display: 'inline-block',
      maxHeight: 36,
    },
  }
}
