import React, { useEffect } from 'react'

import {
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper,
  Card,
  Avatar,
} from '@material-ui/core'

// import moment from 'moment'
import { BsCircleFill as CircleIcon } from 'react-icons/bs'

import DraftsIcon from '@material-ui/icons/Drafts'
import TimerIcon from '@material-ui/icons/Timer'
import PersonIcon from '@material-ui/icons/Person'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as BlipActions } from '~/store/ducks/blip'

import Autocomplete from '~/components/Autocomplete'

import styles from './styles'

const dealershipsSelector = ({ blip }) => blip.dealerships

const departmentsSelector = ({ blip }) => blip.departments

const useStyles = makeStyles(styles)

const blipSelector = ({ blip }) => blip

export default function Blip() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data: dataDealerships, loading: loadingDealerships } =
    useSelector(dealershipsSelector)

  const { data: dataDepartments, loading: loadingDepartments } =
    useSelector(departmentsSelector)

  const {
    dataOpenTickets,
    // dataWaitingTickets,
    dataAttendants,
    // dataTicketsPerHour,
    dataTicketMetrics,
    options,
  } = useSelector(blipSelector)

  useEffect(() => {
    dispatch(BlipActions.getDealershipsBlipRequest())
    dispatch(BlipActions.getDepartmentsBlipRequest())
    dispatch(BlipActions.getOpenTicketsRequest())
    // dispatch(BlipActions.getWaitingTicketsRequest())
    dispatch(BlipActions.getAttendantsRequest())
    // dispatch(BlipActions.getTicketsPerHourRequest())
    dispatch(BlipActions.getTicketMetricsRequest())
  }, [])

  const handleDealershipsFilterChange = (dealershipId) => {
    dispatch(BlipActions.setBlipDealershipFilter(dealershipId))
  }

  const handleDepartmentsFilterChange = (departmentId) => {
    dispatch(BlipActions.setBlipDepartmentFilter(departmentId))
  }

  const getStatus = (item) => {
    return item.status === 'Online' ? '#06892e' : '#E00A02'
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(_, option) => {
              handleDealershipsFilterChange(option ? option.value : null)
            }}
            options={dataDealerships.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="dealership"
            value={options.dealershipId}
            className={classes.autocomplete}
            label="Filtrar por concessionária"
            placeholder="Selecione uma concessionária"
            loading={loadingDealerships}
            autoSelect={false}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(_, option) => {
              handleDepartmentsFilterChange(option ? option.value : null)
            }}
            options={dataDepartments.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="department"
            value={options.departmentId}
            className={classes.autocomplete}
            label="Filtrar por Departamento"
            placeholder="Selecione um departamento"
            loading={loadingDepartments}
            autoSelect={false}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 16 }}>
        {dataTicketMetrics && dataOpenTickets && (
          <Grid container className={classes.cardMetrics} spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <DraftsIcon size={10} />
                <Typography variant="h6" color="primary">
                  Tickets Abertos
                </Typography>
                <Typography variant="h4">
                  {dataOpenTickets?.data?.resource?.total}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Máximo na Fila
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.maxQueueTime}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Máximo da 1° Resposta
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.maxFirstResponseTime}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Médio de Atendimento
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.avgAttendanceTime}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        )}
        {/* {dataTicketMetrics && dataOpenTickets && (
          <Grid
            container
            spacing={2}
            className={classes.cardMetrics}
            style={{ marginTop: '8px' }}
          >
             <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Médio da 1° Reposta
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.avgFirstResponseTime}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Médio de resposta
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.avgResponseTime}
                </Typography>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <TimerIcon />
                <Typography variant="h6" color="primary">
                  Tempo Médio na Fila
                </Typography>
                <Typography variant="h4">
                  {dataTicketMetrics?.data?.resource?.avgQueueTime}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card className={classes.card}>
                <PersonIcon />
                <Typography variant="h6" color="primary">
                  Tickets por Atendente
                </Typography>
                <Typography variant="h4">
                  {Number(
                    dataTicketMetrics?.data?.resource?.ticketsPerAttendant,
                  ).toFixed(2)}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        )} */}
        {/* <Grid container justifyContent="space-between" spacing={2}>
          {dataTicketsPerHour && (
            <Grid item md={6} xs={12} style={{ marginTop: '16px' }}>
              <Paper className={classes.paper}>
                <Typography variant="h2" color="primary">
                  Por Hora
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell>Hora</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTicketsPerHour?.data?.resource?.items?.map((item) => {
                      return (
                        <TableRow className={classes.tableRow}>
                          <TableCell>
                            {moment(item.storageDate || '-').format('HH:mm')}
                          </TableCell>
                          <TableCell align="right">
                            {item.ticketByHour || '-'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          )}
          {dataWaitingTickets && (
            <Grid item md={6} xs={12} style={{ marginTop: '16px' }}>
              <Paper className={classes.paper}>
                <Typography variant="h2" color="primary">
                  Aguardando Atendimento
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell>Nome</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell align="right">Prioridade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataWaitingTickets?.data?.resource?.items?.map((item) => {
                      return (
                        <TableRow className={classes.tableRow}>
                          <TableCell>{item.customerName || '-'}</TableCell>
                          <TableCell>{item.sequentialId || '-'}</TableCell>
                          <TableCell align="right">
                            {item.priority || '-'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          )}
        </Grid> */}

        <Grid container>
          <Grid item xs={12}>
            {dataAttendants && (
              <Paper className={classes.paperTable} style={{ marginTop: 16 }}>
                <Grid item xs={12}>
                  <Typography variant="h2" color="primary">
                    Atendentes
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell />
                        <TableCell>Nome</TableCell>
                        <TableCell align="center">
                          Média de Tempo Por Atendimento
                        </TableCell>
                        <TableCell align="center">
                          Média de Tempo de Resposta
                        </TableCell>
                        <TableCell align="center">Tickets Abertos</TableCell>
                        <TableCell align="center">Tickets Fechados</TableCell>
                        <TableCell align="right">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataAttendants?.data?.map((item) => {
                        return (
                          <TableRow
                            className={classes.tableRow}
                            key={item?.identity}
                          >
                            <TableCell>
                              {item.user?.url_image ? (
                                <Avatar
                                  alt={item.name}
                                  className={classes.avatar}
                                  src={item.user.url_image}
                                />
                              ) : (
                                <Avatar className={classes.avatarIcon}>
                                  <PersonIcon />
                                </Avatar>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.user?.name || item.agentName || '-'}
                            </TableCell>
                            <TableCell align="center">
                              {item.averageAttendanceTime}
                            </TableCell>
                            <TableCell align="center">
                              {item.averageResponseTime}
                            </TableCell>
                            <TableCell align="center">
                              {item.openedTickets || '-'}
                            </TableCell>
                            <TableCell align="center">
                              {item.closedTickets || '-'}
                            </TableCell>
                            <TableCell align="right">
                              {' '}
                              {
                                <CircleIcon
                                  size={8}
                                  style={{ color: getStatus(item) }}
                                />
                              }{' '}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
