import React, { useEffect } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Grid,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as UsersRamalActions } from '~/store/ducks/usersRamal'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'

import SearchInput from '~/components/SearchInput'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'

import EmptyList from '~/components/EmptyList'
import UsersFilters from './components/UsersRamalFilters'
import styles from './styles'

const usersSelector = ({ usersRamal }) => usersRamal

const useStyles = makeStyles(styles)

function UsersPage() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data, options, loading } = useSelector(usersSelector)

  const { sort, direction } = options
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(UsersRamalActions.getUsersRamalRequest())
    dispatch(UsersRamalActions.getUsersRamalFilterDealershipsRequest())
    dispatch(UsersRamalActions.getUsersRamalFilterDepartmentsRequest())
  }, [])

  const handleSearchChange = (value) => {
    dispatch(UsersRamalActions.setUsersRamalSearch(value))
  }

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(UsersRamalActions.setUsersRamalSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(UsersRamalActions.setUsersRamalPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(UsersRamalActions.setUsersRamalPage(page, options.limit))
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.gridContainerButton}
        justifyContent="space-between"
      >
        {!isSmallScreen && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <SearchInput
                style={{ height: 52 }}
                value={options.search}
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise por nome ou  ramal..."
              />
            </Grid>
            <Grid item md={12}>
              <UsersFilters />
            </Grid>
          </Grid>
        )}
      </Grid>

      {isSmallScreen && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.filterWropper}>
              <FilterIcon color="primary" className={classes.filterIcon} />
              <Typography variant="h5" color="primary">
                Filtros
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchInput
                  onChange={(e) => handleSearchChange(e.target.value)}
                  placeholder="Pesquise por nome ou ramal..."
                />
              </Grid>
              <Grid item xs={12}>
                <UsersFilters />
              </Grid>
            </Grid>
          </AccordionDetails>
          <div style={{ paddingTop: 8 }} />
        </Accordion>
      )}
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há colaboradores para serem visualizados" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              {data.length > 0 && (
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'name'}
                          onClick={() => handleSortChange('name')}
                          direction={direction}
                        >
                          Nome
                        </TableSortLabel>
                      </TableCell>

                      <TableCell>Ramal</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'function_dealership_name'}
                          onClick={() =>
                            handleSortChange('function_dealership_name')
                          }
                          direction={direction}
                        >
                          Concessionária
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'function_department_name'}
                          onClick={() =>
                            handleSortChange('function_department_name')
                          }
                          direction={direction}
                        >
                          Departamento
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'function_name'}
                          onClick={() => handleSortChange('function_name')}
                          direction={direction}
                        >
                          Função
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell>
                          <Avatar alt={item.name} src={item.url_image} />
                        </TableCell>
                        <TableCell>{item.name || '-'}</TableCell>
                        <TableCell>{item.ramal_next_ip || '-'}</TableCell>
                        <TableCell>
                          {item.function_dealership_name || '-'}
                        </TableCell>
                        <TableCell>
                          {item.function_department_name || '-'}
                        </TableCell>
                        <TableCell>{item.function_name || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </div>

        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>
    </div>
  )
}

export default UsersPage
