export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  grow: {
    flex: 1,
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  filtersTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  filterIcon: {
    marginRight: theme.spacing(1 / 2),
  },
  accordion: {
    marginTop: 0,
  },
})
