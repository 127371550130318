import { createActions, createReducer } from 'reduxsauce'
import { sortBy } from 'lodash'

export const { Types, Creators } = createActions({
  getBilledFiltersRequest: [],
  getBilledFiltersFailure: [],
  getBilledFiltersSuccess: ['groups'],
  getBilledDataRequest: [],
  getBilledDataFailure: [],
  getBilledDataSuccess: ['data'],
  getBillingsDataRequest: ['id'],
  getBillingsDataFailure: [],
  getBillingsDataSuccess: ['data'],
  setBilledFilter: ['property', 'value'],
  resetBilled: [],
  setChangeShowHideColumnsBilledDialog: ['newTableColumnsDialog'],
})

const INITIAL_STATE = {
  filters: {
    date: new Date(),
    type: 'N',
    group: 'all',
  },
  billings: {
    currentDealership: null,
    data: [],
    sort: '',
    loading: false,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos grupos',
        value: 'all',
      },
    ],
    types: [
      {
        name: 'Novos',
        value: 'N',
      },
      {
        name: 'Seminovos',
        value: 'U',
      },
      {
        name: 'Venda Direta',
        value: 'V',
      },
    ],
  },
  data: [],
  loadingFilters: false,
  loading: false,
  showHideColumnsDialog: {
    segment: true,
    cod: true,
    version: true,
    yearModel: true,
    seller: true,
  },
}

const getBilledFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getBilledFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getBilledFiltersSuccess = (state = INITIAL_STATE, { groups }) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    groups: [
      {
        name: 'Todos grupos',
        value: 'all',
      },
      ...sortBy(groups, (a) => a.name),
    ],
  },
  loadingFilters: false,
})

const getBilledDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getBilledDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getBilledDataSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const setBilledFilter = (state = INITIAL_STATE, { property, value }) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const getBillingsDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  billings: {
    ...state.billings,
    loading: true,
  },
})

const getBillingsDataSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  billings: {
    ...state.billings,
    data,
    loading: false,
  },
})

const getBillingsDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  billings: {
    ...state.billings,
    loading: false,
  },
})

const resetBilled = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsBilledDialog = (
  state = INITIAL_STATE,
  { newTableColumnsDialog },
) => {
  localStorage.setItem(
    'caid.columnsTableBilledDialog',
    JSON.stringify(newTableColumnsDialog),
  )
  return {
    ...state,
    showHideColumnsDialog: newTableColumnsDialog,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_BILLED_FILTERS_REQUEST]: getBilledFiltersRequest,
  [Types.GET_BILLED_FILTERS_FAILURE]: getBilledFiltersFailure,
  [Types.GET_BILLED_FILTERS_SUCCESS]: getBilledFiltersSuccess,
  [Types.GET_BILLED_DATA_REQUEST]: getBilledDataRequest,
  [Types.GET_BILLED_DATA_FAILURE]: getBilledDataFailure,
  [Types.GET_BILLED_DATA_SUCCESS]: getBilledDataSuccess,
  [Types.GET_BILLINGS_DATA_REQUEST]: getBillingsDataRequest,
  [Types.GET_BILLINGS_DATA_FAILURE]: getBillingsDataFailure,
  [Types.GET_BILLINGS_DATA_SUCCESS]: getBillingsDataSuccess,
  [Types.SET_BILLED_FILTER]: setBilledFilter,
  [Types.RESET_BILLED]: resetBilled,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_BILLED_DIALOG]:
    setChangeShowHideColumnsBilledDialog,
})
