import React, { useEffect, useState } from 'react'

import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper,
  IconButton,
} from '@material-ui/core'

import { mdiPencil } from '@mdi/js'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as IslandsActions } from '~/store/ducks/islands'

import ListFooter from '~/components/ListFooter'
import Autocomplete from '~/components/Autocomplete'
import CustomIcon from '~/components/CustomIcon'
import IslandsDialog from './components/IslandsDialog'

import styles from './styles'

const dealershipsSelector = ({ islands }) => islands.dealerships

const useStyles = makeStyles(styles)

const IslandsSelector = ({ islands }) => islands

export default function IslandsPage() {
  const classes = useStyles()
  const [islandId, setIslandId] = useState(null)
  const [openIslandDialog, setOpenIslandDialog] = useState(false)
  const dispatch = useDispatch()

  const { data: dataDealerships, loading: loadingDealerships } =
    useSelector(dealershipsSelector)

  const { data, options } = useSelector(IslandsSelector)

  useEffect(() => {
    dispatch(IslandsActions.getIslandsRequest())
    dispatch(IslandsActions.getDealershipsIslandsRequest())
  }, [])

  const handleDealershipsFilterChange = (dealershipId) => {
    dispatch(IslandsActions.setIslandsDealershipsFilter(dealershipId))
  }

  const handleEditIsland = (id) => {
    setIslandId(id)
    setOpenIslandDialog(true)
  }

  const handleCloseIslandDialog = () => {
    setIslandId(null)
    setOpenIslandDialog(false)
    dispatch(IslandsActions.getIslandsRequest())
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(IslandsActions.setIslandsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(IslandsActions.setIslandsPage(page, options.limit))
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} style={{ marginBottom: 16 }}>
            <Autocomplete
              onChange={(_, option) => {
                handleDealershipsFilterChange(option ? option.value : null)
              }}
              options={dataDealerships.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              name="dealership"
              value={options.dealershipId}
              className={classes.autocomplete}
              label="Filtrar por concessionária"
              placeholder="Selecione uma concessionária"
              loading={loadingDealerships}
              autoSelect={false}
              size="small"
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            {data && (
              <Paper className={classes.paperTable}>
                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Id</TableCell> */}
                        <TableCell>Concessionária</TableCell>
                        <TableCell align="center">Departamento</TableCell>
                        <TableCell align="center">Ramal</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.data?.map((item) => {
                        return (
                          <TableRow className={classes.tableRow} key={item?.id}>
                            {/* <TableCell>{item.id}</TableCell> */}
                            <TableCell>{item.dealership?.name}</TableCell>
                            <TableCell align="center">
                              {item.department?.name}
                            </TableCell>
                            <TableCell align="center">
                              {item.ramal || '-'}
                            </TableCell>
                            <TableCell align="right">
                              <div className={classes.tableCellActions}>
                                <IconButton
                                  className={classes.editIcon}
                                  onClick={() => handleEditIsland(item.id)}
                                >
                                  <CustomIcon pathComponent={mdiPencil} />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid>
                <ListFooter
                  options={options}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onChangePage={handleChangePage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
      <IslandsDialog
        id={islandId}
        open={openIslandDialog}
        onClose={handleCloseIslandDialog}
      />
    </>
  )
}
