import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Button,
  // TableSortLabel,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { Controller, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import { Creators as DealershipActions } from '~/store/ducks/dealership'

import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import styles from './styles'

const useStyles = makeStyles(styles)

const allDepartmentsSelector = ({ dealership: { allDepartments } }) =>
  allDepartments

const departmentsSelector = ({ dealership: { departments } }) => departments

export default function DepartmentFormTab({ id }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isEdit = !!id
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()
  const {
    loading: allDepartmentsLoading,
    data: allDepartmentsData,
  } = useSelector(allDepartmentsSelector)
  const { data: departmentsData, loading } = useSelector(departmentsSelector)

  const [dealershipDepartmentId, setDealershipDepartmentId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffect(() => {
    if (isEdit) {
      dispatch(DealershipActions.getDealershipAllDepartmentsRequest())
      dispatch(DealershipActions.getDealershipDepartmentsRequest(id))
    }
  }, [isEdit])

  function realodDepartmentList() {
    dispatch(DealershipActions.getDealershipDepartmentsRequest(id))
  }

  function handleSubmitDepartmentFormTab(formData) {
    const formattedData = {
      ...formData,
      dealership_id: id,
    }
    dispatch(
      DealershipActions.addDealershipDepartmentRequest(formattedData, () => {
        realodDepartmentList()
      }),
    )
  }

  function handleResetForm() {
    reset({ department_id: '' })
  }

  function handleRemoveDepartment(departmentId) {
    setDealershipDepartmentId(departmentId)
    setOpenConfirmDialog(true)
  }

  function handleRemoveDealershipDepartmentCancel() {
    setDealershipDepartmentId(null)
    setOpenConfirmDialog(false)
  }

  function handleRemoveDealershipDepartmentAccept() {
    dispatch(
      DealershipActions.removeDealershipDepartmentRequest(
        dealershipDepartmentId,
        () => {
          realodDepartmentList()
          setOpenConfirmDialog(false)
          setDealershipDepartmentId(null)
        },
      ),
    )
  }

  return (
    <>
      <div className={classes.root}>
        {loading && <OverlayLoading />}
        {!loading && (
          <>
            <form
              id="form-dealership-department"
              onSubmit={handleSubmit(handleSubmitDepartmentFormTab)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="department_id"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { onChange, value, ...rest } }) => (
                      <TextField
                        variant="outlined"
                        select
                        autoFocus
                        label="Departamento"
                        type="text"
                        onChange={onChange}
                        error={!!errors.department_id}
                        value={value}
                        helperText={
                          !!errors.department_id && 'Campo obrigatório'
                        }
                        fullWidth
                        {...rest}
                      >
                        {allDepartmentsLoading && <OverlayLoading size={20} />}
                        {allDepartmentsData.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                className={classes.subjectButtonsWrapper}
              >
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleResetForm}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form="form-dealership-department"
                  >
                    Salvar departamento
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Portlet className={classes.portlet}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {/* <TableSortLabel
                      active={sort === 'name'}
                      onClick={() => handleSortChange('name')}
                      direction={direction}
                    > */}
                      Departamento
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departmentsData.length > 0 &&
                    sortBy(departmentsData, (d) => d.department.name).map(
                      (item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.department.name}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => handleRemoveDepartment(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </Portlet>
          </>
        )}
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este departamento?"
        onCancel={handleRemoveDealershipDepartmentCancel}
        onAccept={handleRemoveDealershipDepartmentAccept}
      />
    </>
  )
}

DepartmentFormTab.propTypes = {
  id: PropTypes.number,
}

DepartmentFormTab.defaultProps = {
  id: null,
}
