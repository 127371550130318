import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
  AttachMoney as RevenuesIcon,
  Money as CostIcon,
  TrendingUp as ProfitIcon,
} from '@material-ui/icons'

import { mdiPercent } from '@mdi/js'

import { last } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as AnalitycalPartsActions } from '~/store/ducks/analitycalParts'
import { formatPrice } from '~/helpers/masks'
import CustomIcon from '~/components/CustomIcon'
import AnalitycalCard from '~/components/AnalitycalCard'
import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'

import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import AnalitycalPartsFilters from './components/AnalyticalPartsFilters'
import styles from './styles'
import AnalitycalPartsDialog from './components/AnalitycalPartsDialog'

const analitycalPartsSelector = ({ analitycalParts }) => analitycalParts

const useStyles = makeStyles(styles)

function AnalitycalPartsPage() {
  const isSmallScreen = useSmallScreen()
  const [openAnalitycalPartsDialog, setOpenAnalitycalPartsDialog] =
    useState(false)
  const [typeDialog, setTypeDialog] = useState(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, showHideColumns, loading, dataPreviousMonthParts } =
    useSelector(analitycalPartsSelector)

  const optionsShowHideColumn = [
    { name: 'type', label: 'Tipo' },
    { name: 'billing', label: 'Faturamento' },
    { name: 'quantity', label: 'Quantidade' },
    { name: 'cost', label: 'Custo' },
    { name: 'liquidValue', label: 'Lucro líquido' },
    { name: 'porcentage', label: '%' },
  ]

  useEffect(() => {
    dispatch(AnalitycalPartsActions.getAnalitycalPartsRequest())
    dispatch(AnalitycalPartsActions.getFiltersAnalitycalPartsRequest())
    return () => {
      dispatch(AnalitycalPartsActions.resetAnalitycalParts())
    }
  }, [])

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      AnalitycalPartsActions.setChangeShowHideColumnsAnalitycalParts(
        newColumnsTable,
      ),
    )
  }

  const getColorTableCellProfit = (item) => {
    return item.profit < 0 ? 'red' : 'black'
  }

  const getColorTableCellProfitPorcentage = (item) => {
    return item.profit_percentage < 0 ? 'red' : 'black'
  }

  const getFontTotal = (segment) => {
    return segment === 'total' ? 'totalRow' : ''
  }

  const openDetails = (segment) => {
    // if (segment) {
    //   return
    // }
    setOpenAnalitycalPartsDialog(true)
    setTypeDialog(segment)
  }

  const handleClose = () => {
    setOpenAnalitycalPartsDialog(false)
    setTypeDialog(null)
  }

  const currentData = last(data)

  return (
    <>
      <div className={classes.root}>
        {isSmallScreen && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filterWropper}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <AnalitycalPartsFilters />
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && <AnalitycalPartsFilters />}
        {!loading && (
          <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="revenues"
                icon={<RevenuesIcon />}
                data={{
                  name: 'Faturamento',
                  value: currentData?.revenues,
                  previousValue: dataPreviousMonthParts?.revenues,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="cost"
                icon={<CostIcon />}
                data={{
                  name: 'Custo',
                  value: currentData?.cost,
                  previousValue: dataPreviousMonthParts?.cost,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="profit"
                icon={<ProfitIcon />}
                data={{
                  name: 'Lucro Líquido',
                  value: currentData?.profit,
                  previousValue: dataPreviousMonthParts?.profit,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <AnalitycalCard
                variant="profit_percentage"
                icon={<CustomIcon pathComponent={mdiPercent} />}
                data={{
                  name: 'Porcentagem de Lucro',
                  value: currentData?.profit_percentage,
                  previousValue: dataPreviousMonthParts?.profit_percentage,
                }}
              />
            </Grid>
          </Grid>
        )}
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {showHideColumns.type && (
                        <TableCell align="left">Tipo</TableCell>
                      )}
                      {showHideColumns.billing && (
                        <TableCell align="center">Faturamento</TableCell>
                      )}
                      {showHideColumns.quantity && (
                        <TableCell align="center">Quantidade</TableCell>
                      )}
                      {showHideColumns.cost && (
                        <TableCell align="center">Custo</TableCell>
                      )}
                      {showHideColumns.liquidValue && (
                        <TableCell align="center">Lucro Líquido</TableCell>
                      )}
                      {showHideColumns.porcentage && (
                        <TableCell align="center">%</TableCell>
                      )}

                      <TableCell className={classes.showHideColumns}>
                        <ShowHideColumns
                          showHideColumns={showHideColumns}
                          optionsShowHideColumn={optionsShowHideColumn}
                          onChangeShowHideColumn={handleSetChangeShowHideColumn}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow
                        hover
                        disabled={item.segment === 'total'}
                        key={item.segment}
                        className={[
                          classes.tableRow,
                          item.segment !== 'total' && classes.tableRowClicked,
                        ].join(' ')}
                        onClick={() => {
                          if (item.segment !== 'total') {
                            openDetails(item.segment)
                          }
                        }}
                      >
                        {showHideColumns.type && (
                          <TableCell
                            align="left"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {item.segment === 'balcao' && 'Balcão'}
                            {item.segment === 'atacado' && 'Atacado'}
                            {item.segment === 'oficina' && 'Oficina'}
                            {item.segment === 'total' && 'Total'}
                          </TableCell>
                        )}
                        {showHideColumns.billing && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {formatPrice(Number(item.revenues)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.quantity && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {item.amount || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.cost && (
                          <TableCell
                            align="center"
                            className={classes[getFontTotal(item.segment)]}
                          >
                            {formatPrice(Number(item.cost)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.liquidValue && (
                          <TableCell
                            className={classes[getFontTotal(item.segment)]}
                            style={{
                              color: getColorTableCellProfit(item),
                            }}
                            align="center"
                          >
                            {formatPrice(Number(item.profit)) || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.porcentage && (
                          <TableCell
                            className={classes[getFontTotal(item.segment)]}
                            align="center"
                            style={{
                              color: getColorTableCellProfitPorcentage(item),
                            }}
                          >
                            {`${parseFloat(item.profit_percentage || 0).toFixed(
                              2,
                            )}%` || '-'}
                          </TableCell>
                        )}
                        <TableCell className={classes.showHideColumns} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </Portlet>
      </div>
      <AnalitycalPartsDialog
        type={typeDialog}
        open={openAnalitycalPartsDialog}
        onClose={handleClose}
      />
    </>
  )
}

export default AnalitycalPartsPage
