import palette from '../palette'

export default {
  root: {
    '&$active': {
      color: palette.primary.main,
      '&& $icon': {
        color: palette.secondary.main,
      },
    },
  },
}
