import React, { useState, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import QrReader from 'react-qr-reader'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import Portlet from '~/components/Portlet'
import { Creators as EquipmentsActions } from '~/store/ducks/equipments'
import styles from './styles'
import EquipmentsDialog from '../EquipmentsPage/components/EquipmentsDialog'

const useStyles = makeStyles(styles)
const equipmentsSelector = ({ equipments }) => equipments

function EquipmentsQrCode() {
  const [currentEquipment, setCurrentEquipment] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [dataQrCode, setDataQrCode] = useState(null)

  const classes = useStyles()
  const dispatch = useDispatch()
  const { data } = useSelector(equipmentsSelector)

  useEffect(() => {
    dispatch(EquipmentsActions.getEquipmentsListQrCodeRequest())
  }, [])

  const handleScan = (qrCodeData) => {
    if (qrCodeData) {
      const equipment = data.find((item) => item.cod_equipment === qrCodeData)
      setCurrentEquipment(equipment)
      setOpenDialog(true)
      setDataQrCode(qrCodeData)
    }
  }

  const handleError = (err) => {
    console.error(err)
    dispatch(
      FlashMessageActions.showMessage({
        message: 'Algo inesperado aconteceu. Tento novamente.',
        variant: 'error',
      }),
    )
  }

  const handleClose = () => {
    setOpenDialog(false)
    setDataQrCode(null)
  }

  return (
    <Portlet>
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.instructions}>
          Para consultar um equipamento centralize o QrCode dentro da marcação
          indicada.
        </Typography>
        <QrReader delay={300} onError={handleError} onScan={handleScan} />
      </div>
      <EquipmentsDialog
        open={openDialog}
        onClose={handleClose}
        id={currentEquipment ? currentEquipment.id : null}
        codEquipment={dataQrCode}
      />
    </Portlet>
  )
}

export default EquipmentsQrCode
