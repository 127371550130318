import React from 'react'
import PropTypes from 'prop-types'
import { Chart, HighchartsChart, Legend, Title, XAxis, YAxis, Pane, SolidGaugeSeries } from 'react-jsx-highcharts'
// import { Typography } from '@material-ui/core'


const dataLabels = {
  format: '<div style="text-align:center"><span style="font-size:25px;color:black">{y}%</span><br/><span style="font-size:12px;color:silver"></span></div>',
  y: -50
};

function SolidGaugeChart({ data, labels, title, plotOptions}) {
  return (
    <>
      {/* <Typography variant="h5">{title}</Typography> */}
      <HighchartsChart gauge plotOptions={plotOptions}>
        <Pane
          center={['50%', '75%']}
          size='100%'
          startAngle={-90}
          endAngle={90}
          background={{
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }}
        />
        <Chart type="solidgauge" />
        <Title y={30}>{title}</Title>
        <XAxis  />
        <YAxis
          stops={[
            [0.3, '#DF5353'],
            [0.7, '#DDDF0D'],
            [0.8, '#55BF3B']
          ]}
          lineWidth={1}
          minorTickInterval={null}
          tickPixelInterval={400}
          tickWidth={1}
          tickInterval={20}
          min={0}
          max={120}>
          <YAxis.Title y={-110}>Faturados</YAxis.Title>
          <SolidGaugeSeries name="Faturados" dataLabels={dataLabels} data={data.map((y, i) => ({ name: labels[i], y }))} />
        </YAxis>
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      </HighchartsChart>
    </>
  )
}

SolidGaugeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ])),
  plotOptions: PropTypes.shape({
    pie: PropTypes.shape({
      shadow: PropTypes.bool,
      innerSize: PropTypes.string,
      dataLabels: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      showInLegend: PropTypes.bool
    })
  }),
  // color: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
}

SolidGaugeChart.defaultProps = {
  plotOptions: {
    pie: {
      dataLabels: {
          enabled: true,
          distance: -50,
          style: {
              fontWeight: 'bold',
              color: 'white'
          }
      },
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '75%'],
      size: '110%'
  }
  },
  data: [],
  // color: [],
  title: 'Title',
  labels: []
}

export default SolidGaugeChart

