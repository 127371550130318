export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  iconAdd: {
    marginRight: 8,
  },
  buttonAdd: {
    minHeight: 40,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
    },
  },
  grow: {
    flex: 1,
    marginTop: theme.spacing(3),
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  descriptionCell: {
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    padding: 0,
  },
  editIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  actionsCell: {
    padding: 0,
  },
  actionsWrapper: {
    display: 'flex',
  },
  gridContainerButton: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
})
