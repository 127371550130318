export default (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterWropper: {
    display: 'flex',
  },
})
