import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import styles from './styles'

const useStyles = makeStyles(styles)

const StatusDialog = ({ open, onClose, data }) => {
  const isMobileScreen = useMobileScreen()

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Colaboradores do recibo</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.dialogContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.user.name}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          VOLTAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

StatusDialog.defaultProps = {
  data: [],
}

export default StatusDialog
