import { all } from 'redux-saga/effects'

import sessionSaga from './session'
import dealershipsSaga from './dealerships'
import dealershipSaga from './dealership'
import departmentsSaga from './departments'
import departmentSaga from './department'
import functionsSaga from './functions'
import functionSaga from './function'
import superiorsSaga from './superiors'
import superiorSaga from './superior'
import statusListSaga from './statusList'
import statusSaga from './status'
import equipmentsTypesSaga from './equipmentsTypes'
import equipmentTypeSaga from './equipmentType'
import equipmentsSaga from './equipments'
import equipmentSaga from './equipment'
import callsSaga from './calls'
import callSaga from './call'
import usersSaga from './users'
import userSaga from './user'
import quizzesSaga from './quizzes'
import quizzeSaga from './quiz'
import salesGoalsSaga from './salesGoals'
import objectivesSaga from './objectives'
import objectiveSaga from './objective'
import helpdeskDashboardSaga from './helpdeskDashboard'
import sellersSaga from './sellers'
import rhMonthComparativeSaga from './rhMonthComparative'
import billedSaga from './billed'
import dailySalesSaga from './dailySales'
import biDashboardSaga from './biDashboard'
import dashboardSaga from './dashboard'
import biSellersDashboardSaga from './biSellersDashboard'
import newsBoardSaga from './newsBoard'
import organogramSaga from './organogram'
import rhDashboardSaga from './rhDashboard'
import equipmentDashboardSaga from './equipmentDashboard'
import surveysSaga from './surveys'
import billedMonthComparativeSaga from './billedMonthComparative'
import analitycalSaga from './analitycal'
import analitycalPartsSaga from './analitycalParts'
import analitycalDashboardSaga from './analitycalDashboard'
import dailyEmails from './dailyEmails'
import newsSingle from './newsSingle'
import notificationsSaga from './notifications'
import profileSaga from './profile'
import blipSaga from './blip'
import ipc from './ipc'
import pops from './pops'
import receipt from './receipt'
import usersRamal from './usersRamal'
import islands from './islands'

export default function* rootSaga() {
  yield all([
    sessionSaga(),
    dealershipsSaga(),
    departmentsSaga(),
    departmentSaga(),
    dealershipSaga(),
    functionsSaga(),
    superiorsSaga(),
    superiorSaga(),
    statusListSaga(),
    statusSaga(),
    equipmentsTypesSaga(),
    equipmentTypeSaga(),
    equipmentsSaga(),
    equipmentSaga(),
    functionSaga(),
    callsSaga(),
    callSaga(),
    usersSaga(),
    userSaga(),
    quizzesSaga(),
    quizzeSaga(),
    salesGoalsSaga(),
    objectivesSaga(),
    objectiveSaga(),
    helpdeskDashboardSaga(),
    sellersSaga(),
    rhMonthComparativeSaga(),
    billedSaga(),
    dailySalesSaga(),
    biDashboardSaga(),
    dashboardSaga(),
    biSellersDashboardSaga(),
    newsBoardSaga(),
    organogramSaga(),
    rhDashboardSaga(),
    equipmentDashboardSaga(),
    surveysSaga(),
    billedMonthComparativeSaga(),
    analitycalSaga(),
    analitycalPartsSaga(),
    analitycalDashboardSaga(),
    dailyEmails(),
    newsSingle(),
    notificationsSaga(),
    profileSaga(),
    blipSaga(),
    ipc(),
    pops(),
    receipt(),
    usersRamal(),
    islands(),
  ])
}
