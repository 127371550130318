import React, { useState, useEffect } from 'react'

import {
  Grid,
  Tabs,
  Tab,
  Button,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import history from '~/helpers/history'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import TabPanel from '~/components/TabPanel'

import { Creators as UserActions } from '~/store/ducks/user'
import Portlet from '~/components/Portlet'

import SubjectUserFormTab from './components/SubjectUserFormTab'
import ContractsFormTab from './components/ContractsFormTab'
import UserDataFormTab from './components/UserDataFormTab'
import EquipmentsFormTab from './components/EquipmentsFormTab'
import ReceiptUserTab from './components/ReceiptUserTab'

import styles from './styles'

const useStyles = makeStyles(styles)

const sessionSelector = ({ session }) => session
const receiptsUserSelector = ({ user }) => user

export default function NewUserPage({
  match: {
    params: { id, mode },
  },
}) {
  const {
    user: { roles },
  } = useSelector(sessionSelector)
  const { dataReceipts } = useSelector(receiptsUserSelector)

  const userRoles = roles.map((r) => r.name)
  const hasEditPermission = !userRoles.includes('rh_consult')
  const classes = useStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(0)
  const isEdit = !!id && mode === 'edit'
  const isView = !!id && mode === 'view'
  const isSmallScreen = useSmallScreen()
  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  useEffect(() => {
    setCurrentTab(0)
    dispatch(UserActions.getUserDealershipsRequest())
    dispatch(UserActions.getUserRolesRequest())
    dispatch(UserActions.getUserBankRequest())
    dispatch(UserActions.getUserFunctionRequest())
    dispatch(UserActions.getReceiptsUsersRequest(id))

    if (isEdit || isView) {
      dispatch(UserActions.getUserRequest(id))
      dispatch(UserActions.getUserFunctionsListRequest(id))
      dispatch(UserActions.getUserVwAttendantsRequest(id))
      dispatch(UserActions.getReceiptsUsersRequest(id))

      setCurrentTab(isView ? 0 : 1)
    }
  }, [isEdit, id])

  const announceHiring = () => {
    dispatch(UserActions.announceHiringRequest(id))
  }
  const announceResignation = () => {
    dispatch(UserActions.announceResignationRequest(id))
  }

  return (
    <div className={classes.root}>
      <Portlet className={classes.grow}>
        {!isEdit && !isView ? (
          <>
            <Grid container className={classes.subtitleWrapper}>
              <Grid item xs={12}>
                <Typography variant="h5">Dados</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.dividerBottom} />
          </>
        ) : (
          <Tabs
            variant="fullWidth"
            value={currentTab}
            onChange={handleTabsChange}
          >
            <Tab label="Dados" />
            <Tab label="Contratos" disabled={!isEdit && !isView} />
            <Tab label="Assuntos" disabled={!isEdit && !isView} />
            <Tab label="Equipamentos" disabled={!isEdit && !isView} />
            <Tab label="Recibos" disabled={!isEdit && !isView} />
          </Tabs>
        )}

        <Grid container className={classes.container}>
          <div className={classes.tabsWrapper}>
            <TabPanel index={0} value={currentTab}>
              <UserDataFormTab id={id} mode={mode} />
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <ContractsFormTab id={id} mode={mode} />
            </TabPanel>
            <TabPanel index={2} value={currentTab}>
              <SubjectUserFormTab />
            </TabPanel>
            <TabPanel index={3} value={currentTab}>
              <EquipmentsFormTab id={id} />
            </TabPanel>{' '}
            <TabPanel index={4} value={currentTab}>
              <ReceiptUserTab idUser={id} data={dataReceipts.data} />
            </TabPanel>
          </div>
        </Grid>
        <Divider />
        <Grid
          container
          justifyContent="space-between"
          className={classes.actionsWrapper}
          spacing={2}
        >
          <Grid item xs={12} md={3}>
            <Button
              fullWidth={isSmallScreen}
              variant="contained"
              onClick={() => history.push('/users')}
            >
              Voltar
            </Button>
          </Grid>
          {!isEdit && !isView && (
            <Grid item xs={12} md={3} className={classes.saveButton}>
              <Button
                fullWidth={isSmallScreen}
                variant="contained"
                color="primary"
                type="submit"
                form="user-data-form"
              >
                Salvar colaborador
              </Button>
            </Grid>
          )}

          {currentTab === 0 && isEdit && (
            <>
              <Grid item xs={12} md={3} className={classes.announceResignation}>
                <Button
                  fullWidth={isSmallScreen}
                  variant="contained"
                  onClick={() => announceResignation()}
                >
                  <SentimentVeryDissatisfiedIcon className={classes.Icon} />
                  Anunciar Desligamento
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth={isSmallScreen}
                  variant="contained"
                  color="primary"
                  onClick={() => announceHiring()}
                >
                  <InsertEmoticonIcon className={classes.Icon} />
                  Anunciar Contratação
                </Button>
              </Grid>
              <Grid item xs={12} md={3} className={classes.saveButton}>
                <Button
                  fullWidth={isSmallScreen}
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="user-data-form"
                >
                  Salvar colaborador
                </Button>
              </Grid>
            </>
          )}
          {isView && hasEditPermission && (
            <Grid item xs={12} md={3} className={classes.saveButton}>
              <Button
                onClick={() => {
                  history.push(`/users/${id}/edit`)
                }}
                fullWidth={isSmallScreen}
                variant="contained"
                color="primary"
              >
                Editar Colaborador
              </Button>
            </Grid>
          )}
        </Grid>
      </Portlet>
    </div>
  )
}

NewUserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      mode: PropTypes.string,
    }),
  }).isRequired,
}
