import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Avatar,
  Divider,
  makeStyles,
} from '@material-ui/core'
import { BsCircleFill as CircleIcon } from 'react-icons/bs'

import moment from 'moment'
import { useSelector } from 'react-redux'

import AvatarNotification from '~/assets/images/avatar.png'

import styles from './styles'

const SessionActionsSelector = ({ session }) => session

const useStyles = makeStyles(styles)

export default function NotificationsList({ item, readGeneral }) {
  const classes = useStyles()
  const { user } = useSelector(SessionActionsSelector)

  const slugDocumentTypes = {
    advancePayment: 'Adiantamento',
    receipt: 'Recibo',
    vacation: 'Férias',
    salary: 'Salário',
    bonus: 'Décimo Terceiro',
    reportIncome: 'Relatório de Rendimentos',
  }

  const textMessage = () => {
    if (item.context.month > 9) {
      return `Você tem um novo recibo de   ${
        slugDocumentTypes[item.context.document_type]
      } referente a ${item.context.month}/${item.context.year}`
    }
    return `Você tem um novo recibo de   ${
      slugDocumentTypes[item.context.document_type]
    } referente a 0${item.context.month}/${item.context.year}`
  }

  return (
    <>
      <Grid
        container
        key={item?.id}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => readGeneral(item)}
      >
        <Grid item xs={12}>
          <div className={classes.content}>
            {item.type === 'birthday' ||
              (item.type === 'company_anniversary' &&
                user.name !== item.context.name && (
                  <Avatar
                    className={classes.avatar}
                    src={item?.context?.url_image}
                    alt={item?.context?.name}
                  />
                ))}

            {item.type === 'birthday' && (
              <Avatar
                className={classes.avatar}
                src={item?.context?.url_image}
                alt={item?.context?.name}
              />
            )}

            {item.type === 'company_anniversary' &&
              user.name === item.context.name && (
                <Avatar
                  style={{ backgroundColor: '#1b1464' }}
                  className={classes.avatar}
                  src={AvatarNotification}
                  alt={item?.sender?.name}
                />
              )}

            {item.type !== 'birthday' &&
              item.type !== 'company_anniversary' && (
                <Avatar
                  className={classes.avatar}
                  src={item?.sender?.url_image}
                  alt={item?.sender?.name}
                />
              )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {item.type === 'birthday' ||
                  (item.type === 'company_anniversary' &&
                    user.name !== item.context.name && (
                      <Typography variant="h6" style={{ marginRight: 8 }}>
                        {item?.context?.name}
                      </Typography>
                    ))}

                {item.type === 'birthday' && (
                  <Typography variant="h6" style={{ marginRight: 8 }}>
                    {item?.context?.name}
                  </Typography>
                )}

                {item.type === 'birthday' ||
                  (item.type === 'company_anniversary' &&
                    user.name === item.context.name && (
                      <Typography variant="h6" style={{ marginRight: 8 }}>
                        Grupo Nissul Gala
                      </Typography>
                    ))}

                {item.type !== 'birthday' &&
                  item.type !== 'company_anniversary' && (
                    <Typography variant="h6" style={{ marginRight: 8 }}>
                      {item?.sender?.name}
                    </Typography>
                  )}

                {item.status === 'unread' ? (
                  <CircleIcon size={4} color="#ED1E79" />
                ) : (
                  ''
                )}
                {item.created_at !== null && (
                  <Typography variant="body2" style={{ marginLeft: 8 }}>
                    {moment(
                      moment(item?.created_at).format('YYYY-MM-DD'),
                    ).isSame(moment(new Date()).format('YYYY-MM-DD'), 'day')
                      ? moment(item?.created_at).format('HH:mm')
                      : moment(item?.created_at).format('DD/MM')}
                  </Typography>
                )}
              </div>
              {item?.type === 'reaction' && (
                <Typography variant="body2">
                  <span>
                    {item?.message
                      .replace('like', '👍')
                      .replace('love', '❤️')
                      .replace('wow', '😮')
                      .replace('haha', '😆')
                      .replace('clap', '👏')
                      .replace('fire', ' 🔥')}
                  </span>
                </Typography>
              )}
              {item?.type === 'comment' && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 500 }}>
                    Comentou a sua publicação:
                  </span>
                  <span> {item?.message}</span>
                </Typography>
              )}
              {item?.type === 'news' && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 500 }}>Fez uma publicação:</span>
                  <span> {item?.message}</span>
                </Typography>
              )}
              {item?.type === 'rh_document' && (
                <>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 500 }}>{textMessage()}</span>
                  </Typography>
                </>
              )}
              {item?.type === 'birthday' && (
                <>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 500 }}>
                      Está de aniversário hoje. 🎉
                    </span>
                  </Typography>
                  {/* <Typography variant="body2">
                    Ajude a tornar seu dia mais feliz! Envie felicitações!
                  </Typography> */}
                </>
              )}
              {item?.type === 'congratulate_birthday' && (
                <>
                  <Typography variant="body2">
                    <span>Te desejou um feliz aniversário! 🎉</span>
                  </Typography>
                </>
              )}
              {item?.type === 'company_anniversary' &&
                user.name !== item.context.name && (
                  <>
                    <Typography variant="body2">
                      <span>{item.message}</span>
                    </Typography>
                  </>
                )}
              {item?.type === 'company_anniversary' &&
                user.name === item.context.name && (
                  <>
                    <Typography variant="body2">
                      <span>
                        Parabéns, hoje você está completando{' '}
                        {item.context.years} anos de Nissul Gala! 🎉
                      </span>
                    </Typography>
                  </>
                )}
              {item?.type === 'mention' && (
                <Typography variant="body2">
                  <span>Mencionou você em uma publicação.</span>
                </Typography>
              )}
              {item?.type === 'congratulate_anniversary' && (
                <Typography variant="body2">
                  <span>
                    Cumprimentou você por estar completando aniversário de
                    empresa!{' '}
                  </span>
                </Typography>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}

NotificationsList.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    sender: PropTypes.arrayOf(PropTypes.shape({})),
    context: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  readGeneral: PropTypes.func.isRequired,
}
