import { head } from 'lodash'

const normalizedOldSubordinates = (data) => {
  const oldSubordinates = data?.map((item) => {
    return {
      id: item.id,
      person: {
        id: item.id,
        avatar: item.imageUrl,
        department: 'teste',
        name: item.away > 0 ? `${item.name} (Afastado)` : item.name,
        title: item.designation,
        totalReports: item.old_subordinates ? item.old_subordinates.length : 0,
        away: item.away > 0,
      },
      hasChild: !!item.subordinates,
      hasParent: !!item.parent_id,
      children: item.old_subordinates
        ? normalizedOldSubordinates(item.old_subordinates)
        : [],
    }
  })

  return oldSubordinates
}

export const normalizedChildren = (data) => {
  const formattedData = data?.map((item) => ({
    id: item.id,
    person: {
      id: item.id,
      avatar: item.imageUrl,
      department: 'teste',
      name: item.away > 0 ? `${item.name} (Afastado)` : item.name,
      title: item.designation,
      totalReports: item.old_subordinates.length,
      away: item.away > 0,
    },
    hasChild: !!item.old_subordinates,
    hasParent: !!item.parent_id,
    children: item.old_subordinates
      ? normalizedOldSubordinates(item.old_subordinates)
      : [],
    parentId: item.parent_id,
  }))

  return formattedData
}

export const normalizedOrganogram = (data) => {
  const person = head(data)
  return {
    id: person.id,
    person: {
      id: person.id,
      avatar: person.imageUrl,
      department: 'teste',
      name: person.away > 0 ? `${person.name} (Afastado)` : person.name,
      title: person.designation,
      totalReports: person.subordinates.length,
      away: person.away > 0,
      // link: ',
    },
    hasChild: !!(person.subordinates.length > 0),
    hasParent: !!person.parent_id,
    // children: [],
    children: person.subordinates
      ? normalizedChildren(person.subordinates)
      : [],
  }
}
