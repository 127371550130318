import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'
import moment from 'moment'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as StatusListActions } from '~/store/ducks/statusList'
import { Creators as StatusActions } from '~/store/ducks/status'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import CustomIcon from '~/components/CustomIcon'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import StatusDialog from './components/StatusDialog'
import styles from './styles'

const statusSelector = ({ statusList }) => statusList

const useStyles = makeStyles(styles)

function StatusPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, options, loading } = useSelector(statusSelector)
  const [statusId, setStatusId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffect(() => {
    dispatch(StatusListActions.getStatusListRequest())
  }, [])

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(StatusListActions.setStatusPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(StatusListActions.setStatusPage(page, options.limit))
  }

  const handleEditStatus = (id) => {
    setStatusId(id)
    setOpenDialog(true)
  }
  const handleAddStatus = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
    setStatusId(null)
  }

  const handleRemoveStatus = (id) => {
    setStatusId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setStatusId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      StatusActions.removeStatusRequest(statusId, () => {
        dispatch(StatusListActions.getStatusListRequest())
        setStatusId(null)
        setOpenConfirmDialog(false)
      }),
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.gridContainerButton}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleAddStatus}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Status
            </Button>
          </Grid>
        </Grid>
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Data de Cadastro</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                        >
                          <TableCell>{item.name || '-'}</TableCell>
                          <TableCell>
                            {moment(item.created_at || '-').format(
                              'DD/MM/YYYY HH:mm',
                              'HH/MM',
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <div className={classes.tableCellActions}>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() => handleEditStatus(item.id)}
                              >
                                <CustomIcon pathComponent={mdiPencil} />
                              </IconButton>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => handleRemoveStatus(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>
      <StatusDialog open={openDialog} onClose={handleClose} id={statusId} />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este status?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}

export default StatusPage
