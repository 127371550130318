export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  instructions: {
    marginBottom: theme.spacing(2),
  },
})
