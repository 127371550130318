import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSellersRequest: [],
  getSellersFailure: [],
  getSellersSuccess: ['data', 'total'],
  setSellersSort: ['sort', 'direction'],
  setSellersPage: ['page', 'limit'],
  setSellersDate: ['value'],
  resetSellers: [],
  getDealershipsSellersRequest: [],
  getDealershipsSellersSuccess: ['data'],
  getDealershipsSellersFailure: [],
  setSellersDealershipFilter: ['dealershipId'],
  setChangeShowHideColumnsSellers: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    sort: 'nome',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
    dateValue: new Date(),
    dealershipId: null,
  },
  dealerships: {
    data: [],
    loading: false,
  },
  data: [],
  loading: false,
  showHideColumns: {
    name: true,
    dealership: true,
    month1: true,
    month2: true,
    month3: true,
    month4: true,
    month5: true,
    currentMonth: true,
    avaliation: true,
    media: true,
  },
}

const getSellersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSellersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getSellersSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setSellersSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setSellersPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setSellersDate = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    dateValue: value,
  },
})

const resetSellers = () => ({
  ...INITIAL_STATE,
})

const getDealershipsSellersRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})
const getDealershipsSellersSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getDealershipsSellersFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const setSellersDealershipFilter = (
  state = INITIAL_STATE,
  { dealershipId },
) => ({
  ...state,
  options: {
    ...state.options,
    dealershipId,
  },
})

const setChangeShowHideColumnsSellers = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableSellers',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_SELLERS_REQUEST]: getSellersRequest,
  [Types.GET_SELLERS_FAILURE]: getSellersFailure,
  [Types.GET_SELLERS_SUCCESS]: getSellersSuccess,
  [Types.SET_SELLERS_SORT]: setSellersSort,
  [Types.SET_SELLERS_PAGE]: setSellersPage,
  [Types.SET_SELLERS_DATE]: setSellersDate,
  [Types.RESET_SELLERS]: resetSellers,
  [Types.GET_DEALERSHIPS_SELLERS_REQUEST]: getDealershipsSellersRequest,
  [Types.GET_DEALERSHIPS_SELLERS_SUCCESS]: getDealershipsSellersSuccess,
  [Types.GET_DEALERSHIPS_SELLERS_FAILURE]: getDealershipsSellersFailure,
  [Types.SET_SELLERS_DEALERSHIP_FILTER]: setSellersDealershipFilter,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_SELLERS]: setChangeShowHideColumnsSellers,
})
