import { call, takeLatest, put } from 'redux-saga/effects'
// import { get } from 'lodash'
import configureApi from '~/helpers/api'

// import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'

import {
  Types as NotificationsTypes,
  Creators as NotificationsActions,
} from '../ducks/notifications'

function* getNotifications() {
  try {
    const api = configureApi()

    const { data } = yield call(api.get, 'notifications/me', {
      params: {
        pagination: 0,
      },
    })
    yield put(NotificationsActions.getNotificationsSuccess(data))
  } catch (e) {
    yield put(NotificationsActions.getNotificationsFailure())
  }
}

function* handleReadNotification({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `notifications/read/${id}`)

    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(NotificationsActions.handleReadNotificationFinish())
  }
}

// function* getCongratulations({ id }) {
//   try {
//     const api = configureApi()

//     yield call(api.put, `notifications/happy-birthday/${id}`)
//     yield put(NotificationsActions.getCongratulationsBirthdaySuccess())
//     yield put(
//       FlashMessageActions.showMessage({
//         message: 'Felicitações enviadas com sucesso!',
//         variant: 'success',
//       }),
//     )
//   } catch (e) {
//     yield put(NotificationsActions.getCongratulationsBirthdayFailure())
//     const error = get(e, 'response.data.error[0]')
//     yield put(
//       FlashMessageActions.showMessage({
//         message: error || 'Ops! Algo deu errado. Tente novamente',
//         variant: 'error',
//       }),
//     )
//   }
// }

// function* getCongratulationsCompanyAnniversary({ id }) {
//   try {
//     const api = configureApi()

//     yield call(api.put, `notifications/happy-anniversary/${id}`)
//     yield put(
//       NotificationsActions.getCongratulationsCompanyAnniversarySuccess(),
//     )
//     yield put(
//       FlashMessageActions.showMessage({
//         message: 'Felicitações enviadas com sucesso!',
//         variant: 'success',
//       }),
//     )
//   } catch (e) {
//     yield put(
//       NotificationsActions.getCongratulationsCompanyAnniversaryFailure(),
//     )
//     const error = get(e, 'response.data.error[0]')
//     yield put(
//       FlashMessageActions.showMessage({
//         message: error || 'Ops! Algo deu errado. Tente novamente',
//         variant: 'error',
//       }),
//     )
//   }
// }

function* readAllNotifications() {
  try {
    const api = configureApi()

    const { data } = yield call(api.get, 'notifications/read-all')

    yield put(NotificationsActions.getNotificationsRequest())

    yield put(NotificationsActions.readAllNotificationsFinish(data))
  } catch (e) {
    yield put(NotificationsActions.readAllNotificationsFinish())
  }
}

export default function* () {
  yield takeLatest(
    NotificationsTypes.GET_NOTIFICATIONS_REQUEST,
    getNotifications,
  )
  yield takeLatest(
    NotificationsTypes.HANDLE_READ_NOTIFICATION_REQUEST,
    handleReadNotification,
  )
  // yield takeLatest(
  //   NotificationsTypes.GET_CONGRATULATIONS_BIRTHDAY_REQUEST,
  //   getCongratulations,
  // )
  // yield takeLatest(
  //   NotificationsTypes.GET_CONGRATULATIONS_COMPANY_ANNIVERSARY_REQUEST,
  //   getCongratulationsCompanyAnniversary,
  // )
  yield takeLatest(
    NotificationsTypes.READ_ALL_NOTIFICATIONS_REQUEST,
    readAllNotifications,
  )
}
