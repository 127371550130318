import React from 'react'

export const reactions = [
  {
    label: 'haha',
    node: <div>😄</div>,
  },
  {
    label: 'like',
    node: <div>👍</div>,
  },
  {
    label: 'love',
    node: <div>❤️</div>,
  },
  {
    label: 'wow',
    node: <div>😮</div>,
  },
  {
    label: 'fire',
    node: <div>🔥</div>,
  },
  {
    label: 'clap',
    node: <div>👏</div>,
  },
]
