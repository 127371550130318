import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getObjectivesRequest: [],
  getObjectivesFailure: [],
  getObjectivesSuccess: ['data', 'total'],
  setObjectivesSort: ['sort', 'direction'],
  setObjectivesPage: ['page', 'limit'],
  removeObjectiveRequest: ['id', 'callback'],
  removeObjectiveFinish: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'id',
    direction: 'desc',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const getObjectivesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getObjectivesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getObjectivesSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setObjectivesSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    pagination: 0,
    sort,
    direction,
  },
})

const setObjectivesPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const removeObjectiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeObjectiveFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_OBJECTIVES_REQUEST]: getObjectivesRequest,
  [Types.GET_OBJECTIVES_FAILURE]: getObjectivesFailure,
  [Types.GET_OBJECTIVES_SUCCESS]: getObjectivesSuccess,
  [Types.SET_OBJECTIVES_SORT]: setObjectivesSort,
  [Types.SET_OBJECTIVES_PAGE]: setObjectivesPage,
  [Types.REMOVE_OBJECTIVE_REQUEST]: removeObjectiveRequest,
  [Types.REMOVE_OBJECTIVE_FINISH]: removeObjectiveFinish,
})
