import palette from '../palette'

export default {
  root: {
    fontSize: 12,
    fontWeight: 500,
  },
  colorDefault: {
    backgroundColor: palette.primary.main,
  },
}
