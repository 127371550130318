import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as DepartmentTypes,
  Creators as DepartmentActions,
} from '../ducks/department'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSubjectsSelector = ({ department: { subjects } }) =>
  subjects.options

function* removeDepartment({ id, callback }) {
  try {
    const api = configureApi()

    yield call(api.delete, `departments/${id}`)

    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Departamento removido com sucesso!',
        variant: 'success',
      }),
    )
    yield put(DepartmentActions.removeDepartmentFinish())
  } catch (e) {
    yield put(DepartmentActions.removeDepartmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addDepartment({ data, callback }) {
  try {
    const api = configureApi()
    const formattedData = {
      ...data,
      dealership_id: null,
    }
    yield call(api.post, 'departments', formattedData)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Departamento adicionado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(DepartmentActions.addDepartmentFinish())
  } catch (e) {
    yield put(DepartmentActions.addDepartmentFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDepartment({ id }) {
  try {
    const api = configureApi()

    const { data } = yield call(api.get, `departments/${id}`)
    yield put(DepartmentActions.getDepartmentSuccess(data))
  } catch (e) {
    yield put(DepartmentActions.getDepartmentFailure())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDeparmentSubjects({ id }) {
  try {
    const api = configureApi()
    const options = yield select(optionsSubjectsSelector)
    const { data } = yield call(api.get, `subjects`, {
      params: {
        pagination: 0,
        order: `${options.sort},${options.direction}`,
        'where[department_id]': id,
      },
    })
    yield put(DepartmentActions.getDepartmentSubjectsSuccess(data))
  } catch (e) {
    yield put(DepartmentActions.getDepartmentSubjectsFailure())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addDepartmentSubject({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'subjects', data)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Assunto adicionado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(DepartmentActions.addDepartmentSubjectFinish())
  } catch (e) {
    yield put(DepartmentActions.addDepartmentSubjectFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateDepartmentSubject({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `subjects/${id}`, data)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Assunto editado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(DepartmentActions.updateDepartmentSubjectFinish())
  } catch (e) {
    yield put(DepartmentActions.updateDepartmentSubjectFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeDepartmentSubject({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `subjects/${id}`)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Assunto removido com sucesso!',
        variant: 'success',
      }),
    )

    yield put(DepartmentActions.removeDepartmentSubjectFinish())
  } catch (e) {
    yield put(DepartmentActions.removeDepartmentSubjectFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadDepartmentSubjects({ id }) {
  yield put(DepartmentActions.getDepartmentSubjectsRequest(id))
}

function* getDepartmentAttendants() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'get_attendants')
    yield put(DepartmentActions.getDepartmentAttendantsSuccess(data))
  } catch (e) {
    yield put(DepartmentActions.getDepartmentAttendantsFailure())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addDepartmentAttendant({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'attendants', data)

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Atendente adicionado com sucesso!',
        variant: 'success',
      }),
    )

    yield put(DepartmentActions.addDepartmentAttendantFinish())
  } catch (e) {
    yield put(DepartmentActions.addDepartmentAttendantFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDepartmentVwAttendants({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `vw_attendants`, {
      params: {
        pagination: 0,
        'where[department_id]': id,
      },
    })
    yield put(DepartmentActions.getDepartmentVwAttendantsSuccess(data))
  } catch (e) {
    yield put(DepartmentActions.getDepartmentVwAttendantsFailure())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeDepartmentAttendant({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `attendants/${id}`)
    if (callback) {
      yield call(callback)
    }

    yield put(DepartmentActions.removeDepartmentAttendantFinish())
  } catch (e) {
    yield put(DepartmentActions.removeDepartmentAttendantFinish())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateDepartment({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `/departments/${id}`, data)
    yield put(DepartmentActions.updateDepartmentFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Departamento editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(DepartmentActions.updateDepartmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(DepartmentTypes.REMOVE_DEPARTMENT_REQUEST, removeDepartment)
  yield takeLatest(DepartmentTypes.ADD_DEPARTMENT_REQUEST, addDepartment)
  yield takeLatest(DepartmentTypes.GET_DEPARTMENT_REQUEST, getDepartment)
  yield takeLatest(
    DepartmentTypes.GET_DEPARTMENT_SUBJECTS_REQUEST,
    getDeparmentSubjects,
  )
  yield takeLatest(
    DepartmentTypes.ADD_DEPARTMENT_SUBJECT_REQUEST,
    addDepartmentSubject,
  )

  yield takeLatest(
    DepartmentTypes.UPDATE_DEPARTMENT_SUBJECT_REQUEST,
    updateDepartmentSubject,
  )

  yield takeLatest(
    DepartmentTypes.REMOVE_DEPARTMENT_SUBJECT_REQUEST,
    removeDepartmentSubject,
  )

  yield takeLatest(
    DepartmentTypes.SET_DEPARTMENT_SUBJECTS_SORT,
    reloadDepartmentSubjects,
  )

  yield takeLatest(
    DepartmentTypes.GET_DEPARTMENT_ATTENDANTS_REQUEST,
    getDepartmentAttendants,
  )

  yield takeLatest(
    DepartmentTypes.ADD_DEPARTMENT_ATTENDANT_REQUEST,
    addDepartmentAttendant,
  )

  yield takeLatest(
    DepartmentTypes.GET_DEPARTMENT_VW_ATTENDANTS_REQUEST,
    getDepartmentVwAttendants,
  )

  yield takeLatest(
    DepartmentTypes.REMOVE_DEPARTMENT_ATTENDANT_REQUEST,
    removeDepartmentAttendant,
  )
  yield takeLatest(DepartmentTypes.UPDATE_DEPARTMENT_REQUEST, updateDepartment)
}
