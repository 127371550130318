import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getNewsSingleRequest: ['id'],
  getNewsSingleSuccess: ['data'],
  getNewsSingleFailure: [],
})

const INITIAL_STATE = {
  data: {
    comments: [],
    reactions: [],
  },
  loading: false,
}

const getNewsSingleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getNewsSingleSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data: { ...state.data, ...data },
  loading: false,
})

const getNewsSingleFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_NEWS_SINGLE_REQUEST]: getNewsSingleRequest,
  [Types.GET_NEWS_SINGLE_SUCCESS]: getNewsSingleSuccess,
  [Types.GET_NEWS_SINGLE_FAILURE]: getNewsSingleFailure,
})
