import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addDepartmentRequest: ['data', 'callback'],
  addDepartmentFinish: [],
  removeDepartmentRequest: ['id', 'callback'],
  removeDepartmentFinish: [],
  getDepartmentRequest: ['id'],
  getDepartmentSuccess: ['data'],
  getDepartmentFailure: [],
  getDepartmentSubjectsRequest: ['id'],
  getDepartmentSubjectsSuccess: ['data'],
  getDepartmentSubjectsFailure: [],
  setDepartmentSubjectsSort: ['id', 'sort', 'direction'],
  addDepartmentSubjectRequest: ['data', 'callback'],
  addDepartmentSubjectFinish: [],
  updateDepartmentSubjectRequest: ['id', 'data', 'callback'],
  updateDepartmentSubjectFinish: [],
  removeDepartmentSubjectRequest: ['id', 'callback'],
  removeDepartmentSubjectFinish: [],
  getDepartmentAttendantsRequest: [],
  getDepartmentAttendantsSuccess: ['data'],
  getDepartmentAttendantsFailure: [],
  addDepartmentAttendantRequest: ['data', 'callback'],
  addDepartmentAttendantFinish: [],
  getDepartmentVwAttendantsRequest: ['id'],
  getDepartmentVwAttendantsSuccess: ['data'],
  getDepartmentVwAttendantsFailure: [],
  removeDepartmentAttendantRequest: ['id', 'callback'],
  removeDepartmentAttendantFinish: [],
  updateDepartmentRequest: ['id', 'data', 'callback'],
  updateDepartmentFinish: [],
})

const INITIAL_STATE = {
  data: {},
  loading: false,
  subjects: {
    options: {
      sort: 'name',
      direction: 'asc',
    },
    data: [],
    loading: false,
  },
  attendants: {
    data: [],
    loading: false,
  },
  vwAttendants: {
    data: [],
    loading: false,
  },
}

const removeDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeDepartmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addDepartmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getDepartmentSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getDepartmentFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDepartmentSubjectsRequest = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: true,
  },
})

const getDepartmentSubjectsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  subjects: {
    ...state.subjects,
    data,
    loading: false,
  },
})

const getDepartmentSubjectsFailure = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: false,
  },
})

const addDepartmentSubjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: true,
  },
})

const addDepartmentSubjectFinish = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: false,
  },
})

const updateDepartmentSubjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: true,
  },
})

const updateDepartmentSubjectFinish = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: false,
  },
})

const removeDepartmentSubjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: true,
  },
})

const removeDepartmentSubjectFinish = (state = INITIAL_STATE) => ({
  ...state,
  subjects: {
    ...state.subjects,
    loading: false,
  },
})

const setDepartmentSubjectsSort = (
  state = INITIAL_STATE,
  { sort, direction },
) => ({
  ...state,
  subjects: {
    ...state.subjects,
    options: {
      ...state.subjects.options,
      sort,
      direction,
    },
  },
})

const getDepartmentAttendantsRequest = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: true,
  },
})

const getDepartmentAttendantsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  attendants: {
    ...state.attendants,
    data,
    loading: false,
  },
})

const getDepartmentAttendantsFailure = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: false,
  },
})

const addDepartmentAttendantRequest = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: true,
  },
})
const addDepartmentAttendantFinish = (state = INITIAL_STATE) => ({
  ...state,
  attendants: {
    ...state.attendants,
    loading: false,
  },
})

const getDepartmentVwAttendantsRequest = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.subjects,
    loading: true,
  },
})

const getDepartmentVwAttendantsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    data,
    loading: false,
  },
})

const getDepartmentVwAttendantsFailure = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    loading: false,
  },
})

const removeDepartmentAttendantRequest = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    loading: true,
  },
})

const removeDepartmentAttendantFinish = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    loading: false,
  },
})

const updateDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateDepartmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
export default createReducer(INITIAL_STATE, {
  [Types.REMOVE_DEPARTMENT_REQUEST]: removeDepartmentRequest,
  [Types.REMOVE_DEPARTMENT_FINISH]: removeDepartmentFinish,
  [Types.ADD_DEPARTMENT_REQUEST]: addDepartmentRequest,
  [Types.ADD_DEPARTMENT_FINISH]: addDepartmentFinish,
  [Types.GET_DEPARTMENT_REQUEST]: getDepartmentRequest,
  [Types.GET_DEPARTMENT_SUCCESS]: getDepartmentSuccess,
  [Types.GET_DEPARTMENT_FAILURE]: getDepartmentFailure,
  [Types.GET_DEPARTMENT_SUBJECTS_REQUEST]: getDepartmentSubjectsRequest,
  [Types.GET_DEPARTMENT_SUBJECTS_SUCCESS]: getDepartmentSubjectsSuccess,
  [Types.SET_DEPARTMENT_SUBJECTS_SORT]: setDepartmentSubjectsSort,
  [Types.GET_DEPARTMENT_SUBJECTS_FAILURE]: getDepartmentSubjectsFailure,
  [Types.ADD_DEPARTMENT_SUBJECT_REQUEST]: addDepartmentSubjectRequest,
  [Types.ADD_DEPARTMENT_SUBJECT_FINISH]: addDepartmentSubjectFinish,
  [Types.UPDATE_DEPARTMENT_SUBJECT_REQUEST]: updateDepartmentSubjectRequest,
  [Types.UPDATE_DEPARTMENT_SUBJECT_FINISH]: updateDepartmentSubjectFinish,
  [Types.REMOVE_DEPARTMENT_SUBJECT_REQUEST]: removeDepartmentSubjectRequest,
  [Types.REMOVE_DEPARTMENT_SUBJECT_REQUEST]: removeDepartmentSubjectFinish,
  [Types.GET_DEPARTMENT_ATTENDANTS_REQUEST]: getDepartmentAttendantsRequest,
  [Types.GET_DEPARTMENT_ATTENDANTS_SUCCESS]: getDepartmentAttendantsSuccess,
  [Types.GET_DEPARTMENT_ATTENDANTS_FAILURE]: getDepartmentAttendantsFailure,
  [Types.ADD_DEPARTMENT_ATTENDANT_REQUEST]: addDepartmentAttendantRequest,
  [Types.ADD_DEPARTMENT_ATTENDANT_FINISH]: addDepartmentAttendantFinish,
  [Types.GET_DEPARTMENT_VW_ATTENDANTS_REQUEST]: getDepartmentVwAttendantsRequest,
  [Types.GET_DEPARTMENT_VW_ATTENDANTS_SUCCESS]: getDepartmentVwAttendantsSuccess,
  [Types.GET_DEPARTMENT_VW_ATTENDANTS_FAILURE]: getDepartmentVwAttendantsFailure,
  [Types.REMOVE_DEPARTMENT_ATTENDANT_REQUEST]: removeDepartmentAttendantRequest,
  [Types.REMOVE_DEPARTMENT_ATTENDANT_FINISH]: removeDepartmentAttendantFinish,
  [Types.UPDATE_DEPARTMENT_REQUEST]: updateDepartmentRequest,
  [Types.UPDATE_DEPARTMENT_FINISH]: updateDepartmentFinish,
})
