import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects'
import { get } from 'lodash'
import moment from 'moment'
import configureApi from '~/helpers/api'

import {
  Types as BiDashboardTypes,
  Creators as BiDashboardActions,
} from '../ducks/biDashboard'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()
const filterSelector = ({ biDashboard }) => biDashboard.filters

function* getBiDashboardTableData() {
  const filters = yield select(filterSelector)

  const getParams = (code) => {
    return {
      params: {
        pagination: 0,
        'where[year]': moment(filters.date).year(),
        'where[month]': moment(filters.date).month() + 1,
        'where[with_total]': 'N',
        'where[type]': code,
      },
    }
  }
  try {
    const { news, seminews, direct } = yield all({
      news: call(api.get, 'table_data_split', getParams('N')),
      seminews: call(api.get, 'table_data_split', getParams('U')),
      direct: call(api.get, 'table_data_split', getParams('V')),
    })
    const newsData = news ? news.data : []
    const semiNewsData = seminews ? seminews.data : []
    const directData = direct ? direct.data : []

    yield put(
      BiDashboardActions.getBiDashboardTableDataSuccess(
        newsData,
        semiNewsData,
        directData,
      ),
    )
  } catch (e) {
    yield put(BiDashboardActions.getBiDashboardTableDataFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getBiDashboardData() {
  const filters = yield select(filterSelector)

  try {
    const { data } = yield call(api.get, 'invoiced', {
      params: {
        pagination: 0,
        'where[year]': moment(filters.date).year(),
        'where[month]': moment(filters.date).month() + 1,
      },
    })
    yield put(BiDashboardActions.getBiDashboardDataSuccess(data))
  } catch (e) {
    yield put(BiDashboardActions.getBiDashboardDataFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getBiDashboardSales({ dealershipId }) {
  const filters = yield select(filterSelector)

  try {
    const { data } = yield call(api.get, 'proposals', {
      params: {
        'where[dealership_id]': dealershipId,
        'where[year]': moment(filters.date).year(),
        'where[month]': moment(filters.date).month() + 1,
      },
    })
    yield put(BiDashboardActions.getBiDashboardSalesSuccess(data))
  } catch (e) {
    yield put(BiDashboardActions.getBiDashboardSalesFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getBiDashboardGroups() {
  try {
    const { data } = yield call(api.get, 'groups', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })

    const formattedData = data?.map((d) => ({ name: d.name, value: d.id }))
    yield put(BiDashboardActions.getBiDashboardGroupsSuccess(formattedData))
  } catch (e) {
    yield put(BiDashboardActions.getBiDashboardGroupsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadBiDashboard() {
  yield put(BiDashboardActions.getBiDashboardDataRequest())
  yield put(BiDashboardActions.getBiDashboardTableDataRequest())
}

export default function* () {
  yield takeLatest(
    BiDashboardTypes.GET_BI_DASHBOARD_TABLE_DATA_REQUEST,
    getBiDashboardTableData,
  )

  yield takeLatest(
    BiDashboardTypes.GET_BI_DASHBOARD_DATA_REQUEST,
    getBiDashboardData,
  )

  yield takeLatest(
    BiDashboardTypes.GET_BI_DASHBOARD_SALES_REQUEST,
    getBiDashboardSales,
  )

  yield takeLatest(
    BiDashboardTypes.GET_BI_DASHBOARD_GROUPS_REQUEST,
    getBiDashboardGroups,
  )

  yield debounce(
    400,
    BiDashboardTypes.SET_BI_DASHBOARD_FILTER,
    reloadBiDashboard,
  )
}
