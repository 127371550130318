import { call, takeLatest, put, select, all } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as AnalitycalDashboardTypes,
  Creators as AnalitycalDashboardActions,
} from '../ducks/analitycalDashboard'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const analitycalDashboardSelector = ({ analitycalDashboard }) =>
  analitycalDashboard

const api = configureApi()

function* getAnalitycalDashboard() {
  const { filters } = yield select(analitycalDashboardSelector)
  const rangeDiff = moment(filters?.endDate).diff(
    moment(filters?.startDate),
    'days',
  )
  try {
    const { currentMonth, previousMonth } = yield all({
      currentMonth: call(api.get, 'analytic/dashboard', {
        params: {
          start_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          end_at: moment(filters.endDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
      previousMonth: call(api.get, 'analytic/dashboard', {
        params: {
          start_at: moment(filters?.startDate)
            .subtract(rangeDiff, 'days')
            .format('YYYY-MM-DD'),
          end_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
    })
    // const { data } = yield call(api.get, 'analytic/dashboard', {
    //   params: {
    //     month: moment(filters.date).format('MM'),
    //     year: moment(filters.date).format('YYYY'),
    //     dealership_id: filters.dealership,
    //     group_id: filters.group,
    //   },
    // })

    yield put(
      AnalitycalDashboardActions.getAnalitycalDashboardSuccess(
        currentMonth.data,
        previousMonth?.data,
      ),
    )
  } catch (e) {
    yield put(AnalitycalDashboardActions.getAnalitycalDashboardFailure())
  }
}

function* reloadAnalitycalDashboard() {
  yield put(AnalitycalDashboardActions.getAnalitycalDashboardRequest())
}

function* getAnalitycalDashboardFilters() {
  try {
    const { groups, dealerships } = yield all({
      groups: call(api.get, 'groups', {
        params: {
          pagination: 0,
          order: 'name,asc',
        },
      }),
      dealerships: call(api.get, 'dealerships', {
        params: {
          pagination: 0,
          order: 'sort,asc',
        },
      }),
    })

    const groupsData = groups.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    const dealershipsData = dealerships.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      AnalitycalDashboardActions.getFiltersAnalitycalDashboardSuccess(
        groupsData,
        dealershipsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(AnalitycalDashboardActions.getFiltersAnalitycalDashboardFailure())
  }
}

function* getAnalitycalDashboardDetails({ segment }) {
  const { filters } = yield select(analitycalDashboardSelector)
  try {
    const { data: dataDetails } = yield call(
      api.get,
      'bi-comercial/detailedParts',
      {
        params: {
          month: moment(filters.date).format('MM'),
          year: moment(filters.date).format('YYYY'),
          dealership_id: filters.dealership,
          group_id: filters.group,
          segment: segment.substr(0, 1).toUpperCase(),
        },
      },
    )

    yield put(
      AnalitycalDashboardActions.getAnalitycalDashboardDetailsSuccess(
        dataDetails,
      ),
    )
  } catch (e) {
    yield put(AnalitycalDashboardActions.getAnalitycalDashboardDetailsFailure())
  }
}

export default function* () {
  yield takeLatest(
    AnalitycalDashboardTypes.GET_ANALITYCAL_DASHBOARD_REQUEST,
    getAnalitycalDashboard,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.SET_ANALITYCAL_DASHBOARD_SORT,
    reloadAnalitycalDashboard,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.SET_ANALITYCAL_DASHBOARD_PAGE,
    reloadAnalitycalDashboard,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.SET_ANALITYCAL_DASHBOARD_DATE,
    reloadAnalitycalDashboard,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.GET_FILTERS_ANALITYCAL_DASHBOARD_REQUEST,
    getAnalitycalDashboardFilters,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.SET_ANALITYCAL_DASHBOARD_CHANGE_FILTER,
    reloadAnalitycalDashboard,
  )
  yield takeLatest(
    AnalitycalDashboardTypes.GET_ANALITYCAL_DASHBOARD_DETAILS_REQUEST,
    getAnalitycalDashboardDetails,
  )
}
