export default () => ({
  root: {
    '& textarea:focus, input:focus, select:focus': {
      boxShadow: '0 0 0 0',
      border: '0 none',
      outline: 0,
    },
    '&multiLine': {
      control: {
        fontFamily: 'Roboto, Helvica',
        minHeight: 400,
        color: '#757575',
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        color: '#757575',
        fontSize: 14,
        fontFamily: 'Roboto, Helvica',
        border: '1px solid #B8BAB7',
        borderRadius: 5,
      },
    },
  },
  mentioInput: {},
})
