/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { intersection } from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'

import {
  Dashboard as DashboardIcon,
  DesktopMac as HelpdeskIcon,
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
  Settings as SettingsIcon,
  People as PeopleIcon,
  Assessment as AnalyticsIcon,
} from '@material-ui/icons'

import {
  mdiHammerScrewdriver,
  mdiFile,
  mdiTextBox,
  mdiCarBack,
  mdiFilter,
} from '@mdi/js'

import { useSelector } from 'react-redux'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import CustomIcon from '~/components/CustomIcon'
import LogoImg from '~/assets/images/logo.png'

import styles from './styles'

const useStyles = makeStyles(styles)

const sessionSelector = ({ session }) => session

const ForwardNavLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
))

function Sidebar({ className, isMobile: isSmallScreen, handleDrawerClose }) {
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState({})
  const {
    user: { roles },
  } = useSelector(sessionSelector)
  const userRoles = roles?.map((r) => r.name)

  const isMobileScreen = useMobileScreen()

  const pages = [
    {
      title: 'Home',
      url: '/dashboard',
      icon: <DashboardIcon />,
      exact: true,
      roles: [], // all users can access this page
    },
    {
      title: 'Helpdesk',
      icon: <HelpdeskIcon />,
      roles: ['administrator', 'requester'],
      children: [
        {
          title: 'Chamados',
          url: '/calls',
          roles: ['administrator', 'requester'],
          // icon: <TicketsIcon />,
        },
        {
          title: 'Atendimento',
          url: '/pipeline-calls',
          roles: ['administrator', 'clerk'],
        },
        {
          title: 'Dashboard',
          url: '/dashboard-helpdesk',
          roles: ['administrator'],
        },
        {
          title: 'Ramais',
          url: '/ramal',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
            'analytic_query',
            'bi_parts_admin',
            'bi_parts_consultation',
            'bi_seller',
            'rh',
            'rh_consult',
            'rh_comunication',
            'inventory',
            'inventory_query',
            'requester',
          ],
        },
        {
          title: 'Agenda',
          url: '/calendar',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
            'analytic_query',
            'bi_parts_admin',
            'bi_parts_consultation',
            'bi_seller',
            'rh',
            'rh_consult',
            'rh_comunication',
            'inventory',
            'inventory_query',
            'requester',
          ],
        },
      ],
    },
    {
      title: 'Veículos',
      icon: <CustomIcon pathComponent={mdiCarBack} />,
      roles: [
        'administrator',
        'sells_bi_admin',
        'sells_bi_consult',
        'sells_bi_manager',
      ],
      children: [
        {
          title: 'Vendas',
          url: '/bi-dashboard',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
          ],
        },
        {
          title: 'Faturados',
          url: '/billed-list',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
          ],
        },

        {
          title: 'Comparativo Mensal',
          url: '/billed-month-comparative',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
          ],
        },
        {
          title: 'Análise de Vendedores',
          url: '/sellers',
          roles: [
            'administrator',
            'sells_bi_admin',
            'sells_bi_consult',
            'sells_bi_manager',
          ],
        },
        {
          title: 'Movimentação',
          url: '/daily-sales',
          roles: ['administrator', 'sells_bi_manager'],
        },
        {
          title: 'Planejamento',
          url: '/objectives',
          roles: ['administrator', 'sells_bi_admin', 'sells_bi_manager'],
        },
        {
          title: 'Analítico',
          url: '/analytical',
          roles: [
            'administrator',
            'analytic_query',
            'sells_bi_consult',
            'sells_bi_manager',
          ],
        },
      ],
    },
    {
      title: 'Peças',
      icon: <CustomIcon pathComponent={mdiHammerScrewdriver} />,
      roles: [
        'administrator',
        'bi_parts_admin',
        'bi_parts_consultation',
        'bi_seller',
      ],
      children: [
        {
          title: 'Faturamento',
          url: '/bi-sellers-dashboard',
          roles: [
            'administrator',
            'bi_parts_admin',
            'bi_parts_consultation',
            'bi_seller',
          ],
        },
        {
          title: 'Planejamento',
          url: '/salesgoals',
          roles: ['administrator', 'bi_parts_admin'],
        },
        {
          title: 'Analítico',
          url: '/analytical-parts',
          roles: [
            'administrator',
            'analytic_query',
            'bi_parts_consultation',
            'bi_parts_admin',
          ],
        },
      ],
    },

    {
      title: 'RH',
      icon: <PeopleIcon />,
      roles: ['administrator', 'rh', 'rh_consult', 'rh_comunication'],
      children: [
        {
          title: 'Colaboradores',
          url: '/users',
          roles: ['administrator', 'rh', 'rh_consult'],
        },
        {
          title: 'Dashboard',
          url: '/rh-dashboard',
          roles: ['administrator', 'rh', 'rh_consult'],
        },
        {
          title: 'Comparativo Mensal',
          url: '/rh-month-comparative',
          roles: ['administrator', 'rh', 'rh_consult'],
        },
        {
          title: 'Organograma',
          url: '/employees-organogram',
          roles: ['administrator', 'rh', 'rh_consult'],
        },
        {
          title: 'Notícias',
          url: '/news-board',
          roles: ['administrator', 'rh', 'rh_comunication'],
        },

        {
          title: 'Comunicados',
          url: '/communication-board',
          roles: ['administrator'],
        },
        // {
        //   title: 'Recibos',
        //   url: '/receipts',
        //   roles: ['administrator'],
        // },
      ],
    },
    {
      title: 'Analítico',
      icon: <AnalyticsIcon />,
      roles: [
        'administrator',
        'analytic_query',
        'bi_parts_consultation',
        'sells_bi_consult',
        'sells_bi_manager',
      ],
      children: [
        {
          title: 'Geral',
          url: '/analytics-dashboard',
          roles: ['administrator', 'analytic_query'],
        },
      ],
    },
    {
      title: 'CRM',
      icon: <CustomIcon pathComponent={mdiFilter} />,
      roles: ['administrator'],
      children: [
        {
          title: 'Blip',
          url: '/blip',
          roles: ['administrator'],
        },
        {
          title: 'Ligações Internas',
          url: '/ipc',
          roles: ['administrator'],
        },
        {
          title: 'Ilhas',
          url: '/islands',
          roles: ['administrator'],
        },
      ],
    },

    {
      title: 'E-Learning',
      icon: <CustomIcon pathComponent={mdiTextBox} />,
      roles: ['administrator'],
      children: [
        { title: 'Questionários', url: '/quizzes', roles: ['administrator'] },

        {
          title: 'POP',
          url: '/pop',
          roles: ['administrator'],
        },
      ],
    },
    {
      title: 'Inventário',
      icon: <CustomIcon pathComponent={mdiFile} />,
      roles: ['administrator', 'inventory'],

      children: [
        {
          title: 'Dashboard',
          url: '/equipment-dashboard',
          roles: ['administrator', 'inventory'],
        },
        {
          title: 'Equipamentos',
          url: '/equipments',
          roles: ['administrator', 'inventory'],
        },
        {
          title: 'Consultar equipamento',
          url: '/equipments-qr-code',
          onlyMobile: true,
          roles: ['administrator', 'inventory', 'inventory_query'],
        },
      ],
    },

    {
      title: 'Configurações',
      icon: <SettingsIcon />,
      roles: ['administrator', 'rh'],
      children: [
        {
          title: 'Concessionárias',
          url: '/dealerships',
          roles: ['administrator'],
        },
        {
          title: 'Departamentos',
          url: '/departments',
          roles: ['administrator'],
        },
        { title: 'Funções', url: '/functions', roles: ['administrator', 'rh'] },
        {
          title: 'Superiores',
          url: '/superior-list',
          roles: ['administrator', 'rh'],
        },
        { title: 'Status', url: '/status', roles: ['administrator'] },
        {
          title: 'Tipos de Equipamento',
          url: '/equipment_types',
          roles: ['administrator'],
        },
        {
          title: 'E-mails Diários',
          url: '/daily-emails',
          roles: ['administrator'],
        },
      ],
    },
  ]

  const rootClassName = classNames(classes.root, className)

  return (
    <>
      <div className={classes.logoWrapper}>
        <Link to="/dashboard">
          <img alt="logo" className={classes.logoImage} src={LogoImg} />
        </Link>
      </div>
      <nav className={rootClassName}>
        <>
          <List component="div" disablePadding>
            {pages.map((page) => {
              const itemKey = page.title + page.url
              const hasPermission =
                !!intersection(page.roles, userRoles).length ||
                !page.roles.length // if page has no roles, it is always visible for all users
              return (
                <React.Fragment key={itemKey}>
                  {!page.children && hasPermission && (
                    <ListItem
                      exact={page.exact}
                      className={classes.listItem}
                      to={page.url}
                      key={page.title}
                      component={ForwardNavLink}
                      activeClassName={classes.activeListItem}
                      onClick={() => {
                        if (isSmallScreen) handleDrawerClose()
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>
                        {page.title}
                      </ListItemText>
                    </ListItem>
                  )}
                  {page.children && hasPermission && (
                    <>
                      <ListItem
                        className={classes.listItem}
                        onClick={() =>
                          setCollapsed({
                            ...collapsed,
                            [itemKey]: !collapsed[itemKey],
                          })
                        }
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {page.icon || <span />}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={page.title}
                        />
                        <ListItemIcon className={classes.dropdownIcon}>
                          <>
                            {!collapsed[itemKey] && <ArrowDown />}
                            {collapsed[itemKey] && <ArrowUp />}
                          </>
                        </ListItemIcon>
                      </ListItem>
                      <Collapse
                        className={classes.collapsedContainer}
                        in={collapsed[itemKey]}
                      >
                        {page.children.map((item) => {
                          const hasPermissonChildren =
                            !!intersection(item.roles, userRoles).length ||
                            !page.roles.length // if page has no roles, it is always visible for all users
                          if (hasPermissonChildren) {
                            if (item.onlyMobile) {
                              if (isMobileScreen) {
                                return (
                                  <ListItem
                                    exact={item.exact}
                                    className={classes.listItem}
                                    to={item.url}
                                    key={item.title}
                                    component={ForwardNavLink}
                                    activeClassName={classes.activeListItem}
                                    onClick={() => {
                                      if (isSmallScreen) handleDrawerClose()
                                    }}
                                  >
                                    <ListItemIcon
                                      className={classes.listItemIcon}
                                    >
                                      {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      className={classes.listItemText}
                                    >
                                      {item.title}
                                    </ListItemText>
                                  </ListItem>
                                )
                              }
                              return ''
                            }
                            return (
                              <ListItem
                                exact={item.exact}
                                className={classes.listItem}
                                to={item.url}
                                key={item.title}
                                component={ForwardNavLink}
                                activeClassName={classes.activeListItem}
                                onClick={() => {
                                  if (isSmallScreen) handleDrawerClose()
                                }}
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  {item.icon}
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText}>
                                  {item.title}
                                </ListItemText>
                              </ListItem>
                            )
                          }
                          return ''
                        })}
                      </Collapse>
                    </>
                  )}
                </React.Fragment>
              )
            })}
          </List>
        </>
      </nav>
    </>
  )
}

Sidebar.defaultProps = {
  className: '',
  isMobile: false,
  handleDrawerClose: () => { },
}

Sidebar.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
}

export default Sidebar
