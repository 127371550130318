import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { filter } from 'lodash'
import {
  makeStyles,
  Grid,
  Button,
  Badge,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import {
  Chart,
  HighchartsChart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highcharts'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as RhDashboardActions } from '~/store/ducks/rhDashboard'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import RhDashboardUsersDialog from './components/RhDashboardUsersDialog'

import styles from './styles'
import RhDashboardFilters from './components/RhDashboardFilters'

const rhDashboardSelector = ({ rhDashboard }) => rhDashboard
const useStyles = makeStyles(styles)

export default function RHDashboardPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [dealershipId, setDealershipId] = useState(null)
  const [dealershipName, setDealershipName] = useState(null)
  const isSmallScreen = useSmallScreen()

  const { data: rhDashboardData, loading, filters } = useSelector(
    rhDashboardSelector,
  )

  useEffectOnce(() => {
    dispatch(RhDashboardActions.getRhDashboardFiltersRequest())
    dispatch(RhDashboardActions.getRhDashboardDataRequest())
  })

  const getTotalByDepartment = (data, department) => {
    const dataByDepartment = data.filter((d) => d.department === department)

    const totalByDepartment =
      dataByDepartment.length > 0
        ? dataByDepartment.reduce(
            (total, users) => Number(total + users.users_number),
            0,
          )
        : []
    return totalByDepartment
  }

  const getTotalUsers = (data) => {
    return data.length > 0
      ? data.reduce((total, users) => Number(total + users.users_number), 0)
      : []
  }

  const getTotalAwayUsers = (data) => {
    return data.length > 0
      ? data.reduce((total, users) => Number(total + users.total_away), 0)
      : []
  }

  const handleOpenUsersDialog = (dId, dName) => {
    setDealershipId(dId)
    setDealershipName(dName)
  }

  const handleCloseDialog = () => {
    setDealershipId(null)
    setDealershipName(null)
  }

  const plotOptions = {
    pie: {
      shadow: false,
      innerSize: '50%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  }

  const colors = ['#1b1464', '#cb175b', '#ffa600']

  const getFilteredData = (data) => {
    let filteredData = []
    if (filters.group !== 0) {
      filteredData = filter(data, { group_id: filters.group })
    } else {
      filteredData = data
    }
    return filteredData
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            {isSmallScreen && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className={classes.filterWropper}>
                    <FilterIcon
                      color="primary"
                      className={classes.filterIcon}
                    />
                    <Typography variant="h5" color="primary">
                      Filtros
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <RhDashboardFilters />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {!isSmallScreen && (
              <Grid item xs={12}>
                <RhDashboardFilters />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {loading && <OverlayLoading />}

          {!loading &&
            rhDashboardData &&
            getFilteredData(rhDashboardData)?.map((item) => {
              return (
                <Grid key={item.dealership_name} item xs={12} sm={6} md={3}>
                  <Portlet>
                    <HighchartsChart colors={colors} plotOptions={plotOptions}>
                      <Tooltip />
                      <Chart />
                      <Title y={30}>{item.dealership_name}</Title>
                      <XAxis type="category" />
                      <YAxis>
                        <PieSeries
                          name="Colaboradores"
                          data={[
                            {
                              name: 'Administrativo',
                              y: getTotalByDepartment(
                                item.data,
                                'Administrativo',
                              ),
                            },
                            {
                              name: 'Comercial',
                              y: getTotalByDepartment(item.data, 'Comercial'),
                            },
                            {
                              name: 'Pós-venda',
                              y: getTotalByDepartment(item.data, 'Pós-venda'),
                            },
                          ]}
                        />
                      </YAxis>
                      <Legend
                        layout="horizontal"
                        align="center"
                        verticalAlign="top"
                      />
                    </HighchartsChart>
                    <Grid container justifyContent="flex-end">
                      <Button
                        onClick={() =>
                          handleOpenUsersDialog(
                            item.dealership_id,
                            item.dealership_name,
                          )
                        }
                        variant="outlined"
                        className={classes.button}
                      >
                        <Badge
                          color="error"
                          badgeContent={getTotalAwayUsers(item.data)}
                        >
                          {getTotalUsers(item.data)}
                          {getTotalUsers(item.data) > 1
                            ? ' COLABORADORES'
                            : ' COLABORADOR'}
                        </Badge>
                      </Button>
                    </Grid>
                  </Portlet>
                </Grid>
              )
            })}
        </Grid>
      </div>
      <RhDashboardUsersDialog
        dealershipName={dealershipName}
        dealershipId={dealershipId}
        open={!!dealershipId}
        onClose={handleCloseDialog}
      />
    </>
  )
}
