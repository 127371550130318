import { createActions, createReducer } from 'reduxsauce'
import moment from 'moment'
import { last } from 'lodash'

export const { Types, Creators } = createActions({
  getAnalitycalDashboardRequest: [],
  getAnalitycalDashboardFailure: [],
  getAnalitycalDashboardSuccess: ['data', 'dataPreviousMonth', 'total'],
  setAnalitycalDashboardSort: ['sort', 'direction'],
  setAnalitycalDashboardPage: ['page', 'limit'],
  setAnalitycalDashboardDate: ['start', 'end'],
  resetAnalitycalDashboard: [],
  setChangeShowHideColumnsAnalitycalDashboard: ['newTableColumns'],
  getFiltersAnalitycalDashboardRequest: [],
  getFiltersAnalitycalDashboardFailure: [],
  getFiltersAnalitycalDashboardSuccess: ['groups', 'dealerships'],
  setAnalitycalDashboardChangeFilter: ['property', 'value'],
  getAnalitycalDashboardDetailsRequest: ['segment'],
  getAnalitycalDashboardDetailsFailure: [],
  getAnalitycalDashboardDetailsSuccess: ['dataDetails'],
  setChangeShowHideColumnsAnalitycalDashboardDialog: ['newTableColumnsDialog'],
})

const INITIAL_STATE = {
  showHideColumns: {
    type: true,
    billing: true,
    quantity: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
  },
  showHideColumnsDialog: {
    type: true,
    amount: true,
    billing: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
  },
  options: {
    sort: 'nome',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  filters: {
    startDate: moment().subtract(30, 'days').format(),
    endDate: moment().format(),
    group: 0,
    dealership: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    dealerships: [
      {
        name: 'Todas as concessionárias',
        value: 0,
      },
    ],
  },
  data: [],
  dataPreviousMonth: {},
  loading: false,
  loadingFilters: false,
  dataDetails: [],
}

const getAnalitycalDashboardRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalDashboardFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalDashboardSuccess = (
  state = INITIAL_STATE,
  { data, dataPreviousMonth, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  dataPreviousMonth: last(dataPreviousMonth),
  loading: false,
})

const setAnalitycalDashboardSort = (
  state = INITIAL_STATE,
  { sort, direction },
) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setAnalitycalDashboardPage = (
  state = INITIAL_STATE,
  { page, limit },
) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setAnalitycalDashboardDate = (state = INITIAL_STATE, { start, end }) => ({
  ...state,
  filters: {
    ...state.filters,
    startDate: start,
    endDate: end,
  },
})

const resetAnalitycalDashboard = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsAnalitycalDashboard = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycalDashboard',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}
const getFiltersAnalitycalDashboardRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getFiltersAnalitycalDashboardSuccess = (
  state = INITIAL_STATE,
  { groups, dealerships },
) => {
  return {
    ...state,
    filtersData: {
      ...state.filtersData,
      groups: [
        {
          name: 'Todas os grupos',
          value: 0,
        },
        ...groups,
      ],
      dealerships: [
        {
          name: 'Todas as concessionárias',
          value: 0,
        },
        ...dealerships,
      ],
    },
    loading: false,
  }
}

const getFiltersAnalitycalDashboardFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setAnalitycalDashboardChangeFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const getAnalitycalDashboardDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalDashboardDetailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalDashboardDetailsSuccess = (
  state = INITIAL_STATE,
  { dataDetails },
) => ({
  ...state,
  dataDetails,
  loading: false,
})

const setChangeShowHideColumnsAnalitycalDashboardDialog = (
  state = INITIAL_STATE,
  { newTableColumnsDialog },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycalDashboardDialog',
    JSON.stringify(newTableColumnsDialog),
  )
  return {
    ...state,
    showHideColumnsDialog: newTableColumnsDialog,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_ANALITYCAL_DASHBOARD_REQUEST]: getAnalitycalDashboardRequest,
  [Types.GET_ANALITYCAL_DASHBOARD_FAILURE]: getAnalitycalDashboardFailure,
  [Types.GET_ANALITYCAL_DASHBOARD_SUCCESS]: getAnalitycalDashboardSuccess,
  [Types.SET_ANALITYCAL_DASHBOARD_SORT]: setAnalitycalDashboardSort,
  [Types.SET_ANALITYCAL_DASHBOARD_PAGE]: setAnalitycalDashboardPage,
  [Types.SET_ANALITYCAL_DASHBOARD_DATE]: setAnalitycalDashboardDate,
  [Types.RESET_ANALITYCAL_DASHBOARD]: resetAnalitycalDashboard,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL_DASHBOARD]:
    setChangeShowHideColumnsAnalitycalDashboard,
  [Types.GET_FILTERS_ANALITYCAL_DASHBOARD_REQUEST]:
    getFiltersAnalitycalDashboardRequest,
  [Types.GET_FILTERS_ANALITYCAL_DASHBOARD_SUCCESS]:
    getFiltersAnalitycalDashboardSuccess,
  [Types.GET_FILTERS_ANALITYCAL_DASHBOARD_FAILURE]:
    getFiltersAnalitycalDashboardFailure,
  [Types.SET_ANALITYCAL_DASHBOARD_CHANGE_FILTER]:
    setAnalitycalDashboardChangeFilter,
  [Types.GET_ANALITYCAL_DASHBOARD_DETAILS_REQUEST]:
    getAnalitycalDashboardDetailsRequest,
  [Types.GET_ANALITYCAL_DASHBOARD_DETAILS_FAILURE]:
    getAnalitycalDashboardDetailsFailure,
  [Types.GET_ANALITYCAL_DASHBOARD_DETAILS_SUCCESS]:
    getAnalitycalDashboardDetailsSuccess,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL_DASHBOARD_DIALOG]:
    setChangeShowHideColumnsAnalitycalDashboardDialog,
})
