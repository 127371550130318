export default (theme) => ({
  root: {
    display: 'flex',
    zIndex: theme.zIndex.appBar,
    borderLeft: (props) =>
      props.isSidebarOpen ? '2px solid rgba(255, 255, 255, 0.25)' : 'none',
    backgroundColor: '#1B1464',
    alignItems: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: '#fff',
  },
  toolbar: {
    minHeight: '64px',
    width: '100%',
    color: theme.palette.primary.main,
  },
  menuButton: {
    color: '#fff',
  },
  divider: {
    marginLeft: 16,
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    width: 2,
  },
})
