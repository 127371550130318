import useMediaQuery from '@material-ui/core/useMediaQuery'

export function useSmallScreen() {
  const isSmallScreen = useMediaQuery('(max-width:960px)')

  return isSmallScreen
}

export function useMobileScreen() {
  const isMobileScreen = useMediaQuery('(max-width:480px)')

  return isMobileScreen
}

export function useTabletScreen() {
  const isTabletScreen = useMediaQuery('(max-width:768px)')

  return isTabletScreen
}

export function useMdScreen() {
  const isMdScreen = useMediaQuery('(max-width:900px)')

  return isMdScreen
}
