import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as SalesGoalsTypes,
  Creators as SalesGoalsActions,
} from '../ducks/salesGoals'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ salesGoals }) => salesGoals.options

function* getSalesGoals() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'sales_goal', {
      params: {
        ...options,
        pagination: 1,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
      },
    })

    yield put(SalesGoalsActions.getSalesGoalsSuccess(data.data, data.total))
  } catch (e) {
    yield put(SalesGoalsActions.getSalesGoalsFailure())
  }
}

function* getSalesGoalNew() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'sales_new')

    yield put(SalesGoalsActions.getSalesGoalNewDataSuccess(data))
  } catch (e) {
    yield put(SalesGoalsActions.getSalesGoalNewDataFailure())
  }
}

function* addSalesGoal({ data, callback }) {
  try {
    const api = configureApi()
    const response = yield call(api.post, 'sales_goal', data)

    if (callback) {
      yield call(callback(response.data))
    }

    yield put(SalesGoalsActions.addSalesGoalFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento adicionado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(SalesGoalsActions.addSalesGoalFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento adicionado com sucesso!',
        variant: 'success',
      }),
    )
  }
}

function* addSellersGoals({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'sellers_goals', data)

    if (callback) {
      yield call(callback)
    }
    yield put(SalesGoalsActions.addSalesGoalFinish())
  } catch (e) {
    yield put(SalesGoalsActions.addSalesGoalFinish())
  }
}

function* reloadSalesGoals() {
  yield put(SalesGoalsActions.getSalesGoalsRequest())
}

function* removeSalesGoals({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/sales_goal/${id}`)

    yield put(SalesGoalsActions.removeSalesGoalsFinish())

    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(SalesGoalsActions.removeSalesGoalsFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getSellerGoals({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `/sellers_goals/${id}`)

    yield put(SalesGoalsActions.getSellerGoalsSuccess(data))
  } catch (e) {
    yield put(SalesGoalsActions.getSellerGoalsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateSalesGoals({ id, data, callback }) {
  try {
    const api = configureApi()
    const response = yield call(api.put, `sales_goal/${id}`, data)
    if (callback) {
      yield call(callback(response.data.id))
    }
  } catch (e) {
    yield put(SalesGoalsActions.updateSalesGoalFinish())
  }
}

function* getCopySalesGoal({ callback }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'sales_goal', {
      params: {
        limit: 1,
        pagination: 1,
      },
    })
    const salesGoalId = data.data[0].id || null

    if (callback) {
      yield call(callback, salesGoalId)
    }
    yield put(SalesGoalsActions.getCopySalesGoalSuccess())
  } catch (e) {
    yield put(SalesGoalsActions.getCopySalesGoalFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(SalesGoalsTypes.GET_SALES_GOALS_REQUEST, getSalesGoals)
  yield takeLatest(SalesGoalsTypes.SET_SALES_GOALS_SORT, reloadSalesGoals)
  yield takeLatest(SalesGoalsTypes.SET_SALES_GOALS_PAGE, reloadSalesGoals)

  yield takeLatest(
    SalesGoalsTypes.GET_SALES_GOAL_NEW_DATA_REQUEST,
    getSalesGoalNew,
  )

  yield takeLatest(SalesGoalsTypes.ADD_SALES_GOAL_REQUEST, addSalesGoal)

  yield takeLatest(SalesGoalsTypes.ADD_SELLERS_GOALS_REQUEST, addSellersGoals)
  yield takeLatest(SalesGoalsTypes.REMOVE_SALES_GOALS_REQUEST, removeSalesGoals)
  yield takeLatest(SalesGoalsTypes.GET_SELLER_GOALS_REQUEST, getSellerGoals)
  yield takeLatest(SalesGoalsTypes.UPDATE_SALES_GOAL_REQUEST, updateSalesGoals)
  yield takeLatest(
    SalesGoalsTypes.GET_COPY_SALES_GOAL_REQUEST,
    getCopySalesGoal,
  )
}
