import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { phoneMask } from '~/helpers/masks'
import { Creators as DealershipActions } from '~/store/ducks/dealership'
import { Creators as DealershipsActions } from '~/store/ducks/dealerships'
import OverlayLoading from '~/components/OverlayLoading'

import styles from './styles'

const useStyles = makeStyles(styles)

const dealershipGroupsSelector = ({ dealership: { groups } }) => groups
const dealershipAutomakersSelector = ({ dealership: { automakers } }) =>
  automakers

const dealershipSelector = ({ dealership }) => dealership

export default function DealershipFormTab({ onCloseDialog, id }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isEdit = !!id
  const { data: dealershipData, loading: loadingDealership } =
    useSelector(dealershipSelector)

  const { data: dataGroups, loading: loadingGroups } = useSelector(
    dealershipGroupsSelector,
  )
  const { data: dataAutomakers } = useSelector(dealershipAutomakersSelector)

  const defaultValues = {
    group_id: '',
    automaker_id: '',
    name: '',
    cod_nbs: '',
    city: '',
    address: '',
    phone: '',
  }
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const onSubmit = (data) => {
    if (isEdit) {
      dispatch(
        DealershipActions.updateDealershipRequest(id, data, () => {
          onCloseDialog()
          dispatch(DealershipsActions.getDealershipsRequest())
        }),
      )
    } else {
      dispatch(
        DealershipActions.addDealershipRequest(data, () => {
          onCloseDialog()
          dispatch(DealershipsActions.getDealershipsRequest())
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(DealershipActions.getDealershipGroupsRequest())
    dispatch(DealershipActions.getDealershipAutomakersRequest())
  }, [])

  useEffect(() => {
    if (isEdit) {
      reset(dealershipData)
    } else {
      reset(defaultValues)
    }
  }, [isEdit, reset, dealershipData])

  return (
    <div className={classes.root}>
      {loadingDealership && <OverlayLoading />}
      {!loadingDealership && (
        <form onSubmit={handleSubmit(onSubmit)} id="formDealerships">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="group_id"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    select
                    label="Grupo*"
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value || ''}
                    {...field}
                    error={!!errors.group_id}
                    helperText={!!errors.group_id && 'Campo obrigatório'}
                  >
                    {loadingGroups && <OverlayLoading size={20} />}
                    {dataGroups.map((group) => (
                      <MenuItem value={group.id} key={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="automaker_id"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    onChange={onChange}
                    value={value || ''}
                    select
                    label="Marca*"
                    variant="outlined"
                    error={!!errors.automaker_id}
                    helperText={!!errors.automaker_id && 'Campo obrigatório'}
                    fullWidth
                    {...field}
                  >
                    {dataAutomakers.map((automaker) => (
                      <MenuItem value={automaker.id} key={automaker.id}>
                        {automaker.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    variant="outlined"
                    name="name"
                    label="Nome*"
                    onChange={onChange}
                    value={value || ''}
                    error={!!errors.name}
                    helperText={!!errors.name && 'Campo obrigatório'}
                    type="text"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="cod_nbs"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    variant="outlined"
                    label="Código NBS*"
                    type="number"
                    error={!!errors.cod_nbs}
                    helperText={!!errors.cod_nbs && 'Campo obrigatório'}
                    onChange={onChange}
                    value={value || ''}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="cnpj"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <InputMask
                    mask="99.999.999/9999-99"
                    onChange={onChange}
                    value={value || ''}
                    {...field}
                    error={!!errors.cpf}
                    helperText={!!errors.cpf && 'Campo obrigatório'}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        variant="outlined"
                        label="CNPJ*"
                        autoComplete="off"
                        fullWidth
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    variant="outlined"
                    label="Cidade*"
                    onChange={onChange}
                    value={value || ''}
                    error={!!errors.city}
                    helperText={!!errors.city && 'Campo obrigatório'}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    variant="outlined"
                    onChange={onChange}
                    value={value || ''}
                    label="Endereço*"
                    error={!!errors.address}
                    helperText={!!errors.address && 'Campo obrigatório'}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.phone}
                    helperText={!!errors.phone && 'Campo obrigatório'}
                    onChange={(e) => {
                      e.target.value = phoneMask(e.target.value)
                      onChange(e)
                    }}
                    value={value || ''}
                    label="Telefone*"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            className={classes.buttonsWrapper}
          >
            <Button
              color="primary"
              type="submit"
              form="formDealerships"
              variant="contained"
            >
              salvar concessionária
            </Button>
          </Grid>
        </form>
      )}
    </div>
  )
}

DealershipFormTab.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  id: PropTypes.number,
}
DealershipFormTab.defaultProps = {
  id: null,
}
