import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getDealershipGroupsRequest: [],
  getDealershipGroupsSuccess: ['data'],
  getDealershipGroupsFailure: [],
  getDealershipAutomakersRequest: [],
  getDealershipAutomakersSuccess: ['data'],
  getDealershipAutomakersFailure: [],
  addDealershipRequest: ['data', 'callback'],
  addDealershipFinish: [],
  removeDealershipRequest: ['id', 'callback'],
  removeDealershipFinish: [],
  getDealershipRequest: ['id'],
  getDealershipSuccess: ['data'],
  getDealershipFailure: [],
  getDealershipAllDepartmentsRequest: [],
  getDealershipAllDepartmentsSuccess: ['data'],
  getDealershipAllDepartmentsFailure: [],
  addDealershipDepartmentRequest: ['data', 'callback'],
  addDealershipDepartmentFinish: [],
  getDealershipDepartmentsRequest: ['id'],
  getDealershipDepartmentsSuccess: ['data'],
  getDealershipDepartmentsFailure: [],
  removeDealershipDepartmentRequest: ['id', 'callback'],
  removeDealershipDepartmentFinish: [],
  updateDealershipRequest: ['id', 'data', 'callback'],
  updateDealershipFinish: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  groups: {
    data: [],
    loading: false,
  },
  automakers: {
    data: [],
    loading: false,
  },
  departments: {
    data: [],
    loading: false,
  },
  allDepartments: {
    data: [],
    loading: false,
  },
}

const updateDealershipRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateDealershipFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeDealershipDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeDealershipDepartmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDealershipAllDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  allDepartments: {
    ...state.allDepartments,
    loading: true,
  },
})

const getDealershipAllDepartmentsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  allDepartments: {
    ...state.allDepartments,
    data,
    loading: false,
  },
})

const getDealershipAllDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  allDepartments: {
    ...state.allDepartments,
    loading: false,
  },
})

const getDealershipRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getDealershipSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getDealershipFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDealershipGroupsRequest = (state = INITIAL_STATE) => ({
  ...state,
  groups: {
    ...state.groups,
    loading: true,
  },
})

const getDealershipGroupsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  groups: {
    ...state.groups,
    data,
    loading: false,
  },
})
const getDealershipGroupsFailure = (state = INITIAL_STATE) => ({
  ...state,
  groups: {
    ...state.groups,
    loading: false,
  },
})

const getDealershipAutomakersRequest = (state = INITIAL_STATE) => ({
  ...state,
  automakers: {
    ...state.automakers,
    loading: true,
  },
})

const getDealershipAutomakersSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  automakers: {
    ...state.automakers,
    data,
    loading: false,
  },
})
const getDealershipAutomakersFailure = (state = INITIAL_STATE) => ({
  ...state,
  automakers: {
    ...state.automakers,
    loading: false,
  },
})

const addDealershipRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addDealershipFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeDealershipRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeDealershipFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addDealershipDepartmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addDealershipDepartmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDealershipDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: true,
  },
})

const getDealershipDepartmentsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  departments: {
    ...state.departments,
    data,
    loading: false,
  },
})

const getDealershipDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: false,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_DEALERSHIP_REQUEST]: getDealershipRequest,
  [Types.GET_DEALERSHIP_SUCCESS]: getDealershipSuccess,
  [Types.GET_DEALERSHIP_FAILURE]: getDealershipFailure,
  [Types.GET_DEALERSHIP_GROUPS_REQUEST]: getDealershipGroupsRequest,
  [Types.GET_DEALERSHIP_GROUPS_SUCCESS]: getDealershipGroupsSuccess,
  [Types.GET_DEALERSHIP_GROUPS_FAILURE]: getDealershipGroupsFailure,
  [Types.GET_DEALERSHIP_AUTOMAKERS_REQUEST]: getDealershipAutomakersRequest,
  [Types.GET_DEALERSHIP_AUTOMAKERS_SUCCESS]: getDealershipAutomakersSuccess,
  [Types.GET_DEALERSHIP_AUTOMAKERS_FAILURE]: getDealershipAutomakersFailure,
  [Types.ADD_DEALERSHIP_REQUEST]: addDealershipRequest,
  [Types.ADD_DEALERSHIP_FINISH]: addDealershipFinish,
  [Types.REMOVE_DEALERSHIP_REQUEST]: removeDealershipRequest,
  [Types.REMOVE_DEALERSHIP_FINISH]: removeDealershipFinish,
  [Types.GET_DEALERSHIP_ALL_DEPARTMENTS_REQUEST]: getDealershipAllDepartmentsRequest,
  [Types.GET_DEALERSHIP_ALL_DEPARTMENTS_SUCCESS]: getDealershipAllDepartmentsSuccess,
  [Types.GET_DEALERSHIP_ALL_DEPARTMENTS_FAILURE]: getDealershipAllDepartmentsFailure,
  [Types.ADD_DEALERSHIP_DEPARTMENT_REQUEST]: addDealershipDepartmentRequest,
  [Types.ADD_DEALERSHIP_DEPARTMENT_FINISH]: addDealershipDepartmentFinish,
  [Types.GET_DEALERSHIP_DEPARTMENTS_REQUEST]: getDealershipDepartmentsRequest,
  [Types.GET_DEALERSHIP_DEPARTMENTS_SUCCESS]: getDealershipDepartmentsSuccess,
  [Types.GET_DEALERSHIP_DEPARTMENTS_FAILURE]: getDealershipDepartmentsFailure,
  [Types.REMOVE_DEALERSHIP_DEPARTMENT_REQUEST]: removeDealershipDepartmentRequest,
  [Types.REMOVE_DEALERSHIP_DEPARTMENT_FINISH]: removeDealershipDepartmentFinish,
  [Types.UPDATE_DEALERSHIP_REQUEST]: updateDealershipRequest,
  [Types.UPDATE_DEALERSHIP_FINISH]: updateDealershipFinish,
})
