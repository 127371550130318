import { call, takeLatest, put, select, all } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as AnalitycalPartsTypes,
  Creators as AnalitycalPartsActions,
} from '../ducks/analitycalParts'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const analitycalPartsSelector = ({ analitycalParts }) => analitycalParts

const api = configureApi()

function* getAnalitycalParts() {
  const { filters } = yield select(analitycalPartsSelector)
  const rangeDiff = moment(filters?.endDate).diff(
    moment(filters?.startDate),
    'days',
  )
  try {
    const { currentMonth, previousMonthParts } = yield all({
      currentMonth: call(api.get, 'bi-pecas/analytic', {
        params: {
          start_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          end_at: moment(filters?.endDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
      previousMonthParts: call(api.get, 'bi-pecas/analytic', {
        params: {
          start_at: moment(filters?.startDate)
            .subtract(rangeDiff, 'days')
            .format('YYYY-MM-DD'),
          end_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
    })

    yield put(
      AnalitycalPartsActions.getAnalitycalPartsSuccess(
        currentMonth.data,
        previousMonthParts.data,
      ),
    )
  } catch (e) {
    yield put(AnalitycalPartsActions.getAnalitycalPartsFailure())
  }
}

function* reloadAnalitycalParts() {
  yield put(AnalitycalPartsActions.getAnalitycalPartsRequest())
}

function* getAnalitycalPartsFilters() {
  try {
    const { groups, dealerships } = yield all({
      groups: call(api.get, 'groups', {
        params: {
          pagination: 0,
          order: 'name,asc',
        },
      }),
      dealerships: call(api.get, 'dealerships', {
        params: {
          pagination: 0,
          order: 'sort,asc',
        },
      }),
    })

    const groupsData = groups.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    const dealershipsData = dealerships.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      AnalitycalPartsActions.getFiltersAnalitycalPartsSuccess(
        groupsData,
        dealershipsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(AnalitycalPartsActions.getAnalitycalPartsFiltersFailure())
  }
}

function* getAnalitycalPartsDetails({ segment }) {
  const { filters } = yield select(analitycalPartsSelector)
  try {
    const { data: dataDetails } = yield call(api.get, 'bi-pecas/detailed', {
      params: {
        start_at: moment(filters?.startDate).format('YYYY-MM-DD'),
        end_at: moment(filters?.endDate).format('YYYY-MM-DD'),
        dealership_id: filters.dealership,
        group_id: filters.group,
        segment,
      },
    })

    yield put(
      AnalitycalPartsActions.getAnalitycalPartsDetailsSuccess(dataDetails),
    )
  } catch (e) {
    yield put(AnalitycalPartsActions.getAnalitycalPartsDetailsFailure())
  }
}

export default function* () {
  yield takeLatest(
    AnalitycalPartsTypes.GET_ANALITYCAL_PARTS_REQUEST,
    getAnalitycalParts,
  )
  yield takeLatest(
    AnalitycalPartsTypes.SET_ANALITYCAL_PARTS_SORT,
    reloadAnalitycalParts,
  )
  yield takeLatest(
    AnalitycalPartsTypes.SET_ANALITYCAL_PARTS_PAGE,
    reloadAnalitycalParts,
  )
  yield takeLatest(
    AnalitycalPartsTypes.SET_ANALITYCAL_PARTS_DATE,
    reloadAnalitycalParts,
  )
  yield takeLatest(
    AnalitycalPartsTypes.GET_FILTERS_ANALITYCAL_PARTS_REQUEST,
    getAnalitycalPartsFilters,
  )
  yield takeLatest(
    AnalitycalPartsTypes.SET_ANALITYCAL_PARTS_CHANGE_FILTER,
    reloadAnalitycalParts,
  )
  yield takeLatest(
    AnalitycalPartsTypes.GET_ANALITYCAL_PARTS_DETAILS_REQUEST,
    getAnalitycalPartsDetails,
  )
}
