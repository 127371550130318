import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
  Divider,
  Badge,
  Grid,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMobileScreen } from '~/helpers/useMediaQuery'

import { Creators as CallActions } from '~/store/ducks/call'

import TabPanel from '~/components/TabPanel'
import OverlayLoading from '~/components/OverlayLoading'

import CallFormTab from './CallFormTab'
import styles from './styles'
import CallAttachmentsFormTab from './CallAttachmentsFormTab'
import CallActionsFormTab from './CallActionsFormTab'

import CallDuplicateDialog from './CallDuplicateDialog'

const useStyles = makeStyles(styles)

const callSelector = ({ call }) => call

const CallDialog = ({ open, onClose, id }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const call = useSelector(callSelector)
  const isMobileScreen = useMobileScreen()
  // const isMdScreen = useMdScreen()
  const [openCallDuplicateDialog, setOpenCallDuplicateDialog] = useState(false)

  const { attachments, loading } = call

  const isEdit = !!id

  const [currentTab, setCurrentTab] = useState(0)
  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  useEffect(() => {
    if (isEdit && open) {
      dispatch(CallActions.getCallRequest(id))
      dispatch(CallActions.getCallAttachmentsRequest(id))
      dispatch(CallActions.getCallActionsRequest())
      dispatch(CallActions.getCallHistoriesRequest(id))
      setCurrentTab(2)
    }

    return () => {
      dispatch(CallActions.resetCall())
      setCurrentTab(0)
    }
  }, [isEdit, id, open])

  const handleMarkAsDuplicate = () => {
    setOpenCallDuplicateDialog(true)
  }

  const handleCloseDuplicateCallDialog = () => {
    setOpenCallDuplicateDialog(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        fullScreen={isMobileScreen}
        maxWidth="md"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">
            {isEdit ? `Editar chamado #${id}` : 'Criar chamado'}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        {!isEdit && (
          <>
            <Grid container className={classes.subtitleWrapper}>
              <Grid item xs={12}>
                <Typography variant="h5">Novo Chamado</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.dividerBottom} />
          </>
        )}

        <DialogContent className={classes.dialogContent}>
          {loading && <OverlayLoading />}
          {!loading && (
            <>
              <Tabs
                variant="fullWidth"
                value={currentTab}
                onChange={handleTabsChange}
              >
                <Tab label="Chamado" />
                <Tab
                  label={
                    attachments?.data.length > 0 ? (
                      <Badge
                        color="primary"
                        badgeContent={attachments?.data.length}
                      >
                        Anexos
                      </Badge>
                    ) : (
                      'Anexos'
                    )
                  }
                  disabled={!isEdit}
                />
                <Tab label="Ações" disabled={!isEdit} />
              </Tabs>
              <TabPanel index={0} value={currentTab}>
                <CallFormTab initialValues={call} id={id} onClose={onClose} />
              </TabPanel>
              <TabPanel index={1} value={currentTab}>
                <CallAttachmentsFormTab id={id} />
              </TabPanel>
              <TabPanel index={2} value={currentTab}>
                <CallActionsFormTab onClose={onClose} />
              </TabPanel>
            </>
          )}
        </DialogContent>
        <Divider className={classes.divider} />
        <DialogActions className={classes.buttonForm}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                fullWidth={isMobileScreen}
                onClick={onClose}
                variant="contained"
              >
                CANCELAR
              </Button>
            </Grid>
            {isEdit && (
              <Grid item>
                <Button
                  className={classes.duplicateButton}
                  onClick={() => handleMarkAsDuplicate()}
                  variant="contained"
                  color="primary"
                  fullWidth={isMobileScreen}
                >
                  Marcar como duplicado
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      <CallDuplicateDialog
        open={openCallDuplicateDialog}
        onClose={handleCloseDuplicateCallDialog}
        onCallDialogClose={onClose}
      />
    </>
  )
}

CallDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

CallDialog.defaultProps = {
  id: null,
}

export default CallDialog
