import React, { useEffect } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { useMobileScreen } from '~/helpers/useMediaQuery'
import { Creators as EquipmentTypeActions } from '~/store/ducks/equipmentType'
import { Creators as EquipmentsTypesActions } from '~/store/ducks/equipmentsTypes'
import OverlayLoading from '~/components/OverlayLoading'

import styles from './style'

const useStyles = makeStyles(styles)

const equipmentTypeSelector = ({ equipmentType }) => equipmentType.data
const loadingSelector = ({ equipmentType: { loading } }) => loading

const DialogEquipmentTypes = ({ open, onClose, id }) => {
  const isMobileScreen = useMobileScreen()
  const dispatch = useDispatch()
  const classes = useStyles()
  const EquipmentTypeData = useSelector(equipmentTypeSelector)
  const loading = useSelector(loadingSelector)
  const isEdit = !!id

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (isEdit) {
      dispatch(EquipmentTypeActions.getEquipmentTypeRequest(id))
    } else {
      reset({ name: '' })
    }
  }, [isEdit])

  const reloadEquipmentsTypes = () => {
    dispatch(EquipmentsTypesActions.getEquipmentsTypesRequest())
  }

  const onSubmit = (data) => {
    if (isEdit) {
      // update -> PUT
      dispatch(
        EquipmentTypeActions.updateEquipmentTypeRequest(id, data, () =>
          reloadEquipmentsTypes(),
        ),
      )
    } else {
      // create -> POST
      dispatch(
        EquipmentTypeActions.addEquipmentTypeRequest(data, () =>
          reloadEquipmentsTypes(),
        ),
      )
    }
    onClose()
  }

  useEffect(() => {
    if (isEdit) {
      reset(EquipmentTypeData)
    }
  }, [isEdit, EquipmentTypeData])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          tipo de equipamento
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      {!isEdit && (
        <>
          <Grid container className={classes.subtitleWrapper}>
            <Grid item xs={12}>
              <Typography variant="h5">Novo Tipo de Equipamento</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dividerBottom} />
        </>
      )}

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)} id="formEquipmentType">
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, field }) => (
                    <TextField
                      variant="outlined"
                      autoFocus
                      label="Nome"
                      type="text"
                      onChange={onChange}
                      value={value}
                      error={!!errors.name}
                      helperText={!!errors.name && 'Campo obrigatório'}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button
          color="primary"
          type="submit"
          form="formEquipmentType"
          variant="contained"
        >
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogEquipmentTypes.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

DialogEquipmentTypes.defaultProps = {
  id: null,
}

export default DialogEquipmentTypes
