import React from 'react'
import moment from 'moment'
import { Grid, makeStyles, TextField, MenuItem } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import 'moment/locale/pt-br'
import { Creators as AnalitycalActions } from '~/store/ducks/analitycal'
import Autocomplete from '~/components/Autocomplete'
import RangeTimeSelect from '~/components/RangeTimeSelect'
import DateRangePicker from '~/components/DateRangePicker'
import styles from './styles'

const filtersDataSelector = ({ analitycal }) => analitycal.filtersData

const useStyles = makeStyles(styles)
const analitycalSelector = ({ analitycal }) => analitycal

function AnalitycalFilters() {
  const { filters } = useSelector(analitycalSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const { dealerships: dataDealerships, groups: dataGroups } =
    useSelector(filtersDataSelector)

  const handleDatesChange = (dates) => {
    const { startDate, endDate } = dates
    dispatch(AnalitycalActions.setAnalitycalDate(startDate, endDate))
  }

  const handleChangeFilter = (property, value) => {
    dispatch(AnalitycalActions.setAnalitycalChangeFilter(property, value))
  }

  const handleTimerChange = (value) => {
    const from = moment().subtract(value, 'd')
    const to = moment()
    dispatch(AnalitycalActions.setAnalitycalDate(from, to))
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.gridContainerButton}
      spacing={2}
    >
      <Grid item xs={12} sm={6} md>
        <DateRangePicker
          startDate={moment(filters.startDate)}
          startDateId="analitycal-start"
          endDate={moment(filters.endDate)}
          isOutsideRange={() => false}
          endDateId="analitycal-end"
          onDatesChange={handleDatesChange}
        />
        <RangeTimeSelect onSelect={handleTimerChange} />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          variant="outlined"
          label="Grupo"
          fullWidth
          select
          size="small"
          value={filters.groups || 0}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {dataGroups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          onChange={(_, option) =>
            handleChangeFilter('dealership', option ? option.value : null)
          }
          options={dataDealerships
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((option) => ({
              label: option.name,
              value: option.value,
            }))}
          size="small"
          name="dealership"
          value={filters.dealership || 0}
          label="Filtrar por concessionária"
          placeholder="Selecione uma concessionária"
          // loading={loadingDealerships}
          autoSelect={false}
        />
      </Grid>
    </Grid>
  )
}

export default AnalitycalFilters
