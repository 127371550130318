import React, { useState, useRef, useEffect } from 'react'
import {
  makeStyles,
  Popper,
  Grow,
  MenuList,
  Paper,
  ClickAwayListener,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import {
  ViewColumn as ViewColumnIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons'

import styles from './styles'

const useStyles = makeStyles(styles)

function ShowHideColumns({
  showHideColumns,
  optionsShowHideColumn,
  onChangeShowHideColumn,
  popperLocale,
}) {
  const classes = useStyles()
  const [openShowHideColumn, setOpenShowHideColumn] = useState(false)
  const anchorRefShowHideColumn = useRef(null)

  const handleToggle = () => {
    setOpenShowHideColumn(!openShowHideColumn)
  }

  const handleCloseShowHideColumn = (event) => {
    if (
      anchorRefShowHideColumn.current &&
      anchorRefShowHideColumn.current.contains(event.target)
    ) {
      return
    }
    setOpenShowHideColumn(false)
  }

  const prevOpen = useRef(openShowHideColumn)
  useEffect(() => {
    if (prevOpen.current === true && openShowHideColumn === false) {
      anchorRefShowHideColumn.current.focus()
    }

    prevOpen.current = openShowHideColumn
  }, [openShowHideColumn])

  const handleSetChangeShowHideColumn = (name, value) => {
    onChangeShowHideColumn(name, value)
  }
  return (
    <>
      <IconButton
        className={classes.showHideButton}
        disableRipple
        ref={anchorRefShowHideColumn}
        onClick={handleToggle}
      >
        <ViewColumnIcon />
        {openShowHideColumn ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>

      <Popper
        placement={popperLocale}
        className={classes.popper}
        open={openShowHideColumn}
        anchorEl={anchorRefShowHideColumn.current}
        role={undefined}
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseShowHideColumn}>
                <FormControl component="fieldset">
                  <FormGroup className={classes.formGroup}>
                    {optionsShowHideColumn.map((item) => {
                      return (
                        <MenuList className={classes.menuList}>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            key={item.label}
                            control={
                              <Checkbox
                                checked={showHideColumns[item.name]}
                                color="primary"
                              />
                            }
                            label={item.label}
                            onChange={() =>
                              handleSetChangeShowHideColumn(
                                item.name,
                                !showHideColumns[item.name],
                              )
                            }
                            disabled={item.disabled}
                          />
                        </MenuList>
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

ShowHideColumns.propTypes = {
  showHideColumns: PropTypes.objectOf(PropTypes.bool).isRequired,
  optionsShowHideColumn: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeShowHideColumn: PropTypes.func.isRequired,
  popperLocale: PropTypes.string,
}
ShowHideColumns.defaultProps = {
  popperLocale: 'bottom-end',
}

export default ShowHideColumns
