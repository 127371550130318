import React from 'react'
import PropTypes from 'prop-types'
import { Hover } from '../helpers/Hover'
import { HoverStyle } from '../helpers/HoverStyles'

const wrapStyle = {
  padding: '5px',
  position: 'relative',
}
const iconStyle = {
  transformOrigin: 'bottom',
  cursor: 'pointer',
  transition: '200ms transform cubic-bezier(0.23, 1, 0.32, 1)',
}
const iconStyleHover = { transform: 'scale(1.3)' }

export const ReactionBarSelectorEmoji = ({ reaction, onSelect }) => {
  const { node, label } = reaction

  const handleClick = () => {
    // eslint-disable-next-line no-unused-expressions
    onSelect && onSelect(label)
  }

  return (
    <Hover style={wrapStyle}>
      <HoverStyle
        hoverStyle={iconStyleHover}
        style={iconStyle}
        onClick={handleClick}
      >
        {node}
      </HoverStyle>
    </Hover>
  )
}

ReactionBarSelectorEmoji.propTypes = {
  onSelect: PropTypes.func.isRequired,
  reaction: PropTypes.shape({
    label: PropTypes.string,
    node: PropTypes.node,
  }).isRequired,
}

export default ReactionBarSelectorEmoji
