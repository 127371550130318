import { call, takeLatest, put, select } from 'redux-saga/effects'
import moment from 'moment'
import configureApi from '~/helpers/api'

import {
  Types as BiSellersDashboardPageTypes,
  Creators as BiSellersDashboardPageActions,
} from '../ducks/biSellersDashboard'

const optionsSelector = ({ biSellersDashboard }) => biSellersDashboard.options

function* getBiSellersDashboardPage() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const response = yield call(api.get, 'get_sellers', {
      params: {
        pagination: 0,
        page: options.page + 1,
        limit: options.limit,
        month: moment(options.dateValue).format('MM'),
        year: moment(options.dateValue).format('YYYY'),
      },
    })
    yield put(
      BiSellersDashboardPageActions.getBiSellersDashboardPageSuccess(
        response.data,
      ),
    )
  } catch (e) {
    yield put(BiSellersDashboardPageActions.getBiSellersDashboardPageFailure())
  }
}

function* reloadBiSellers() {
  yield put(BiSellersDashboardPageActions.getBiSellersDashboardPageRequest())
}

function* getSellers({ id, codNbs }) {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const response = yield call(api.get, 'sellers_days', {
      params: {
        seller_id: id,
        cod_nbs: codNbs,
        month: moment(options.dateValue).format('MM'),
        year: moment(options.dateValue).format('YYYY'),
      },
    })
    yield put(
      BiSellersDashboardPageActions.getBiSellersDashboardPageSellerSuccess(
        response.data,
      ),
    )
  } catch (e) {
    yield put(
      BiSellersDashboardPageActions.getBiSellersDashboardPageSellerFailure(),
    )
  }
}

export default function* () {
  yield takeLatest(
    BiSellersDashboardPageTypes.GET_BI_SELLERS_DASHBOARD_PAGE_REQUEST,
    getBiSellersDashboardPage,
  )
  yield takeLatest(
    BiSellersDashboardPageTypes.SET_BI_SELLERS_DASHBOARD_PAGE_DATE,
    reloadBiSellers,
  )

  yield takeLatest(
    BiSellersDashboardPageTypes.GET_BI_SELLERS_DASHBOARD_PAGE_SELLER_REQUEST,
    getSellers,
  )
}
