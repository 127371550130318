import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getFunctionsRequest: [],
  getFunctionsFailure: [],
  getFunctionsSuccess: ['data', 'total'],
  setFunctionsSort: ['sort', 'direction'],
  setFunctionsPage: ['page', 'limit'],
  setFunctionsSearch: ['value'],
  resetFunctions: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const getFunctionsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getFunctionsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getFunctionsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setFunctionsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setFunctionsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setFunctionsSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

const resetFunctions = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_FUNCTIONS_REQUEST]: getFunctionsRequest,
  [Types.GET_FUNCTIONS_FAILURE]: getFunctionsFailure,
  [Types.GET_FUNCTIONS_SUCCESS]: getFunctionsSuccess,
  [Types.SET_FUNCTIONS_SORT]: setFunctionsSort,
  [Types.SET_FUNCTIONS_PAGE]: setFunctionsPage,
  [Types.SET_FUNCTIONS_SEARCH]: setFunctionsSearch,
  [Types.RESET_FUNCTIONS]: resetFunctions,
})
