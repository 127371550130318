import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getEquipmentsTypesRequest: [],
  getEquipmentsTypesFailure: [],
  getEquipmentsTypesSuccess: ['newData', 'newTotal'],
  setEquipmentsTypesSort: ['sort', 'direction'],
  setEquipmentsTypesPage: ['page', 'limit'],
  setEquipmentsTypesSearch: ['value'],
  resetEquipmentsTypes: [],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  loading: false,
}

const resetEquipmentsTypes = () => ({
  ...INITIAL_STATE,
})

const getEquipmentsTypesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentsTypesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getEquipmentsTypesSuccess = (
  state = INITIAL_STATE,
  { newData, newTotal },
) => ({
  ...state,
  options: {
    ...state.options,
    total: newTotal,
  },
  data: newData,
  loading: false,
})

const setEquipmentsTypesSort = (
  state = INITIAL_STATE,
  { sort, direction },
) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setEquipmentsTypesPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setEquipmentsTypesSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_EQUIPMENTS_TYPES_REQUEST]: getEquipmentsTypesRequest,
  [Types.GET_EQUIPMENTS_TYPES_FAILURE]: getEquipmentsTypesFailure,
  [Types.GET_EQUIPMENTS_TYPES_SUCCESS]: getEquipmentsTypesSuccess,
  [Types.SET_EQUIPMENTS_TYPES_SORT]: setEquipmentsTypesSort,
  [Types.SET_EQUIPMENTS_TYPES_PAGE]: setEquipmentsTypesPage,
  [Types.SET_EQUIPMENTS_TYPES_SEARCH]: setEquipmentsTypesSearch,
  [Types.RESET_EQUIPMENTS_TYPES]: resetEquipmentsTypes,
})
