import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles, CircularProgress } from '@material-ui/core'

const styles = () => ({
  root: ({ transparency, position, zIndex }) => ({
    position,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex,
    backgroundColor: `rgba(255, 255, 255, ${transparency})`,
  }),
  progress: ({ size }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: (size / 2) * -1,
    marginLeft: (size / 2) * -1,
  }),
})

const useStyles = makeStyles(styles)

const OverlayLoading = ({
  size,
  transparency,
  position,
  zIndex,
  className,
}) => {
  const classes = useStyles({ size, transparency, position, zIndex })
  return (
    <div className={classNames(classes.root, className)}>
      <CircularProgress className={classes.progress} size={size} />
    </div>
  )
}

OverlayLoading.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  transparency: PropTypes.number,
  position: PropTypes.string,
  zIndex: PropTypes.number,
}

OverlayLoading.defaultProps = {
  transparency: 0.5,
  className: '',
  size: 60,
  position: 'absolute',
  zIndex: 9999,
}

export default OverlayLoading
