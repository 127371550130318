import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { Creators as RhDashboardActions } from '~/store/ducks/rhDashboard'
import OverlayLoading from '~/components/OverlayLoading'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import CustomRow from './components/CustomRow'

import styles from './styles'

const helpdeskCallsSelector = ({ rhDashboard }) => rhDashboard.users

const useStyles = makeStyles(styles)

function RhDashboardUserDialog({
  dealershipId,
  open,
  onClose,
  dealershipName,
}) {
  const { data, loading } = useSelector(helpdeskCallsSelector)
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()

  useEffect(() => {
    if (open) {
      dispatch(RhDashboardActions.getRhDashboardUsersRequest(dealershipId))
    }
  }, [open, dealershipId])

  function handleClose() {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      fullScreen={isMobileScreen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Colaboradores - {dealershipName}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Table>
          <TableBody>
            {data?.map((item) => (
              <CustomRow row={item} key={item.id} />
            ))}
          </TableBody>
        </Table>
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RhDashboardUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dealershipId: PropTypes.number,
  dealershipName: PropTypes.string,
}

RhDashboardUserDialog.defaultProps = {
  open: true,
  onClose: () => {},
  dealershipId: null,
  dealershipName: null,
}

export default RhDashboardUserDialog
