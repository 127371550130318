import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as ObjectivesTypes,
  Creators as ObjectivesActions,
} from '../ducks/objectives'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ objectives }) => objectives.options

function* getObjectives() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'objectives', {
      params: {
        ...options,
        pagination: 1,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
      },
    })

    yield put(ObjectivesActions.getObjectivesSuccess(data.data, data.total))
  } catch (e) {
    yield put(ObjectivesActions.getObjectivesFailure())
  }
}

function* reloadObjectives() {
  yield put(ObjectivesActions.getObjectivesRequest())
}

function* removeObjective({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/objectives/${id}`)

    yield put(ObjectivesActions.removeObjectiveFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(ObjectivesActions.removeObjectiveFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(ObjectivesTypes.GET_OBJECTIVES_REQUEST, getObjectives)
  yield takeLatest(ObjectivesTypes.SET_OBJECTIVES_SORT, reloadObjectives)
  yield takeLatest(ObjectivesTypes.SET_OBJECTIVES_PAGE, reloadObjectives)
  yield takeLatest(ObjectivesTypes.REMOVE_OBJECTIVE_REQUEST, removeObjective)
}
