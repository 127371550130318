import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as DealershipsTypes,
  Creators as DealershipsActions,
} from '../ducks/dealerships'

const optionsSelector = ({ dealerships }) => dealerships.options

function* getDealerships() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const response = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: `${options.sort},${options.direction}`,
      },
    })
    yield put(DealershipsActions.getDealershipsSuccess(response.data))
  } catch (e) {
    yield put(DealershipsActions.getDealershipsFailure())
  }
}

function* reloadDealerships() {
  yield put(DealershipsActions.getDealershipsRequest())
}

export default function* () {
  yield takeLatest(DealershipsTypes.GET_DEALERSHIPS_REQUEST, getDealerships)
  yield takeLatest(DealershipsTypes.SET_DEALERSHIPS_SORT, reloadDealerships)
}
