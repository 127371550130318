export default (theme) => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 12,
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
      minHeight: '550px',
    },
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },
  dialogContent: {
    height: 650,
    padding: 0,
  },
  subtitleWrapper: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  dividerBottom: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },

  form: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  container: {
    height: '400px',
  },
  wrapper: {
    position: 'relative',
    bottom: 250,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 20,
    alignItems: 'center',
  },
  emptyIcon: {
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    height: 100,
    color: '#e4e4e4',
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
})
