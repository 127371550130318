import React from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  makeStyles,
} from '@material-ui/core'

import moment from 'moment'

import { Creators as ProfileActions } from '~/store/ducks/profile'

import EmptyList from '~/components/EmptyList'

import Portlet from '~/components/Portlet'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import EqualizerIcon from '@material-ui/icons/Equalizer'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import styles from './styles'

const ProfileSelector = ({ profile }) => profile

export default function SalesTab({ dataSales, user }) {
  const { options } = useSelector(ProfileSelector)

  const dispatch = useDispatch()

  const useStyles = makeStyles(styles)

  const date = moment(new Date())
  const classes = useStyles()

  const getMedia = () => {
    const media =
      (dataSales.historical[0][1] +
        dataSales.historical[1][1] +
        dataSales.historical[2][1] +
        dataSales.historical[3][1] +
        dataSales.historical[4][1]) /
      5
    return media.toFixed(2)
  }

  const handleDateChange = (value) => {
    dispatch(ProfileActions.setProfilePageDate(value))
    dispatch(ProfileActions.getUserSalesProfileRequest(user.id, value))
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          style={{ height: 700 }}
        >
          {dataSales?.result?.data?.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    marginTop: '34px',
                    height: '53px',
                    marginRight: '24px',
                  }}
                >
                  <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                    <KeyboardDatePicker
                      format="MM/yyyy"
                      inputVariant="outlined"
                      fullWidth
                      views={['month', 'year']}
                      label="Mês/Ano"
                      invalidDateMessage="Data inválida"
                      value={options.dateValueSales}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={8} spacing={2}>
                  <Grid container className={classes.sellersMedia}>
                    <Grid item>
                      <CalendarTodayIcon
                        style={{
                          color: '#1B1464',
                          marginRight: 8,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ marginRight: 8 }}
                      >
                        Últimos 5 meses:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        {' '}
                        {moment(date).subtract(4, 'month').format('MM/YY')} :
                        <strong> {dataSales.historical[4][1]}</strong>
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: 4 }}>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        {' '}
                        {moment(date).subtract(3, 'month').format('MM/YY')} :
                        <strong> {dataSales.historical[3][1]}</strong>
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: 4 }}>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        {' '}
                        {moment(date).subtract(2, 'month').format('MM/YY')} :
                        <strong> {dataSales.historical[2][1]}</strong>
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: 4 }}>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        {' '}
                        {moment(date).subtract(1, 'month').format('MM/YY')} :
                        <strong> {dataSales.historical[1][1]}</strong>
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: 4 }}>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        {' '}
                        {moment(date).format('MM/YY')}:
                        <strong> {dataSales.historical[0][1]}</strong>
                      </Typography>
                    </Grid>

                    <Grid item style={{ marginLeft: 84 }}>
                      <EqualizerIcon
                        style={{ color: '#1B1464', marginRight: 8 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ marginRight: 8 }}
                      >
                        Média Final:
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="h6">
                        <strong>{getMedia()}</strong>
                      </Typography>
                    </Grid>
                    {/* <Grid item style={{ marginLeft: 84 }}>
                        <InsertEmoticonIcon style={{ color: '#1B1464' }} />
                      </Grid> */}
                    {/* <Grid item>
                        <Typography variant="h6" color="primary">
                          Avaliação:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Rating
                          readOnly
                          value={1}
                          max={4}
                          style={{ margin: 0, padding: 0 }}
                        />
                      </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Portlet className={classes.grow}>
                <Table size="small" className={classes.tableSales}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cod. da Proposta</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Segmento</TableCell>
                      <TableCell>Versão</TableCell>
                      <TableCell>Ano/Modelo</TableCell>
                      <TableCell>Cliente</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSales?.result?.data?.map((item) => {
                      return (
                        <TableRow className={classes.tableRow}>
                          <TableCell>{item.cod_proposta} </TableCell>
                          <TableCell>
                            {moment(item.emissao).format('DD/MM/YYYY')}{' '}
                          </TableCell>
                          <TableCell>{item.novo_usado} </TableCell>
                          <TableCell>{item.descricao_modelo}</TableCell>
                          <TableCell>{item.ano_modelo} </TableCell>
                          <TableCell>{item.nome_cliente} </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Portlet>
            </Grid>
          )}
          {dataSales?.result?.data?.length === 0 && (
            <Grid item xs={12} className={classes.emptyWrapper}>
              <Portlet className={classes.growEmpty}>
                <EmptyList message="Não há vendas para serem visualizadas" />
              </Portlet>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

SalesTab.propTypes = {
  dataSales: PropTypes.arrayOf().isRequired,

  user: PropTypes.shape.isRequired,
}
