import React, { useEffect } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import { Creators as StatusActions } from '~/store/ducks/status'
import { Creators as StatusListActions } from '~/store/ducks/statusList'
import OverlayLoading from '~/components/OverlayLoading'

import styles from './styles'

const useStyles = makeStyles(styles)

const statusSelector = ({ status }) => status.data
const loadingSelector = ({ status: { loading } }) => loading

const StatusDialog = ({ open, onClose, id }) => {
  const isMobileScreen = useMobileScreen()
  const dispatch = useDispatch()
  const classes = useStyles()
  const statusData = useSelector(statusSelector)
  const loading = useSelector(loadingSelector)
  const isEdit = !!id

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  const reloadStatusList = () => {
    dispatch(StatusListActions.getStatusListRequest())
  }

  const onSubmit = (data) => {
    if (isEdit) {
      // update -> PUT
      dispatch(
        StatusActions.updateStatusRequest(id, data, () => reloadStatusList()),
      )
    } else {
      // create -> POST
      dispatch(StatusActions.addStatusRequest(data, () => reloadStatusList()))
    }
    onClose()
  }

  useEffect(() => {
    if (isEdit) {
      dispatch(StatusActions.getStatusRequest(id))
    } else {
      reset({ name: '' })
    }
  }, [isEdit])

  useEffect(() => {
    if (isEdit) {
      reset(statusData)
    }
  }, [isEdit, statusData])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          Status
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      {!isEdit && (
        <>
          <Grid container className={classes.subtitleWrapper}>
            <Grid item xs={12}>
              <Typography variant="h5">Novo Status</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dividerBottom} />
        </>
      )}

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)} id="formStatus">
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, field }) => (
                    <TextField
                      variant="outlined"
                      autoFocus
                      label="Nome"
                      type="text"
                      onChange={onChange}
                      value={value}
                      error={!!errors.name}
                      helperText={!!errors.name && 'Campo obrigatório'}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
        <Button
          color="primary"
          type="submit"
          form="formStatus"
          variant="contained"
        >
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

StatusDialog.defaultProps = {
  id: null,
}

export default StatusDialog
