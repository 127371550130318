import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { get, find } from 'lodash'

function RhfAutocomplete({ name, label, control, options, error }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      defaultValue=""
      render={({ field: { onChange, value, onBlur, ...rest } }) => (
        <Autocomplete
          name={name}
          onChange={(ev, opt) => {
            onChange(get(opt, 'value', ''))
          }}
          value={value ? find(options, (opt) => opt.value === value) : null}
          onBlur={onBlur}
          options={options}
          getOptionLabel={(option) => {
            return option.value ? option.label : option
          }}
          fullWidth
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              {...rest}
              error={error}
              label={label}
              // inputRef={ref}
              variant="outlined"
            />
          )}
        />
      )}
    />
  )
}

RhfAutocomplete.propTypes = {
  unlistedValue: PropTypes.shape({
    allow: PropTypes.bool,
    setValue: PropTypes.func,
  }),
  setArbitraryValue: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      prefix: PropTypes.string, // Helps to identify translation topic
    }),
  ).isRequired,
}

RhfAutocomplete.defaultProps = {
  unlistedValue: {
    allow: false,
    setValue: () => {},
  },
  setArbitraryValue: () => {},
  defaultValue: null,
  error: null,
}

export default RhfAutocomplete
