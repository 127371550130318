import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addEquipmentRequest: ['data', 'callback'],
  addEquipmentFinish: [],
  getEquipmentRequest: ['id'],
  getEquipmentSuccess: ['data'],
  getEquipmentFailure: [],
  updateEquipmentRequest: ['id', 'data', 'callback'],
  updateEquipmentFinish: [],
  removeEquipmentRequest: ['id', 'callback'],
  removeEquipmentFinish: [],
  getEquipmentDealershipsRequest: [],
  getEquipmentDealershipsSuccess: ['data'],
  getEquipmentDealershipsFailure: [],
  getEquipmentUsersRequest: [],
  getEquipmentUsersSuccess: ['data'],
  getEquipmentUsersFailure: [],
  getEquipmentEquipmentsTypesRequest: [],
  getEquipmentEquipmentsTypesSuccess: ['data'],
  getEquipmentEquipmentsTypesFailure: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  dealerships: {
    data: [],
    loading: false,
  },
  users: {
    data: [],
    loading: false,
  },
  equipmentsTypes: {
    data: [],
    loading: false,
  },
}

const getEquipmentDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getEquipmentDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getEquipmentDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getEquipmentEquipmentsTypesRequest = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    loading: true,
  },
})

const getEquipmentEquipmentsTypesSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    data,
    loading: false,
  },
})

const getEquipmentEquipmentsTypesFailure = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    loading: false,
  },
})

const getEquipmentUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
  users: {
    ...state.users,
    loading: true,
  },
})

const getEquipmentUsersSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  users: {
    ...state.users,
    data,
    loading: false,
  },
})

const getEquipmentUsersFailure = (state = INITIAL_STATE) => ({
  ...state,
  users: {
    ...state.users,
    loading: false,
  },
})

const addEquipmentRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  }
}

const addEquipmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getEquipmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getEquipmentFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateEquipmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateEquipmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeEquipmentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeEquipmentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_EQUIPMENT_REQUEST]: addEquipmentRequest,
  [Types.ADD_EQUIPMENT_FINISH]: addEquipmentFinish,
  [Types.GET_EQUIPMENT_REQUEST]: getEquipmentRequest,
  [Types.GET_EQUIPMENT_SUCCESS]: getEquipmentSuccess,
  [Types.GET_EQUIPMENT_FAILURE]: getEquipmentFailure,
  [Types.UPDATE_EQUIPMENT_REQUEST]: updateEquipmentRequest,
  [Types.UPDATE_EQUIPMENT_FINISH]: updateEquipmentFinish,
  [Types.REMOVE_EQUIPMENT_REQUEST]: removeEquipmentRequest,
  [Types.REMOVE_EQUIPMENT_FINISH]: removeEquipmentFinish,
  [Types.GET_EQUIPMENT_DEALERSHIPS_REQUEST]: getEquipmentDealershipsRequest,
  [Types.GET_EQUIPMENT_DEALERSHIPS_SUCCESS]: getEquipmentDealershipsSuccess,
  [Types.GET_EQUIPMENT_DEALERSHIPS_FAILURE]: getEquipmentDealershipsFailure,
  [Types.GET_EQUIPMENT_USERS_REQUEST]: getEquipmentUsersRequest,
  [Types.GET_EQUIPMENT_USERS_SUCCESS]: getEquipmentUsersSuccess,
  [Types.GET_EQUIPMENT_USERS_FAILURE]: getEquipmentUsersFailure,
  [Types.GET_EQUIPMENT_EQUIPMENTS_TYPES_REQUEST]: getEquipmentEquipmentsTypesRequest,
  [Types.GET_EQUIPMENT_EQUIPMENTS_TYPES_SUCCESS]: getEquipmentEquipmentsTypesSuccess,
  [Types.GET_EQUIPMENT_EQUIPMENTS_TYPES_FAILURE]: getEquipmentEquipmentsTypesFailure,
})
