export const generateBlobImage = async (croppedCompleted, fileInput) => {
  if (!croppedCompleted || !fileInput.current) {
    return null
  }

  const image = fileInput.current
  const canvas = document.createElement('canvas')
  const crop = croppedCompleted

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext('2d')
  const pixelRatio = window.devicePixelRatio

  canvas.width = crop.width * pixelRatio
  canvas.height = crop.height * pixelRatio

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const generateImageUrl = (croppedCompleted, fileInput) => {
  if (!croppedCompleted || !fileInput.current) {
    return null
  }

  const image = fileInput.current
  const canvas = document.createElement('canvas')
  const crop = croppedCompleted

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext('2d')
  const pixelRatio = window.devicePixelRatio

  canvas.width = crop.width * pixelRatio
  canvas.height = crop.height * pixelRatio

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )
  const base64Image = canvas.toDataURL('image/jpeg')
  return base64Image
}
