import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Grid, Typography } from '@material-ui/core'
// import moment from 'moment'
import { Creators as NewsSinglePageActions } from '~/store/ducks/newsSingle'
import { useDispatch, useSelector } from 'react-redux'

import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'
import styles from './styles'
import NewsCardSingle from './components/NewsCardSingle'
import CommentsCard from './components/CommentsCard'

const useStyles = makeStyles(styles)

const newsSinglePageSelector = ({ newsSingle }) => newsSingle

export default function NewSinglePage({
  match: {
    params: { id },
  },
}) {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(newsSinglePageSelector)

  useEffect(() => {
    dispatch(NewsSinglePageActions.getNewsSingleRequest(id))
  }, [])

  const classes = useStyles()

  return (
    <>
      {!loading ? (
        <Grid
          container
          justifyContent="space-between"
          className={classes.grid}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            {data?.id && <NewsCardSingle data={data} />}
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.communicationTitleWrapper}>
              <Typography variant="h5" style={{ color: '#FFFF' }}>
                Comentários
              </Typography>
            </div>
            <Grid>
              <Grid>{data?.id && <CommentsCard data={data} />}</Grid>
              {!loading && data.comments.length === 0 && (
                <Grid item xs={12} className={classes.emptyWrapper}>
                  <Portlet className={classes.grow}>
                    <EmptyList message="Não há comentários para serem visualizados" />
                  </Portlet>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  )
}

NewSinglePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
