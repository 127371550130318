import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core'

function RhfSelect({
  name,
  label,
  control,
  defaultValue,
  error,
  children,
  ...props
}) {
  const labelId = `${name}-label`
  return (
    <FormControl error={!!error} {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            {...field}
            value={field.value || ''}
            labelId={labelId}
            label={label}
          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>{error && error.message}</FormHelperText>
    </FormControl>
  )
}

RhfSelect.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
  defaultValue: PropTypes.string,
}

RhfSelect.defaultProps = {
  error: null,
  defaultValue: null,
}

export default RhfSelect
