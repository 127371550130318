import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MomentBusinessDays from 'moment-business-days'
import { useSelector } from 'react-redux'

import { TableCell, TableRow, IconButton, makeStyles } from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import styles from './styles'

const useStyles = makeStyles(styles)
const biDashboardSelector = ({ biDashboard }) => biDashboard

export default function CustomRow({ row }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const { showHideColumns } = useSelector(biDashboardSelector)

  useEffect(() => {
    moment.updateLocale('en', {
      workingWeekdays: [1, 2, 3, 4, 5, 6],
      holidays: [
        '01-01',
        '02-02',
        '04-03',
        '05-03',
        '06-03',
        '19-04',
        '21-04',
        '01-05',
        '20-06',
        '07-09',
        '20-09',
        '12-10',
        '15-10',
        '28-10',
        '02-11',
        '15-11',
        '24-12',
        '25-12',
        '31-12',
      ],
      holidayFormat: 'DD-MM',
    })
  }, [])

  const getFaturadosPorDia = (item) => {
    const businessDays = MomentBusinessDays().monthBusinessDays()
    const lastBusinessDay = moment(businessDays.pop())

    const days = MomentBusinessDays().businessDiff(lastBusinessDay)

    let total = (item.sales - (item.wallet + item.daily_sales)) / days
    if (total < 0) {
      total = 0
    }
    return Number(total).toFixed(2)
  }

  const getColorRow = (item) => {
    const fpd = getFaturadosPorDia(item)
    return item.sales / item.dias_uteis < Number(fpd) ? 'Red' : 'Blue'
  }

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {showHideColumns.group_name && (
          <TableCell align="center">{row.group_name}</TableCell>
        )}
        {showHideColumns.totalSales && (
          <TableCell align="center">{row.totalSales}</TableCell>
        )}
        {showHideColumns.totalWallet && (
          <TableCell align="center">{row.totalWallet}</TableCell>
        )}
        {showHideColumns.totalDailySales && (
          <TableCell align="center">{row.totalDailySales}</TableCell>
        )}
        {showHideColumns.totalGoals && (
          <TableCell align="center">{row.totalGoals}</TableCell>
        )}
        {showHideColumns.totalDailyGoal && (
          <TableCell align="center">
            {parseFloat(row.totalDailyGoal.toFixed(2))}
          </TableCell>
        )}
        {showHideColumns.totalPreOrders && (
          <TableCell align="center">{row.totalPreOrders}</TableCell>
        )}
        {showHideColumns.totalProposal && (
          <TableCell align="center">{row.totalProposal}</TableCell>
        )}
        {showHideColumns.totalBilled && (
          <TableCell align="center">{row.totalBilled}</TableCell>
        )}
        {showHideColumns.totalGeral && (
          <TableCell align="center">{row.totalGeral}</TableCell>
        )}
        <TableCell />
      </TableRow>
      {open &&
        row?.data?.map((dealership) => {
          return (
            <TableRow
              key={dealership.city_name}
              className={classes.tableRowOpen}
            >
              <TableCell />
              {showHideColumns.group_name && (
                <TableCell component="td" scope="row" align="center">
                  {dealership.city_name}
                </TableCell>
              )}
              {showHideColumns.totalSales && (
                <TableCell
                  align="center"
                  className={classes.leftGroupTableCells}
                >
                  {dealership.sales}
                </TableCell>
              )}
              {showHideColumns.totalWallet && (
                <TableCell
                  align="center"
                  className={classes.leftGroupTableCells}
                >
                  {dealership.wallet}
                </TableCell>
              )}
              {showHideColumns.totalDailySales && (
                <TableCell
                  align="center"
                  className={classes.leftGroupTableCells}
                >
                  {dealership.daily_sales}
                </TableCell>
              )}
              {showHideColumns.totalGoals && (
                <TableCell
                  align="center"
                  className={classes.leftGroupTableCells}
                >
                  {dealership.total_objetivos}
                </TableCell>
              )}
              {showHideColumns.totalDailyGoal && (
                <TableCell
                  align="center"
                  className={classes.leftGroupTableCells}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: getColorRow(dealership),
                    }}
                  >
                    {parseFloat(dealership.faturados_dia).toFixed(2)}
                  </span>
                </TableCell>
              )}
              {showHideColumns.totalPreOrders && (
                <TableCell
                  align="center"
                  className={classes.rightGroupTableCells}
                >
                  {dealership.pre_ordens}
                </TableCell>
              )}
              {showHideColumns.totalProposal && (
                <TableCell
                  align="center"
                  className={classes.rightGroupTableCells}
                >
                  {dealership.total_propostas}
                </TableCell>
              )}
              {showHideColumns.totalBilled && (
                <TableCell
                  align="center"
                  className={classes.rightGroupTableCells}
                >
                  {dealership.total_faturados}
                </TableCell>
              )}
              {showHideColumns.totalGeral && (
                <TableCell
                  align="center"
                  className={classes.rightGroupTableCells}
                >
                  {dealership.total_geral}
                </TableCell>
              )}
            </TableRow>
          )
        })}
    </>
  )
}

CustomRow.propTypes = {
  row: PropTypes.shape({
    group_name: PropTypes.string,
    id: PropTypes.string,
    totalBilled: PropTypes.number,
    totalDailyGoal: PropTypes.number,
    totalDailySales: PropTypes.number,
    totalGeral: PropTypes.number,
    totalGoals: PropTypes.number,
    totalPreOrders: PropTypes.number,
    totalProposal: PropTypes.number,
    totalSales: PropTypes.number,
    totalWallet: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}
