import { call, takeLatest, put } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as NewsSingleTypes,
  Creators as NewsSingleActions,
} from '../ducks/newsSingle'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()

function* getNewsSingle({ id }) {
  try {
    const { data } = yield call(api.get, `news/${id}`)
    yield put(NewsSingleActions.getNewsSingleSuccess(data))
  } catch (e) {
    yield put(NewsSingleActions.getNewsSingleFailure())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(NewsSingleTypes.GET_NEWS_SINGLE_REQUEST, getNewsSingle)
}
