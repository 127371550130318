export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 0,
  },
  tableWrapper: {
    height: '100%',
    position: 'relative',
    minHeight: 400,
    width: '100%',
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  grow: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
  textWrapper: {
    display: 'grid',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 4,
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  circleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    cursor: 'pointer',
  },
})
