/* eslint-disable no-return-assign */
import React from 'react'
import PropTypes from 'prop-types'
import OrgChart from '@unicef/react-org-chart'
import avatarPersonnel from '~/assets/images/avatar-personnel.svg'
import '../../organogram.css'

export default class CustomOrganogram extends React.Component {
  constructor(props) {
    super(props)
    const { data } = this.props
    this.state = {
      tree: data,
      config: {},
      // highlightPostNumbers: [1],
    }
  }

  componentDidUpdate() {
    const allReportsTexts = window.document.querySelectorAll(
      '.org-chart-person-reports',
    )
    if (allReportsTexts.length) {
      allReportsTexts.forEach((_, ind) => {
        return (allReportsTexts[ind].innerHTML = allReportsTexts[
          ind
        ].innerHTML.replace('supervisee', 'Subordinado'))
      })
    }

    const allReportsTitle = window.document.querySelectorAll(
      '.org-chart-person-name',
    )

    if (allReportsTitle.length) {
      allReportsTitle.forEach((_, ind) => {
        if (
          allReportsTitle[ind].children[0]?.textContent.includes('Afastado') ||
          allReportsTitle[ind].children[1]?.textContent.includes('Afastado') ||
          allReportsTitle[ind].children[2]?.textContent.includes('Afastado')
        ) {
          return (allReportsTitle[ind].style.fill = '#FF0000')
        }
        return allReportsTitle[ind]
      })
    }
  }

  handleOnChangeConfig = (config) => {
    this.setState({ config })
  }

  handleLoadConfig = () => {
    const { config } = this.state
    return config
  }

  render() {
    const { tree } = this.state

    return (
      <>
        <div className="zoom-buttons">
          <button
            type="button"
            className="btn btn-outline-primary zoom-button"
            id="zoom-in"
          >
            +
          </button>
          <button
            type="button"
            className="btn btn-outline-primary zoom-button"
            id="zoom-out"
          >
            -
          </button>
        </div>
        {tree && (
          <OrgChart
            tree={tree}
            onConfigChange={(config) => {
              this.handleOnChangeConfig(config)
            }}
            loadConfig={(d) => {
              const configuration = this.handleLoadConfig(d)
              if (configuration) {
                return configuration
              }
              return {}
            }}
            loadImage={(d) => {
              return Promise.resolve(d.person.avatar || avatarPersonnel)
            }}
            loadParent={(d) => {
              const parentData = this.getParent(d)
              return parentData
            }}
            loadChildren={(d) => {
              const childrenData = this.getChild(d.id)
              return childrenData
            }}
          />
        )}
      </>
    )
  }
}

CustomOrganogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
