import React from 'react'
import { find, sortBy } from 'lodash'
import { makeStyles, Grid } from '@material-ui/core'

import 'moment/locale/pt-br'

import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '~/components/Autocomplete'
import { Creators as UsersRamalActions } from '~/store/ducks/usersRamal'
import styles from './styles'

const dealershipsFilterSelector = ({ usersRamal }) => usersRamal.dealerships
const departmentsFilterSelector = ({ usersRamal }) => usersRamal.departments
const usersRamalSelector = ({ usersRamal }) => usersRamal

const useStyles = makeStyles(styles)

function UsersRamalFilters() {
  const { options } = useSelector(usersRamalSelector)

  const classes = useStyles()
  const dispatch = useDispatch()

  const { data: dealershipsFilter, loading: loadingDealershipsFilter } =
    useSelector(dealershipsFilterSelector)

  const { data: departmentsFilter, loading: loadingDepartmentsFilter } =
    useSelector(departmentsFilterSelector)

  const handlePropertyFilterChange = (property, value) => {
    dispatch(UsersRamalActions.setUsersRamalPropertyFilter(property, value))
  }

  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  return (
    <Grid container className={classes.gridContainerButton} spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'dealership_id',
              option ? option.value : null,
            )
          }}
          options={sortBy(dealershipsFilter, (a) => a.name).map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="dealership_id"
          value={getFilterValue('dealership_id')}
          className={classes.autocomplete}
          label="Concessionária Vinculada"
          placeholder="Selecione uma Concessionária"
          loading={loadingDealershipsFilter}
          autoSelect={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'department_id',
              option ? option.value : null,
            )
          }}
          options={sortBy(departmentsFilter, (a) => a.name).map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="department_id"
          value={getFilterValue('department_id')}
          className={classes.autocomplete}
          label="Departamento"
          placeholder="Selecione um departamento"
          loading={loadingDepartmentsFilter}
          autoSelect={false}
        />
      </Grid>
    </Grid>
  )
}

export default UsersRamalFilters
