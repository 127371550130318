// Colors
import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.background.default,
    '&:hover': {
      backgroundColor: palette.background.light,
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
    '&$focused': {
      backgroundColor: palette.background.light,
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
  },
}
