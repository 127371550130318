import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as BiDashboardActions } from '~/store/ducks/biDashboard'

import Portlet from '~/components/Portlet'
import ShowHideColumns from '~/components/ShowHideColumns'

import CustomRow from './components/CustomRow'
import styles from './styles'

const tableDataBiDashboardSelector = ({ biDashboard: { tableData } }) =>
  tableData

const useStyles = makeStyles(styles)

const biDashboardSelector = ({ biDashboard }) => biDashboard

function BiDashboardTable({ variant }) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const tableDataBiDashboard = useSelector(tableDataBiDashboardSelector)
  const { newsData, semiNewsData, directData, loading } = tableDataBiDashboard
  const [data, setData] = useState([])
  const { showHideColumns } = useSelector(biDashboardSelector)
  const optionsShowHideColumn = [
    { name: 'group_name', label: 'Loja', disabled: true },
    { name: 'totalSales', label: 'Objetivo' },
    { name: 'totalWallet', label: 'Carteira' },
    { name: 'totalDailySales', label: 'Vendas do mês' },
    { name: 'totalGoals', label: 'Total' },
    { name: 'totalDailyGoal', label: 'Meta Diária' },
    { name: 'totalPreOrders', label: 'Pré-Ordens' },
    { name: 'totalProposal', label: 'Propostas' },
    { name: 'totalBilled', label: 'Faturados' },
    { name: 'totalGeral', label: 'Total' },
  ]
  useEffect(() => {
    if (variant === 'news') {
      setData(newsData)
    }
    if (variant === 'semiNews') {
      setData(semiNewsData)
    }
    if (variant === 'direct') {
      setData(directData)
    }
  }, [newsData, semiNewsData, directData])

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      BiDashboardActions.setChangeShowHideColumnsBiDashboard(newColumnsTable),
    )
  }

  return (
    <>
      {!loading && data?.length > 0 && (
        <div className={classes.root}>
          <Portlet className={classes.grow}>
            <Typography variant="h4" className={classes.title}>
              {variant === 'news' && 'Novos'}
              {variant === 'semiNews' && 'Seminovos'}
              {variant === 'direct' && 'Venda Direta'}
            </Typography>
            <div className={classes.tableWrapper}>
              <div className={classes.listWrapper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {showHideColumns.group_name && (
                        <TableCell align="center">Loja</TableCell>
                      )}
                      {showHideColumns.totalSales && (
                        <TableCell align="center">Objetivo</TableCell>
                      )}
                      {showHideColumns.totalWallet && (
                        <TableCell align="center">Carteira</TableCell>
                      )}
                      {showHideColumns.totalDailySales && (
                        <TableCell align="center">Vendas do mês</TableCell>
                      )}
                      {showHideColumns.totalGoals && (
                        <TableCell align="center">Total</TableCell>
                      )}
                      {showHideColumns.totalDailyGoal && (
                        <TableCell align="center">Meta Diária</TableCell>
                      )}
                      {showHideColumns.totalPreOrders && (
                        <TableCell align="center">Pré-Ordens</TableCell>
                      )}
                      {showHideColumns.totalProposal && (
                        <TableCell align="center">Propostas</TableCell>
                      )}
                      {showHideColumns.totalBilled && (
                        <TableCell align="center">Faturados</TableCell>
                      )}
                      {showHideColumns.totalGeral && (
                        <TableCell align="center">Total</TableCell>
                      )}
                      {variant === 'news' && (
                        <TableCell>
                          <ShowHideColumns
                            showHideColumns={showHideColumns}
                            optionsShowHideColumn={optionsShowHideColumn}
                            onChangeShowHideColumn={
                              handleSetChangeShowHideColumn
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => (
                      <CustomRow row={row} key={row.id} />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Portlet>
        </div>
      )}
    </>
  )
}

BiDashboardTable.propTypes = {
  variant: PropTypes.string.isRequired,
}

export default BiDashboardTable
