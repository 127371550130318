import { call, takeLatest, put, all } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as ObjectiveTypes,
  Creators as ObjectiveActions,
} from '../ducks/objective'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* getObjective({ id }) {
  const isEdit = !!id
  try {
    const api = configureApi()
    if (isEdit) {
      const { objectives, objectivesDealerships } = yield all({
        objectives: call(api.get, `objectives/${id}`),
        objectivesDealerships: call(api.get, 'objective_dealerships', {
          params: {
            pagination: 0,
            order: 'objective_type_id,asc',
            'where[objective_id]': id,
          },
        }),
      })
      yield put(
        ObjectiveActions.getObjectiveSuccess(isEdit, {
          objectives: objectives.data,
          objectivesDealerships: objectivesDealerships.data,
        }),
      )
    } else {
      const { data } = yield call(api.get, 'objectives_new')
      yield put(ObjectiveActions.getObjectiveSuccess(isEdit, data))
    }
  } catch (e) {
    yield put(ObjectiveActions.getObjectiveFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addObjective({ data, callback }) {
  try {
    const api = configureApi()
    const response = yield call(api.post, 'objectives', data)

    if (callback) {
      yield call(callback(response.data))
    }

    yield put(ObjectiveActions.addObjectiveFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento adicionado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(ObjectiveActions.addObjectiveFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Planejamento adicionado com sucesso!',
        variant: 'success',
      }),
    )
  }
}

function* addObjectiveDealerships({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'objective_dealerships', data)

    if (callback) {
      yield call(callback)
    }
    yield put(ObjectiveActions.addObjectiveDealershipsFinish())
  } catch (e) {
    yield put(ObjectiveActions.addObjectiveDealershipsFinish())
  }
}

function* updateObjective({ id, data, callback }) {
  try {
    const api = configureApi()
    const response = yield call(api.put, `objectives/${id}`, data)
    if (callback) {
      yield call(callback(response.data.id))
    }
  } catch (e) {
    yield put(ObjectiveActions.updateObjectiveFinish())
  }
}

function* getCopyObjective({ callback }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'objectives', {
      params: {
        limit: 1,
        pagination: 1,
      },
    })
    const objectiveId = data.data[0].id || null
    if (callback) {
      yield call(callback, objectiveId)
    }
    yield put(ObjectiveActions.getCopyObjectiveSuccess())
  } catch (e) {
    yield put(ObjectiveActions.getCopyObjectiveFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(ObjectiveTypes.GET_OBJECTIVE_REQUEST, getObjective)
  yield takeLatest(ObjectiveTypes.ADD_OBJECTIVE_REQUEST, addObjective)
  yield takeLatest(
    ObjectiveTypes.ADD_OBJECTIVE_DEALERSHIPS_REQUEST,
    addObjectiveDealerships,
  )
  yield takeLatest(ObjectiveTypes.UPDATE_OBJECTIVE_REQUEST, updateObjective)
  yield takeLatest(ObjectiveTypes.GET_COPY_OBJECTIVE_REQUEST, getCopyObjective)
}
