import React from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as QuizzeActions } from '~/store/ducks/quiz'

import Portlet from '~/components/Portlet'
// import ListFooter from '~/components/ListFooter'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'

import styles from './styles'

const quizzeSelector = ({ quiz }) => quiz

const useStyles = makeStyles(styles)

function AwnsersFormTab() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, options, loading } = useSelector(quizzeSelector)
  const { sort, direction } = options
  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(QuizzeActions.setQuizzeSort(newSort, sortDirection))
  }

  // const handleChangeRowsPerPage = (ev) => {
  //   const {
  //     target: { value },
  //   } = ev
  //   dispatch(QuizzeActions.setQuizzePage(0, value))
  // }

  // const handleChangePage = (_, page) => {
  //   dispatch(QuizzeActions.setQuizzePage(page, options.limit))
  // }

  return (
    <div className={classes.root}>
      <Portlet className={classes.grow}>
        {!loading && data?.users?.length === 0 && (
          <EmptyList message="Não há colaboradores para serem visualizados" />
        )}
        <div className={classes.tableWrapper}>
          {loading && <OverlayLoading />}
          {!loading && data?.users?.length > 0 && (
            <div className={classes.listWrapper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <TableSortLabel
                        active={sort === 'name'}
                        onClick={() => handleSortChange('name')}
                        direction={direction}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={sort === 'created_at'}
                        onClick={() => handleSortChange('created_at')}
                        direction={direction}
                      >
                        Data da resposta
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.users.map((item) => (
                    <TableRow hover key={item.id} className={classes.tableRow}>
                      <TableCell>{item.name || '-'}</TableCell>
                      <TableCell align="Right">
                        {moment(item.created_at || '-').format(
                          'DD/MM/YYYY HH:mm',
                          'HH/MM',
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>

        {/* {!loading && data?.users?.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )} */}
      </Portlet>
    </div>
  )
}

export default AwnsersFormTab
