import React, { useEffect } from 'react'

import 'moment/locale/pt-br'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import EmptyList from '~/components/EmptyList'
import { Creators as RhMonthComparativeActions } from '~/store/ducks/rhMonthComparative'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import RhMonthComparativeFilters from './components/RhComparativeFilters/index'
import RhMonthComparativeChart from './components/RhMonthComparativeChart'

import styles from './styles'

const rhMonthComparativeSelector = ({ rhMonthComparative }) =>
  rhMonthComparative

const useStyles = makeStyles(styles)

export default function RHMonthComparative() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { data, loading } = useSelector(rhMonthComparativeSelector)
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(RhMonthComparativeActions.getMonthComparativeDataRequest())
    dispatch(RhMonthComparativeActions.getMonthComparativeFiltersRequest())
  }, [])

  return (
    <>
      <div className={classes.root}>
        {!isSmallScreen && <RhMonthComparativeFilters />}
        {isSmallScreen && (
          <>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.filterWropper}>
                  <FilterIcon color="primary" className={classes.filterIcon} />
                  <Typography variant="h5" color="primary">
                    Filtros
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <RhMonthComparativeFilters />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {loading && <OverlayLoading />}
        {!loading && (
          <Grid container spacing={2}>
            {data.length > 0 &&
              data.map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.dealership}>
                  <div className={classes.wrapper}>
                    <Portlet>
                      <RhMonthComparativeChart
                        datasets={item.datasets}
                        labels={item.labels}
                        dealership={item.dealership}
                      />
                    </Portlet>
                  </div>
                </Grid>
              ))}
          </Grid>
        )}
        {!loading && data.length === 0 && (
          <div className={classes.emptyWrapper}>
            <Portlet className={classes.portlet}>
              <EmptyList message="Não há dados para serem visualizados" />
            </Portlet>
          </div>
        )}
      </div>
    </>
  )
}
