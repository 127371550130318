import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addUserRequest: ['data', 'callback'],
  addUserFinish: [],
  getUserRequest: ['id'],
  getUserSuccess: ['data'],
  getUserFailure: [],
  updateUserRequest: ['id', 'data', 'callback'],
  updateUserFinish: [],
  removeUserRequest: ['id', 'callback'],
  removeUserFinish: [],
  getUserDealershipsRequest: [],
  getUserDealershipsSuccess: ['data'],
  getUserDealershipsFailure: [],
  getUserRolesRequest: [],
  getUserRolesSuccess: ['data'],
  getUserRolesFailure: [],
  getUserBankRequest: [],
  getUserBankSuccess: ['data'],
  getUserBankFailure: [],
  getUserFunctionRequest: [],
  getUserFunctionSuccess: ['data'],
  getUserFunctionFailure: [],
  getUserFunctionsListRequest: ['id'],
  getUserFunctionsListSuccess: ['data'],
  getUserFunctionsListFailure: [],
  getUserVwAttendantsRequest: ['id'],
  getUserVwAttendantsSuccess: ['data'],
  getUserVwAttendantsFailure: [],
  addUserFunctionRequest: ['data', 'callback'],
  addUserFunctionFinish: [],
  updateUserFunctionRequest: ['id', 'data', 'callback'],
  updateUserFunctionFinish: [],
  announceHiringRequest: ['id', 'callback'],
  announceHiringSuccess: [],
  announceHiringFailure: [],
  announceResignationRequest: ['id', 'callback'],
  announceResignationSuccess: [],
  announceResignationFailure: [],
  announceNewFunctionRequest: ['id', 'callback'],
  announceNewFunctionSuccess: [],
  announceNewFunctionFailure: [],
  removeUserFunctionRequest: ['id', 'callback'],
  removeUserFunctionSuccess: [],
  removeUserFunctionFailure: [],
  getReceiptsUsersRequest: ['id'],
  getReceiptsUsersFailure: [],
  getReceiptsUsersSuccess: ['data', 'total'],
  getReceiptsUsersRequestPage: ['id'],
  getReceiptsUsersFailurePage: [],
  getReceiptsUsersSuccessPage: ['data', 'total'],
  setReceiptsUserTab: ['page', 'limit', 'idUser'],
})

const INITIAL_STATE = {
  data: {
    name: '',
  },
  idUserTabs: null,
  options: {
    total: 0,
    page: 0,
    limit: 20,
    pagination: 1,
    sort: 'created_at',
  },
  loading: false,
  dealerships: {
    data: [],
    loading: false,
  },
  roles: {
    data: [],
    loading: false,
  },
  bank: {
    data: [],
    loading: false,
  },
  function: {
    data: [],
    loading: false,
  },
  functionsList: {
    data: [],
    loading: false,
  },
  vwAttendants: {
    data: [],
    loading: false,
  },
  dataReceipts: [],
  loadingReceipts: false,
}

const addUserRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  }
}

const addUserFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUserSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getUserFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateUserFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeUserFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUserDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getUserDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getUserDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getUserRolesRequest = (state = INITIAL_STATE) => ({
  ...state,
  roles: {
    ...state.roles,
    loading: true,
  },
})

const getUserRolesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  roles: {
    ...state.roles,
    data,
    loading: false,
  },
})

const getUserRolesFailure = (state = INITIAL_STATE) => ({
  ...state,
  roles: {
    ...state.roles,
    loading: false,
  },
})

const getUserBankRequest = (state = INITIAL_STATE) => ({
  ...state,
  bank: {
    ...state.bank,
    loading: true,
  },
})

const getUserBankSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  bank: {
    ...state.bank,
    data,
    loading: false,
  },
})

const getUserBankFailure = (state = INITIAL_STATE) => ({
  ...state,
  function: {
    ...state.function,
    loading: false,
  },
})

const getUserFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  function: {
    ...state.function,
    loading: true,
  },
})

const getUserFunctionSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  function: {
    ...state.function,
    data,
    loading: false,
  },
})

const getUserFunctionFailure = (state = INITIAL_STATE) => ({
  ...state,
  function: {
    ...state.function,
    loading: false,
  },
})

const getUserFunctionsListRequest = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: true,
  },
})

const getUserFunctionsListSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    data,
    loading: false,
  },
})

const getUserFunctionsListFailure = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: false,
  },
})

const getUserVwAttendantsRequest = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    loading: true,
  },
})

const getUserVwAttendantsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    data,
    loading: false,
  },
})

const getUserVwAttendantsFailure = (state = INITIAL_STATE) => ({
  ...state,
  vwAttendants: {
    ...state.vwAttendants,
    loading: false,
  },
})

const addUserFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: true,
  },
})

const addUserFunctionFinish = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: false,
  },
})

const updateUserFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: true,
  },
})

const updateUserFunctionFinish = (state = INITIAL_STATE) => ({
  ...state,
  functionsList: {
    ...state.functionsList,
    loading: false,
  },
})
const announceHiringRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const announceHiringSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const announceHiringFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const announceResignationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const announceResignationSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const announceResignationFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const announceNewFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const announceNewFunctionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const announceNewFunctionFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const removeUserFunctionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeUserFunctionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeUserFunctionFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const getReceiptsUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getReceiptsUsersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getReceiptsUsersSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  dataReceipts: data,
  loading: false,
})

const getReceiptsUsersRequestPage = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getReceiptsUsersFailurePage = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getReceiptsUsersSuccessPage = (
  state = INITIAL_STATE,
  { data, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
    loading: false,
    dataReceipts: data,
  },
})

const setReceiptsUserTab = (state = INITIAL_STATE, { page, limit, id }) => ({
  ...state,
  idUserTab: id,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_USER_REQUEST]: addUserRequest,
  [Types.ADD_USER_FINISH]: addUserFinish,
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_FINISH]: updateUserFinish,
  [Types.REMOVE_USER_REQUEST]: removeUserRequest,
  [Types.REMOVE_USER_FINISH]: removeUserFinish,
  [Types.GET_USER_DEALERSHIPS_REQUEST]: getUserDealershipsRequest,
  [Types.GET_USER_DEALERSHIPS_SUCCESS]: getUserDealershipsSuccess,
  [Types.GET_USER_DEALERSHIPS_FAILURE]: getUserDealershipsFailure,
  [Types.GET_USER_ROLES_REQUEST]: getUserRolesRequest,
  [Types.GET_USER_ROLES_SUCCESS]: getUserRolesSuccess,
  [Types.GET_USER_ROLES_FAILURE]: getUserRolesFailure,
  [Types.GET_USER_BANK_REQUEST]: getUserBankRequest,
  [Types.GET_USER_BANK_SUCCESS]: getUserBankSuccess,
  [Types.GET_USER_BANK_FAILURE]: getUserBankFailure,
  [Types.GET_USER_FUNCTION_REQUEST]: getUserFunctionRequest,
  [Types.GET_USER_FUNCTION_SUCCESS]: getUserFunctionSuccess,
  [Types.GET_USER_FUNCTION_FAILURE]: getUserFunctionFailure,
  [Types.GET_USER_FUNCTIONS_LIST_REQUEST]: getUserFunctionsListRequest,
  [Types.GET_USER_FUNCTIONS_LIST_SUCCESS]: getUserFunctionsListSuccess,
  [Types.GET_USER_FUNCTIONS_LIST_FAILURE]: getUserFunctionsListFailure,
  [Types.GET_USER_VW_ATTENDANTS_REQUEST]: getUserVwAttendantsRequest,
  [Types.GET_USER_VW_ATTENDANTS_SUCCESS]: getUserVwAttendantsSuccess,
  [Types.GET_USER_VW_ATTENDANTS_FAILURE]: getUserVwAttendantsFailure,
  [Types.ADD_USER_FUNCTION_REQUEST]: addUserFunctionRequest,
  [Types.ADD_USER_FUNCTION_FINISH]: addUserFunctionFinish,
  [Types.UPDATE_USER_FUNCTION_REQUEST]: updateUserFunctionRequest,
  [Types.UPDATE_USER_FUNCTION_FINISH]: updateUserFunctionFinish,
  [Types.ANNOUNCE_HIRING_REQUEST]: announceHiringRequest,
  [Types.ANNOUNCE_HIRING_SUCCESS]: announceHiringSuccess,
  [Types.ANNOUNCE_HIRING_FAILURE]: announceHiringFailure,
  [Types.ANNOUNCE_RESIGNATION_REQUEST]: announceResignationRequest,
  [Types.ANNOUNCE_RESIGNATION_SUCCESS]: announceResignationSuccess,
  [Types.ANNOUNCE_RESIGNATION_FAILURE]: announceResignationFailure,
  [Types.ANNOUNCE_NEW_FUNCTION_REQUEST]: announceNewFunctionRequest,
  [Types.ANNOUNCE_NEW_FUNCTION_SUCCESS]: announceNewFunctionSuccess,
  [Types.ANNOUNCE_NEW_FUNCTION_FAILURE]: announceNewFunctionFailure,
  [Types.REMOVE_USER_FUNCTION_REQUEST]: removeUserFunctionRequest,
  [Types.REMOVE_USER_FUNCTION_SUCCESS]: removeUserFunctionSuccess,
  [Types.REMOVE_USER_FUNCTION_FAILURE]: removeUserFunctionFailure,
  [Types.GET_RECEIPTS_USERS_REQUEST]: getReceiptsUsersRequest,
  [Types.GET_RECEIPTS_USERS_FAILURE]: getReceiptsUsersFailure,
  [Types.GET_RECEIPTS_USERS_SUCCESS]: getReceiptsUsersSuccess,
  [Types.GET_RECEIPTS_USERS_REQUEST_PAGE]: getReceiptsUsersRequestPage,
  [Types.GET_RECEIPTS_USERS_FAILURE_PAGE]: getReceiptsUsersFailurePage,
  [Types.GET_RECEIPTS_USERS_SUCCESS_PAGE]: getReceiptsUsersSuccessPage,
  [Types.SET_RECEIPTS_USER_TAB]: setReceiptsUserTab,
})
