import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, IconButton } from '@material-ui/core'
import { useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CustomRow from './components/CustomRow'

const biSellersDashboardPageSelector = ({ biSellersDashboard }) =>
  biSellersDashboard

export default function BiSellerDashboardTableRow({ row }) {
  const [open, setOpen] = useState(false)
  const { showHideColumns } = useSelector(biSellersDashboardPageSelector)

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {showHideColumns.seller && (
          <TableCell align="left">{row.group_name}</TableCell>
        )}
        {showHideColumns.sale && (
          <TableCell align="right">{row.total_vendas}</TableCell>
        )}
        {showHideColumns.devolution && (
          <TableCell align="right">{row.total_devolucao}</TableCell>
        )}
        {showHideColumns.total && (
          <TableCell align="right">{row.total}</TableCell>
        )}
        {showHideColumns.dailyGoal && (
          <TableCell align="right">{row.dailyGoal}</TableCell>
        )}
        {showHideColumns.goal && (
          <TableCell align="right">{row.counter}</TableCell>
        )}
        <TableCell style={{ width: '1%', padding: 0, margin: 0 }} />
      </TableRow>
      {open &&
        row?.data?.map((dealership) => (
          <CustomRow row={dealership} key={dealership.dealership_id} />
        ))}
    </>
  )
}

BiSellerDashboardTableRow.propTypes = {
  row: PropTypes.shape({
    group_name: PropTypes.string,
    id: PropTypes.string,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_vendas: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_devolucao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    working_days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dailyGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}
