import React, { useEffect } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import { Rating } from '@material-ui/lab'
import {
  Person as UserIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import { useDispatch, useSelector } from 'react-redux'

// import { Creators as SellerActions } from '~/store/ducks/seller'
import moment from 'moment'
import { Creators as SellersActions } from '~/store/ducks/sellers'

import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import EmptyList from '~/components/EmptyList'
// import useSmallScreen from '~/helpers/useSmallScreen'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import SellersFilters from './components/SellersFilters'
import styles from './styles'

const sellersSelector = ({ sellers }) => sellers

const useStyles = makeStyles(styles)

function SellersPage() {
  const isSmallScreen = useSmallScreen()

  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, options, loading, showHideColumns } =
    useSelector(sellersSelector)

  const { sort, direction, dateValue } = options
  // const isSmallScreen = useSmallScreen()

  const getLabel = (subtractMonth) => {
    const label = moment(dateValue)
      .subtract(subtractMonth, 'month')
      .format('MM/YY')
    return label
  }

  const optionsShowHideColumn = [
    { name: 'name', label: 'Nome', disabled: true },
    { name: 'dealership', label: 'Concessionária' },
    {
      name: 'month4',
      label: getLabel(4),
    },
    {
      name: 'month3',
      label: getLabel(3),
    },
    {
      name: 'month2',
      label: getLabel(2),
    },
    {
      name: 'month1',
      label: getLabel(1),
    },
    { name: 'currentMonth', label: moment(dateValue).format('MM/YY') },
    { name: 'avaliation', label: 'Avaliação' },
    { name: 'media', label: 'Média' },
  ]

  useEffect(() => {
    dispatch(SellersActions.getSellersRequest())
    dispatch(SellersActions.getDealershipsSellersRequest())
    return () => {
      dispatch(SellersActions.resetSellers())
    }
  }, [])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(SellersActions.setSellersSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(SellersActions.setSellersPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(SellersActions.setSellersPage(page, options.limit))
  }

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(SellersActions.setChangeShowHideColumnsSellers(newColumnsTable))
  }

  return (
    <>
      <div className={classes.root}>
        {isSmallScreen && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filterWropper}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <SellersFilters />
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && <SellersFilters />}
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {!loading && data.length === 0 && (
              <EmptyList message="Não há vendedores para serem visualizados" />
            )}
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {showHideColumns.name && (
                          <TableCell>
                            <TableSortLabel
                              active={sort === 'nome'}
                              onClick={() => handleSortChange('nome')}
                              direction={direction}
                            >
                              Nome
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.dealership && (
                          <TableCell>
                            <TableSortLabel
                              active={sort === 'dealership_name'}
                              onClick={() =>
                                handleSortChange('dealership_name')
                              }
                              direction={direction}
                            >
                              Concessionária
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.month4 && (
                          <TableCell>
                            {moment(dateValue)
                              .subtract(4, 'month')
                              .format('MM/YY')}
                          </TableCell>
                        )}
                        {showHideColumns.month3 && (
                          <TableCell>
                            {moment(dateValue)
                              .subtract(3, 'month')
                              .format('MM/YY')}
                          </TableCell>
                        )}
                        {showHideColumns.month2 && (
                          <TableCell>
                            {moment(dateValue)
                              .subtract(2, 'month')
                              .format('MM/YY')}
                          </TableCell>
                        )}
                        {showHideColumns.month1 && (
                          <TableCell>
                            {moment(dateValue)
                              .subtract(1, 'month')
                              .format('MM/YY')}
                          </TableCell>
                        )}
                        {showHideColumns.currentMonth && (
                          <TableCell>
                            {moment(dateValue).format('MM/YY')}
                          </TableCell>
                        )}
                        {showHideColumns.avaliation && (
                          <TableCell>Avaliação</TableCell>
                        )}
                        {showHideColumns.media && (
                          <TableCell>
                            <TableSortLabel
                              active={sort === 'media'}
                              onClick={() => handleSortChange('media')}
                              direction={direction}
                            >
                              Média
                            </TableSortLabel>
                          </TableCell>
                        )}
                        <TableCell className={classes.showHideColumns}>
                          <ShowHideColumns
                            showHideColumns={showHideColumns}
                            optionsShowHideColumn={optionsShowHideColumn}
                            onChangeShowHideColumn={
                              handleSetChangeShowHideColumn
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            {item.url_image ? (
                              <Avatar
                                alt={item.name}
                                className={classes.avatar}
                                src={item.url_image}
                              />
                            ) : (
                              <Avatar className={classes.avatarIcon}>
                                <UserIcon />
                              </Avatar>
                            )}
                          </TableCell>
                          {showHideColumns.name && (
                            <TableCell>{item.nome || '-'}</TableCell>
                          )}
                          {showHideColumns.dealership && (
                            <TableCell>{item.dealership_name || '-'}</TableCell>
                          )}
                          {showHideColumns.month1 && (
                            <TableCell>{item.mes_1 || 0}</TableCell>
                          )}
                          {showHideColumns.month2 && (
                            <TableCell>{item.mes_2 || 0}</TableCell>
                          )}
                          {showHideColumns.month3 && (
                            <TableCell>{item.mes_3 || 0}</TableCell>
                          )}
                          {showHideColumns.month4 && (
                            <TableCell>{item.mes_4 || 0}</TableCell>
                          )}
                          {showHideColumns.currentMonth && (
                            <TableCell>{item.mes_5 || 0}</TableCell>
                          )}
                          {showHideColumns.avaliation && (
                            <TableCell>
                              <Rating readOnly value={item.rating} max={4} />
                            </TableCell>
                          )}
                          {showHideColumns.media && (
                            <TableCell>{item.media || '-'}</TableCell>
                          )}
                          <TableCell className={classes.showHideColumns} />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>
    </>
  )
}

export default SellersPage
