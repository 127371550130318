import React from 'react'
import PropTypes from 'prop-types'
import { useHover } from './useHover'

export const HoverStyle = ({ style = {}, hoverStyle, children, ...rest }) => {
  const isHovered = useHover()
  const calculatedStyle = { ...style, ...(isHovered ? hoverStyle : {}) }

  return (
    <div {...rest} style={calculatedStyle}>
      {children}
    </div>
  )
}

HoverStyle.propTypes = {
  hoverStyle: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.string).isRequired,
}

HoverStyle.defaultProps = {
  hoverStyle: null,
}
