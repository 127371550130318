import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  TextField,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  MenuItem,
  Chip,
  IconButton,
} from '@material-ui/core'

import { Delete as DeleteIcon } from '@material-ui/icons'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import { Creators as DepartmentActions } from '~/store/ducks/department'

import styles from './styles'

const useStyles = makeStyles(styles)

const subjectsSelector = ({ department: { subjects } }) => subjects
const attendantsSelector = ({ department: { attendants } }) => attendants
const vwAttendantsSelector = ({ department: { vwAttendants } }) => vwAttendants

export default function AttendantsFormTab({ id }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const subjects = useSelector(subjectsSelector)
  const { data: vwAttendantsData, loading: loadingVwAttendants } = useSelector(
    vwAttendantsSelector,
  )
  const { data: attendants } = useSelector(attendantsSelector)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [attendantId, setAttendantId] = useState(null)

  useEffect(() => {
    dispatch(DepartmentActions.getDepartmentAttendantsRequest())
    dispatch(DepartmentActions.getDepartmentVwAttendantsRequest(id))
  }, [])

  function handleSubmitAttendantsForm(formData) {
    const formattedData = {
      ...formData,
      subjects_id: formData.subjects.map((s) => s.id),
      department_id: id,
      user_id: formData.user,
    }
    dispatch(
      DepartmentActions.addDepartmentAttendantRequest(formattedData, () => {
        dispatch(DepartmentActions.getDepartmentVwAttendantsRequest(id))
        reset({ user: '', subjects: '' })
      }),
    )
  }

  function handleClearSubjectForm() {
    reset({ user: '', subjects: '' })
  }

  function handleRemoveAttendant(idValue) {
    setAttendantId(idValue)
    setOpenConfirmDialog(true)
  }

  function handleRemoveAttendantCancel() {
    setAttendantId(null)
    setOpenConfirmDialog(false)
  }

  function handleRemoveAttendantAccept() {
    dispatch(
      DepartmentActions.removeDepartmentAttendantRequest(attendantId, () => {
        setAttendantId(null)
        setOpenConfirmDialog(false)
        dispatch(DepartmentActions.getDepartmentVwAttendantsRequest(id))
      }),
    )
  }

  return (
    <>
      <div className={classes.root}>
        {loadingVwAttendants && <OverlayLoading />}
        {!loadingVwAttendants && (
          <>
            <form
              id="form-department-attendants"
              onSubmit={handleSubmit(handleSubmitAttendantsForm)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="user"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <TextField
                        variant="outlined"
                        select
                        autoFocus
                        label="Colaborador"
                        type="text"
                        onChange={onChange}
                        error={!!errors.user}
                        value={value}
                        helperText={!!errors.user && 'Campo obrigatório'}
                        fullWidth
                        {...rest}
                      >
                        {attendants.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name} ({option.email})
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="subjects"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Autocomplete
                        fullWidth
                        multiple
                        value={value || []}
                        onChange={(event, v) => {
                          onChange(v)
                        }}
                        name="subjects"
                        filterSelectedOptions
                        freeSolo
                        options={subjects.data}
                        {...rest}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.name}
                              style={{
                                backgroundColor: '#ED1E79',
                                color: '#FFFFFF',
                                // eslint-disable-next-line no-nested-ternary
                              }}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.subjects}
                            helperText={
                              !!errors.subjects && 'Campo obrigatório'
                            }
                            label="Assunto"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                className={classes.subjectButtonsWrapper}
              >
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleClearSubjectForm}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form="form-department-attendants"
                  >
                    Salvar atendente
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Portlet className={classes.portlet}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Colaborador</TableCell>
                    <TableCell>Assunto</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vwAttendantsData.length > 0 &&
                    vwAttendantsData.map((vwAttendant) => (
                      <TableRow key={vwAttendant.id}>
                        <TableCell>{vwAttendant.user_name}</TableCell>
                        <TableCell>{vwAttendant.subject}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              handleRemoveAttendant(vwAttendant.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Portlet>
          </>
        )}
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir?"
        onCancel={handleRemoveAttendantCancel}
        onAccept={handleRemoveAttendantAccept}
      />
    </>
  )
}

AttendantsFormTab.propTypes = {
  id: PropTypes.number,
}

AttendantsFormTab.defaultProps = {
  id: null,
}
