import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import jsonToFormData from '~/helpers/jsonToFormData'

import { Types as QuizzeTypes, Creators as QuizzeActions } from '../ducks/quiz'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* addQuizze({ data, callback }) {
  const formattedData = jsonToFormData(data)
  try {
    const api = configureApi()
    yield call(api.post, 'quizzes', formattedData)

    if (callback) {
      yield call(callback)
    }

    yield put(QuizzeActions.addQuizzeSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Questionário cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(QuizzeActions.addQuizzeFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getQuizze({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `quizzes/${id}`)
    yield put(QuizzeActions.getQuizzeSuccess(data))
  } catch (error) {
    yield put(QuizzeActions.getQuizzeFailure())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeQuizze({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/quizzes/${id}`)

    yield put(QuizzeActions.removeQuizzeFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Questionário removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(QuizzeActions.removeQuizzeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addQuestion({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'questions', data)

    if (callback) {
      yield call(callback)
    }

    yield put(QuizzeActions.addQuestionSuccess())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Pergunta cadastrada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(QuizzeActions.addQuestionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateQuestion({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `questions/${id}`, data)

    if (callback) {
      yield call(callback)
    }

    yield put(QuizzeActions.updateQuestionFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Pergunta editada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(QuizzeActions.updateQuestionFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeQuestion({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/questions/${id}`)

    yield put(QuizzeActions.removeQuizzeFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Pegunta removiada com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(QuizzeActions.removeQuizzeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateQuizze({ id, data, callback }) {
  const formattedData = jsonToFormData(data)

  try {
    const api = configureApi()
    yield call(api.post, `quizzes/${id}`, formattedData)

    if (callback) {
      yield call(callback)
    }

    yield put(QuizzeActions.updateQuizzeFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Questionário editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(QuizzeActions.updateQuizzeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(QuizzeTypes.ADD_QUIZZE_REQUEST, addQuizze)
  yield takeLatest(QuizzeTypes.GET_QUIZZE_REQUEST, getQuizze)
  yield takeLatest(QuizzeTypes.REMOVE_QUIZZE_REQUEST, removeQuizze)
  yield takeLatest(QuizzeTypes.ADD_QUESTION_REQUEST, addQuestion)
  yield takeLatest(QuizzeTypes.UPDATE_QUESTION_REQUEST, updateQuestion)
  yield takeLatest(QuizzeTypes.REMOVE_QUESTION_REQUEST, removeQuestion)
  yield takeLatest(QuizzeTypes.UPDATE_QUIZZE_REQUEST, updateQuizze)
}
