import { call, takeLatest, put, select, all } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as AnalitycalTypes,
  Creators as AnalitycalActions,
} from '../ducks/analitycal'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const analitycalSelector = ({ analitycal }) => analitycal

const api = configureApi()

function* getAnalitycal() {
  const { filters } = yield select(analitycalSelector)
  const rangeDiff = moment(filters.endDate).diff(
    moment(filters?.startDate),
    'days',
  )
  try {
    const { currentMonth, previousMonth } = yield all({
      currentMonth: call(api.get, 'bi-comercial/analytic', {
        params: {
          start_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          end_at: moment(filters.endDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
      previousMonth: call(api.get, 'bi-comercial/analytic', {
        params: {
          start_at: moment(filters?.startDate)
            .subtract(rangeDiff, 'days')
            .format('YYYY-MM-DD'),
          end_at: moment(filters?.startDate).format('YYYY-MM-DD'),
          dealership_id: filters.dealership,
          group_id: filters.group,
        },
      }),
    })

    yield put(
      AnalitycalActions.getAnalitycalSuccess(
        currentMonth.data,
        previousMonth?.data,
      ),
    )
  } catch (e) {
    yield put(AnalitycalActions.getAnalitycalFailure())
  }
}

function* reloadAnalitycal() {
  yield put(AnalitycalActions.getAnalitycalRequest())
}

function* getAnalitycalFilters() {
  try {
    const { groups, dealerships } = yield all({
      groups: call(api.get, 'groups', {
        params: {
          pagination: 0,
          order: 'name,asc',
        },
      }),
      dealerships: call(api.get, 'dealerships', {
        params: {
          pagination: 0,
          order: 'sort,asc',
        },
      }),
    })
    const groupsData = groups.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    const dealershipsData = dealerships.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      AnalitycalActions.getFiltersAnalitycalSuccess(
        groupsData,
        dealershipsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(AnalitycalActions.getAnalitycalFiltersFailure())
  }
}

function* getAnalitycalDetails({ segment }) {
  const { filters } = yield select(analitycalSelector)
  try {
    const { data: dataDetails } = yield call(api.get, 'bi-comercial/detailed', {
      params: {
        start_at: moment(filters?.startDate).format('YYYY-MM-DD'),
        end_at: moment(filters.endDate).format('YYYY-MM-DD'),
        dealership_id: filters.dealership,
        group_id: filters.group,
        segment: segment.substr(0, 1).toUpperCase(),
      },
    })
    yield put(AnalitycalActions.getAnalitycalDetailsSuccess(dataDetails))
  } catch (e) {
    yield put(AnalitycalActions.getAnalitycalDetailsFailure())
  }
}

export default function* () {
  yield takeLatest(AnalitycalTypes.GET_ANALITYCAL_REQUEST, getAnalitycal)
  yield takeLatest(AnalitycalTypes.SET_ANALITYCAL_SORT, reloadAnalitycal)
  yield takeLatest(AnalitycalTypes.SET_ANALITYCAL_PAGE, reloadAnalitycal)
  yield takeLatest(AnalitycalTypes.SET_ANALITYCAL_DATE, reloadAnalitycal)
  yield takeLatest(
    AnalitycalTypes.GET_FILTERS_ANALITYCAL_REQUEST,
    getAnalitycalFilters,
  )
  yield takeLatest(
    AnalitycalTypes.SET_ANALITYCAL_CHANGE_FILTER,
    reloadAnalitycal,
  )
  yield takeLatest(
    AnalitycalTypes.GET_ANALITYCAL_DETAILS_REQUEST,
    getAnalitycalDetails,
  )
}
