import React, { useState, useRef, useEffect } from 'react'

import moment from 'moment'

import { find } from 'lodash'

import {
  Grid,
  Button,
  makeStyles,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Switch,
  Tooltip,
} from '@material-ui/core'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import filesize from 'filesize'

import {
  Backup as UploadIcon,
  Cancel as CancelIcon,
  InsertDriveFile as AttachmentIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
} from '@material-ui/icons'

import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import LaunchIcon from '@material-ui/icons/Launch'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'

import { Creators as ReceiptActions } from '~/store/ducks/receipts'

import RhfSelect from '~/components/FormComponents/RhfSelect'

import UserReceiptsDialog from './components/UsersReceiptsDialog/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const receiptsSelector = ({ receipts }) => receipts

const usersSelector = ({ session: { user } }) => user

export default function ReceiptsPage() {
  const userSession = useSelector(usersSelector)
  const [viewList, setViewList] = useState(true)
  const [receipts, setReceipts] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const fileInput = useRef(null)

  const dispatch = useDispatch()
  const classes = useStyles()
  const { options, receiptsList } = useSelector(receiptsSelector)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [documentId, setDocumentId] = useState(null)

  const [attachment, setAttachment] = useState('')

  function handleChangeFilter(property, value) {
    dispatch(ReceiptActions.setReceiptFilter(property, value))
  }

  function handleUploadAttachment(e) {
    const file = e.target.files[0]
    if (file) {
      setAttachment(file)
    }
  }

  useEffect(() => {
    dispatch(ReceiptActions.getReceiptsRequest())
  }, [])

  const { handleSubmit, control } = useForm()

  const handleFormReceiptSubmit = (data) => {
    const formattedData = {
      ...data,
      user_id: userSession.id,
    }

    if (attachment) {
      formattedData.file = attachment
    }

    dispatch(
      ReceiptActions.addDocumentRequest(formattedData, () => {
        dispatch(ReceiptActions.getReceiptsRequest())
        setViewList(true)
      }),
    )
  }
  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(ReceiptActions.setReceiptsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(ReceiptActions.setReceiptsPage(page, options.limit))
  }

  const handleDeleteDocument = (id) => {
    setDocumentId(id)
    setOpenConfirmDialog(true)
  }

  const handleSwitchChange = (item) => {
    dispatch(
      ReceiptActions.getReceiptVisibilityRequest(item, () => {
        dispatch(ReceiptActions.getReceiptsRequest())
      }),
    )
  }

  const handleRemoveCancel = () => {
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      ReceiptActions.removeDocumentRequest(documentId, () => {
        setDocumentId(null)
        setOpenConfirmDialog(false)
        dispatch(ReceiptActions.getReceiptsRequest())
      }),
    )
  }

  const slugDocumentTypes = {
    advancePayment: 'Adiantamento',
    receipt: 'Recibo',
    vacation: 'Férias',
    salary: 'Salário',
    bonus: 'Décimo Terceiro',
    reportIncome: 'Relatório de Rendimentos',
  }

  const receiptLink = (item) => {
    const apiUrl = process.env.REACT_APP_API_URL
    window.open(`${apiUrl}view-document/${item.url_file}`, '_blank')
  }

  const handleOpenModal = (item) => {
    setReceipts(item.receipts)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setReceipts(null)
  }

  // const dataUsers = receiptsList.data.map((item) => {
  //   return item.receipts
  // })

  return (
    <>
      {viewList && (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setViewList(false)}
              style={{ marginTop: 16, marginRight: 24 }}
            >
              Adicionar Recibo
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.root}>
        <Portlet className={classes.grow} style={{ padding: 8 }}>
          {!viewList && (
            <Grid container className={classes.container}>
              <div className={classes.tabsWrapper}>
                <div className={classes.root}>
                  <div>
                    <form
                      onSubmit={handleSubmit(handleFormReceiptSubmit)}
                      id="form-receipt"
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item md={6} xs={12}>
                          <MuiPickersUtilsProvider
                            locale="pt-br"
                            utils={MomentUtils}
                          >
                            <KeyboardDatePicker
                              inputVariant="outlined"
                              fullWidth
                              views={['month', 'year']}
                              label="Mês/Ano"
                              invalidDateMessage="Data inválida"
                              format="MM/yyyy"
                              value={getFilterValue('date')}
                              onChange={(dValue) =>
                                handleChangeFilter('date', dValue)
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <RhfSelect
                            name="document_type"
                            label="Tipo de documento"
                            variant="outlined"
                            fullWidth
                            control={control}
                            defaultValue=""
                          >
                            <MenuItem value="advancePayment">
                              Adiantamento
                            </MenuItem>
                            <MenuItem value="vacation">Férias</MenuItem>
                            <MenuItem value="salary">Salário</MenuItem>
                            <MenuItem value="bonus">Décimo Terceiro</MenuItem>
                            <MenuItem value="reportIncome">
                              Relatório de Rendimentos
                            </MenuItem>
                          </RhfSelect>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Button
                          fullWidth
                          size="large"
                          variant="outlined"
                          onClick={() => {
                            fileInput.current.click()
                          }}
                        >
                          <UploadIcon className={classes.uploadIcon} />
                          Selecionar arquivo
                          <input
                            type="file"
                            multiple={false}
                            ref={fileInput}
                            onClick={(e) => {
                              e.target.value = ''
                            }}
                            style={{ display: 'none' }}
                            onChange={handleUploadAttachment}
                          />
                        </Button>
                      </Grid>
                      {attachment && (
                        <Grid item xs={12}>
                          <Card className={classes.upload}>
                            <CardContent className={classes.uploadContent}>
                              <AttachmentIcon className={classes.uploadIcon} />
                              <div className={classes.uploadDetail}>
                                <span>{attachment.name}</span>
                                <span className={classes.fileSize}>
                                  {filesize(attachment.size, {
                                    round: 0,
                                  })}
                                </span>
                              </div>
                              <IconButton onClick={() => setAttachment('')}>
                                <CancelIcon
                                  className={classes.cancelUploadIcon}
                                />
                              </IconButton>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Grid>
          )}
          {!viewList && <Divider />}
          {!viewList && (
            <Grid
              container
              justifyContent="space-between"
              className={classes.actionsWrapper}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => window.location.reload()}
                >
                  Voltar
                </Button>
              </Grid>
              <Button
                color="primary"
                type="submit"
                form="form-receipt"
                variant="contained"
                //
              >
                SALVAR
              </Button>
            </Grid>
          )}
          {viewList && receiptsList?.data?.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ano</TableCell>
                      <TableCell>Mês</TableCell>
                      <TableCell>Concessionária</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Criado em</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receiptsList?.data?.map((item) => {
                      return (
                        <TableRow hover key={item.id}>
                          <TableCell>{item?.year}</TableCell>
                          <TableCell>{item?.month}</TableCell>
                          <TableCell>{item?.dealership?.name}</TableCell>
                          <TableCell>
                            {slugDocumentTypes[item.document_type]}
                          </TableCell>
                          <TableCell>{item?.status}</TableCell>
                          <TableCell>
                            {moment(item.created_at).format('DD/MM/YYYY LT')}
                          </TableCell>
                          <TableCell className={classes.actionsCell}>
                            <div className={classes.actionsWrapperIcon}>
                              <IconButton
                                className={classes.icon}
                                onClick={() => handleDeleteDocument(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>

                              <Tooltip
                                placement="top"
                                title={`${
                                  item.visible ? 'Vísivel' : 'Não vísivel'
                                }`}
                              >
                                <Switch
                                  size="small"
                                  checked={item.visible}
                                  color="primary"
                                  onChange={() => handleSwitchChange(item.id)}
                                />
                              </Tooltip>
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => receiptLink(item)}
                              >
                                <LaunchIcon />
                              </IconButton>
                              <Tooltip placement="top" title="Colaboradores">
                                <IconButton
                                  className={classes.icon}
                                  onClick={() => handleOpenModal(item)}
                                >
                                  <PersonIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <Divider className={classes.divider} />
              </Grid>
              <ListFooter
                options={options}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
              />
            </Grid>
          )}
        </Portlet>
      </Grid>
      <UserReceiptsDialog
        open={openDialog}
        onClose={handleClose}
        data={receipts}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este recibo?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}
