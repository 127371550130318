export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  grow: {
    height: '100%',
    position: 'relative',
    paddingBottom: theme.spacing(9),
  },
  content: {
    overflowX: 'auto',
    padding: theme.spacing(3),
  },
  form: {
    width: '100%',
    display: 'flex',
  },
  actionsWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderTop: '1px solid #E4E7EB',
  },
  uploadIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxHeight: 500,
    },
  },
  dealershipsSelect: {
    marginTop: theme.spacing(2),
  },
})
