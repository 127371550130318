import React, { useState } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import { useSelector } from 'react-redux'
import Portlet from '~/components/Portlet'
import SolidGaugeChart from '~/components/SolidGaugeChart'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import EmptyList from '~/components/EmptyList'
import BilledFilters from './components/BilledFilters'

import styles from './styles'
import BillingsDialog from './components/BillingsDialog'

const billedSelector = ({ billed }) => billed

const useStyles = makeStyles(styles)

function BilledListPage() {
  const { data, loading } = useSelector(billedSelector)
  const [billed, setBilled] = useState(null)
  const isSmallScreen = useSmallScreen()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          {isSmallScreen && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.filterWropper}>
                  <FilterIcon color="primary" className={classes.filterIcon} />
                  <Typography variant="h5" color="primary">
                    Filtros
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <BilledFilters />
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {!isSmallScreen && (
            <Grid item xs={12}>
              <BilledFilters />
            </Grid>
          )}
        </Grid>
      </Grid>

      {!loading && (
        <Grid container spacing={2}>
          {data.map((item) => (
            <Grid key={item.title} item md={3} sm={6} xs={12}>
              <Portlet className={classes.portlet}>
                <SolidGaugeChart {...item} />

                <Grid container justifyContent="flex-end">
                  <Button
                    disabled={!item.total || item.total <= 0}
                    onClick={() => setBilled(item)}
                    variant="outlined"
                    className={classes.button}
                  >
                    FATURADOS: {item.total} OBJETIVO: {item.goal}
                  </Button>
                </Grid>
              </Portlet>
            </Grid>
          ))}
        </Grid>
      )}

      {!loading && data.length === 0 && (
        <Grid item xs={12} className={classes.emptyWrapper}>
          <Portlet className={classes.grow}>
            <EmptyList message="Não há dados para serem visualizadas" />
          </Portlet>
        </Grid>
      )}

      {loading && <OverlayLoading />}

      <BillingsDialog
        open={!!billed}
        name={billed?.title}
        dealership={billed?.id}
        onClose={() => setBilled(null)}
      />
    </div>
  )
}

export default BilledListPage
