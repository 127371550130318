import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import {
  makeStyles,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'

import OverlayLoading from '~/components/OverlayLoading'

import { Creators as DashboardActions } from '~/store/ducks/dashboard'
import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

import { useSmallScreen } from '~/helpers/useMediaQuery'

import LogosNissul from '~/assets/images/logosNissul.png'
import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'

import BirthdaysSlider from './components/BirthdaysSlider'
import ImageDialog from './components/ImageDialog'
import CommunicationDialog from './components/CommunicationDialog'
import NewsCard from './components/NewsCard'
import CommunicationsCard from './components/CommunicationsCard'

import styles from './styles'

const useStyles = makeStyles(styles)

const dashboardSelector = ({ dashboard }) => dashboard
const newsBoardLoadingsSelector = ({ newsBoard }) => newsBoard
const newsBoardSelector = ({ newsBoard }) => newsBoard.data
const newsBoardFeedSelector = ({ newsBoard }) => newsBoard.dataFeed

export default function DashboardPage() {
  const isSmallScreen = useSmallScreen()
  const classes = useStyles(isSmallScreen)

  const dispatch = useDispatch()
  const newsBoardCommunication = useSelector(newsBoardSelector)
  const newsBoardFeed = useSelector(newsBoardFeedSelector)
  const { loadingFeed } = useSelector(newsBoardLoadingsSelector)

  const {
    birthdays: { loading },
  } = useSelector(dashboardSelector)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [communicationImageUrl, setCommunicationImageUrl] = useState(null)
  const [openCommunicationDialog, setOpenCommunicationDialog] = useState(false)
  const [communicationTitle, setCommunicationTitle] = useState(null)
  const [communicationText, setCommunicationText] = useState(null)
  const [currentTab, setCurrentTab] = useState(0)

  useEffectOnce(() => {
    dispatch(DashboardActions.getDashboardBirthdaysRequest())
    dispatch(NewsBoardActions.getNewsBoardRequestFeed('feed', true))
    dispatch(NewsBoardActions.getNewsBoardRequest('feed-communication', true))
    dispatch(DashboardActions.getQuizzesDashboardRequest())
  })

  const handleOpenImageDialog = (url) => {
    setOpenImageDialog(true)
    setImageUrl(url)
  }

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false)
    setImageUrl(null)
  }

  const handleOpenCommunicationDialog = (title, text, image) => {
    setOpenCommunicationDialog(true)
    setCommunicationTitle(title)
    setCommunicationText(text)
    setCommunicationImageUrl(image)
  }

  const handleCloseCommunicationDialog = () => {
    setOpenCommunicationDialog(false)
  }

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  const handleSetNewsPerPageFeed = () => {
    dispatch(NewsBoardActions.setNewsPerPageFeedRequest())
    dispatch(NewsBoardActions.getNewsBoardRequestFeed('feed', true))
  }

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}

      {isSmallScreen && !loading && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                style={{ width: '100%' }}
                src={LogosNissul}
                alt="grupo nissul logos"
              />
            </Grid>

            <Grid item xs={12}>
              <BirthdaysSlider />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Portlet className={classes.tabsPortlet}>
                <Tabs
                  variant="fullWidth"
                  value={currentTab}
                  onChange={handleTabsChange}
                >
                  <Tab label="Feed" />
                  <Tab label="Comunicados" />
                </Tabs>
              </Portlet>
            </Grid>
          </Grid>
        </>
      )}

      {!loading && !isSmallScreen && (
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6} className={classes.postWrapper}>
            <Grid
              container
              justifyContent="center"
              className={classes.imgLogoContainer}
            >
              <Grid item>
                <img
                  style={{ width: '100%' }}
                  src={LogosNissul}
                  alt="grupo nissul logos"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BirthdaysSlider />
              </Grid>

              {newsBoardFeed?.map((news) => (
                <Grid item xs={12} key={news.id}>
                  <NewsCard
                    data={news}
                    onOpenImageDialog={handleOpenImageDialog}
                  />
                </Grid>
              ))}
              <Grid container justifyContent="center">
                {newsBoardFeed.length === 0 && (
                  <Grid item xs={12} className={classes.emptyPost}>
                    <Portlet className={classes.grow}>
                      <EmptyList message="Não há notícias para serem visualizadas" />
                    </Portlet>
                  </Grid>
                )}
                <Grid className={classes.buttonContainer}>
                  {loadingFeed && <OverlayLoading size={40} />}
                  <Button
                    onClick={() => {
                      handleSetNewsPerPageFeed()
                    }}
                  >
                    <Typography className={classes.textButton}>
                      VER MAIS...
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.communicationWrapper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className={classes.containerCommunicationCard}
                >
                  <CommunicationsCard
                    data={newsBoardCommunication}
                    onOpenCommunicationDialog={handleOpenCommunicationDialog}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}

      {!loading && isSmallScreen && currentTab === 0 && (
        <>
          {newsBoardFeed.length > 0 ? (
            <>
              {newsBoardFeed?.map((news) => (
                <Grid
                  container
                  key={news.id}
                  spacing={6}
                  className={classes.container}
                >
                  <Grid item xs={12}>
                    <NewsCard
                      data={news}
                      onOpenImageDialog={handleOpenImageDialog}
                    />
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {newsBoardFeed.length === 0 && (
                <Grid item xs={12} md={6} className={classes.emptyPost}>
                  <Portlet className={classes.grow}>
                    <EmptyList message="Não há notícias para serem visualizadas" />
                  </Portlet>
                </Grid>
              )}
            </>
          )}
        </>
      )}

      {!loading && isSmallScreen && currentTab === 1 && (
        <>
          <Grid container spacing={2}>
            <CommunicationsCard
              isSmallScreen={isSmallScreen}
              data={newsBoardCommunication}
              onOpenCommunicationDialog={handleOpenCommunicationDialog}
            />
          </Grid>
        </>
      )}
      {isSmallScreen && (
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              onClick={() => {
                handleSetNewsPerPageFeed()
              }}
            >
              <Typography className={classes.textButton}>
                VER MAIS...
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}

      <ImageDialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        imageUrl={imageUrl}
      />
      <CommunicationDialog
        open={openCommunicationDialog}
        onClose={handleCloseCommunicationDialog}
        title={communicationTitle}
        text={communicationText}
        image={communicationImageUrl}
      />
    </div>
  )
}
