import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { TableCell, TableRow, IconButton, makeStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { formatPrice } from '~/helpers/formatPrice'
import SellerDialog from '../SellerDialog'

const biSellersDashboardPageSelector = ({ biSellersDashboard }) =>
  biSellersDashboard

const useStyles = makeStyles({
  tableRowOpen: {
    backgroundColor: '#dddd',
    // height: 32,
  },
  tableRow: {
    background: '#f9f9f9',
  },
})

export default function CustomRow({ row: dealership }) {
  const { showHideColumns } = useSelector(biSellersDashboardPageSelector)
  const classes = useStyles()
  const [openSellers, setOpenSellers] = useState(false)
  const [sellerId, setSellerId] = useState(null)
  const [codNbs, setCodNbs] = useState(null)
  const [sellerDailyGoal, setSellerDailyGoal] = useState(null)

  const dailyGoal = Number(dealership.counter) / Number(dealership.working_days)

  const handleCloseSellerDialog = () => {
    setSellerId(null)
    setCodNbs(null)
    setSellerDailyGoal(null)
  }

  const getTotalColor = (total, counter) => {
    if (total > counter) {
      return '#008000'
    }
    return '#3A3B3F'
  }

  return (
    <>
      <TableRow key={dealership.dealership_city} className={classes.tableRow}>
        {showHideColumns.seller && (
          <TableCell align="left">
            <IconButton
              size="small"
              onClick={() => setOpenSellers(!openSellers)}
            >
              {openSellers ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {showHideColumns.seller && (
          <TableCell component="td" scope="row" align="left">
            <b>{dealership.dealership_city}</b>
          </TableCell>
        )}
        {showHideColumns.sale && (
          <TableCell align="right">
            <b>{formatPrice(Number(dealership.total_vendas))}</b>
          </TableCell>
        )}
        {showHideColumns.devolution && (
          <TableCell align="right">
            <b>{formatPrice(Number(dealership.total_devolucao))}</b>
          </TableCell>
        )}
        {showHideColumns.total && (
          <TableCell
            align="right"
            style={{
              color: getTotalColor(
                Number(dealership.total),
                Number(dealership.counter),
              ),
            }}
          >
            <b>{formatPrice(Number(dealership.total))}</b>
          </TableCell>
        )}
        {showHideColumns.dailyGoal && (
          <TableCell align="right">
            <b>{formatPrice(Number(dailyGoal))}</b>
          </TableCell>
        )}
        {showHideColumns.goal && (
          <TableCell align="right">
            <b>{formatPrice(Number(dealership.counter))}</b>
          </TableCell>
        )}
        <TableCell />
      </TableRow>
      {openSellers &&
        dealership?.sellers?.map((seller) => {
          const dailyGoalSeller =
            Number(seller.counter) / Number(seller.working_days)
          return (
            <TableRow key={seller.seller_name} className={classes.tableRowOpen}>
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSellerId(seller.seller_id)
                    setCodNbs(seller.cod_nbs)
                    setSellerDailyGoal(dailyGoalSeller)
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              {showHideColumns.seller && (
                <TableCell component="td" scope="row" align="left">
                  {seller.seller_name}
                </TableCell>
              )}
              {showHideColumns.sale && (
                <TableCell align="right">
                  {formatPrice(Number(seller.total_vendas))}
                </TableCell>
              )}
              {showHideColumns.devolution && (
                <TableCell align="right">
                  {formatPrice(Number(seller.total_devolucao))}
                </TableCell>
              )}
              {showHideColumns.total && (
                <TableCell
                  align="right"
                  style={{
                    color: getTotalColor(
                      Number(seller.total),
                      Number(seller.counter),
                    ),
                  }}
                >
                  {formatPrice(Number(seller.total))}
                </TableCell>
              )}
              {showHideColumns.dailyGoal && (
                <TableCell align="right">
                  {formatPrice(Number(dailyGoalSeller))}
                </TableCell>
              )}
              {showHideColumns.goal && (
                <TableCell align="right">
                  {formatPrice(Number(seller.counter))}
                </TableCell>
              )}
              <TableCell />
            </TableRow>
          )
        })}
      <SellerDialog
        open={!!sellerId && !!codNbs}
        sellerId={sellerId}
        codNbs={codNbs}
        sellerDailyGoal={sellerDailyGoal}
        onClose={handleCloseSellerDialog}
      />
    </>
  )
}
CustomRow.propTypes = {
  row: PropTypes.shape({
    group_name: PropTypes.string,
    id: PropTypes.string,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_vendas: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_devolucao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    working_days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dailyGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dealership_city: PropTypes.string,
    sellers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}
