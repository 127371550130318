import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getNotificationsRequest: [],
  getNotificationsSuccess: ['data'],
  getNotificationsFailure: [],
  handleNotificationsFilterChange: ['value'],
  handleReadNotificationRequest: ['id', 'callback'],
  handleReadNotificationFinish: [],
  getCongratulationsBirthdayRequest: ['id', 'callback'],
  getCongratulationsBirthdaySuccess: [],
  getCongratulationsBirthdayFailure: [],
  getCongratulationsCompanyAnniversaryRequest: ['id', 'callback'],
  getCongratulationsCompanyAnniversarySuccess: [],
  getCongratulationsCompanyAnniversaryFailure: [],
  readAllNotificationsRequest: [],
  readAllNotificationsFinish: [],
})

const INITIAL_STATE = {
  data: {
    calls: [],
    general: [],
    communication: [],
    unread: 0,
  },
  loading: false,
  loadingRead: false,
  onlyUnread: false,
}

const handleNotificationsFilterChange = (state = INITIAL_STATE, { value }) => ({
  ...state,
  onlyUnread: value,
})

const getNotificationsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getNotificationsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data: {
    ...state.data,
    calls: data.calls || [],
    general: data.general || [],
    communication: data.communication || [],
    unread: data.unread || 0,
  },
  loading: false,
})

const getNotificationsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const handleReadNotificationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingRead: true,
})

const handleReadNotificationFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingRead: false,
})

const getCongratulationsBirthdayRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCongratulationsBirthdaySuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const getCongratulationsBirthdayFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const getCongratulationsCompanyAnniversaryRequest = (
  state = INITIAL_STATE,
) => ({
  ...state,
  loading: false,
})

const getCongratulationsCompanyAnniversarySuccess = (
  state = INITIAL_STATE,
) => ({
  ...state,
  loading: false,
})
const getCongratulationsCompanyAnniversaryFailure = (
  state = INITIAL_STATE,
) => ({
  ...state,
  loading: false,
})

const readAllNotificationsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const readAllNotificationsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.HANDLE_NOTIFICATIONS_FILTER_CHANGE]: handleNotificationsFilterChange,
  [Types.HANDLE_READ_NOTIFICATION_REQUEST]: handleReadNotificationRequest,
  [Types.HANDLE_READ_NOTIFICATION_FINISH]: handleReadNotificationFinish,
  [Types.GET_CONGRATULATIONS_BIRTHDAY_REQUEST]:
    getCongratulationsBirthdayRequest,
  [Types.GET_CONGRATULATIONS_BIRTHDAY_SUCCESS]:
    getCongratulationsBirthdaySuccess,
  [Types.GET_CONGRATULATIONS_BIRTHDAY_FAILURE]:
    getCongratulationsBirthdayFailure,
  [Types.GET_CONGRATULATIONS_COMPANY_ANNIVERSARY_REQUEST]:
    getCongratulationsCompanyAnniversaryRequest,
  [Types.GET_CONGRATULATIONS_COMPANY_ANNIVERSARY_SUCCESS]:
    getCongratulationsCompanyAnniversarySuccess,
  [Types.GET_CONGRATULATIONS_COMPANY_ANNIVERSARY_FAILURE]:
    getCongratulationsCompanyAnniversaryFailure,
  [Types.READ_ALL_NOTIFICATIONS_REQUEST]: readAllNotificationsRequest,
  [Types.READ_ALL_NOTIFICATIONS_FINISH]: readAllNotificationsFinish,
})
