import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'
import { normalizedOrganogram } from '~/helpers/organogram'

import {
  Types as OrganogramTypes,
  Creators as OrganogramActions,
} from '../ducks/organogram'

const filterSelector = ({ organogram }) => organogram.filter

function* getOrganogramData() {
  const filters = yield select(filterSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'organogram', {
      params: {
        dealership_id: filters.dealership_id,
      },
    })
    yield put(
      OrganogramActions.getOrganogramDataSuccess(normalizedOrganogram(data)),
    )
  } catch (e) {
    yield put(OrganogramActions.getOrganogramDataFailure())
  }
}

// function* reloadOrganogram() {
//   yield put(OrganogramActions.OrganogramActionsRequest())
// }

export default function* () {
  yield takeLatest(
    OrganogramTypes.GET_ORGANOGRAM_DATA_REQUEST,
    getOrganogramData,
  )
}
