import moment from 'moment'
import momentBussinessDays from 'moment-business-days'
import 'moment/locale/pt-br'

const holidays = [
  '01-01',
  '15-04',
  '21-04',
  '01-05',
  '07-09',
  '20-09',
  '12-10',
  '02-11',
  '15-11',
  '25-12',
]

export const getWorkingDays = (date) => {
  momentBussinessDays.updateLocale('pt-br', {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidays,
    holidayFormat: 'DD-MM',
  })
  let formattedDate
  if (!date) {
    formattedDate = moment().endOf('month').format('YYYY-MM-DD')
  }
  formattedDate = moment(date).endOf('month').format('YYYY-MM-DD')

  const businessDaysinMonth = momentBussinessDays(
    formattedDate,
    'YYYY-MM-DD',
  ).businessDaysIntoMonth()

  return businessDaysinMonth
}
