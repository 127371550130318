import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Button, Chip, TextField } from '@material-ui/core'

import { Backup as UploadIcon } from '@material-ui/icons'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import Autocomplete from '@material-ui/lab/Autocomplete'

import RhfTextField from '~/components/FormComponents/RhfTextField'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import history from '~/helpers/history'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

import styles from './styles'

const useStyles = makeStyles(styles)

const newsBoardSelector = ({ newsBoard }) => newsBoard
const newsBoardDealershipsSelector = ({ newsBoard }) => newsBoard.dealerships

export default function EditNewsBoardPage({
  match: {
    params: { id },
  },
}) {
  const classes = useStyles()
  const fileInput = useRef(null)
  const [image, setImage] = useState('')
  const [imageUpload, setImageUpload] = useState('')
  const dispatch = useDispatch()
  const { news } = useSelector(newsBoardSelector)
  const { data, loading } = news
  const isEdit = id !== 'new'
  const communicationDealerships = useSelector(newsBoardDealershipsSelector)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (isEdit) {
      dispatch(NewsBoardActions.getNewsRequest(id))
      dispatch(NewsBoardActions.getCommunicationDealershipsRequest())
    }
  }, [isEdit, id])
  useEffect(() => {
    reset(data)
    if (data.url_image) {
      setImage(data.url_image)
    }
    dispatch(NewsBoardActions.getCommunicationDealershipsRequest())
    return () => {
      reset({})
      setImage('')
    }
  }, [reset, data])

  const handleUploadImage = (e) => {
    const allowedExtensions = {
      image: ['png', 'jpg', 'jpeg'],
    }
    const [file] = e.target.files
    if (!file) {
      return
    }
    const [fileType, ext] = file.type.split('/')

    if (
      !allowedExtensions[fileType] ||
      !allowedExtensions[fileType].includes(ext)
    ) {
      dispatch(
        FlashMessageActions.showMessage({
          message: 'Extensão não permitida',
          variant: 'error',
        }),
      )
      return
    }
    setImageUpload(file)
    const reader = new FileReader()
    reader.addEventListener('load', () => setImage(reader.result))
    reader.readAsDataURL(file)
    e.target.value = null
  }

  const handleSubmitNewsForm = (formData) => {
    let formattedData = {
      ...formData,
      type: 'communication',
    }

    if (image) {
      formattedData = {
        ...formattedData,
        image: imageUpload,
      }
    }
    if (isEdit) {
      dispatch(NewsBoardActions.updateNewsRequest(id, formattedData))
    } else {
      dispatch(NewsBoardActions.addNewsRequest(formattedData))
    }
  }

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      <Portlet className={classes.grow}>
        {!loading && (
          <>
            <div className={classes.content}>
              <form
                className={classes.form}
                id="news-form"
                onSubmit={handleSubmit(handleSubmitNewsForm)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RhfTextField
                      fullWidth
                      control={control}
                      name="title"
                      rules={{ required: true }}
                      label="Título da Publicação"
                      helperText={errors && errors.title && 'Campo obrigatório'}
                      error={errors && !!errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RhfTextField
                      multiline
                      rows={20}
                      fullWidth
                      control={control}
                      rules={{ required: true }}
                      name="text"
                      label="Descrição"
                      helperText={errors && errors.text && 'Campo obrigatório'}
                      error={errors && !!errors.text}
                    />
                  </Grid>

                  {image && (
                    <Grid item xs={12} className={classes.imageWrapper}>
                      <img src={image} alt="imagem" />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      className={classes.uploadButton}
                      onClick={() => {
                        fileInput.current.click()
                      }}
                    >
                      <UploadIcon className={classes.uploadIcon} />
                      Selecionar imagem
                      <input
                        type="file"
                        multiple={false}
                        onClick={(e) => {
                          e.target.value = ''
                        }}
                        ref={fileInput}
                        style={{ display: 'none' }}
                        onChange={handleUploadImage}
                      />
                    </Button>
                    <Grid item xs={12} className={classes.dealershipsSelect}>
                      <Controller
                        name="dealerships_id"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ...rest } }) => (
                          <Autocomplete
                            fullWidth
                            multiple
                            value={value || []}
                            onChange={(event, v) => {
                              onChange(v)
                            }}
                            disableCloseOnSelect
                            filterSelectedOptions
                            options={communicationDealerships.data}
                            {...rest}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, val) => {
                              return option.id === val.id
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue?.map((option, index) => (
                                <Chip
                                  label={option.name}
                                  style={{
                                    backgroundColor: '#ED1E79',
                                    color: '#FFFFFF',
                                    // eslint-disable-next-line no-nested-ternary
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors.dealerships_id}
                                helperText={
                                  !!errors.dealerships_id && 'Campo obrigatório'
                                }
                                label="Selecione as concessionárias que terão acesso ao comunicado *"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Grid
              container
              justifyContent="space-between"
              className={classes.actionsWrapper}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => history.push('/communication-board')}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="news-form"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Portlet>
    </div>
  )
}

EditNewsBoardPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
