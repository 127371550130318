export default (theme) => ({
  root: {
    backgroundColor: 'rgba(78, 60, 147, 0.05)',
    paddingBottom: 16,
    position: 'relative',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 24px',
    marginBottom: 24,
    cursor: 'pointer',
  },
  textButton: {
    textDecoration: 'underline',
    fontWeight: 500,
    padding: '12px 0px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  communicationWrapper: {
    position: 'sticky',
    top: 80,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
  },
  communicationTitle: {
    color: '#fff',
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#3A3B3F',
  },
  cardContent: {
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 200,
    marginBottom: theme.spacing(2),
  },
  communicationTitleWrapper: {
    width: '100%',
    background: 'linear-gradient(270.2deg, #ED1E79 0%, #1B1464 100%);',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    padding: '14px 0px',
  },
  titleWrapper: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  emptyPost: {
    padding: '0 24px',
  },
  mobileCard: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
  },
  grow: {
    display: 'flex',
    height: 200,
    flex: 1,
    position: 'relative',
  },
})
