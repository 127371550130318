import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Card,
  Grid,
  Divider,
  CardContent,
  makeStyles,
} from '@material-ui/core'

import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function CommunicationsCard({
  data,
  onOpenCommunicationDialog,
  isSmallScreen,
}) {
  const classes = useStyles()

  return (
    <>
      {!isSmallScreen && (
        <div className={classes.root}>
          <div className={classes.communicationTitleWrapper}>
            <Typography variant="h5" className={classes.communicationTitle}>
              MURAL DE COMUNICADOS
            </Typography>
          </div>
          <Grid item xs={12}>
            {data?.map((news) => (
              <div className={classes.cardWrapper} key={news.id}>
                <Card key={news.id} style={{ width: '100%' }}>
                  <Grid container className={classes.titleWrapper}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{news.title}</Typography>
                    </Grid>
                  </Grid>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.div}>
                      <Typography
                        color="primary"
                        variant="body1"
                        className={classes.text}
                      >
                        {news.text}
                      </Typography>
                    </div>
                  </CardContent>
                  <Divider className={classes.divider} />
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      onClick={() =>
                        onOpenCommunicationDialog(
                          news.title,
                          news.text,
                          news.url_image,
                        )
                      }
                    >
                      <Typography className={classes.textButton}>
                        LER MAIS
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            ))}
          </Grid>

          {data.length === 0 && (
            <Grid item xs={12} md={12} className={classes.emptyPost}>
              <Portlet className={classes.grow}>
                <EmptyList message="Não há comunicados para serem visualizados" />
              </Portlet>
            </Grid>
          )}
        </div>
      )}
      {isSmallScreen && (
        <Grid item xs={12}>
          {data?.map((news) => (
            <div key={news.id}>
              <Card className={classes.mobileCard}>
                <Grid container className={classes.titleWrapper}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{news.title}</Typography>
                  </Grid>
                </Grid>
                <CardContent className={classes.cardContent}>
                  <div className={classes.div}>
                    <Typography
                      color="primary"
                      variant="body1"
                      className={classes.text}
                    >
                      {news.text}
                    </Typography>
                  </div>
                </CardContent>
                <Divider className={classes.divider} />
                <Grid container justifyContent="center">
                  <Grid
                    item
                    onClick={() =>
                      onOpenCommunicationDialog(
                        news.title,
                        news.text,
                        news.url_image,
                      )
                    }
                  >
                    <Typography className={classes.textButton}>
                      LER MAIS
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </div>
          ))}

          {data.length === 0 && (
            <Grid item xs={12} md={12}>
              <Portlet className={classes.grow}>
                <EmptyList message="Não há comunicados para serem visualizados" />
              </Portlet>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

CommunicationsCard.propTypes = {
  onOpenCommunicationDialog: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
  isSmallScreen: PropTypes.bool,
}

CommunicationsCard.defaultProps = {
  isSmallScreen: false,
}
