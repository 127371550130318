export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  gridContainerButton: {
    marginBottom: 24,
  },
  buttonAdd: {
    minWidth: 259,
    minHeight: 40,
  },
  iconAdd: {
    marginRight: 8,
  },
  grow: {
    flex: 1,
    overflowX: 'auto',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#FCFCFC',
    },
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },

  showHideButton: {
    padding: 0,
    float: 'right',
  },
  formControlLabel: {
    minWidth: 292,
    maxHeight: 500,
  },
  menuList: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  popper: {
    zIndex: 5,
    left: -21,
  },
})
