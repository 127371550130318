import React from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Highcharts from 'highcharts'
import { HighchartsProvider } from 'react-jsx-highcharts'
import addHighchartsMore from 'highcharts/highcharts-more'
import addSolidGaugeModule from 'highcharts/modules/solid-gauge'
import addSankeyModule from 'highcharts/modules/sankey'
import addOrganization from 'highcharts/modules/organization'
import { highchartsLang } from '~/helpers/charts'
import theme from '../theme'
import AppRouter from '../router/AppRouter'

addHighchartsMore(Highcharts)
addSolidGaugeModule(Highcharts)
addSankeyModule(Highcharts)
addOrganization(Highcharts)

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Roboto',
    },
  },
  lang: highchartsLang,
})

function AppContainer() {
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <AppRouter />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HighchartsProvider>
  )
}
export default AppContainer
