import green from '@material-ui/core/colors/green'
import { white, black } from './colors'

const palette = {
  common: {
    black,
    white,
    neutral: '#1B1464',
    muted: '#c3c0c0',
  },
  primary: {
    contrastText: white,
    main: '#1B1464',
    dark: '#000039',
  },
  secondary: {
    contrastText: white,
    main: '#ED1E79',
    hover: '#ED5079',
  },
  success: {
    contrastText: white,
    main: green[600],
    dark: '#348137',
  },
  text: {
    main: '#3A3B3F',
    secondary: '#757575',
    disabled: '#B3B3B3',
  },
  info: {
    contrastText: white,
    main: '#1665D8',
  },
  button: {
    containedHover: '#000000, 10%',
  },
  warning: {
    contrastText: white,
    main: '#FFB300',
  },
  error: {
    dark: '#E53935',
    main: '#E53935',
  },
  danger: {
    contrastText: white,
    dark: '#E53935',
  },
  background: {
    light: '#FBFBFB',
    default: '#F6F6F6',
    paper: white,
    table: '#FCFCFC',
    active: '#F6F5F9',
  },
  border: '#DFE3E8',
  divider: '#E4E7EB',
  tableBoder: '#B8BAB7',
}

export default palette
