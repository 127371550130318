import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getNewsBoardRequest: ['newsType', 'onlyVisible'],
  getNewsBoardFailure: [],
  getNewsBoardSuccess: ['data', 'total'],
  getNewsBoardRequestFeed: ['newsType', 'onlyVisible'],
  getNewsBoardFailureFeed: [],
  getNewsBoardSuccessFeed: ['data', 'total'],
  setNewsBoardSort: ['sort', 'direction'],
  setNewsBoardCommunicationPage: ['page', 'limit'],
  setNewsBoardPage: ['page', 'limit'],
  setNewsBoardPageFeed: ['page', 'limit'],
  setNewsPerPageFeedRequest: [],
  setNewsPerPageFeedFinish: [],
  setCommunicationPerPageFeed: [],
  setNewsBoardSearch: ['value'],
  addNewsRequest: ['data'],
  addNewsFinish: [],
  getNewsRequest: ['id'],
  getNewsSuccess: ['data'],
  getNewsFailure: [],
  getNewsUsersRequest: ['search', 'callback'],
  updateNewsRequest: ['id', 'data', 'callback'],
  updateNewsFinish: [],
  resetNews: [],
  removeNewsRequest: ['id', 'newsType', 'callback'],
  removeNewsFinish: [],
  sendMailNewsRequest: ['id'],
  sendMailNewsFinish: [],
  sendMailCommunicationRequest: ['id'],
  sendMailCommunicationFinish: [],
  setNewsReactionRequest: ['id', 'reaction'],
  setNewsReactionSuccess: ['id', 'data'],
  setNewsReactionFailure: [],
  addNewsCommentRequest: ['formData', 'callback'],
  addNewsCommentFinish: [],
  updateNewsCommentRequest: ['commentId', 'data', 'callback'],
  updateNewsCommentFinish: [],
  removeNewsCommentRequest: ['commentId', 'callback'],
  removeNewsCommentFinish: [],
  getCommunicationDealershipsRequest: [],
  getCommunicationDealershipsSuccess: ['data'],
  getCommunicationDealershipsFailure: [],
})

const INITIAL_STATE = {
  data: [],
  dataFeed: [],
  loading: false,
  dealerships: {
    data: [],
    loading: false,
    loadingFeed: false,
    loadingCommunication: false,
  },
  options: {
    sort: 'created_at',
    direction: 'desc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
  },
  optionsCommunicationHome: {
    sort: 'created_at',
    direction: 'desc',
    search: '',
    page: 0,
    total: 0,
    limit: 50,
  },
  optionsFeed: {
    sort: 'created_at',
    direction: 'desc',
    search: '',
    page: 0,
    total: 0,
    limit: 5,
  },
  news: {
    data: {},
    loading: false,
  },
  newsType: null,
  loadingReactions: false,
  loadingComments: false,
}

const resetNews = () => ({
  ...INITIAL_STATE,
})

const getNewsRequest = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: true,
  },
})

const getNewsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  news: {
    ...state.news,
    data: {
      ...data,
      dealerships_id: data.dealerships,
    },
    loading: false,
  },
})

const getNewsFailure = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: true,
  },
})

const addNewsRequest = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: true,
  },
})

const addNewsFinish = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: false,
  },
})

const updateNewsRequest = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: true,
  },
})

const updateNewsFinish = (state = INITIAL_STATE) => ({
  ...state,
  news: {
    ...state.news,
    loading: false,
  },
})

const getNewsBoardRequest = (state = INITIAL_STATE, { newsType }) => ({
  ...state,
  newsType,
  loading: true,
})

const getNewsBoardFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getNewsBoardSuccess = (state = INITIAL_STATE, { data, total }) => {
  return {
    ...state,
    options: {
      ...state.options,
      total,
    },
    data,
    loading: false,
  }
}
const getNewsBoardRequestFeed = (state = INITIAL_STATE, { newsType }) => ({
  ...state,
  newsType,
  loading: true,
})

const getNewsBoardFailureFeed = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})
const getNewsBoardSuccessFeed = (state = INITIAL_STATE, { data, total }) => {
  return {
    ...state,
    optionsFeed: {
      ...state.optionsFeed,
      total,
    },
    dataFeed: data,
    loading: false,
  }
}

const setNewsBoardSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setNewsBoardCommunicationPage = (
  state = INITIAL_STATE,
  { page, limit },
) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setNewsBoardPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setNewsBoardPageFeed = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setNewsPerPageFeedRequest = (state = INITIAL_STATE) => ({
  ...state,
  optionsFeed: {
    ...state.optionsFeed,
    limit: state.optionsFeed.limit + 5,
  },
  loadingFeed: false,
})

const setNewsPerPageFeedFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingFeed: false,
})

const setCommunicationPerPageFeed = (state = INITIAL_STATE) => ({
  ...state,
  options: {
    ...state.options,
    limit: state.options.limit + 5,
  },
})

const setNewsBoardSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})
const removeNewsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeNewsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const sendMailNewsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const sendMailNewsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const sendMailCommunicationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const setNewsReactionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingReactions: true,
})

const setNewsReactionSuccess = (state = INITIAL_STATE, { id, data }) => {
  return {
    ...state,
    data: state.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          reactions: data,
        }
      }
      return item
    }),
    loadingReactions: false,
  }
}

const sendMailCommunicationFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setNewsReactionFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: false,
})

const addNewsCommentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: true,
})

const addNewsCommentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: false,
})

const updateNewsCommentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: true,
})

const updateNewsCommentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: false,
})

const removeNewsCommentRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: true,
})

const removeNewsCommentFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingComments: false,
})

const getCommunicationDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getCommunicationDealershipsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getCommunicationDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getNewsUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_NEWS_BOARD_REQUEST]: getNewsBoardRequest,
  [Types.GET_NEWS_BOARD_FAILURE]: getNewsBoardFailure,
  [Types.GET_NEWS_BOARD_SUCCESS]: getNewsBoardSuccess,
  [Types.GET_NEWS_BOARD_REQUEST_FEED]: getNewsBoardRequestFeed,
  [Types.GET_NEWS_BOARD_FAILURE_FEED]: getNewsBoardFailureFeed,
  [Types.GET_NEWS_BOARD_SUCCESS_FEED]: getNewsBoardSuccessFeed,
  [Types.SET_NEWS_BOARD_COMMUNICATION_PAGE]: setNewsBoardCommunicationPage,
  [Types.SET_NEWS_BOARD_PAGE]: setNewsBoardPage,
  [Types.SET_NEWS_BOARD_PAGE_FEED]: setNewsBoardPageFeed,
  [Types.SET_NEWS_PER_PAGE_FEED_REQUEST]: setNewsPerPageFeedRequest,
  [Types.SET_NEWS_PER_PAGE_FEED_FINISH]: setNewsPerPageFeedFinish,
  [Types.SET_COMMUNICATION_PER_PAGE_FEED]: setCommunicationPerPageFeed,
  [Types.SET_NEWS_BOARD_SEARCH]: setNewsBoardSearch,
  [Types.SET_NEWS_BOARD_SORT]: setNewsBoardSort,
  [Types.ADD_NEWS_REQUEST]: addNewsRequest,
  [Types.ADD_NEWS_FINISH]: addNewsFinish,
  [Types.GET_NEWS_REQUEST]: getNewsRequest,
  [Types.GET_NEWS_FAILURE]: getNewsFailure,
  [Types.GET_NEWS_SUCCESS]: getNewsSuccess,
  [Types.UPDATE_NEWS_REQUEST]: updateNewsRequest,
  [Types.UPDATE_NEWS_FINISH]: updateNewsFinish,
  [Types.RESET_NEWS]: resetNews,
  [Types.REMOVE_NEWS_REQUEST]: removeNewsRequest,
  [Types.REMOVE_NEWS_FINISH]: removeNewsFinish,
  [Types.SEND_MAIL_NEWS_REQUEST]: sendMailNewsRequest,
  [Types.SEND_MAIL_NEWS_FINISH]: sendMailNewsFinish,
  [Types.SEND_MAIL_COMMUNICATION_REQUEST]: sendMailCommunicationRequest,
  [Types.SEND_MAIL_COMMUNICATION_FINISH]: sendMailCommunicationFinish,
  [Types.SET_NEWS_REACTION_REQUEST]: setNewsReactionRequest,
  [Types.SET_NEWS_REACTION_SUCCESS]: setNewsReactionSuccess,
  [Types.SET_NEWS_REACTION_FAILURE]: setNewsReactionFailure,
  [Types.ADD_NEWS_COMMENT_REQUEST]: addNewsCommentRequest,
  [Types.ADD_NEWS_COMMENT_FINISH]: addNewsCommentFinish,
  [Types.UPDATE_NEWS_COMMENT_REQUEST]: updateNewsCommentRequest,
  [Types.UPDATE_NEWS_COMMENT_FINISH]: updateNewsCommentFinish,
  [Types.REMOVE_NEWS_COMMENT_REQUEST]: removeNewsCommentRequest,
  [Types.REMOVE_NEWS_COMMENT_FINISH]: removeNewsCommentFinish,
  [Types.GET_COMMUNICATION_DEALERSHIPS_REQUEST]:
    getCommunicationDealershipsRequest,
  [Types.GET_COMMUNICATION_DEALERSHIPS_SUCCESS]:
    getCommunicationDealershipsSuccess,
  [Types.GET_COMMUNICATION_DEALERSHIPS_FAILURE]:
    getCommunicationDealershipsFailure,
  [Types.GET_NEWS_USERS_REQUEST]: getNewsUsersRequest,
})
