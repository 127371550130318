import { call, takeLatest, put, select } from 'redux-saga/effects'

import { get } from 'lodash'

import configureApi from '~/helpers/api'

import jsonToFormData from '~/helpers/jsonToFormData'

import { Types as PopsTypes, Creators as PopsActions } from '../ducks/pops'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ pops }) => pops.options

function* getPops() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'quizzes', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        'where[type]': 'pop',
      },
    })

    yield put(PopsActions.getPopsSuccess(data.data, data.total))
  } catch (e) {
    yield put(PopsActions.getPopsFailure())
  }
}

function* getPop({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `quizzes/${id}`)
    yield put(PopsActions.getPopSuccess(data))
  } catch (error) {
    yield put(PopsActions.getPopFailure())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadPops() {
  yield put(PopsActions.getPopsRequest())
}

function* removePop({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/quizzes/${id}`)

    yield put(PopsActions.removePopFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Procedimento removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(PopsActions.removePopFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addPop({ data, callback }) {
  const formattedData = jsonToFormData(data)

  data.functions.map((item) =>
    formattedData.append('functions_id[]', String(item.id)),
  )

  try {
    const api = configureApi()
    yield call(api.post, 'quizzes', formattedData)

    if (callback) {
      yield call(callback)
    }

    yield put(PopsActions.addPopSuccess())

    yield put(
      FlashMessageActions.showMessage({
        message: 'Procedimento cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(PopsActions.addPopFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updatePop({ id, data, callback }) {
  // delete data.quiz_category_id

  const formattedData = jsonToFormData(data)

  data.functions.map((item) =>
    formattedData.append('functions_id[]', String(item.id)),
  )

  // formattedData.quiz_category_id = null

  try {
    const api = configureApi()
    yield call(api.post, `quizzes/${id}`, formattedData)

    if (callback) {
      yield call(callback)
    }

    yield put(PopsActions.updatePopFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Questionário editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(PopsActions.updatePopFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getPopFunctions() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'functions', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        limit: 200,
      },
    })

    yield put(PopsActions.getPopFunctionsSuccess(data.data, data.total))
  } catch (e) {
    yield put(PopsActions.getPopFunctionsFailure())
  }
}

export default function* () {
  yield takeLatest(PopsTypes.GET_POPS_REQUEST, getPops)
  yield takeLatest(PopsTypes.SET_POPS_SORT, reloadPops)
  yield takeLatest(PopsTypes.SET_POPS_PAGE, reloadPops)
  yield takeLatest(PopsTypes.GET_POP_REQUEST, getPop)
  yield takeLatest(PopsTypes.REMOVE_POP_REQUEST, removePop)
  yield takeLatest(PopsTypes.ADD_POP_REQUEST, addPop)
  yield takeLatest(PopsTypes.UPDATE_POP_REQUEST, updatePop)
  yield takeLatest(PopsTypes.GET_POP_FUNCTIONS_REQUEST, getPopFunctions)
}
