import React from 'react'

import { Grid, MenuItem, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { useSelector, useDispatch } from 'react-redux'
import { useEffectOnce, useUnmount } from 'react-use'

import { Creators as BiDashboardActions } from '~/store/ducks/biDashboard'

const biDashboardSelector = ({ biDashboard }) => biDashboard

function BiDashbaordFilters() {
  const { filtersData, filters } = useSelector(biDashboardSelector)
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(BiDashboardActions.getBiDashboardGroupsRequest())
  })

  useUnmount(() => {
    dispatch(BiDashboardActions.resetBiDashboard())
  })

  function handleChangeFilter(property, value) {
    if (property === 'group') {
      dispatch(BiDashboardActions.setBiDashboardGroupFilter(value))
    } else {
      dispatch(BiDashboardActions.setBiDashboardFilter(property, value))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            fullWidth
            views={['month', 'year']}
            label="Mês/Ano"
            invalidDateMessage="Data inválida"
            format="MM/yyyy"
            value={filters.date}
            onChange={(dValue) => handleChangeFilter('date', dValue)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Grupos"
          fullWidth
          select
          value={filters.group || 0}
          onChange={(ev) => handleChangeFilter('group', ev.target.value)}
        >
          {filtersData.groups.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default BiDashbaordFilters
