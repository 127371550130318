import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'
import ShowHideColumns from '~/components/ShowHideColumns'

import { Creators as BiDashboardActions } from '~/store/ducks/biDashboard'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import styles from './styles'

const biDashboardSalesSelector = ({ biDashboard: { sales } }) => sales
const biDashboardShowHideColumnsDialog = ({ biDashboard }) => biDashboard

const useStyles = makeStyles(styles)

function SalesDialog({ dealershipId, dealershipName, open, onClose }) {
  const { data, loading } = useSelector(biDashboardSalesSelector)
  const { showHideColumnsDialog } = useSelector(
    biDashboardShowHideColumnsDialog,
  )
  const [sort, setSort] = useState('type')
  const [direction, setDirection] = useState(1)
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()
  const directionName = direction === 1 ? 'desc' : 'asc'

  useEffect(() => {
    if (open && dealershipId) {
      dispatch(BiDashboardActions.getBiDashboardSalesRequest(dealershipId))
    }
  }, [open, dealershipId])

  function handleSortChange(property) {
    if (sort === property) {
      setDirection(direction * -1)
    } else {
      setSort(property)
      setDirection(1)
    }
  }

  function handleSort(arr) {
    return arr.sort((a, b) => {
      if (typeof a[sort] === 'string' || typeof b[sort] === 'string') {
        return (a[sort] || '').localeCompare(b[sort] || '') * direction
      }

      return (a[sort] - b[sort]) * direction
    })
  }

  function handleClose() {
    setSort('type')
    setDirection(1)
    onClose()
  }

  const optionsShowHideColumnDialog = [
    { name: 'segment', label: 'Segmento', disabled: true },
    { name: 'cod', label: 'Cod. Proposta' },
    { name: 'version', label: 'Versão' },
    { name: 'yearModel', label: 'Ano/Modelo' },
    { name: 'seller', label: 'Vendedor' },
    { name: 'client', label: 'Cliente' },
  ]

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumnsDialog,
      [name]: value,
    }

    dispatch(
      BiDashboardActions.setChangeShowHideColumnsBiDashboardDialog(
        newColumnsTable,
      ),
    )
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobileScreen}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Vendas - {dealershipName}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <div className={classes.tableWrapper}>
          {!loading && (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {showHideColumnsDialog.segment && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'type'}
                        onClick={() => handleSortChange('type')}
                        direction={directionName}
                      >
                        Segmento
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumnsDialog.cod && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'cod_proposta'}
                        onClick={() => handleSortChange('cod_proposta')}
                        direction={directionName}
                      >
                        Cod. Proposta
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumnsDialog.version && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'descricao_modelo'}
                        onClick={() => handleSortChange('descricao_modelo')}
                        direction={directionName}
                      >
                        Versão
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumnsDialog.yearModel && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'ano_modelo'}
                        onClick={() => handleSortChange('ano_modelo')}
                        direction={directionName}
                      >
                        Ano/Modelo
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumnsDialog.seller && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'vendedor'}
                        onClick={() => handleSortChange('vendedor')}
                        direction={directionName}
                      >
                        Vendedor
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showHideColumnsDialog.client && (
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'nome_cliente'}
                        onClick={() => handleSortChange('nome_cliente')}
                        direction={directionName}
                      >
                        Cliente
                      </TableSortLabel>
                    </TableCell>
                  )}
                  <TableCell className={classes.showHideColumns}>
                    <ShowHideColumns
                      showHideColumns={showHideColumnsDialog}
                      optionsShowHideColumn={optionsShowHideColumnDialog}
                      onChangeShowHideColumn={handleSetChangeShowHideColumn}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handleSort(data).map((item) => (
                  <TableRow key={item.id}>
                    {showHideColumnsDialog.segment && (
                      <TableCell>{item.type}</TableCell>
                    )}
                    {showHideColumnsDialog.cod && (
                      <TableCell>{item.cod_proposta}</TableCell>
                    )}
                    {showHideColumnsDialog.version && (
                      <TableCell>{item.descricao_modelo}</TableCell>
                    )}
                    {showHideColumnsDialog.yearModel && (
                      <TableCell>{item.ano_modelo}</TableCell>
                    )}
                    {showHideColumnsDialog.seller && (
                      <TableCell>{item.vendedor}</TableCell>
                    )}
                    {showHideColumnsDialog.client && (
                      <TableCell>{item.nome_cliente}</TableCell>
                    )}
                    <TableCell className={classes.showHideColumns} />
                  </TableRow>
                ))}
              </TableBody>
              {data.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan="6">
                      <Grid container justifyContent="flex-end">
                        Total {data.length}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          )}
        </div>
        {!loading && data.length === 0 && (
          <EmptyList message="Não há vendas para serem visualizadas" />
        )}
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SalesDialog.propTypes = {
  dealershipId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dealershipName: PropTypes.string,
}

SalesDialog.defaultProps = {
  dealershipId: null,
  open: true,
  onClose: () => {},
  dealershipName: '',
}

export default SalesDialog
