import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Divider,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as HelpdeskDashboardActions } from '~/store/ducks/helpdeskDashboard'
import OverlayLoading from '~/components/OverlayLoading'
import ShowHideColumns from '~/components/ShowHideColumns'
import PieChart from '~/components/PieChart'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import styles from './styles'

const helpdeskCallsSelector = ({ helpdeskDashboard }) => helpdeskDashboard.calls

const helpdeskShowHideSelector = ({ helpdeskDashboard }) => helpdeskDashboard
const useStyles = makeStyles(styles)

function CallsDialog({ subject, open, onClose }) {
  const { data, currentSubject, loading, dataChart } = useSelector(
    helpdeskCallsSelector,
  )
  const { showHideColumns } = useSelector(helpdeskShowHideSelector)
  const [sort, setSort] = useState('id')
  const [direction, setDirection] = useState(1)
  const dispatch = useDispatch()
  const classes = useStyles()
  const directionName = direction === 1 ? 'desc' : 'asc'

  const dataPieChart = dataChart[0]

  const formattedDataChart = {
    ...dataPieChart,
    title: 'Departamentos',
    color: ['#1b1464', '#71056c', '#b10064', '#e0334f', '#fb6c33', '#ffa600'],
  }

  const isMobileScreen = useMobileScreen()

  useEffect(() => {
    if (open && currentSubject !== subject) {
      dispatch(HelpdeskDashboardActions.getHelpdeskCallsDataRequest(subject))
    }
  }, [currentSubject, open, subject])

  function handleSortChange(property) {
    if (sort === property) {
      setDirection(direction * -1)
    } else {
      setSort(property)
      setDirection(1)
    }
  }

  function handleSort(arr) {
    return arr.sort((a, b) => {
      if (typeof a[sort] === 'string' || typeof b[sort] === 'string') {
        return (a[sort] || '').localeCompare(b[sort] || '') * direction
      }

      return (a[sort] - b[sort]) * direction
    })
  }

  function handleClose() {
    setSort('id')
    setDirection(1)
    onClose()
  }

  const optionsShowHideColumn = [
    { name: 'id', label: 'ID', disabled: true },
    { name: 'status', label: 'Status' },
    { name: 'subject', label: 'Assunto' },
    { name: 'requester', label: 'Requerente' },
    { name: 'responsible', label: 'Responsável' },
  ]

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      HelpdeskDashboardActions.setChangeShowHideColumnsHelpdeskDashboard(
        newColumnsTable,
      ),
    )
  }

  return (
    <Dialog
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">Chamados - {dataChart[0]?.title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Grid container justifyContent="center">
          <Grid item xs={4} className={classes.portlet}>
            <PieChart {...formattedDataChart} />
          </Grid>
        </Grid>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              {showHideColumns.id && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'id'}
                    onClick={() => handleSortChange('id')}
                    direction={directionName}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumns.status && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'status'}
                    onClick={() => handleSortChange('status')}
                    direction={directionName}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumns.subject && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'subject'}
                    onClick={() => handleSortChange('subject')}
                    direction={directionName}
                  >
                    Assunto
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumns.requester && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'requester_name'}
                    onClick={() => handleSortChange('requester_name')}
                    direction={directionName}
                  >
                    Requerente
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumns.responsible && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'responsible_name'}
                    onClick={() => handleSortChange('responsible_name')}
                    direction={directionName}
                  >
                    Responsável
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell className={classes.showHideColumns}>
                <ShowHideColumns
                  showHideColumns={showHideColumns}
                  optionsShowHideColumn={optionsShowHideColumn}
                  onChangeShowHideColumn={handleSetChangeShowHideColumn}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handleSort(data).map((item) => (
              <TableRow key={item.id}>
                {showHideColumns.id && <TableCell>{item.id}</TableCell>}
                {showHideColumns.status && <TableCell>{item.status}</TableCell>}
                {showHideColumns.subject && (
                  <TableCell>{item.subject}</TableCell>
                )}
                {showHideColumns.requester && (
                  <TableCell>{item.requester_name}</TableCell>
                )}
                {showHideColumns.responsible && (
                  <TableCell>{item.responsible_name}</TableCell>
                )}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan="5">
                <Grid container justifyContent="flex-end">
                  Total {data.length}
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CallsDialog.propTypes = {
  subject: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

CallsDialog.defaultProps = {
  subject: '',
  open: true,
  onClose: () => {},
}

export default CallsDialog
