export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
    minHeight: 400,
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  grow: {
    flex: 1,
    marginTop: theme.spacing(3),
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  tableRowClicked: {
    cursor: 'pointer',
  },
  iconAdd: {
    marginRight: 8,
  },
  buttonAdd: {
    minWidth: 259,
    minHeight: 40,
  },
  editIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avatarIcon: {
    backgroundColor: '#fafafa',
    color: theme.palette.primary.main,
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterWropper: {
    display: 'flex',
  },
  totalRow: {
    fontWeight: 600,
  },
  showHideColumns: {
    width: '1%',
    padding: 0,
    margin: 0,
  },
})
