export default (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flex: 1,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  logoContainer: {
    width: '40%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    background: 'linear-gradient(180deg, #1B1464 0%, #191919 100%);',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    padding: '0 40px',
  },
  logoImg: {
    width: '100%',
    maxWidth: 501,
  },
  loginContainer: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formWrapper: {
    maxWidth: 400,
    [theme.breakpoints.down('md')]: {
      maxWidth: '',
      padding: theme.spacing(0, 2),
    },
  },
  title: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  forgotPassword: {
    textAlign: 'right',
    '& > button': {
      textTransform: 'none',
      padding: 0,
    },
  },
  logoMobile: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&>img': {
      width: '100%',
      maxWidth: 160,
    },
  },
})
