export default (theme) => ({
  root: {
    height: 1000,
  },
  titleDivider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  iconButton: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  grow: {
    flex: 1,
  },
  emptyWrapper: {
    display: 'flex',
    height: '85vh',
    flex: 1,
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: 16,
    border: 'solid 1px #DFE3E8',
    borderRadius: '4px',
  },
  actionsWrapperIcon: {
    display: 'flex',
  },
  actionsCell: {
    padding: 0,
  },
  icon: {
    padding: 0,
    marginRight: 16,
  },
})
