import React from 'react'
import classNames from 'classnames'

import {
  Snackbar,
  SnackbarContent,
  makeStyles,
  IconButton,
} from '@material-ui/core'

import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'

import PropTypes from 'prop-types'

import styles from './styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(styles)

const Toastr = ({ open, message, variant, onClose }) => {
  const classes = useStyles()
  const Icon = variantIcon[variant]
  const msg = (
    <span id="message-toastr" className={classes.message}>
      <Icon className={classNames(classes.icon, classes.iconVariant)} />
      {message}
    </span>
  )
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes[variant])}
        aria-describedby="message-toastr"
        message={msg}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

Toastr.defaultProps = {
  open: false,
  variant: 'info',
  onClose: () => {},
}

Toastr.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClose: PropTypes.func,
}

export default Toastr
