export default (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    '@media print': {
      display: 'none',
    },
    marginTop: 80,
  },

  logoWrapper: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    background: '#1B1464',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 271,
    height: 64,
  },
  logoImage: {
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  listDivider: {
    marginBottom: theme.spacing(1 / 2),
  },
  listItem: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.main,
    '&:hover': {
      backgroundColor: theme.palette.background.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      '& $listItemIcon': {
        color: theme.palette.primary.main,
        marginLeft: '-4px',
      },
    },
  },
  activeListItem: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.background.active,
    '& $listItemText': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    '& $listItemIcon': {
      color: theme.palette.primary.main,
      marginLeft: '-4px',
    },
  },
  listItemText: {
    color: theme.palette.text.main,
    fontWeight: 500,
    '& .MuiTypography-body1': {
      fontWeight: 500,
      color: theme.palette.text.main,
    },
  },
  dropdownIcon: {
    minWidth: 0,
    marginRight: 0,
  },
  listItemIcon: {
    marginRight: 0,
    color: theme.palette.primary.main,
  },
  collapsedContainer: {
    marginBottom: theme.spacing(1),
  },
  menuLabel: {
    margin: theme.spacing(2, 0),
    fontWeight: 500,
  },
  backgraundLogo: {
    background: 'linear-gradient(180deg, #1B1464 0%, #000039 100%);',
    width: 270,
    height: 84,
    marginBottom: 24,
    display: 'flex',
    alignContent: 'center',
  },
})
