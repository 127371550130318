import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  makeStyles,
  TableHead,
} from '@material-ui/core'

import LaunchIcon from '@material-ui/icons/Launch'

import { Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'

import history from '~/helpers/history'

import OverlayLoading from '~/components/OverlayLoading'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'

import { Creators as ReceiptActions } from '~/store/ducks/receipts'
import { Creators as UserActions } from '~/store/ducks/user'

import EmptyList from '~/components/EmptyList'
import styles from './styles'

const useStyles = makeStyles(styles)

const receiptsUserSelector = ({ user }) => user

function ReceiptTab({ idUser, data }) {
  const dispatch = useDispatch()
  const [receiptId, setReceiptId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const classes = useStyles()
  const { options, loading } = useSelector(receiptsUserSelector)

  const quizzeLink = (item) => {
    const apiUrl = process.env.REACT_APP_API_URL

    window.open(`${apiUrl}view-document/${item.url_image}`, '_blank')
  }

  const slugDocumentTypes = {
    advancePayment: 'Adiantamento',
    receipt: 'Recibo',
    vacation: 'Férias',
    salary: 'Salário',
    bonus: 'Décimo Terceiro',
    reportIncome: 'Relatório de Rendimentos',
  }

  const handleDeleteReceiptUser = (id) => {
    setReceiptId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      ReceiptActions.removeReceiptUserRequest(receiptId, () => {
        setReceiptId(null)
        setOpenConfirmDialog(false)
        history.push('/users')
      }),
    )
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(UserActions.setReceiptsUserTab(0, value, idUser))
  }

  const handleChangePage = (_, page) => {
    dispatch(UserActions.setReceiptsUserTab(page, options.limit, idUser))
  }

  return (
    <>
      {loading && <OverlayLoading />}
      {!loading && data.length > 0 && (
        <Grid container>
          <Grid xs={12} className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Ano</TableCell>
                  <TableCell>Mês</TableCell>
                  <TableCell>Recibo</TableCell>
                  <TableCell>Criado em</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => {
                  return (
                    <TableRow hover key={item.id}>
                      <TableCell>{item?.document?.year || '-'}</TableCell>
                      <TableCell>{item?.document?.month || '-'}</TableCell>
                      <TableCell>
                        {slugDocumentTypes[item.document.document_type]}
                      </TableCell>
                      <TableCell>
                        {moment(item.created_at).format('DD/MM/YYYY LT')}
                      </TableCell>
                      <TableCell className={classes.actionsCell} align="right">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => quizzeLink(item)}
                        >
                          <LaunchIcon />
                        </IconButton>

                        <IconButton
                          className={classes.icon}
                          onClick={() => handleDeleteReceiptUser(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
            <Divider className={classes.divider} />
          </Grid>
          <ConfirmDialog
            open={openConfirmDialog}
            title="Atenção"
            description="Deseja realmente excluir este recibo?"
            onCancel={handleRemoveCancel}
            onAccept={handleRemoveAccept}
          />
        </Grid>
      )}
      {data.length === 0 && (
        <Grid item xs={12} className={classes.emptyWrapper}>
          <Portlet className={classes.grow}>
            <EmptyList message="Não há recibos para serem visualizados." />
          </Portlet>
        </Grid>
      )}
    </>
  )
}

ReceiptTab.propTypes = {
  idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string,
    }),
  ).isRequired,
}

export default ReceiptTab
