import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getIslandsRequest: [],
  getIslandsFailure: [],
  getIslandsSuccess: ['data', 'total'],
  getDealershipsIslandsRequest: [],
  getDealershipsIslandsSuccess: ['data'],
  getDealershipsIslandsFailure: [],
  setIslandsDealershipsFilter: ['dealershipId'],
  getIslandRequest: ['id'],
  getIslandSuccess: ['data'],
  getIslandFailure: [],
  updateIslandRequest: ['id', 'data', 'callback'],
  updateIslandFinish: [],
  setIslandsPage: ['page', 'limit'],
})

const INITIAL_STATE = {
  data: [],
  dealerships: {
    data: [],
    loading: false,
  },
  options: {
    page: 0,
    total: 0,
    limit: 20,
    dealershipId: null,
  },
}

const getIslandsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getIslandsFailure = (state = INITIAL_STATE) => ({
  ...state,

  loading: false,
})

const getIslandsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})
const getDealershipsIslandsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})
const getDealershipsIslandsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getDealershipsIslandsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})
const setIslandsDealershipsFilter = (
  state = INITIAL_STATE,
  { dealershipId },
) => ({
  ...state,
  options: {
    ...state.options,
    dealershipId,
  },
})

const getIslandRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getIslandSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getIslandFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateIslandRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateIslandFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setIslandsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_ISLANDS_REQUEST]: getIslandsRequest,
  [Types.GET_ISLANDS_FAILURE]: getIslandsFailure,
  [Types.GET_ISLANDS_SUCCESS]: getIslandsSuccess,
  [Types.GET_DEALERSHIPS_ISLANDS_REQUEST]: getDealershipsIslandsRequest,
  [Types.GET_DEALERSHIPS_ISLANDS_SUCCESS]: getDealershipsIslandsSuccess,
  [Types.GET_DEALERSHIPS_ISLANDS_FAILURE]: getDealershipsIslandsFailure,
  [Types.SET_ISLANDS_DEALERSHIPS_FILTER]: setIslandsDealershipsFilter,
  [Types.GET_ISLAND_REQUEST]: getIslandRequest,
  [Types.GET_ISLAND_SUCCESS]: getIslandSuccess,
  [Types.GET_ISLAND_FAILURE]: getIslandFailure,
  [Types.UPDATE_ISLAND_REQUEST]: updateIslandRequest,
  [Types.UPDATE_ISLAND_FINISH]: updateIslandFinish,
  [Types.SET_ISLANDS_PAGE]: setIslandsPage,
})
