import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  makeStyles,
} from '@material-ui/core'

import GetAppIcon from '@material-ui/icons/GetApp'
import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'
import styles from './styles'

const useStyles = makeStyles(styles)

function PopTab({ data }) {
  const classes = useStyles()
  const quizzeLink = (item) => {
    window.open(`${item.url_pdf_file}`, '_blank')
  }

  return (
    <>
      {data?.length === 0 && (
        <Grid item xs={12} className={classes.emptyWrapper}>
          <Portlet className={classes.grow}>
            <EmptyList message="Não há POPs para serem visualizados." />
          </Portlet>
        </Grid>
      )}
      {data.length > 0 && (
        <Grid container>
          <Grid xs={12} className={classes.root}>
            <Table className={classes.table}>
              <TableBody>
                {data?.map((item) => {
                  return (
                    <TableRow hover key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => quizzeLink(item)}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

PopTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
}

export default PopTab
