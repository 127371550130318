import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addEquipmentTypeRequest: ['data', 'callback'],
  addEquipmentTypeFinish: [],
  getEquipmentTypeRequest: ['id'],
  getEquipmentTypeSuccess: ['data'],
  getEquipmentTypeFailure: [],
  updateEquipmentTypeRequest: ['id', 'data', 'callback'],
  updateEquipmentTypeFinish: [],
  removeEquipmentTypeRequest: ['id', 'callback'],
  removeEquipmentTypeFinish: [],
})

const INITIAL_STATE = {
  data: {
    name: '',
  },
  loading: false,
}

const addEquipmentTypeRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  }
}

const addEquipmentTypeFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getEquipmentTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentTypeSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getEquipmentTypeFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateEquipmentTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateEquipmentTypeFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeEquipmentTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeEquipmentTypeFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_EQUIPMENT_TYPE_REQUEST]: addEquipmentTypeRequest,
  [Types.ADD_EQUIPMENT_TYPE_FINISH]: addEquipmentTypeFinish,
  [Types.GET_EQUIPMENT_TYPE_REQUEST]: getEquipmentTypeRequest,
  [Types.GET_EQUIPMENT_TYPE_SUCCESS]: getEquipmentTypeSuccess,
  [Types.GET_EQUIPMENT_TYPE_FAILURE]: getEquipmentTypeFailure,
  [Types.UPDATE_EQUIPMENT_TYPE_REQUEST]: updateEquipmentTypeRequest,
  [Types.UPDATE_EQUIPMENT_TYPE_FINISH]: updateEquipmentTypeFinish,
  [Types.REMOVE_EQUIPMENT_TYPE_REQUEST]: removeEquipmentTypeRequest,
  [Types.REMOVE_EQUIPMENT_TYPE_FINISH]: removeEquipmentTypeFinish,
})
