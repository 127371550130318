import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as EquipmentTypeTypes,
  Creators as EquipmentTypeActions,
} from '../ducks/equipmentType'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* addEquipmentType({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'equipment_types', data)

    if (callback) {
      yield call(callback)
    }

    yield put(EquipmentTypeActions.addEquipmentTypeFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Tipo de equipamento cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(EquipmentTypeActions.addEquipmentTypeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getEquipmentType({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `equipment_types/${id}`)
    yield put(EquipmentTypeActions.getEquipmentTypeSuccess(data))
  } catch (e) {
    yield put(EquipmentTypeActions.getEquipmentTypeFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateEquipmentType({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `/equipment_types/${id}`, data)
    yield put(EquipmentTypeActions.updateEquipmentTypeFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Tipo de equipamento editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(EquipmentTypeActions.updateEquipmentTypeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeEquipmentType({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `/equipment_types/${id}`)

    yield put(EquipmentTypeActions.removeEquipmentTypeFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Tipo de equipamento removido com sucesso!',
        variant: 'success',
      }),
    )
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(EquipmentTypeActions.removeEquipmentTypeFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(
    EquipmentTypeTypes.ADD_EQUIPMENT_TYPE_REQUEST,
    addEquipmentType,
  )
  yield takeLatest(
    EquipmentTypeTypes.GET_EQUIPMENT_TYPE_REQUEST,
    getEquipmentType,
  )
  yield takeLatest(
    EquipmentTypeTypes.UPDATE_EQUIPMENT_TYPE_REQUEST,
    updateEquipmentType,
  )
  yield takeLatest(
    EquipmentTypeTypes.REMOVE_EQUIPMENT_TYPE_REQUEST,
    removeEquipmentType,
  )
}
