import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import history from '~/helpers/history'

import jsonToFormData from '~/helpers/jsonToFormData'
import {
  Types as NewsBoardTypes,
  Creators as NewsBoardActions,
} from '../ducks/newsBoard'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()
const optionsSelector = ({ newsBoard }) => newsBoard.options
const optionsFeedSelector = ({ newsBoard }) => newsBoard.optionsFeed
const newsTypeSelector = ({ newsBoard: { newsType } }) => newsType

function* getNewsBoard({ newsType, onlyVisible }) {
  const options = yield select(optionsSelector)
  const params = {
    ...options,
    pagination: 1,
    page: options.page + 1,
    limit: options.limit,
    order: `${options.sort},${options.direction}`,
  }

  if (options.search) {
    params.search = `text,${options.search}`
  }

  if (newsType) {
    params['where[type]'] = newsType
  }

  if (onlyVisible) {
    params['where[visible]'] = true
  }

  try {
    const { data } = yield call(api.get, 'news', {
      params,
    })

    yield put(NewsBoardActions.getNewsBoardSuccess(data.data, data.total))
  } catch (e) {
    yield put(NewsBoardActions.getNewsBoardFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getNewsBoardFeed({ newsType, onlyVisible }) {
  const options = yield select(optionsFeedSelector)
  const params = {
    ...options,
    pagination: 1,
    page: options.page + 1,
    limit: options.limit,
    order: `${options.sort},${options.direction}`,
  }

  if (options.search) {
    params.search = `text,${options.search}`
  }

  if (newsType) {
    params['where[type]'] = newsType
  }

  if (onlyVisible) {
    params['where[visible]'] = true
  }

  try {
    const { data } = yield call(api.get, 'news', {
      params,
    })

    yield put(NewsBoardActions.getNewsBoardSuccessFeed(data.data, data.total))
  } catch (e) {
    yield put(NewsBoardActions.getNewsBoardFailureFeed())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addNewsBoard({ data }) {
  const formData = jsonToFormData(data)

  formData.delete('dealerships_id')

  if (data?.dealerships_id) {
    data?.dealerships_id.forEach((dealership) =>
      formData.append('dealerships_id[]', dealership?.id),
    )
  }

  if (data?.mentions_id) {
    data?.mentions_id.forEach((mention) =>
      formData.append('mentions_id[]', mention),
    )
  }

  try {
    yield call(api.post, 'news', formData)
    yield put(NewsBoardActions.addNewsFinish())
    if (data.type === 'news') {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Notícia cadastrada com sucesso!',
          variant: 'success',
        }),
      )
      history.push('/news-board')
    } else {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Comunicado cadastrado com sucesso!',
          variant: 'success',
        }),
      )
      history.push('/communication-board')
    }
  } catch (e) {
    const error = get(e, 'response.data.error[0]')

    yield put(NewsBoardActions.addNewsinish())
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateNewsBoard({ id, data, callback }) {
  const formattedData = data
  if (!formattedData.url_video) {
    delete formattedData.url_video
  }

  const formData = jsonToFormData(formattedData)

  formData.delete('dealerships_id')

  if (data?.dealerships_id) {
    data?.dealerships_id.forEach((dealership) =>
      formData.append('dealerships_id[]', dealership?.id),
    )
  }

  if (data?.mentions_id) {
    data?.mentions_id.forEach((mention) =>
      formData.append('mentions_id[]', mention),
    )
  }

  try {
    yield call(api.post, `news/${id}`, formData)
    yield put(NewsBoardActions.updateNewsFinish())
    if (data.type === 'news') {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Notícia editada com sucesso!',
          variant: 'success',
        }),
      )
    } else {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Comunicado editado com sucesso!',
          variant: 'success',
        }),
      )
    }

    if (callback) {
      yield call(callback)
    } else if (data.type === 'news') {
      history.push('/news-board')
    } else {
      history.push('/communication-board')
    }
  } catch (e) {
    const error = get(e, 'response.data.error[0]')

    yield put(NewsBoardActions.updateNewsFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getNews({ id }) {
  try {
    const { data } = yield call(api.get, `news/${id}`)
    yield put(NewsBoardActions.getNewsSuccess(data))
  } catch (e) {
    yield put(NewsBoardActions.getNewsFailure())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadNewsBoard() {
  const newsType = yield select(newsTypeSelector)
  yield put(NewsBoardActions.getNewsBoardRequest(newsType, false))
  yield put(NewsBoardActions.setNewsPerPageFeedFinish())
}

function* removeNews({ id, newsType, callback }) {
  try {
    yield call(api.delete, `news/${id}`)

    if (callback) {
      yield call(callback)
    }

    if (newsType === 'news') {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Noticía removida com sucesso!',
          variant: 'success',
        }),
      )
      history.push('/news-board')
    } else {
      yield put(
        FlashMessageActions.showMessage({
          message: 'Comunicado removido com sucesso!',
          variant: 'success',
        }),
      )
      history.push('/communication-board')
    }
    yield put(NewsBoardActions.removeNewsFinish())
  } catch (e) {
    yield put(NewsBoardActions.removeNewsFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* sendMail({ id }) {
  try {
    yield call(api.post, `news/${id}/notify`)
    yield put(NewsBoardActions.sendMailNewsFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Notícia enviada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(NewsBoardActions.sendMailNewsFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* setNewsReaction({ id, reaction }) {
  try {
    const response = yield call(api.post, `news/${id}/reaction`, { reaction })
    yield put(NewsBoardActions.setNewsReactionSuccess(id, response.data))
  } catch (e) {
    yield call(NewsBoardActions.setNewsReactionFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* sendMailCommunication({ id }) {
  try {
    yield call(api.post, `sendCommunication/${id}`)
    yield put(NewsBoardActions.sendMailCommunicationFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Comunicado enviado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(NewsBoardActions.sendMailCommunicationFinish())
  }
}

function* updateComment({ commentId, data, callback }) {
  try {
    yield call(api.put, `news-comments/${commentId}`, data)

    yield put(
      FlashMessageActions.showMessage({
        message: 'Comentário editado com sucesso!',
        variant: 'success',
      }),
    )
    yield put(NewsBoardActions.updateNewsCommentFinish())
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(NewsBoardActions.updateNewsCommentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeComment({ commentId, callback }) {
  try {
    yield call(api.delete, `news-comments/${commentId}`)

    yield put(
      FlashMessageActions.showMessage({
        message: 'Comentário excluído com sucesso!',
        variant: 'success',
      }),
    )
    yield put(NewsBoardActions.removeNewsCommentFinish())
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(NewsBoardActions.removeNewsCommentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addComment({ formData, callback }) {
  try {
    yield call(api.post, 'news-comments', formData)
    yield put(
      FlashMessageActions.showMessage({
        message: 'Comentário adicionado com sucesso!',
        variant: 'success',
      }),
    )
    yield put(NewsBoardActions.addNewsCommentFinish())
    if (callback) {
      yield call(callback)
    }
  } catch (e) {
    yield put(NewsBoardActions.addNewsCommentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCommunicationDealerships() {
  try {
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })

    yield put(NewsBoardActions.getCommunicationDealershipsSuccess(data))
  } catch (e) {
    yield put(NewsBoardActions.getCommunicationDealershipsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getNewsUsers({ search, callback }) {
  try {
    const { data } = yield call(api.get, 'users', {
      params: {
        limit: 10,
        search: `name,${search}`,
      },
    })
    if (callback) {
      yield call(() =>
        callback(
          data?.data?.length > 0
            ? data.data.map((user) => ({
                id: user.id,
                display: user.name,
              }))
            : [],
        ),
      )
    }
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(NewsBoardTypes.GET_NEWS_BOARD_REQUEST, getNewsBoard)
  yield takeLatest(NewsBoardTypes.GET_NEWS_BOARD_REQUEST_FEED, getNewsBoardFeed)

  yield takeLatest(
    NewsBoardTypes.SET_NEWS_BOARD_COMMUNICATION_PAGE,
    reloadNewsBoard,
  )
  yield takeLatest(NewsBoardTypes.SET_NEWS_BOARD_PAGE, reloadNewsBoard)
  yield debounce(1000, NewsBoardTypes.SET_NEWS_BOARD_SEARCH, reloadNewsBoard)
  yield takeLatest(NewsBoardTypes.SET_NEWS_BOARD_SORT, reloadNewsBoard)
  yield takeLatest(NewsBoardTypes.ADD_NEWS_REQUEST, addNewsBoard)
  yield takeLatest(NewsBoardTypes.UPDATE_NEWS_REQUEST, updateNewsBoard)
  yield takeLatest(NewsBoardTypes.GET_NEWS_REQUEST, getNews)
  yield takeLatest(NewsBoardTypes.REMOVE_NEWS_REQUEST, removeNews)
  yield takeLatest(NewsBoardTypes.SEND_MAIL_NEWS_REQUEST, sendMail)
  yield takeLatest(
    NewsBoardTypes.SEND_MAIL_COMMUNICATION_REQUEST,
    sendMailCommunication,
  )
  yield takeLatest(NewsBoardTypes.SET_NEWS_REACTION_REQUEST, setNewsReaction)
  yield takeLatest(NewsBoardTypes.ADD_NEWS_COMMENT_REQUEST, addComment)
  yield takeLatest(NewsBoardTypes.UPDATE_NEWS_COMMENT_REQUEST, updateComment)
  yield takeLatest(NewsBoardTypes.REMOVE_NEWS_COMMENT_REQUEST, removeComment)
  yield takeLatest(
    NewsBoardTypes.GET_COMMUNICATION_DEALERSHIPS_REQUEST,
    getCommunicationDealerships,
  )
  yield debounce(500, NewsBoardTypes.GET_NEWS_USERS_REQUEST, getNewsUsers)
}
