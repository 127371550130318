export default (theme) => ({
  divider: {
    display: 'none',
    '@media (min-width: 1501px)': {
      // when slides to show is bigger than one
      display: 'block',
      color: '#000',
      width: 1,
      height: 60,
      marginLeft: 30,
    },
  },
  portlet: {
    width: '100%',
    position: 'relative',
    borderRadius: theme.spacing(1 / 2),
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
  },
  birthdayCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    marginRight: 20,
  },
  birthdayCardContent: {
    textAlign: 'left',
  },
  avatar: {
    width: 58,
    height: 58,
    marginRight: 16,
    border: '1px solid #ED1E79',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    alignContent: 'center',
  },
  sliderWrapper: {
    position: 'relative',
    alignItems: 'center',
    alignContent: 'center',
    padding: '0 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 24px',
    },
  },
  arrowPrevIcon: {
    position: 'absolute',
    left: '-10%',
    top: '25%',

    [theme.breakpoints.up('lg')]: {
      left: '-8%',
    },
  },
  arrowNextIcon: {
    position: 'absolute',
    top: '25%',
    right: '-10%',

    [theme.breakpoints.up('lg')]: {
      right: '-8%',
    },
  },
})
