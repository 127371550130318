import { createActions, createReducer } from 'reduxsauce'
import { groupBy } from 'lodash'
import moment from 'moment'

import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getObjectiveRequest: ['id'],
  getObjectiveSuccess: ['isEdit', 'data'],
  getObjectiveFailure: [],
  handleObjectiveTypeChange: ['property', 'id', 'dealershipId', 'value'],
  addObjectiveRequest: ['data', 'callback'],
  addObjectiveFinish: [],
  addObjectiveDealershipsRequest: ['data', 'callback'],
  addObjectiveDealershipsFinish: [],
  updateObjectiveRequest: ['id', 'data', 'callback'],
  updateObjectiveFinish: [],
  resetObjectiveData: [],
  getCopyObjectiveRequest: ['callback'],
  getCopyObjectiveFailure: [],
  getCopyObjectiveSuccess: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  loadingCopy: false,
  dataCopy: {},
}

const resetObjectiveData = () => ({
  ...INITIAL_STATE,
})

const getObjectiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getObjectiveFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getObjectiveSuccess = (state = INITIAL_STATE, { isEdit, data }) => {
  if (!isEdit) {
    const grouppedByDealership = groupBy(data, 'dealership')
    const grouppedFormatted = Object.keys(grouppedByDealership)?.map((key) => {
      return {
        dealership: grouppedByDealership[key][0]?.dealership,
        id: uniqueId(),
        data: grouppedByDealership[key],
      }
    })
    return {
      ...state,
      data: grouppedFormatted,
      loading: false,
    }
  }
  const objectivesDealerships = data?.objectivesDealerships.map((d) => ({
    ...d,
    dealership_name: d.dealership.name,
  }))
  const grouppedByDealership =
    objectivesDealerships && groupBy(objectivesDealerships, 'dealership_name')
  const grouppedFormatted = Object.keys(grouppedByDealership)?.map((key) => {
    return {
      dealership: grouppedByDealership[key][0]?.dealership_name,
      id: uniqueId(),
      data: grouppedByDealership[key].map((item) => ({
        actions: item.actions,
        dealership: item.dealership.name,
        dealership_id: item.dealership_id,
        objective_type: item.name,
        objective_type_id: item.objective_type_id,
        sales: item.sales,
        wallet: item.wallet,
      })),
    }
  })
  return {
    ...state,
    date: moment({
      year: data.objectives.year,
      month: data.objectives.month - 1,
    }),
    workingDays: data.objectives.working_days,
    data: grouppedFormatted,
    loading: false,
  }
}

const handleObjectiveTypeChange = (
  state = INITIAL_STATE,
  { id, dealershipId, property, value },
) => {
  return {
    ...state,
    data: state.data?.map((d) => {
      return {
        ...d,
        data: d.data?.map((item) => {
          if (
            item.objective_type_id === id &&
            item.dealership_id === dealershipId
          ) {
            return {
              ...item,
              [property]: Number(value),
            }
          }
          return item
        }),
      }
    }),
  }
}

const addObjectiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addObjectiveFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addObjectiveDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addObjectiveDealershipsFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateObjectiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateObjectiveFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCopyObjectiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: true,
})

const getCopyObjectiveFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: false,
})

const getCopyObjectiveSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingCopy: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_OBJECTIVE_REQUEST]: getObjectiveRequest,
  [Types.GET_OBJECTIVE_FAILURE]: getObjectiveFailure,
  [Types.GET_OBJECTIVE_SUCCESS]: getObjectiveSuccess,
  [Types.HANDLE_OBJECTIVE_TYPE_CHANGE]: handleObjectiveTypeChange,
  [Types.ADD_OBJECTIVE_REQUEST]: addObjectiveRequest,
  [Types.ADD_OBJECTIVE_FINISH]: addObjectiveFinish,
  [Types.ADD_OBJECTIVE_DEALERSHIPS_REQUEST]: addObjectiveDealershipsRequest,
  [Types.ADD_OBJECTIVE_DEALERSHIPS_FINISH]: addObjectiveDealershipsFinish,
  [Types.UPDATE_OBJECTIVE_REQUEST]: updateObjectiveRequest,
  [Types.UPDATE_OBJECTIVE_FINISH]: updateObjectiveFinish,
  [Types.RESET_OBJECTIVE_DATA]: resetObjectiveData,
  [Types.GET_COPY_OBJECTIVE_REQUEST]: getCopyObjectiveRequest,
  [Types.GET_COPY_OBJECTIVE_FAILURE]: getCopyObjectiveFailure,
  [Types.GET_COPY_OBJECTIVE_SUCCESS]: getCopyObjectiveSuccess,
})
