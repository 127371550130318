import { call, takeLatest, put, select } from 'redux-saga/effects'
import moment from 'moment'

import { get } from 'lodash'

import configureApi from '~/helpers/api'

import {
  Types as ProfileTypes,
  Creators as ProfileActions,
} from '../ducks/profile'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ profile }) => profile.options
const userSelector = ({ profile }) => profile.dataUserProfile

const api = configureApi()

function* reloadReceiptsProfile() {
  yield put(ProfileActions.getReceiptsProfileRequest())
}

function* getQuizzesProfile() {
  try {
    const { data } = yield call(api.get, 'quizzes', {
      params: {
        'where[active]': true,
        'where[type]': 'quiz',
      },
    })

    yield put(ProfileActions.getQuizzesProfileSuccess(data.data))
  } catch (e) {
    yield put(ProfileActions.getQuizzesProfileFailure())
  }
}

function* updateUser({ id, data, callback }) {
  try {
    yield call(api.post, `/users/${id}`, data)
    yield put(ProfileActions.updateUserProfileFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Perfil editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(ProfileActions.updateUserProfileFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserProfile({ id }) {
  try {
    const { data } = yield call(api.get, `users/${id}`)
    const formattedData = {
      ...data,
      dealerships_id: data.dealerships,
      roles_id: data.roles,
    }
    yield put(ProfileActions.getUserProfileSuccess(formattedData))
  } catch (e) {
    yield put(ProfileActions.getUserProfileFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getUserSalesProfile({ id }) {
  const options = yield select(optionsSelector)
  const user = yield select(userSelector)
  try {
    const requestId = id || user.id
    const { data } = yield call(api.get, `sales/${requestId}`, {
      params: {
        limit: options.limit,
        pagination: options.pagination,
        page: options.page,
        month: moment(options.dateValueSales).format('MM'),
        year: moment(options.dateValueSales).format('YYYY'),
      },
    })
    yield put(ProfileActions.getUserSalesProfileSuccess(data))
  } catch (e) {
    yield put(ProfileActions.getUserSalesProfileFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getPopsProfile() {
  try {
    const { data } = yield call(api.get, `user-pops`)

    yield put(ProfileActions.getPopsProfileSuccess(data))
  } catch (e) {
    yield put(ProfileActions.getPopsProfileFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getReceiptsProfile() {
  const options = yield select(optionsSelector)
  try {
    const { data } = yield call(api.get, `user-receipts`, {
      params: {
        pagination: 1,
        limit: options.limit,
        page: options.page + 1,
        total: options.total,
        order: 'created_at',
      },
    })

    yield put(ProfileActions.getReceiptsProfileSuccess(data, data.total))
  } catch (e) {
    yield put(ProfileActions.getReceiptsProfileFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(ProfileTypes.GET_QUIZZES_PROFILE_REQUEST, getQuizzesProfile)
  yield takeLatest(ProfileTypes.UPDATE_USER_PROFILE_REQUEST, updateUser)
  yield takeLatest(ProfileTypes.GET_USER_PROFILE_REQUEST, getUserProfile)
  yield takeLatest(
    ProfileTypes.GET_USER_SALES_PROFILE_REQUEST,
    getUserSalesProfile,
  )
  yield takeLatest(ProfileTypes.GET_POPS_PROFILE_REQUEST, getPopsProfile)
  yield takeLatest(
    ProfileTypes.GET_RECEIPTS_PROFILE_REQUEST,
    getReceiptsProfile,
  )
  yield takeLatest(
    ProfileTypes.SET_RECEIPTS_PAGE_PROFILE,
    reloadReceiptsProfile,
  )
}
