import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core'
import moment from 'moment'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as FunctionsActions } from '~/store/ducks/functions'
import { Creators as FunctionActions } from '~/store/ducks/function'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import CustomIcon from '~/components/CustomIcon'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'
import SearchInput from '~/components/SearchInput'
import EmptyList from '~/components/EmptyList'
import DialogFunctions from './components/DialogFunctions'

import styles from './styles'

const functionsSelector = ({ functions }) => functions

const useStyles = makeStyles(styles)

function FunctionsPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, options, loading } = useSelector(functionsSelector)
  const { sort, direction } = options
  const [functionId, setFunctionId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(FunctionsActions.getFunctionsRequest())
    return () => {
      dispatch(FunctionsActions.resetFunctions())
    }
  }, [])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(FunctionsActions.setFunctionsSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(FunctionsActions.setFunctionsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(FunctionsActions.setFunctionsPage(page, options.limit))
  }

  const handleEditFunction = (id) => {
    setFunctionId(id)
    setOpenDialog(true)
  }
  const handleAddFunction = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setFunctionId(null)
  }
  const handleRemoveFunction = (id) => {
    setFunctionId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setFunctionId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      FunctionActions.removeFunctionRequest(functionId, () => {
        dispatch(FunctionsActions.getFunctionsRequest())
        setFunctionId(null)
        setOpenConfirmDialog(false)
      }),
    )
  }

  const handleSearchChange = (value) => {
    dispatch(FunctionsActions.setFunctionsSearch(value))
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.gridContainerButton}
      >
        <Grid item xs={12} md={6}>
          <SearchInput
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder="Pesquise por funções..."
          />
        </Grid>
        <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonAdd}
            onClick={handleAddFunction}
          >
            <AddIcon className={classes.iconAdd} /> Adicionar Função
          </Button>
        </Grid>
      </Grid>
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há funções para serem visualizadas" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              {data.length > 0 && (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'name'}
                          onClick={() => handleSortChange('name')}
                          direction={direction}
                        >
                          Nome
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sort === 'created_at'}
                          onClick={() => handleSortChange('created_at')}
                          direction={direction}
                        >
                          Data de Cadastro
                        </TableSortLabel>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell>{item.name || '-'}</TableCell>
                        <TableCell>
                          {moment(item.created_at || '-').format(
                            'DD/MM/YYYY HH:mm',
                            'HH/MM',
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <div className={classes.tableCellActions}>
                            <IconButton
                              className={classes.editIcon}
                              onClick={() => handleEditFunction(item.id)}
                            >
                              <CustomIcon pathComponent={mdiPencil} />
                            </IconButton>
                            <IconButton
                              className={classes.iconButton}
                              onClick={() => handleRemoveFunction(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </div>

        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>
      <DialogFunctions
        open={openDialog}
        onClose={handleClose}
        id={functionId}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir esta função?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </div>
  )
}

export default FunctionsPage
