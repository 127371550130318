import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'

const CustomIcon = React.forwardRef(({ pathComponent, ...rest }, ref) => (
  <SvgIcon {...rest} innerRef={ref}>
    <path d={pathComponent} />
  </SvgIcon>
))

CustomIcon.propTypes = {
  pathComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
}

export default CustomIcon
