import { call, takeLatest, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as DealershipTypes,
  Creators as DealershipActions,
} from '../ducks/dealership'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* getDealershipGroups() {
  try {
    const api = configureApi()
    const response = yield call(api.get, 'groups', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(DealershipActions.getDealershipGroupsSuccess(response.data))
  } catch (e) {
    yield put(DealershipActions.getDealershipGroupsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDealershipAutomakers() {
  try {
    const api = configureApi()
    const response = yield call(api.get, 'automakers', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(DealershipActions.getDealershipAutomakersSuccess(response.data))
  } catch (e) {
    yield put(DealershipActions.getDealershipAutomakersFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addDealership({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, 'dealerships', data)

    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Concessionária adicionada com sucesso!',
        variant: 'success',
      }),
    )
    yield put(DealershipActions.addDealershipFinish())
  } catch (e) {
    yield put(DealershipActions.addDealershipFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeDealerhip({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `dealerships/${id}`)

    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Concessionária removida com sucesso!',
        variant: 'success',
      }),
    )
    yield put(DealershipActions.removeDealershipFinish())
  } catch (e) {
    yield put(DealershipActions.removeDealershipFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDealership({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `dealerships/${id}`)

    yield put(DealershipActions.getDealershipSuccess(data))
  } catch (e) {
    yield put(DealershipActions.getDealershipFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDealershipAllDepartments() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `departments`, {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })

    yield put(DealershipActions.getDealershipAllDepartmentsSuccess(data))
  } catch (e) {
    yield put(DealershipActions.getDealershipAllDepartmentsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* addDealershipDepartment({ data, callback }) {
  try {
    const api = configureApi()
    yield call(api.post, `dealership_departments`, data)

    if (callback) {
      yield call(callback)
    }
    yield put(DealershipActions.addDealershipDepartmentFinish(data))
  } catch (e) {
    yield put(DealershipActions.addDealershipDepartmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getDealershipDepartments({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealership_departments', {
      params: {
        pagination: 0,
        order: 'id,asc',
        'where[dealership_id]': id,
      },
    })

    yield put(DealershipActions.getDealershipDepartmentsSuccess(data))
  } catch (e) {
    yield put(DealershipActions.getDealershipDepartmentsFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* removeDealershipDepartment({ id, callback }) {
  try {
    const api = configureApi()
    yield call(api.delete, `dealership_departments/${id}`)
    if (callback) {
      yield call(callback)
    }
    yield put(DealershipActions.removeDealershipDepartmentFinish())
  } catch (e) {
    yield put(DealershipActions.removeDealershipDepartmentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateDealership({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `dealerships/${id}`, data)
    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Concessionária editada com sucesso!',
        variant: 'success',
      }),
    )
    yield put(DealershipActions.updateDealershipFinish())
  } catch (e) {
    yield put(DealershipActions.updateDealershipFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(DealershipTypes.GET_DEALERSHIP_REQUEST, getDealership)

  yield takeLatest(
    DealershipTypes.GET_DEALERSHIP_GROUPS_REQUEST,
    getDealershipGroups,
  )
  yield takeLatest(
    DealershipTypes.GET_DEALERSHIP_AUTOMAKERS_REQUEST,
    getDealershipAutomakers,
  )
  yield takeLatest(DealershipTypes.ADD_DEALERSHIP_REQUEST, addDealership)

  yield takeLatest(DealershipTypes.REMOVE_DEALERSHIP_REQUEST, removeDealerhip)

  yield takeLatest(
    DealershipTypes.GET_DEALERSHIP_ALL_DEPARTMENTS_REQUEST,
    getDealershipAllDepartments,
  )

  yield takeLatest(
    DealershipTypes.ADD_DEALERSHIP_DEPARTMENT_REQUEST,
    addDealershipDepartment,
  )

  yield takeLatest(
    DealershipTypes.GET_DEALERSHIP_DEPARTMENTS_REQUEST,
    getDealershipDepartments,
  )

  yield takeLatest(
    DealershipTypes.REMOVE_DEALERSHIP_DEPARTMENT_REQUEST,
    removeDealershipDepartment,
  )

  yield takeLatest(DealershipTypes.UPDATE_DEALERSHIP_REQUEST, updateDealership)
}
