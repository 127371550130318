/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import PropTypes from 'prop-types'
import { sortBy, pickBy, keys } from 'lodash'
import {
  makeStyles,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  Button,
} from '@material-ui/core'
import Carousel from '@brainhubeu/react-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import '@brainhubeu/react-carousel/lib/style.css'

import { Creators as SurveysActions } from '~/store/ducks/surveys'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import RhfRadioGroup from '~/components/FormComponents/RhfRadioGroup'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import styles from './styles'

const useStyles = makeStyles(styles)

const surveysSelector = ({ surveys }) => surveys
const userSelector = ({ session: { user } }) => user

function SurveysPage({
  match: {
    params: { id },
  },
}) {
  const { control, watch } = useForm()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [carouselValue, setCarouselValue] = useState(0)
  const { data, loading } = useSelector(surveysSelector)
  const user = useSelector(userSelector)
  const { questions } = data
  const isMobileScreen = useMobileScreen()

  const getEmbedId = (urlVideo) => {
    if (urlVideo.includes('youtube')) {
      return urlVideo?.split('v=')[1]
    }
    return urlVideo?.split('.com/')[1]
  }

  const sortedQuestions = data && questions && sortBy(questions, (q) => q.id)
  const formattedData = data.url_link?.includes('http') ? [{
    id: data.id,
    title_video: data.title_video,
    url_link: data.url_link,
    isVideo: true,
  }] : []

  if (data.slides) {
    formattedData.push(...data.slides.map((s) => ({ ...s, isSlide: true })))
  }

  if (sortedQuestions) {
    formattedData.push(...sortedQuestions)
  }

  useEffectOnce(() => {
    if (id) {
      dispatch(SurveysActions.getSurveysRequest(id))
    }
  })

  const getQuestionValueSelected = (questionId) => {
    return watch(`question_${questionId}`)
  }

  const isCorrectAlternative = (question, alternative) => {
    return question[`${alternative}_correct`]
  }

  const getCorrectAnswer = (questionId) => {
    const currentQuestion = sortedQuestions?.find(
      (question) => question.id === questionId,
    )
    const correctAnswer = pickBy(currentQuestion, (item) => item === true)
    return keys(correctAnswer)[0].replace('_correct', '')
  }
  const handleCarouselChange = (value) => {
    if (value > (formattedData.length - sortedQuestions.length)) {
      const questionId = sortedQuestions[(value - (formattedData.length - sortedQuestions.length)) - 1].id

      if (
        !getQuestionValueSelected(questionId) ||
        getQuestionValueSelected(questionId) !== getCorrectAnswer(questionId)
      ) {
        return dispatch(
          FlashMessageActions.showMessage({
            variant: 'error',
            message: 'Acerte a resposta para continuar.',
          }),
        )
      }
      setCarouselValue(value)
    }
    return setCarouselValue(value)
  }

  const handleFinishSurvey = () => {
    const formattedSurveyData = {
      ...data,
      user_id: user?.id,
    }
    dispatch(SurveysActions.finishSurveyRequest(formattedSurveyData))
  }

  return (
    <div className={classes.root}>
      <Portlet className={classes.grow}>
        <div className={classes.carouselWrapper}>
          {loading && <OverlayLoading />}
          {formattedData?.length > 0 && !loading && (
            <Carousel
              plugins={['arrows', 'clickToChange']}
              value={carouselValue}
              onChange={(e) => handleCarouselChange(e)}
              slides={formattedData?.map((item) => {
                if (item.isSlide) {
                  return (
                    <div key={item.id}>
                      <img
                        alt="Imagem de questionário"
                        src={item.url_image}
                        className={classes.carouselImage}
                      />
                    </div>
                  )
                }

                if (item.isVideo) {
                  return (
                    <div key={item.id}>
                      <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/${getEmbedId(
                          item.url_link,
                        )}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                  )
                }

                return (
                  <div key={item.id} className={classes.questionsWrapper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant={isMobileScreen ? 'h5' : 'h3'}>
                          {item.question}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RhfRadioGroup
                          control={control}
                          name={`question_${item.id}`}
                        >
                          <FormControlLabel
                            control={<Radio size="small" color="primary" />}
                            value="alternative_1"
                            label={
                              <Typography
                                variant={isMobileScreen ? 'body2' : 'subtitle1'}
                                className={
                                  getQuestionValueSelected(item.id) ===
                                    'alternative_1'
                                    ? isCorrectAlternative(
                                      item,
                                      'alternative_1',
                                    )
                                      ? classes.alternativeCorrect
                                      : classes.alternativeWrong
                                    : ''
                                }
                              >
                                {item.alternative_1}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio size="small" color="primary" />}
                            value="alternative_2"
                            label={
                              <Typography
                                variant={isMobileScreen ? 'body2' : 'subtitle1'}
                                className={
                                  getQuestionValueSelected(item.id) ===
                                    'alternative_2'
                                    ? isCorrectAlternative(
                                      item,
                                      'alternative_2',
                                    )
                                      ? classes.alternativeCorrect
                                      : classes.alternativeWrong
                                    : ''
                                }
                              >
                                {item.alternative_2}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio size="small" color="primary" />}
                            value="alternative_3"
                            label={
                              <Typography
                                variant={isMobileScreen ? 'body2' : 'subtitle1'}
                                className={
                                  getQuestionValueSelected(item.id) ===
                                    'alternative_3'
                                    ? isCorrectAlternative(
                                      item,
                                      'alternative_3',
                                    )
                                      ? classes.alternativeCorrect
                                      : classes.alternativeWrong
                                    : ''
                                }
                              >
                                {item.alternative_3}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            value="alternative_4"
                            label={
                              <Typography
                                variant={isMobileScreen ? 'body2' : 'subtitle1'}
                                className={
                                  getQuestionValueSelected(item.id) ===
                                    'alternative_4'
                                    ? isCorrectAlternative(
                                      item,
                                      'alternative_4',
                                    )
                                      ? classes.alternativeCorrect
                                      : classes.alternativeWrong
                                    : ''
                                }
                              >
                                {item.alternative_4}
                              </Typography>
                            }
                          />
                        </RhfRadioGroup>
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
            />
          )}
        </div>
        {formattedData?.length > 0 && (
          <>
            <div
              className={
                carouselValue + 1 === formattedData.length
                  ? classes.header
                  : classes.footer
              }
            >
              <Typography variant={isMobileScreen ? 'body2' : 'subtitle1'}>
                Slide {carouselValue + 1} de {formattedData?.length}
              </Typography>
            </div>
            {carouselValue + 1 === formattedData.length && (
              <div className={classes.footer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleFinishSurvey}
                >
                  Finalizar
                </Button>
              </div>
            )}
          </>
        )}
      </Portlet>
    </div>
  )
}

SurveysPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default React.memo(SurveysPage)
