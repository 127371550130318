import React from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { Grid, MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useEffectOnce, useUnmount } from 'react-use'
import { Creators as HelpdeskDashboardActions } from '~/store/ducks/helpdeskDashboard'

// import PropTypes from 'prop-types'

const helpdeskDashboardSelector = ({ helpdeskDashboard }) => helpdeskDashboard

function DashboardHelpdeskFilters() {
  const { filtersData, filters } = useSelector(helpdeskDashboardSelector)
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(HelpdeskDashboardActions.getHelpdeskDashboardFiltersRequest())
    dispatch(HelpdeskDashboardActions.getHelpdeskDashboardDataRequest())
  })

  useUnmount(() => {
    dispatch(HelpdeskDashboardActions.resetHelpdeskDashboard())
  })

  function handleChangeFilter(property, value) {
    dispatch(
      HelpdeskDashboardActions.setHelpdeskDashboardFilter(property, value),
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            fullWidth
            views={['month', 'year']}
            label="Mês/Ano"
            invalidDateMessage="Data inválida"
            format="MM/yyyy"
            value={filters.date}
            onChange={(dValue) => handleChangeFilter('date', dValue)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Departamentos"
          fullWidth
          select
          value={filters.department}
          onChange={(ev) => handleChangeFilter('department', ev.target.value)}
        >
          {filtersData.departments.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Atendentes"
          fullWidth
          select
          value={filters.attendant}
          onChange={(ev) => handleChangeFilter('attendant', ev.target.value)}
        >
          {filtersData.attendants.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Tempo de espera"
          fullWidth
          select
          value={filters.delay}
          onChange={(ev) => handleChangeFilter('delay', ev.target.value)}
        >
          {filtersData.delays.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

DashboardHelpdeskFilters.propTypes = {}

export default DashboardHelpdeskFilters
