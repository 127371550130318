const defaultStyles = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    color: '#757575',
    fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      fontFamily: 'Roboto, Helvica',
      minHeight: 400,
      color: '#757575',
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      color: '#757575',
      fontSize: 14,
      fontFamily: 'Roboto, Helvica',
      border: '1px solid #B8BAB7',
      borderRadius: 5,
    },
  },

  suggestions: {
    zIndex: 9999,
    borderRadius: 4,
    list: {
      borderRadius: 4,
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      color: '#757575',
    },
    item: {
      padding: '8px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#F6F6F6',
      },
    },
  },
}

const commentStyles = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    color: '#757575',
    fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      fontFamily: 'Roboto, Helvica',
      minHeight: 150,
      color: '#757575',
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      color: '#757575',
      fontSize: 14,
      fontFamily: 'Roboto, Helvica',
      border: '1px solid #B8BAB7',
      borderRadius: 5,
    },
  },

  suggestions: {
    zIndex: 9999,
    borderRadius: 4,
    list: {
      borderRadius: 4,
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      color: '#757575',
    },
    item: {
      padding: '8px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#F6F6F6',
      },
    },
  },
}

export { defaultStyles, commentStyles }
