import { call, takeLatest, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import moment from 'moment'

import { Types as IpcTypes, Creators as IpcActions } from '../ducks/ipc'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const IpcSelector = ({ ipc }) => ipc

function* getIslandSummary() {
  try {
    const api = configureApi()
    const data = yield call(api.get, 'ipc/island-summary')
    yield put(IpcActions.getIslandSummarySuccess(data))
  } catch (e) {
    // const error = get(e, 'response.data.error[0]')
    // yield put(
    //   FlashMessageActions.showMessage({
    //     message: error || 'Ops! Algo deu errado. Tente novamente',
    //     variant: 'error',
    //   }),
    // )
    yield put(IpcActions.getIslandSummaryFailure())
  }
}

function* getIslandCalls() {
  const { options } = yield select(IpcSelector)
  try {
    const api = configureApi()
    const data = yield call(api.get, 'ipc/island-calls', {
      params: {
        ...options,
        page: options.page + 1,
      },
    })
    yield put(IpcActions.getIslandCallsSuccess(data))
  } catch (e) {
    // const error = get(e, 'response.data.error[0]')
    // yield put(
    //   FlashMessageActions.showMessage({
    //     message: error || 'Ops! Algo deu errado. Tente novamente',
    //     variant: 'error',
    //   }),
    // )
    yield put(IpcActions.getIslandCallsFailure())
  }
}

function* getRanking() {
  const { filters } = yield select(IpcSelector)
  try {
    const api = configureApi()
    const data = yield call(api.get, 'ipc/ranking', {
      params: {
        start_at: moment(filters.startDate).format('YYYY-MM-DD'),
        end_at: moment(filters.endDate).format('YYYY-MM-DD'),
      },
    })
    yield put(IpcActions.getRankingSuccess(data))
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(IpcActions.getRankingFailure())
  }
}

function* reloadRanking() {
  yield put(IpcActions.getRankingRequest())
}

function* reloadCalls() {
  yield put(IpcActions.getIslandCallsRequest())
}

export default function* () {
  yield takeLatest(IpcTypes.GET_ISLAND_SUMMARY_REQUEST, getIslandSummary)
  yield takeLatest(IpcTypes.GET_ISLAND_CALLS_REQUEST, getIslandCalls)
  yield takeLatest(IpcTypes.GET_RANKING_REQUEST, getRanking)
  yield takeLatest(IpcTypes.SET_RANKING_DATE, reloadRanking)
  yield takeLatest(IpcTypes.SET_ISLAND_CALLS_PAGE, reloadCalls)
}
