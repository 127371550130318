import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { sortBy } from 'lodash'
import {
  makeStyles,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'

import { useSelector, useDispatch } from 'react-redux'

import OverlayLoading from '~/components/OverlayLoading'
import Portlet from '~/components/Portlet'

import RhfTextField from '~/components/FormComponents/RhfTextField'
import RhfAutocomplete from '~/components/FormComponents/RhfAutocomplete'

import { Creators as CallActions } from '~/store/ducks/call'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import { getActions } from '~/helpers/actions'

import styles from './styles'

const useStyles = makeStyles(styles)

const userSelector = ({ session: { user } }) => user
const callSelector = ({ call }) => call.data

const actionsSelector = ({ call: { actions } }) => actions
const attendantsSelector = ({ call: { attendants } }) => attendants
const historiesSelector = ({ call: { histories } }) => histories
const dealershipsSelector = ({ call: { dealerships } }) => dealerships

export default function CallActionsFormTab({ onClose }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: actionsData } = useSelector(actionsSelector)
  const call = useSelector(callSelector)
  const user = useSelector(userSelector)
  const actionsList = getActions(call, user, actionsData)
  const histories = useSelector(historiesSelector)
  const { data: dealerships, loading: dealershipsLoading } =
    useSelector(dealershipsSelector)
  const { data: attendants, loading: attendantsLoading } =
    useSelector(attendantsSelector)

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const actionSelected = watch('action_id')
  const dealershipSelected = watch('dealership_id')
  const departmentSelected = watch('department_id')
  const subjectSelected = watch('subject_id')

  const allDepartments =
    dealershipSelected &&
    dealerships?.find((d) => d.id === dealershipSelected)?.departments

  const departments =
    allDepartments && allDepartments?.filter((d) => d.subjects.length > 0)

  const subjects =
    departmentSelected &&
    departments?.find((d) => d.id === departmentSelected)?.subjects

  useEffect(() => {
    if (actionSelected === 5) {
      dispatch(
        CallActions.getCallAttendantsRequest(
          call?.department_id,
          call?.subject_id,
        ),
      )
    }
    if (actionSelected === 8) {
      dispatch(CallActions.getCallDealershipsRequest())
    }
    dispatch(CallActions.getCallHistoriesRequest(call.id))
  }, [actionSelected])

  const handleClearActionForm = () => reset({})

  const reloadCallActions = () => {
    dispatch(CallActions.getCallHistoriesRequest(call.id))
    dispatch(CallActions.getCallRequest(call.id))
    dispatch(CallActions.getCallAttachmentsRequest(call.id))
    dispatch(CallActions.getCallActionsRequest())
  }

  const handleSubmitCallActionsForm = (data) => {
    const formattedData = {
      action_id: data.action_id,
      attendant_id: data.attendant_id || null,
      call_id: call.id || null,
      dealership_id: dealershipSelected || null,
      department_id: departmentSelected || null,
      description: data.description,
      rating: data.rating || null,
      subject_id: subjectSelected || null,
      user_id: user.id,
    }
    dispatch(
      CallActions.addCallActionsRequest(formattedData, () => {
        reloadCallActions()
        handleClearActionForm()
        dispatch(
          FlashMessageActions.showMessage({
            message: 'Ação salva com sucesso!',
            variant: 'success',
          }),
        )
      }),
    )
    onClose()
  }

  const ratingsOptions = [
    { label: 'Ótimo', value: 4 },
    { label: 'Bom', value: 3 },
    { label: 'Regular', value: 2 },
    { label: 'Ruim', value: 1 },
  ]

  return (
    <div className={classes.root}>
      <>
        <form
          id="form-call-action"
          onSubmit={handleSubmit(handleSubmitCallActionsForm)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="action_id"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { onChange, value, ...rest } }) => (
                  <TextField
                    variant="outlined"
                    select
                    label="Ação"
                    type="text"
                    onChange={onChange}
                    error={!!errors.action_id}
                    value={value || ''}
                    helperText={!!errors.action_id && 'Campo obrigatório'}
                    fullWidth
                    {...rest}
                  >
                    {actionsList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {actionSelected && actionSelected === 5 && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="attendant_id"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      variant="outlined"
                      select
                      label="Atendente"
                      placeholder="Selecione o atendente"
                      type="text"
                      onChange={onChange}
                      error={!!errors.attendant_id}
                      value={value || ''}
                      helperText={!!errors.attendant_id && 'Campo obrigatório'}
                      fullWidth
                      {...rest}
                    >
                      {attendantsLoading && <OverlayLoading size={20} />}
                      {!attendantsLoading &&
                        attendants?.map((attendant) => (
                          <MenuItem
                            key={attendant.id}
                            value={attendant.user_id}
                          >
                            {attendant.user?.name} ({attendant.user?.email})
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
            {actionSelected && actionSelected === 7 && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="rating"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      variant="outlined"
                      select
                      label="Avaliação"
                      type="text"
                      onChange={onChange}
                      value={value || ''}
                      error={!!errors.rating}
                      helperText={!!errors.rating && 'Campo obrigatório'}
                      fullWidth
                      {...rest}
                    >
                      {ratingsOptions?.map((rating) => (
                        <MenuItem key={rating.value} value={rating.value}>
                          {rating.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
            {actionSelected && actionSelected === 8 && (
              <>
                <Grid item xs={12} md={6}>
                  <RhfAutocomplete
                    name="dealership_id"
                    control={control}
                    rules={{ required: true }}
                    fullWidth
                    label="Concessionária"
                    placeholder="Selecione a concessionária"
                    options={sortBy(dealerships, (d) => d.name)?.map(
                      (option) => ({
                        label: option.name,
                        value: option.id,
                      }),
                    )}
                    error={!!errors.dealership_id}
                    helperText={!!errors.dealership_id && 'Campo obrigatório'}
                    loading={dealershipsLoading}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RhfAutocomplete
                    name="department_id"
                    control={control}
                    rules={{ required: true }}
                    fullWidth
                    label="Departamento"
                    placeholder="Selecione o departamento"
                    error={!!errors.department_id}
                    helperText={!!errors.department_id && 'Campo obrigatório'}
                    loading={dealershipsLoading}
                    disabled={!dealershipSelected}
                    options={
                      departments
                        ? sortBy(departments, (d) => d.name)?.map((option) => {
                          return {
                            label: option.name,
                            value: option.id,
                          }
                        })
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RhfAutocomplete
                    name="subject_id"
                    control={control}
                    rules={{ required: true }}
                    fullWidth
                    label="Assunto"
                    placeholder="Selecione o assunto"
                    error={!!errors.subject_id}
                    helperText={!!errors.subject_id && 'Campo obrigatório'}
                    loading={dealershipsLoading}
                    disabled={!dealershipSelected}
                    options={
                      subjects
                        ? sortBy(subjects, (s) => s.name)?.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))
                        : []
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <RhfTextField
                defaultValue=""
                control={control}
                name="description"
                rules={{ required: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Descrição"
                multiline
                error={!!errors.description}
                helperText={!!errors.description && 'Campo obrigatório'}
                rows={4}
                placeholder="Informe a observação do chamado"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                onClick={() => handleClearActionForm()}
              >
                Limpar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                form="form-call-action"
              >
                Salvar ação
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container>
          {!!histories?.data.length && (
            <Portlet className={classes.portlet}>
              <div className={classes.tableWrapper}>
                <div className={classes.listWrapper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Dt. cadastro</TableCell>
                        <TableCell align="center">Colaborador</TableCell>
                        <TableCell align="center">Descrição</TableCell>
                        <TableCell align="center">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {histories?.data.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align="center">
                            {moment(item.created_at || '-').format(
                              'DD/MM/YYYY HH:mm',
                              'HH/MM',
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.user?.name}
                          </TableCell>
                          <TableCell align="left">
                            <p
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html:
                                  process.browser && item.description_html,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {item.action?.description}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Portlet>
          )}
        </Grid>
      </>
    </div>
  )
}

CallActionsFormTab.propTypes = {
  onClose: PropTypes.func.isRequired,
}
