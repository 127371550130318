import React, { useState } from 'react'

import {
  IconButton,
  makeStyles,
  Popover,
  Typography,
  Tabs,
  Tab,
  Divider,
  // Switch,
} from '@material-ui/core'

import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { BsCircleFill as CircleIcon } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'

import TabPanel from '~/components/TabPanel'
import { Creators as NotificationsActions } from '~/store/ducks/notifications'

import styles from './styles'
import GeneralList from './components/GeneralList'
import CommunicationsList from './components/CommunicationsList'
import CallsList from './components/CallsList'

const notificationsSelector = ({ notifications }) => notifications

const useStyles = makeStyles(styles)

export default function NotificationsButton() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentTab, setCurrentTab] = useState(0)
  const { data, onlyUnread } = useSelector(notificationsSelector)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(NotificationsActions.readAllNotificationsRequest())
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  // const handleSwitchChange = () => {
  //   dispatch(NotificationsActions.handleNotificationsFilterChange(!onlyUnread))
  // }

  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton className={classes.notificationIcon} onClick={handleClick}>
        <NotificationsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <NotificationsIcon style={{ marginRight: 12 }} />
            <Typography variant="h4" color="primary">
              Minhas Notificações
            </Typography>
          </div>
          <div className={classes.content}>
            <>
              <Tabs
                variant="fullWidth"
                value={currentTab}
                onChange={handleTabsChange}
              >
                <Tab
                  label={
                    <>
                      <div className={classes.tabNotifications}>
                        Notificações
                        {data?.general?.filter(
                          (item) => item?.status === 'unread',
                        )?.length ? (
                          <CircleIcon
                            size={8}
                            style={{
                              marginLeft: 8,
                            }}
                            color="#ED1E79"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  }
                />
                <Tab
                  label={
                    <>
                      <div className={classes.tabNotifications}>
                        Comunicados
                        {data?.communication?.filter(
                          (item) => item?.status === 'unread',
                        )?.length ? (
                          <CircleIcon
                            size={8}
                            style={{
                              marginLeft: 8,
                            }}
                            color="#ED1E79"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  }
                />
                <Tab
                  label={
                    <>
                      <div className={classes.tabNotifications}>
                        Chamados
                        {data?.calls?.filter(
                          (item) => item?.status === 'unread',
                        )?.length ? (
                          <CircleIcon
                            size={8}
                            style={{
                              marginLeft: 8,
                            }}
                            color="#ED1E79"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  }
                />
              </Tabs>
              <TabPanel index={0} value={currentTab} noPadding>
                <GeneralList
                  data={data.general}
                  onlyUnread={onlyUnread}
                  handleCloseCommunications={handleClose}
                />
              </TabPanel>
              <TabPanel index={1} value={currentTab} noPadding>
                <CommunicationsList
                  data={data.communication}
                  onlyUnread={onlyUnread}
                  handleCloseCommunications={handleClose}
                />
              </TabPanel>
              <TabPanel index={2} value={currentTab} noPadding>
                <CallsList
                  data={data.calls}
                  onlyUnread={onlyUnread}
                  handleClose={handleClose}
                />
              </TabPanel>
            </>
          </div>
        </div>
        <Divider />
        <div className={classes.footer}>
          {/* <Switch
            checked={onlyUnread}
            color="primary"
            onChange={handleSwitchChange}
          />
          <Typography variant="h5">Mostrar apenas não lidos</Typography> */}
        </div>
      </Popover>
    </>
  )
}
