import { createActions, createReducer } from 'reduxsauce'
import { groupBy } from 'lodash'

export const { Types, Creators } = createActions({
  getDailySalesRequest: [],
  getDailySalesFailure: [],
  getDailySalesSuccess: ['data'],
  updateDailySaleRequest: ['id', 'data', 'callback'],
  updateDailySaleFinish: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const getDailySalesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getDailySalesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getDailySalesSuccess = (state = INITIAL_STATE, { data }) => {
  const grouped = groupBy(data, 'dealership')
  const formattedData = Object.keys(grouped).map((key) => {
    return {
      data: grouped[key],
      _id: String(Math.random()),
    }
  })
  return {
    ...state,
    data: formattedData,
    loading: false,
  }
}

const updateDailySaleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateDailySaleFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_DAILY_SALES_REQUEST]: getDailySalesRequest,
  [Types.GET_DAILY_SALES_FAILURE]: getDailySalesFailure,
  [Types.GET_DAILY_SALES_SUCCESS]: getDailySalesSuccess,
  [Types.UPDATE_DAILY_SALE_REQUEST]: updateDailySaleRequest,
  [Types.UPDATE_DAILY_SALE_FINISH]: updateDailySaleFinish,
})
