export default () => ({
  smallCell: {
    padding: '6px 0px 6px 16px',
    width: 20,
  },
  tableWrapper: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  awayCell: {
    color: 'red',
  },
})
