import React, { useEffect, useState } from 'react'

import { Tabs, Tab } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import TabPanel from '~/components/TabPanel'
import Portlet from '~/components/Portlet'

import { Creators as ProfileActions } from '~/store/ducks/profile'

import 'moment/locale/pt-br'

import DataFormTab from './components/DataFormTab'
import ELearningTab from './components/ELearningTab'
import PopTab from './components/PopTab'
import SalesTab from './components/SalesTab'
// import ReceiptTab from './components/ReceiptTab'

const SessionActionsSelector = ({ session }) => session

const ProfileSelector = ({ profile }) => profile

export default function SubjectFormTab() {
  const [currentTab, setCurrentTab] = useState(0)
  const { user } = useSelector(SessionActionsSelector)
  const {
    dataPops,
    dataProfile,
    dataUserProfile,
    dataSales,
    options,
    // dataReceipts,
  } = useSelector(ProfileSelector)

  const { dateValueSales } = options

  const dispatch = useDispatch()

  const { reset } = useForm({
    defaultValues: dataUserProfile,
  })

  useEffect(() => {
    reset(dataUserProfile)
  }, [dataUserProfile])

  useEffect(() => {
    dispatch(ProfileActions.getPopsProfileRequest())
    dispatch(ProfileActions.getQuizzesProfileRequest())
    dispatch(ProfileActions.getUserProfileRequest(user.id))
    dispatch(ProfileActions.getUserSalesProfileRequest(user.id, new Date()))
    // dispatch(ProfileActions.getReceiptsProfileRequest())
  }, [user.id, dispatch])

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  return (
    <>
      {user && (
        <Portlet style={{ margin: 16 }}>
          <Tabs
            variant="fullWidth"
            value={currentTab}
            onChange={handleTabsChange}
          >
            <Tab label="Dados" />
            <Tab label="E-Learning" />
            <Tab label="POP" />
            {/* <Tab label="Recibos RH" /> */}
            {user.function_id === 106 && <Tab label="Vendas" />}
          </Tabs>

          <TabPanel
            index={0}
            value={currentTab}
            style={{ margin: 0, padding: 0 }}
          >
            <DataFormTab dataUserProfile={dataUserProfile} />
          </TabPanel>

          <TabPanel
            index={1}
            value={currentTab}
            style={{ margin: 0, padding: 0 }}
          >
            <ELearningTab data={dataProfile} />
          </TabPanel>

          <TabPanel
            index={2}
            value={currentTab}
            style={{ margin: 0, padding: 0 }}
          >
            <PopTab data={dataPops} />
          </TabPanel>

          {/* <TabPanel
          index={3}
          value={currentTab}
          style={{ margin: 0, padding: 0 }}
        >
          <ReceiptTab data={dataReceipts.data} optionsReceipt={options} />
        </TabPanel> */}
          {user.function_id === 106 && (
            <TabPanel
              index={4}
              value={currentTab}
              style={{ margin: 0, padding: 0 }}
            >
              <SalesTab
                dataSales={dataSales}
                date={dateValueSales}
                user={user}
              />
            </TabPanel>
          )}
        </Portlet>
      )}
    </>
  )
}
