import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  CardHeader,
  Avatar,
  Grid,
  Typography,
  makeStyles,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core'

import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'
import { Creators as NewsSinglePageActions } from '~/store/ducks/newsSingle'

import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'
import CustomMentionsInput from '~/components/CustomMentionsInput'

import styles from './styles'

const sessionSelector = ({ session }) => session

const useStyles = makeStyles(styles)

const loadingNewsCommentsSelector = ({ newsBoard: { loadingComments } }) =>
  loadingComments

export default function NewsCard({ data }) {
  const { user: userSession } = useSelector(sessionSelector)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [commentIdDialog, setcommentIdDialog] = useState(false)

  const loadingComments = useSelector(loadingNewsCommentsSelector)
  const [commentValueAdd, setCommentValueAdd] = useState('')
  const [commentValueUpdate, setCommentValueUpdate] = useState('')
  const [commentTextArea, setCommentTextArea] = useState(false)
  const [idComment, setIdComment] = useState(null)

  const isEdit = idComment !== null

  const resetNews = () => {
    dispatch(NewsBoardActions.getNewsBoardRequestFeed('feed', true))
    dispatch(NewsBoardActions.getNewsBoardRequest('feed-communication', true))
    dispatch(NewsSinglePageActions.getNewsSingleRequest(data.id))
  }

  const getMentionsId = () => {
    const text = isEdit ? commentValueUpdate : commentValueAdd
    if (!text) {
      return []
    }

    return [...text?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map((item) =>
      Number(item[2]),
    )
  }

  const onSubmitForm = () => {
    if (isEdit) {
      const updateData = { comment: commentValueUpdate }
      dispatch(
        NewsBoardActions.updateNewsCommentRequest(idComment, updateData, () => {
          resetNews()
          setCommentValueUpdate('')
          setIdComment(null)
        }),
      )
      setCommentTextArea(false)
      dispatch(NewsSinglePageActions.getNewsSingleRequest(data.id))
    } else {
      const formData = {
        news_id: data.id,
        comment: commentValueAdd,
        mentions_id: getMentionsId(),
      }
      setCommentTextArea(false)
      dispatch(
        NewsBoardActions.addNewsCommentRequest(formData, () => {
          resetNews()
          setCommentValueAdd('')
        }),
      )
      dispatch(NewsSinglePageActions.getNewsSingleRequest(data.id))
    }
  }

  const handleSetComment = (comment) => {
    if (isEdit) {
      setCommentValueUpdate(comment)
    } else {
      setCommentValueAdd(comment)
    }
  }

  const handleCancelComment = () => {
    setCommentTextArea(false)
    setOpenConfirmDialog(false)
    setCommentValueAdd('')
    setCommentValueUpdate('')
    setIdComment(null)
  }
  const handleEditComment = (item) => {
    setCommentValueUpdate(item.comment)
    setIdComment(item.id)
  }

  const handleRemoveComment = () => {
    dispatch(
      NewsBoardActions.removeNewsCommentRequest(commentIdDialog, () => {
        resetNews()
      }),
    )
    setOpenConfirmDialog(false)
  }

  const handleOpenDialogRemoveComment = (commentId) => {
    setOpenConfirmDialog(true)
    setcommentIdDialog(commentId)
  }

  const getCurrentMention = (mention) => {
    // return `@[${mention.user.name}](${mention.user.id})`
    return `<span class="mention-text" data-id="mention-${mention.id}-user-${mention.user.id}" data-image=${mention.user.url_image}>@${mention.user.name}</span>`
  }

  const getUsersOptions = async (search, callback) => {
    if (!search) {
      return
    }
    dispatch(
      NewsBoardActions.getNewsUsersRequest(search, (cbUsers) =>
        callback(cbUsers),
      ),
    )
  }

  const getText = (str, mentions) => {
    const usersMentions = [...str?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map(
      (item) => ({
        previous: item[0],
        current: getCurrentMention(
          mentions?.find((mention) => mention.user_id === Number(item[2])),
        ),
      }),
    )
    return usersMentions.reduce(
      (acc, item) => acc.replace(item.previous, item.current),
      str,
    )
  }

  return (
    <>
      {loadingComments && <OverlayLoading size={40} />}
      {commentTextArea && (
        <Grid
          container
          className={classes.commentsWrapper}
          justifyContent="flex-end"
        >
          <div className={classes.commentArea}>
            <CardHeader
              style={{ padding: 0, marginBottom: '16px' }}
              avatar={<Avatar src={userSession.url_image} />}
              title={userSession.name}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault()
                onSubmitForm()
              }}
              id="form-comment-news-add"
            >
              <CustomMentionsInput
                value={commentValueAdd}
                onChange={(e) => {
                  handleSetComment(e.target.value)
                }}
                data={getUsersOptions}
                isComment
              />

              <Grid
                container
                justifyContent="space-between"
                className={classes.buttonsContainer}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => handleCancelComment()}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="form-comment-news-add"
                  disabled={commentValueAdd === '' && commentValueUpdate === ''}
                >
                  Enviar
                </Button>
              </Grid>
            </form>
          </div>
        </Grid>
      )}

      {!!data.comments.length &&
        data.comments
          .sort((a, b) => b.id - a.id)
          .map((item) => {
            if (item.id === idComment) {
              return (
                <Grid
                  container
                  className={classes.commentsWrapper}
                  justifyContent="flex-end"
                  key={item.id}
                >
                  <div className={classes.commentedArea}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <CardHeader
                          style={{ padding: 0, marginBottom: '16px' }}
                          avatar={<Avatar src={item.user.url_image} />}
                          title={item.user.name}
                          subheader={`${moment(item.created_at).format(
                            'L',
                          )} - ${moment(item.created_at).format('HH:mm')}`}
                        />
                      </Grid>

                      {item.user.name === userSession.name && (
                        <Grid item>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleEditComment(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() =>
                              handleOpenDialogRemoveComment(item.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        onSubmitForm()
                      }}
                      id="form-comment-edit"
                    >
                      <TextField
                        fullWidth
                        placeholder="Escreva um comentário!"
                        multiline
                        variant="outlined"
                        onChange={(ev) => handleSetComment(ev.target.value)}
                        minRows={6}
                        defaultValue={commentValueUpdate}
                        autoFocus
                      />
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.buttonsContainer}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={() => handleCancelComment()}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          form="form-comment-edit"
                          disabled={
                            commentValueAdd === '' && commentValueUpdate === ''
                          }
                        >
                          Enviar
                        </Button>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              )
            }
            return (
              <Grid
                container
                key={item.id}
                className={classes.commentsWrapper}
                justifyContent="flex-end"
              >
                <div className={classes.commentedArea}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <CardHeader
                        style={{ padding: 0, marginBottom: '16px' }}
                        avatar={<Avatar src={item.user.url_image} />}
                        title={item.user.name}
                        subheader={`${moment(item.created_at).format(
                          'L',
                        )} - ${moment(item.created_at).format('HH:mm')}`}
                      />
                    </Grid>

                    {item.user.name === userSession.name && (
                      <Grid item>
                        {' '}
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleEditComment(item)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleOpenDialogRemoveComment(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: getText(item.comment, item.mentions),
                    }}
                  />
                </div>
              </Grid>
            )
          })}

      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este comentário?"
        onCancel={handleCancelComment}
        onAccept={handleRemoveComment}
      />
    </>
  )
}

NewsCard.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      url_image: PropTypes.string,
    }).isRequired,
    created_at: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    comment: PropTypes.string,
    id: PropTypes.number,
    text: PropTypes.string,
    user: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    url_image: PropTypes.string,
    url_video: PropTypes.string,
    reactions: PropTypes.arrayOf(PropTypes.shape({})),
    mentions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}
