import { call, takeLatest, put, select } from 'redux-saga/effects'
import configureApi from '~/helpers/api'
import { get } from 'lodash'

import {
  Types as IslandsTypes,
  Creators as IslandsActions,
} from '../ducks/islands'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ islands }) => islands.options

function* getIslands() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const data = yield call(api.get, 'dealership_departments', {
      params: {
        'where[dealership_id]': options.dealershipId,
        page: options.page + 1,
        limit: options.limit,
        total: options.total,
        pagination: 1,
      },
    })
    yield put(IslandsActions.getIslandsSuccess(data.data, data.data.total))
  } catch (e) {
    yield put(IslandsActions.getIslandsFailure())
  }
}

function* getDealershipsIslands() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        limit: 99999,
        order: 'name,asc',
      },
    })
    yield put(IslandsActions.getDealershipsIslandsSuccess(data.data))
  } catch (e) {
    yield put(IslandsActions.getDealershipsIslandsFailure())
  }
}

function* reloadIslands() {
  yield put(IslandsActions.getIslandsRequest())
}

function* getIsland({ id }) {
  try {
    const api = configureApi()

    const { data } = yield call(api.get, `dealership_departments/${id}`)
    yield put(IslandsActions.getIslandSuccess(data))
  } catch (e) {
    yield put(IslandsActions.getIslandFailure())

    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateIsland({ id, data, callback }) {
  try {
    const api = configureApi()
    yield call(api.put, `dealership_departments/${id}`, data)
    yield put(IslandsActions.updateIslandFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Ramal editado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(IslandsActions.updateIslandFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* reloadIslandsPage() {
  yield put(IslandsActions.getIslandsRequest())
}

export default function* () {
  yield takeLatest(IslandsTypes.GET_ISLANDS_REQUEST, getIslands)
  yield takeLatest(
    IslandsTypes.GET_DEALERSHIPS_ISLANDS_REQUEST,
    getDealershipsIslands,
  )
  yield takeLatest(IslandsTypes.SET_ISLANDS_DEALERSHIPS_FILTER, reloadIslands)
  yield takeLatest(IslandsTypes.GET_ISLAND_REQUEST, getIsland)
  yield takeLatest(IslandsTypes.UPDATE_ISLAND_REQUEST, updateIsland)
  yield takeLatest(IslandsTypes.SET_ISLANDS_PAGE, reloadIslandsPage)
}
