import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addStatusRequest: ['data', 'callback'],
  addStatusFinish: [],
  getStatusRequest: ['id'],
  getStatusSuccess: ['data'],
  getStatusFailure: [],
  updateStatusRequest: ['id', 'data', 'callback'],
  updateStatusFinish: [],
  removeStatusRequest: ['id', 'callback'],
  removeStatusFinish: [],
})

const INITIAL_STATE = {
  data: {
    name: '',
  },
  loading: false,
}

const addStatusRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addStatusFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getStatusRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getStatusSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getStatusFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updateStatusRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updateStatusFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const removeStatusRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removeStatusFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.ADD_STATUS_REQUEST]: addStatusRequest,
  [Types.ADD_STATUS_FINISH]: addStatusFinish,
  [Types.GET_STATUS_REQUEST]: getStatusRequest,
  [Types.GET_STATUS_SUCCESS]: getStatusSuccess,
  [Types.GET_STATUS_FAILURE]: getStatusFailure,
  [Types.UPDATE_STATUS_REQUEST]: updateStatusRequest,
  [Types.UPDATE_STATUS_FINISH]: updateStatusFinish,
  [Types.REMOVE_STATUS_REQUEST]: removeStatusRequest,
  [Types.REMOVE_STATUS_FINISH]: removeStatusFinish,
})
