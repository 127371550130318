export default (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  uploadIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  upload: {
    height: 56,
    padding: 10,
    borderRadius: 0,
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    '&:last-child': {
      padding: 0,
    },
  },
  uploadDetail: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  fileSize: {
    fontSize: 10,
  },
  cancelUploadIcon: {
    width: 20,
    height: 20,
    color: '#adadad',
  },
  portlet: {
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(3),
  },
  uploadButtonLabel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  uploadButtonIcon: {
    width: 20,
    height: 20,
  },
})
