import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Button,
  TableSortLabel,
} from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'

import { Creators as DepartmentActions } from '~/store/ducks/department'

import styles from './styles'

const useStyles = makeStyles(styles)

const subjectsSelector = ({ department: { subjects } }) => subjects

export default function SubjectFormTab({ id }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [subjectId, setSubjectId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const { data, loading, options } = useSelector(subjectsSelector)
  const { sort, direction } = options
  const isEdit = !!subjectId

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()

  const prioritiesOptions = [
    { id: 1, name: 'Alta' },
    { id: 2, name: 'Normal' },
    { id: 3, name: 'Baixa' },
  ]

  function getPriority(priorityId) {
    return prioritiesOptions.find((p) => p.id === priorityId).name
  }

  function handleEditSubject(currentSubject) {
    setSubjectId(currentSubject.id)
    reset({ name: currentSubject.name, priority: currentSubject.priority })
  }

  function handleRemoveSubject(idValue) {
    setSubjectId(idValue)
    setOpenConfirmDialog(true)
  }

  function handleSubmitSubjectForm(formData) {
    let formattedData
    if (isEdit) {
      formattedData = {
        ...formData,
        department_id: id,
      }
      // Submit and reset isEdit to false and reset fields.
      dispatch(
        DepartmentActions.updateDepartmentSubjectRequest(
          subjectId,
          formattedData,
          () => {
            reset({ name: '', priority: '' })
            setSubjectId(null)
            dispatch(DepartmentActions.getDepartmentSubjectsRequest(id))
          },
        ),
      )
    } else {
      formattedData = {
        ...formData,
        department_id: id,
      }
      dispatch(
        DepartmentActions.addDepartmentSubjectRequest(formattedData, () => {
          reset({ name: '', priority: '' })
          setSubjectId(null)
          dispatch(DepartmentActions.getDepartmentSubjectsRequest(id))
        }),
      )
    }
  }

  function handleClearSubjectForm() {
    setSubjectId(null)
    reset({ name: '', priority: '' })
  }

  function handleRemoveSubjectCancel() {
    setSubjectId(null)
    setOpenConfirmDialog(false)
  }

  function handleRemoveSubjectAccept() {
    dispatch(
      DepartmentActions.removeDepartmentSubjectRequest(subjectId, () => {
        setSubjectId(null)
        reset({ name: '', priority: '' })
        dispatch(DepartmentActions.getDepartmentSubjectsRequest(id))
        setOpenConfirmDialog(false)
      }),
    )
  }

  function handleSortChange(newSort) {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(
      DepartmentActions.setDepartmentSubjectsSort(id, newSort, sortDirection),
    )
  }

  return (
    <>
      <div className={classes.root}>
        {loading && <OverlayLoading />}
        {!loading && (
          <>
            <form
              id="form-subject"
              onSubmit={handleSubmit(handleSubmitSubjectForm)}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <TextField
                        variant="outlined"
                        autoFocus
                        label="Nome"
                        type="text"
                        onChange={onChange}
                        value={value || ''}
                        error={!!errors.name}
                        helperText={!!errors.name && 'Campo obrigatório'}
                        fullWidth
                        {...rest}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="priority"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { onChange, value, ...rest } }) => (
                      <TextField
                        variant="outlined"
                        select
                        autoFocus
                        label="Prioridade"
                        type="text"
                        onChange={onChange}
                        error={!!errors.priority}
                        value={value}
                        helperText={!!errors.priority && 'Campo obrigatório'}
                        fullWidth
                        {...rest}
                      >
                        {prioritiesOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                className={classes.subjectButtonsWrapper}
              >
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleClearSubjectForm}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    form="form-subject"
                  >
                    Salvar assunto
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Portlet className={classes.portlet}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'name'}
                        onClick={() => handleSortChange('name')}
                        direction={direction}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort === 'priority'}
                        onClick={() => handleSortChange('priority')}
                        direction={direction}
                      >
                        Prioridade
                      </TableSortLabel>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data.map((subject) => (
                      <TableRow key={subject.id}>
                        <TableCell>{subject.name}</TableCell>
                        <TableCell>{getPriority(subject.priority)}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleEditSubject(subject)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleRemoveSubject(subject.id)}
                          >
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Portlet>
          </>
        )}
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este assunto?"
        onCancel={handleRemoveSubjectCancel}
        onAccept={handleRemoveSubjectAccept}
      />
    </>
  )
}

SubjectFormTab.propTypes = {
  id: PropTypes.number,
}

SubjectFormTab.defaultProps = {
  id: null,
}
