import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { flatMap } from 'lodash'
import moment from 'moment'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
  Table,
  TextField,
  TableBody,
} from '@material-ui/core'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { useDispatch, useSelector } from 'react-redux'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import { getWorkingDays } from '~/helpers/workingDays'

import OverlayLoading from '~/components/OverlayLoading'

import { Creators as SalesGoalsActions } from '~/store/ducks/salesGoals'

import CustomRow from './CustomRow'

import styles from './styles'

const useStyles = makeStyles(styles)

const salesNewSelector = ({ salesGoals }) => salesGoals.salesNew

function NewSalesGoalDialog({ open, onClose, id, salesGoalDate, mode }) {
  const isEdit = mode === 'edit'
  const isDuplicated = mode === 'duplicated'
  const classes = useStyles()
  const dispatch = useDispatch()
  const { loading, data } = useSelector(salesNewSelector)
  const [date, setDate] = useState(moment().add(1, 'month'))
  const isMobileScreen = useMobileScreen()
  const [workingDays, setWorkingDays] = useState(getWorkingDays(moment()))

  const handleClose = () => {
    onClose()
    setDate(moment().add(1, 'month'))
  }

  useEffect(() => {
    if (isDuplicated) {
      setDate(moment().add(1, 'month'))
    }
    if ((isEdit || isDuplicated) && id) {
      dispatch(SalesGoalsActions.getSellerGoalsRequest(id))
    } else {
      dispatch(SalesGoalsActions.getSalesGoalNewDataRequest())
    }
    if (isEdit && salesGoalDate) {
      setDate(moment(`${salesGoalDate.year}-${salesGoalDate.month}`))
      setWorkingDays(salesGoalDate.working_days)
    }
  }, [isEdit, id, isDuplicated])

  const handleDateChange = (dValue) => {
    setDate(moment(dValue))
    setWorkingDays(getWorkingDays(dValue))
  }

  const reloadSalesGoals = () => {
    dispatch(SalesGoalsActions.getSalesGoalsRequest())
  }

  const handleNewSalesGoalFormSubmit = () => {
    const flatData = data.map((item) => {
      return flatMap(item.data)
    })
    let saleGoalData = {}
    if (isEdit) {
      saleGoalData = {
        working_days: workingDays,
        year: String(moment(date).year()),
        month: String(moment(date).month() + 1),
        id,
      }

      dispatch(
        SalesGoalsActions.updateSalesGoalRequest(
          id,
          saleGoalData,
          (callbackId) => {
            if (callbackId) {
              const formattedData = {
                sales_goal_id: callbackId,
                rows: flatMap(flatData).map((item) => ({
                  ...item,
                  sales_goal_id: callbackId,
                })),
              }
              dispatch(
                SalesGoalsActions.addSellersGoalsRequest(formattedData, () => {
                  reloadSalesGoals()
                  handleClose()
                }),
              )
            }
          },
        ),
      )
    } else {
      saleGoalData = {
        working_days: workingDays,
        year: String(moment(date).year()),
        month: String(moment(date).month() + 1),
      }
      dispatch(
        SalesGoalsActions.addSalesGoalRequest(saleGoalData, (callback) => {
          const formattedData = {
            sales_goal_id: callback.id,
            rows: flatMap(flatData).map((item) => ({
              ...item,
              sales_goal_id: callback.id,
            })),
          }
          dispatch(
            SalesGoalsActions.addSellersGoalsRequest(formattedData, () => {
              reloadSalesGoals()
              handleClose()
            }),
          )
        }),
      )
    }
  }

  const copySalesGoal = () => {
    dispatch(
      SalesGoalsActions.getCopySalesGoalRequest((salesGoalId) =>
        dispatch(SalesGoalsActions.getSellerGoalsRequest(salesGoalId)),
      ),
    )
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobileScreen}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          Planejamento
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider
                          locale="pt-br"
                          utils={MomentUtils}
                        >
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            fullWidth
                            views={['month', 'year']}
                            label="Mês/Ano"
                            disabled={isEdit}
                            invalidDateMessage="Data inválida"
                            format="MM/yyyy"
                            value={date}
                            onChange={(dValue) => handleDateChange(dValue)}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="working_days"
                          label="Dias úteis"
                          onChange={(e) => setWorkingDays(e.target.value)}
                          value={workingDays}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isEdit && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={6}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => copySalesGoal()}
                          >
                            Copiar Mês Anterior
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <div className={classes.tableWrapper}>
                  <Table size="small">
                    <TableBody>
                      {data?.map((row) => (
                        <CustomRow key={row.id} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={handleClose} variant="contained">
          CANCELAR
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNewSalesGoalFormSubmit}
        >
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewSalesGoalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  mode: PropTypes.string,
  salesGoalDate: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    working_days: PropTypes.number,
  }),
}

NewSalesGoalDialog.defaultProps = {
  id: null,
  salesGoalDate: {
    year: null,
    month: null,
    working_days: null,
  },
  mode: null,
}

export default React.memo(NewSalesGoalDialog)
