import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { pickBy } from 'lodash'

import {
  Grid,
  Button,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  TableBody,
  IconButton,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { mdiPencil } from '@mdi/js'

import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as QuizzeActions } from '~/store/ducks/quiz'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import RhfTextField from '~/components/FormComponents/RhfTextField'
import RhfCheckbox from '~/components/FormComponents/RhfCheckbox'
import CustomIcon from '~/components/CustomIcon'
import ConfirmDialog from '~/components/ConfirmDialog'
import styles from './styles'

const useStyles = makeStyles(styles)

const quizzeSelector = ({ quiz }) => quiz.data
const loadingSelector = ({ quiz: { loading } }) => loading

const QuestionFormTab = ({ id }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const quizzeData = useSelector(quizzeSelector)
  const loading = useSelector(loadingSelector)
  const [questionId, setQuestionId] = useState(null)
  const [mode, setMode] = useState('add')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const isEdit = mode === 'edit'

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  const reloadQuestions = () => {
    dispatch(QuizzeActions.getQuizzeRequest(id))
  }

  const normalizedData = (obj) => {
    return pickBy(obj, undefined)
  }

  const resetQuestionForm = () => {
    reset({})
    setQuestionId(null)
    setMode('add')
  }

  const handleQuestionFormSubmit = (data) => {
    const formattedData = {
      ...data,
      quiz_id: id,
    }

    if (isEdit) {
      dispatch(
        QuizzeActions.updateQuestionRequest(
          questionId,
          normalizedData(formattedData),
          () => {
            resetQuestionForm()
            reloadQuestions()
          },
        ),
      )
    } else {
      dispatch(
        QuizzeActions.addQuestionRequest(normalizedData(formattedData), () =>
          reloadQuestions(),
        ),
      )
    }
  }

  const handleEditQuestion = (currentQuestion) => {
    setMode('edit')
    setQuestionId(currentQuestion.id)
    reset(currentQuestion)
  }

  const questionsFields = [
    {
      name: 'alternative_1',
      label: 'Alternativa 1',
      required: true,
      checkbox_name: 'alternative_1_correct',
    },
    {
      name: 'alternative_2',
      label: 'Alternativa 2',
      required: true,
      checkbox_name: 'alternative_2_correct',
    },
    {
      name: 'alternative_3',
      label: 'Alternativa 3',
      checkbox_name: 'alternative_3_correct',
    },
    {
      name: 'alternative_4',
      label: 'Alternativa 4',
      checkbox_name: 'alternative_4_correct',
    },
  ]

  const handleRemoveQuestion = (idRemove) => {
    setQuestionId(idRemove)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setQuestionId(null)
    setOpenConfirmDialog(false)
  }
  const handleRemoveQuestionAccept = () => {
    dispatch(
      QuizzeActions.removeQuestionRequest(questionId, () => reloadQuestions()),
    )

    setOpenConfirmDialog(false)
  }

  return (
    <div className={classes.div}>
      <div>
        {loading && <OverlayLoading />}
        {!loading && (
          <form
            onSubmit={handleSubmit(handleQuestionFormSubmit)}
            id="form-question"
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.alternatives}
            >
              <Grid item xs={11}>
                <RhfTextField
                  fullWidth
                  variant="standard"
                  control={control}
                  name="question"
                  rules={{ required: true }}
                  label="Pergunta"
                  helperText={errors && errors.question && 'Campo obrigatório'}
                  error={errors && !!errors.question}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="space-between">
              {questionsFields.map((field) => (
                <React.Fragment key={field.name}>
                  <Grid item xs={11} md={5}>
                    <RhfTextField
                      fullWidth
                      variant="standard"
                      control={control}
                      name={field.name}
                      rules={{ required: field.required }}
                      label={field.label}
                      helperText={
                        errors && errors[field.name] && 'Campo obrigatório'
                      }
                      error={errors && !!errors[field.name]}
                    />
                  </Grid>
                  <Grid item>
                    <RhfCheckbox
                      defaultValue={false}
                      name={field.checkbox_name}
                      control={control}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </form>
        )}
      </div>
      <Grid
        container
        className={classes.buttonForm}
        justifyContent="space-between"
      >
        <Grid item>
          <Button variant="contained" onClick={() => resetQuestionForm()}>
            Limpar
          </Button>
        </Grid>
        <Button
          color="primary"
          type="submit"
          form="form-question"
          variant="contained"
        >
          {isEdit && 'Salvar'}
          {!isEdit && 'Adicionar Pergunta'}
        </Button>
      </Grid>
      <Portlet className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel>Pergunta</TableSortLabel>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {quizzeData.questions?.map((item) => (
              <TableRow hover key={item.id} className={classes.tableRow}>
                <TableCell>{item.question || '-'}</TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellActions}>
                    <IconButton
                      className={classes.editIcon}
                      onClick={() => handleEditQuestion(item)}
                    >
                      <CustomIcon pathComponent={mdiPencil} />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => handleRemoveQuestion(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Portlet>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir esta pergunta?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveQuestionAccept}
      />
    </div>
  )
}

QuestionFormTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

QuestionFormTab.defaultProps = {
  id: null,
}

export default QuestionFormTab
