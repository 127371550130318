import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as BilledMonthComparativeTypes,
  Creators as BilledMonthComparativeActions,
} from '../ducks/billedMonthComparative'

const filterSelector = ({ billedMonthComparative }) =>
  billedMonthComparative.filters

const api = configureApi()

function* getBilledMonthComparativeFilters() {
  try {
    const groups = yield call(api.get, 'groups', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    const groupsData = groups.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      BilledMonthComparativeActions.getBilledMonthComparativeFiltersSuccess(
        groupsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(
      BilledMonthComparativeActions.getBilledMonthComparativeFiltersFailure(),
    )
  }
}

function* getBilledMonthComparativeData() {
  try {
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }
    const { data } = yield call(api.get, 'bi-comercial/comparative', {
      params: {
        year: mDate.year(),
        month: mDate.month() + 1,
        group_id: filters.group,
      },
    })

    yield put(
      BilledMonthComparativeActions.getBilledMonthComparativeDataSuccess(
        data?.dealerships || [],
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(
      BilledMonthComparativeActions.getBilledMonthComparativeDataFailure(),
    )
  }
}

function* reloadBilledMonthComparativeData() {
  yield put(
    BilledMonthComparativeActions.getBilledMonthComparativeDataRequest(),
  )
}

export default function* () {
  yield takeLatest(
    BilledMonthComparativeTypes.GET_BILLED_MONTH_COMPARATIVE_DATA_REQUEST,
    getBilledMonthComparativeData,
  )
  yield debounce(
    400,
    BilledMonthComparativeTypes.SET_BILLED_MONTH_COMPARATIVE_FILTER,
    reloadBilledMonthComparativeData,
  )
  yield takeLatest(
    BilledMonthComparativeTypes.GET_BILLED_MONTH_COMPARATIVE_FILTERS_REQUEST,
    getBilledMonthComparativeFilters,
  )
}
