import { takeLatest, call, put } from 'redux-saga/effects'
import { get } from 'lodash'
import configureApi from '../../helpers/api'
import { login, setLoginUser, logout } from '../../helpers/session'
import history from '../../helpers/history'

import {
  Types as SessionTypes,
  Creators as SessionActions,
} from '../ducks/session'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()

function* loginRequest({ credentials }) {
  const formattedData = {
    ...credentials,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    grant_type: 'password',
    scope: null,
  }
  try {
    const { data } = yield call(api.post, 'login', formattedData)
    login(data)
    const { data: userData } = yield call(api.get, 'user')
    setLoginUser(userData)
    yield put(SessionActions.loginSuccess(data, userData))
    yield put(
      FlashMessageActions.showMessage({
        message: 'Bem-vindo',
        variant: 'success',
      }),
    )
    history.push('/dashboard')
  } catch (e) {
    yield put(
      FlashMessageActions.showMessage({
        message: 'CPF e/ou senha estão incorreto(s).',
        variant: 'error',
      }),
    )
    yield put(SessionActions.loginFailure())
  }
}

function* updateProfile({ data, id, callback }) {
  try {
    yield call(api.put, `/profile/${id}`, data)
    yield put(SessionActions.updateProfileFinish())

    if (callback) {
      yield call(callback)
    }

    yield put(
      FlashMessageActions.showMessage({
        message: 'Senha editada com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(SessionActions.updateSuperiorFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(SessionTypes.LOGIN_REQUEST, loginRequest)
  yield takeLatest(SessionTypes.LOGOUT, logout)
  yield takeLatest(SessionTypes.UPDATE_PROFILE_REQUEST, updateProfile)
}
