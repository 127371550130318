import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core'

function RhfCheckbox({ name, control, error, ...props }) {
  return (
    <FormControl error={!!error}>
      <Controller
        render={({ field: { value, ...rest } }) => (
          <FormControlLabel
            control={
              <Checkbox checked={value || false} {...rest} color="primary" />
            }
            {...props}
          />
        )}
        name={name}
        control={control}
      />
      <FormHelperText>{error && error.message}</FormHelperText>
    </FormControl>
  )
}

RhfCheckbox.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
}

RhfCheckbox.defaultProps = {
  error: null,
}

export default RhfCheckbox
