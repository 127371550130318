import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import EquipmentsList from '~/components/EquipmentsList'
import EmptyList from '~/components/EmptyList'

import { Creators as EquipmentsActions } from '~/store/ducks/equipments'

import styles from './styles'

const useStyles = makeStyles(styles)

const equipmentsSelector = ({ equipments }) => equipments

export default function EquipmentsFormTab({ id }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { data, options } = useSelector(equipmentsSelector)

  const { sort, direction } = options

  useEffect(() => {
    dispatch(EquipmentsActions.getEquipmentsRequest(id))
  }, [])



  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(EquipmentsActions.setEquipmentsSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(EquipmentsActions.setEquipmentsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(EquipmentsActions.setEquipmentsPage(page, options.limit))
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        {data.length === 0 && (
          <EmptyList message="Não há equipamentos para serem visualizados" />
        )}
      </div>
      {data?.length > 0 && (
        <Portlet className={classes.grow}>
          <div className={classes.listWrapper}>
            <EquipmentsList fromUsers handleSortChange={handleSortChange} />
          </div>

          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        </Portlet>
      )}
    </div>
  )
}

EquipmentsFormTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EquipmentsFormTab.defaultProps = {
  id: null,
}
