export default () => ({
  paper: {
    borderRadius: 12,
    minHeight: 500,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
  },
  dialogContent: {
    padding: 0,
    height: '100%',
    display: 'flex',
    flex: 1,
    minHeight: 400,
  },
  tableWrapper: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  showHideColumns: {
    width: '1%',
    padding: 0,
    margin: 0,
  },
})
