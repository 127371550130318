export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    height: 400,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  paperTable: {
    height: 710,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  cardMetrics: {},
  card: {
    padding: theme.spacing(2),
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#FCFCFC',
    },
  },
  avatarIcon: {
    backgroundColor: '#fafafa',
    color: theme.palette.primary.main,
  },
})
