import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as RhMonthComparativeTypes,
  Creators as RhMonthComparativeActions,
} from '../ducks/rhMonthComparative'

const filterSelector = ({ rhMonthComparative }) => rhMonthComparative.filters

const api = configureApi()

function* getMonthComparativeFilters() {
  try {
    const groups = yield call(api.get, 'groups', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    const groupsData = groups.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      RhMonthComparativeActions.getMonthComparativeFiltersSuccess(groupsData),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(RhMonthComparativeActions.getMonthComparativeFiltersFailure())
  }
}

function* getMonthComparativeData() {
  try {
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }
    const { data } = yield call(api.get, 'bi-rh/comparative', {
      params: {
        year: mDate.year(),
        month: mDate.month() + 1,
        group_id: filters.group,
        type_bond_id: filters.bond,
      },
    })
    yield put(
      RhMonthComparativeActions.getMonthComparativeDataSuccess(
        data.sort((a, b) => b.total - a.total),
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(RhMonthComparativeActions.getMonthComparativeDataFailure())
  }
}

function* realodMonthComparativeData() {
  yield put(RhMonthComparativeActions.getMonthComparativeDataRequest())
}

export default function* () {
  yield takeLatest(
    RhMonthComparativeTypes.GET_MONTH_COMPARATIVE_DATA_REQUEST,
    getMonthComparativeData,
  )
  yield debounce(
    400,
    RhMonthComparativeTypes.SET_MONTH_COMPARATIVE_FILTER,
    realodMonthComparativeData,
  )
  yield takeLatest(
    RhMonthComparativeTypes.GET_MONTH_COMPARATIVE_FILTERS_REQUEST,
    getMonthComparativeFilters,
  )
}
