import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getUsersRamalRequest: [],
  getUsersRamalFailure: [],
  getUsersRamalSuccess: ['data', 'total', 'ramal'],
  setUsersRamalSort: ['sort', 'direction', 'ramal'],
  setUsersRamalPage: ['page', 'limit', 'ramal'],
  resetUsersRamal: [],
  setUsersRamalSearch: ['value', 'ramal'],
  setUsersRamalPropertyFilter: ['property', 'value', 'ramal'],
  getUsersRamalFilterDealershipsRequest: [],
  getUsersRamalFilterDealershipsSuccess: ['data'],
  getUsersRamalFilterDealershipsFailure: [],
  getUsersRamalFilterDepartmentsRequest: [],
  getUsersRamalFilterDepartmentsSuccess: ['data'],
  getUsersRamalFilterDepartmentsFailure: [],
  resetUsersRamalFilters: [],
  setChangeShowHideColumnsUsersRamal: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
    filters: [
      {
        name: 'dealership_id',
        value: null,
      },
      {
        name: 'active',
        value: true,
      },
      {
        name: 'gender',
        value: null,
      },
      {
        name: 'month',
        value: null,
      },
      {
        name: 'department_id',
        value: null,
      },
    ],
  },
  data: [],
  loading: false,
  dealerships: {
    data: [],
    loading: false,
  },
  departments: {
    data: [],
    loading: false,
  },
  active: {
    data: [],
    loading: false,
  },
  gender: {
    data: [],
    loading: false,
  },
  showHideColumns: {
    name: true,
    function: true,
    department: true,
    dealership: true,
    active: true,
    firstBond: true,
  },
}

const resetUsersRamalFilters = () => ({
  ...INITIAL_STATE,
})

const setUsersRamalPropertyFilter = (
  state = INITIAL_STATE,
  { property, value },
) => {
  return {
    ...state,
    options: {
      ...state.options,
      filters: state.options.filters.map((filter) => {
        if (filter.name === property) {
          return {
            ...filter,
            value,
          }
        }
        return filter
      }),
    },
  }
}

const getUsersRamalFilterDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getUsersRamalFilterDealershipsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getUsersRamalFilterDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getUsersRamalFilterDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: true,
  },
})

const getUsersRamalFilterDepartmentsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  departments: {
    ...state.departments,
    data,
    loading: false,
  },
})

const getUsersRamalFilterDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: false,
  },
})

const getUsersRamalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getUsersRamalFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getUsersRamalSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const setUsersRamalSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setUsersRamalPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setUsersRamalSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

const resetUsersRamal = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsUsersRamal = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableUsers',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_USERS_RAMAL_REQUEST]: getUsersRamalRequest,
  [Types.GET_USERS_RAMAL_FAILURE]: getUsersRamalFailure,
  [Types.GET_USERS_RAMAL_SUCCESS]: getUsersRamalSuccess,
  [Types.SET_USERS_RAMAL_SORT]: setUsersRamalSort,
  [Types.SET_USERS_RAMAL_PAGE]: setUsersRamalPage,
  [Types.RESET_USERS_RAMAL]: resetUsersRamal,
  [Types.SET_USERS_RAMAL_SEARCH]: setUsersRamalSearch,
  [Types.SET_USERS_RAMAL_PROPERTY_FILTER]: setUsersRamalPropertyFilter,
  [Types.GET_USERS_RAMAL_FILTER_DEALERSHIPS_REQUEST]:
    getUsersRamalFilterDealershipsRequest,
  [Types.GET_USERS_RAMAL_FILTER_DEALERSHIPS_SUCCESS]:
    getUsersRamalFilterDealershipsSuccess,
  [Types.GET_USERS_RAMAL_FILTER_DEALERSHIPS_FAILURE]:
    getUsersRamalFilterDealershipsFailure,
  [Types.GET_USERS_RAMAL_FILTER_DEPARTMENTS_REQUEST]:
    getUsersRamalFilterDepartmentsRequest,
  [Types.GET_USERS_RAMAL_FILTER_DEPARTMENTS_SUCCESS]:
    getUsersRamalFilterDepartmentsSuccess,
  [Types.GET_USERS_RAMAL_FILTER_DEPARTMENTS_FAILURE]:
    getUsersRamalFilterDepartmentsFailure,
  [Types.RESET_USERS_RAMAL_FILTERS]: resetUsersRamalFilters,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_USERS_RAMAL]:
    setChangeShowHideColumnsUsersRamal,
})
