import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { FormControl, FormHelperText, RadioGroup } from '@material-ui/core'

function RhfRadioGroup({
  name,
  control,
  children,
  error,
  defaultValue,
  disabled,
  ...props
}) {
  return (
    <FormControl error={!!error} disabled={disabled}>
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...props} {...field} value={field.value || ''}>
            {children}
          </RadioGroup>
        )}
        name={name}
        control={control}
      />
      <FormHelperText>{error && error.message}</FormHelperText>
    </FormControl>
  )
}

RhfRadioGroup.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
  error: PropTypes.shape({
    message: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
}

RhfRadioGroup.defaultProps = {
  error: null,
  defaultValue: '',
  disabled: false,
}

export default RhfRadioGroup
