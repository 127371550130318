import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import { Creators as BilledActions } from '~/store/ducks/billed'
import OverlayLoading from '~/components/OverlayLoading'
import ShowHideColumns from '~/components/ShowHideColumns'
import styles from './styles'

const billingsSelector = ({ billed }) => billed.billings
const billedShowHideColumnsDialog = ({ billed }) => billed

const useStyles = makeStyles(styles)

function BillingsDialog({ dealership, name, open, onClose }) {
  const { showHideColumnsDialog } = useSelector(billedShowHideColumnsDialog)
  const { data, currentDealership, loading } = useSelector(billingsSelector)
  const [sort, setSort] = useState('id')
  const [direction, setDirection] = useState(1)
  const dispatch = useDispatch()
  const classes = useStyles()
  const directionName = direction === 1 ? 'desc' : 'asc'
  const isMobileScreen = useMobileScreen()

  useEffect(() => {
    if (open && currentDealership !== dealership) {
      dispatch(BilledActions.getBillingsDataRequest(dealership))
    }
  }, [currentDealership, open, dealership])

  function handleSortChange(property) {
    if (sort === property) {
      setDirection(direction * -1)
    } else {
      setSort(property)
      setDirection(1)
    }
  }

  function handleSort(arr) {
    return arr.sort((a, b) => {
      if (typeof a[sort] === 'string' || typeof b[sort] === 'string') {
        return (a[sort] || '').localeCompare(b[sort] || '') * direction
      }

      return (a[sort] - b[sort]) * direction
    })
  }

  function handleClose() {
    setSort('id')
    setDirection(1)
    onClose()
  }

  const optionsShowHideColumnDialog = [
    { name: 'segment', label: 'Segmento', disabled: true },
    { name: 'cod', label: 'Cod. Proposta' },
    { name: 'version', label: 'Versão' },
    { name: 'yearModel', label: 'Ano/Modelo' },
    { name: 'seller', label: 'Vendedor' },
  ]

  const handleSetChangeShowHideColumn = (nameShowHide, value) => {
    const newColumnsTable = {
      ...showHideColumnsDialog,
      [nameShowHide]: value,
    }

    dispatch(
      BilledActions.setChangeShowHideColumnsBilledDialog(newColumnsTable),
    )
  }

  return (
    <Dialog
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">{name}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Table>
          <TableHead>
            <TableRow>
              {showHideColumnsDialog.segment && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'type'}
                    onClick={() => handleSortChange('type')}
                    direction={directionName}
                  >
                    Segmento
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumnsDialog.cod && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'cod_proposta'}
                    onClick={() => handleSortChange('cod_proposta')}
                    direction={directionName}
                  >
                    Cod. Proposta
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumnsDialog.version && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'descricao_modelo'}
                    onClick={() => handleSortChange('descricao_modelo')}
                    direction={directionName}
                  >
                    Versão
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumnsDialog.yearModel && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'ano_modelo'}
                    onClick={() => handleSortChange('ano_modelo')}
                    direction={directionName}
                  >
                    Ano/Modelo
                  </TableSortLabel>
                </TableCell>
              )}
              {showHideColumnsDialog.seller && (
                <TableCell>
                  <TableSortLabel
                    active={sort === 'vendedor'}
                    onClick={() => handleSortChange('vendedor')}
                    direction={directionName}
                  >
                    Vendedor
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell className={classes.showHideColumns}>
                <ShowHideColumns
                  showHideColumns={showHideColumnsDialog}
                  optionsShowHideColumn={optionsShowHideColumnDialog}
                  onChangeShowHideColumn={handleSetChangeShowHideColumn}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handleSort(data).map((item) => (
              <TableRow key={item.id}>
                {showHideColumnsDialog.segment && (
                  <TableCell>{item.type}</TableCell>
                )}
                {showHideColumnsDialog.cod && (
                  <TableCell>{item.cod_proposta}</TableCell>
                )}
                {showHideColumnsDialog.version && (
                  <TableCell>{item.descricao_modelo}</TableCell>
                )}
                {showHideColumnsDialog.yearModel && (
                  <TableCell>{item.ano_modelo}</TableCell>
                )}
                {showHideColumnsDialog.seller && (
                  <TableCell>{item.vendedor}</TableCell>
                )}
                <TableCell className={classes.showHideColumns} />
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan="5">
                <Grid container justifyContent="flex-end">
                  Total {data.length}
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

BillingsDialog.propTypes = {
  dealership: PropTypes.number,
  name: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

BillingsDialog.defaultProps = {
  dealership: null,
  name: '',
  open: true,
  onClose: () => {},
}

export default BillingsDialog
