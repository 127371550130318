import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  makeStyles,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  IconButton,
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

import { uniqueId } from 'lodash'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import styles from './styles'

const useStyles = makeStyles(styles)
function CommunicationDialog({ open, onClose, title, text, image }) {
  const classes = useStyles()
  const isMobileScreen = useMobileScreen()

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        fullScreen={isMobileScreen}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle disableTypography>
          <Grid container justifyContent="space-between">
            <Grid item xs={9} md={11}>
              <Typography variant={isMobileScreen ? 'h4' : 'h2'}>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container justifyContent="center">
            <Typography variant="body1" className={classes.text}>
              {text &&
                text.split('\n').map((item) => {
                  return (
                    <span key={uniqueId()}>
                      {item}
                      <br />
                    </span>
                  )
                })}
            </Typography>
            {image && (
              <img
                src={image}
                alt="imagem da notícia"
                className={classes.image}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

CommunicationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
}

CommunicationDialog.defaultProps = {
  open: true,
  onClose: () => {},
  title: '',
  text: '',
  image: '',
}

export default CommunicationDialog
