import history from '~/helpers/history'

const sessionDurationInMs = 1000 * 60 * 60 * 12 // 12 hours

export const login = (tokenData) => {
  localStorage.setItem('caid.token', JSON.stringify(tokenData))
  localStorage.setItem('caid.session_at', JSON.stringify(new Date()))
}

export const setLoginUser = (user) => {
  localStorage.setItem('caid.user', JSON.stringify(user))
}

export const logout = () => {
  localStorage.removeItem('caid.token')
  localStorage.removeItem('caid.user')
  localStorage.removeItem('caid.session_at')
}

export const getTokenData = () =>
  JSON.parse(localStorage.getItem('caid.token') || '{}')

export const getUser = () =>
  JSON.parse(localStorage.getItem('caid.user') || '{}')

export const sessionExpiredLogout = () => {
  history.push('/login/session-expired')
  logout()
}

export const isSessionExpired = () => {
  const sessionAt = JSON.parse(localStorage.getItem('caid.session_at'))
  if (!sessionAt) {
    return true
  }

  const sessionAtDate = new Date(sessionAt)
  const now = new Date()

  const diff = now - sessionAtDate

  return diff > sessionDurationInMs
}
