export function getRating(media) {
  if (parseFloat(media) >= 0 && parseFloat(media) < 5) {
    return 1
  }
  if (parseFloat(media) >= 5 && parseFloat(media) < 7) {
    return 2
  }
  if (parseFloat(media) >= 7 && parseFloat(media) < 10) {
    return 3
  }
  if (parseFloat(media) >= 10) {
    return 4
  }
  return 0
}
