import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  TableBody,
  makeStyles,
} from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function CustomRow({ row }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  return (
    <>
      <TableRow key={row.id}>
        <TableCell align="left" className={classes.smallCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.department_name}{' '}
          {row.total_away > 0 && (
            <>
              ({row.total_away}
              {row.total_away > 1 ? ' Afastados' : ' Afastado'})
            </>
          )}
        </TableCell>
      </TableRow>
      {open && row?.data.length > 0 && (
        <TableRow>
          <TableCell className={classes.tableWrapper} colSpan={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Colaborador</TableCell>
                  <TableCell>Função</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.data.map((item) => (
                  <TableRow key={item.name}>
                    <TableCell
                      className={item.away !== 0 ? classes.awayCell : ''}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      className={item.away !== 0 ? classes.awayCell : ''}
                    >
                      {item.function}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

CustomRow.propTypes = {
  row: PropTypes.shape({
    department_name: PropTypes.string,
    id: PropTypes.string,
    total_away: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}
