import React, { useEffect } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as BiSellersDashboardPageActions } from '~/store/ducks/biSellersDashboard'
import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import EmptyList from '~/components/EmptyList'

import BiSellerDashboardTableRow from './components/BiSellerDashboardTableRow'

import styles from './styles'

const biSellersDashboardPageSelector = ({ biSellersDashboard }) =>
  biSellersDashboard

const useStyles = makeStyles(styles)

function BiSellersDashboardPage() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data, options, loading, showHideColumns } = useSelector(
    biSellersDashboardPageSelector,
  )
  const { dateValue } = options

  useEffect(() => {
    dispatch(BiSellersDashboardPageActions.getBiSellersDashboardPageRequest())
  }, [])

  const handleDateChange = (value) => {
    dispatch(BiSellersDashboardPageActions.setBiSellersDashboardPageDate(value))
  }

  const optionsShowHideColumn = [
    { name: 'seller', label: 'Vendedor', disabled: true },
    { name: 'sale', label: 'Venda' },
    { name: 'devolution', label: 'Devolução' },
    { name: 'dailyGoal', label: 'Meta diária' },
    { name: 'goal', label: 'Objetivo' },
    { name: 'total', label: 'Total' },
  ]

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      BiSellersDashboardPageActions.setChangeShowHideColumnsBiSellersDashboard(
        newColumnsTable,
      ),
    )
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.gridContainerButton}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
            <KeyboardDatePicker
              format="MM/YYYY"
              fullWidth
              inputVariant="outlined"
              views={['month', 'year']}
              label="Ano e mês"
              // helperText="With min and max"
              minDate={new Date('2001-01-01')}
              // maxDate={new Date('2018-06-01')}
              value={dateValue}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há vendas para serem visualizadas" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {showHideColumns.seller && (
                      <TableCell align="left">Vendedor</TableCell>
                    )}
                    {showHideColumns.sale && (
                      <TableCell align="right">Venda</TableCell>
                    )}
                    {showHideColumns.devolution && (
                      <TableCell align="right">Devolução</TableCell>
                    )}
                    {showHideColumns.total && (
                      <TableCell align="right">Total</TableCell>
                    )}
                    {showHideColumns.dailyGoal && (
                      <TableCell align="right">Meta Diária</TableCell>
                    )}
                    {showHideColumns.goal && (
                      <TableCell align="right"> Objetivo</TableCell>
                    )}
                    <TableCell className={classes.showHideColumns}>
                      <ShowHideColumns
                        showHideColumns={showHideColumns}
                        optionsShowHideColumn={optionsShowHideColumn}
                        onChangeShowHideColumn={handleSetChangeShowHideColumn}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item) => (
                    <BiSellerDashboardTableRow row={item} key={item.id} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </Portlet>
    </div>
  )
}

export default BiSellersDashboardPage
