import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getCallsRequest: ['callback'],
  getCallsFailure: [],
  getCallsSuccess: ['data', 'total'],
  getCallsPipelineRequest: ['callback'],
  getCallsPipelineFailure: [],
  getCallsPipelineSuccess: ['data', 'total'],
  setCallsSort: ['sort', 'direction'],
  setCallsPage: ['page', 'limit'],
  setCallsSearch: ['value'],
  setCallsPropertyFilter: ['property', 'value'],
  getCallsFilterDealershipsRequest: [],
  getCallsFilterDealershipsSuccess: ['data'],
  getCallsFilterDealershipsFailure: [],
  getCallsFilterStatusRequest: [],
  getCallsFilterStatusSuccess: ['data'],
  getCallsFilterStatusFailure: [],
  getNextCallRequest: ['callback'],
  getNextCallFinish: [],
  resetCalls: [],
  getCallsFilterDepartmentsRequest: [],
  getCallsFilterDepartmentsSuccess: ['data'],
  getCallsFilterDepartmentsFailure: [],
  setChangeShowHideColumnsCalls: ['newTableColumns'],
  startCallsCountdown: [],
  endCallsCountdown: [],
  tickCallsCountdown: ['count'],
})

const INITIAL_STATE = {
  countdown: {
    status: 'Stopped',
    count: null,
  },
  dataPipeline: [],
  options: {
    sort: 'id',
    direction: 'desc',
    search: '',
    page: 0,
    total: 0,
    limit: 20,
    filters: [
      { name: 'dealership_id', value: null },
      { name: 'department_id', value: null },
      { name: 'subject_id', value: null },
      { name: 'priority', value: null },
      { name: 'status_id', value: null },
    ],
  },
  data: [],
  loading: false,
  dealerships: {
    data: [],
    loading: false,
  },
  status: {
    data: [],
    loading: false,
  },
  departments: {
    data: [],
    loading: false,
  },
  loadingNextCall: false,
  showHideColumns: {
    id: true,
    subject: true,
    requester: true,
    responsible: true,
    status: true,
    created_at: true,
    expected_date: true,
    department: true,
  },
}

const resetCalls = () => ({
  ...INITIAL_STATE,
})

const getCallsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getCallsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCallsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const getCallsPipelineRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getCallsPipelineFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getCallsPipelineSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataPipeline: data,
  loading: false,
})

const setCallsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setCallsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setCallsSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})
const getCallsFilterDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getCallsFilterDealershipsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getCallsFilterDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getCallsFilterStatusRequest = (state = INITIAL_STATE) => ({
  ...state,
  status: {
    ...state.status,
    loading: true,
  },
})

const getCallsFilterStatusSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  status: {
    ...state.status,
    data,
    loading: false,
  },
})

const getCallsFilterStatusFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: {
    ...state.status,
    loading: false,
  },
})

const setCallsPropertyFilter = (state = INITIAL_STATE, { property, value }) => {
  if (property === 'dealership_id') {
    return {
      ...state,
      options: {
        ...state.options,
        filters: state.options.filters.map((filter) => {
          if (filter.name === property) {
            return {
              ...filter,
              value,
            }
          }
          if (filter.name === 'department_id') {
            return {
              ...filter,
              value: null,
            }
          }
          if (filter.name === 'subject_id') {
            return {
              ...filter,
              value: null,
            }
          }
          return filter
        }),
      },
    }
  }
  return {
    ...state,
    options: {
      ...state.options,
      filters: state.options.filters.map((filter) => {
        if (filter.name === property) {
          return {
            ...filter,
            value,
          }
        }
        return filter
      }),
    },
  }
}

const getNextCallRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingNextCall: true,
})

const getNextCallFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingNextCall: false,
})

const getCallsFilterDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: true,
  },
})

const getCallsFilterDepartmentsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    departments: {
      ...state.departments,
      data,
      loading: false,
    },
  }
}

const getCallsFilterDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: false,
  },
})

const setChangeShowHideColumnsCalls = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableCalls',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

const startCallsCountdown = (state = INITIAL_STATE) => ({
  ...state,
  countdown: {
    ...state.countdown,
    status: 'Running',
  },
})

const endCallsCountdown = (state = INITIAL_STATE) => ({
  ...state,
  countdown: {
    count: null,
    status: 'Stopped',
  },
})

const tickCallsCountdown = (state = INITIAL_STATE, { count }) => ({
  ...state,
  countdown: {
    ...state.countdown,
    count,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_CALLS_REQUEST]: getCallsRequest,
  [Types.GET_CALLS_FAILURE]: getCallsFailure,
  [Types.GET_CALLS_SUCCESS]: getCallsSuccess,
  [Types.SET_CALLS_SORT]: setCallsSort,
  [Types.SET_CALLS_PAGE]: setCallsPage,
  [Types.SET_CALLS_SEARCH]: setCallsSearch,
  [Types.SET_CALLS_PROPERTY_FILTER]: setCallsPropertyFilter,
  [Types.GET_CALLS_FILTER_DEALERSHIPS_REQUEST]:
    getCallsFilterDealershipsRequest,
  [Types.GET_CALLS_FILTER_DEALERSHIPS_SUCCESS]:
    getCallsFilterDealershipsSuccess,
  [Types.GET_CALLS_FILTER_DEALERSHIPS_FAILURE]:
    getCallsFilterDealershipsFailure,
  [Types.GET_CALLS_FILTER_STATUS_REQUEST]: getCallsFilterStatusRequest,
  [Types.GET_CALLS_FILTER_STATUS_SUCCESS]: getCallsFilterStatusSuccess,
  [Types.GET_CALLS_FILTER_STATUS_FAILURE]: getCallsFilterStatusFailure,
  [Types.GET_NEXT_CALL_REQUEST]: getNextCallRequest,
  [Types.GET_NEXT_CALL_FINISH]: getNextCallFinish,
  [Types.RESET_CALLS]: resetCalls,
  [Types.GET_CALLS_FILTER_DEPARTMENTS_REQUEST]:
    getCallsFilterDepartmentsRequest,
  [Types.GET_CALLS_FILTER_DEPARTMENTS_SUCCESS]:
    getCallsFilterDepartmentsSuccess,
  [Types.GET_CALLS_FILTER_DEPARTMENTS_FAILURE]:
    getCallsFilterDepartmentsFailure,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_CALLS]: setChangeShowHideColumnsCalls,
  [Types.START_CALLS_COUNTDOWN]: startCallsCountdown,
  [Types.END_CALLS_COUNTDOWN]: endCallsCountdown,
  [Types.TICK_CALLS_COUNTDOWN]: tickCallsCountdown,
  [Types.GET_CALLS_PIPELINE_REQUEST]: getCallsPipelineRequest,
  [Types.GET_CALLS_PIPELINE_FAILURE]: getCallsPipelineFailure,
  [Types.GET_CALLS_PIPELINE_SUCCESS]: getCallsPipelineSuccess,
})
