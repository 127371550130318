import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { formatPrice } from '~/helpers/formatPrice'
import OverlayLoading from '~/components/OverlayLoading'
import { useMobileScreen } from '~/helpers/useMediaQuery'

import EmptyList from '~/components/EmptyList'

import { Creators as BiSellersDashboardPageActions } from '~/store/ducks/biSellersDashboard'

import styles from './styles'

const biSellersDashboardPageSelector = ({ biSellersDashboard }) =>
  biSellersDashboard.sellers

const useStyles = makeStyles(styles)

function SalesDialog({ sellerId, codNbs, open, onClose, sellerDailyGoal }) {
  const { data, loading } = useSelector(biSellersDashboardPageSelector)
  const [sort, setSort] = useState('data')
  const [direction, setDirection] = useState(1)
  const dispatch = useDispatch()
  const classes = useStyles()
  const directionName = direction === 1 ? 'desc' : 'asc'
  const isMobileScreen = useMobileScreen()

  const totalSales = formatPrice(
    data.reduce(
      (totalSale, sale) => Number(totalSale + Number(sale.total_vendas)),
      0,
    ),
  )

  const totalReturns = formatPrice(
    data.reduce(
      (totalSale, sale) => Number(totalSale + Number(sale.total_devolucao)),
      0,
    ),
  )

  const sumTotal = formatPrice(
    data.reduce((totalSale, sale) => Number(totalSale + Number(sale.total)), 0),
  )

  useEffect(() => {
    if (open && sellerId && codNbs) {
      dispatch(
        BiSellersDashboardPageActions.getBiSellersDashboardPageSellerRequest(
          sellerId,
          codNbs,
        ),
      )
    }
  }, [open, sellerId, codNbs])

  function handleSortChange(property) {
    if (sort === property) {
      setDirection(direction * -1)
    } else {
      setSort(property)
      setDirection(1)
    }
  }

  function handleSort(arr) {
    return arr.sort((a, b) => {
      if (typeof a[sort] === 'string' || typeof b[sort] === 'string') {
        return (a[sort] || '').localeCompare(b[sort] || '') * direction
      }

      return (a[sort] - b[sort]) * direction
    })
  }

  function handleClose() {
    setSort('data')
    setDirection(1)
    onClose()
  }

  return (
    <Dialog
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      {!loading && data.length > 0 && (
        <DialogTitle disableTypography>
          <Typography variant="h3">{data[0].name}</Typography>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent} dividers>
        <div className={classes.tableWrapper}>
          {!loading && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'data'}
                      onClick={() => handleSortChange('data')}
                      direction={directionName}
                    >
                      Data
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'total_vendas'}
                      onClick={() => handleSortChange('total_vendas')}
                      direction={directionName}
                    >
                      Venda
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'total_devolucao'}
                      onClick={() => handleSortChange('total_devolucao')}
                      direction={directionName}
                    >
                      Devolução
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'total'}
                      onClick={() => handleSortChange('total')}
                      direction={directionName}
                    >
                      Total
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handleSort(data).map((item) => (
                  <TableRow key={item.data + item.total_vendas}>
                    <TableCell>{moment(item.data).format('L')}</TableCell>
                    <TableCell>
                      {formatPrice(Number(item.total_vendas))}
                    </TableCell>
                    <TableCell>
                      {formatPrice(Number(item.total_devolucao))}
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          item.total > sellerDailyGoal ? '#008000' : '#FF0000',
                      }}
                    >
                      {formatPrice(Number(item.total))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {data.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{totalSales}</TableCell>
                    <TableCell>{totalReturns}</TableCell>
                    <TableCell>{sumTotal}</TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          )}
        </div>
        {!loading && data.length === 0 && (
          <EmptyList message="Não há vendas para serem visualizadas" />
        )}
        {loading && <OverlayLoading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SalesDialog.propTypes = {
  sellerId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  codNbs: PropTypes.string,
  sellerDailyGoal: PropTypes.number,
}

SalesDialog.defaultProps = {
  sellerId: null,
  codNbs: null,
  sellerDailyGoal: null,
  open: true,
  onClose: () => {},
}

export default SalesDialog
