import { createActions, createReducer } from 'reduxsauce'
import { sortBy } from 'lodash'

export const { Types, Creators } = createActions({
  getBilledMonthComparativeDataRequest: [],
  getBilledMonthComparativeDataSuccess: ['data'],
  getBilledMonthComparativeDataFailure: [],
  getBilledMonthComparativeFiltersRequest: [],
  getBilledMonthComparativeFiltersFailure: [],
  getBilledMonthComparativeFiltersSuccess: ['groups'],
  setBilledMonthComparativeFilter: ['property', 'value'],
  resetBilledMonthComparative: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
  loadingFilters: false,
  filters: {
    date: new Date(),
    group: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
  },
}

const getBilledMonthComparativeDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getBilledMonthComparativeDataSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  data,
  loading: false,
})

const getBilledMonthComparativeDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getBilledMonthComparativeFiltersRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: true,
})

const getBilledMonthComparativeFiltersFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingFilters: false,
})

const getBilledMonthComparativeFiltersSuccess = (
  state = INITIAL_STATE,
  { groups },
) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
      ...sortBy(groups, (a) => a.name),
    ],
  },
  loadingFilters: false,
})

const setBilledMonthComparativeFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const resetBilledMonthComparative = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_BILLED_MONTH_COMPARATIVE_DATA_REQUEST]:
    getBilledMonthComparativeDataRequest,
  [Types.GET_BILLED_MONTH_COMPARATIVE_DATA_SUCCESS]:
    getBilledMonthComparativeDataSuccess,
  [Types.GET_BILLED_MONTH_COMPARATIVE_DATA_FAILURE]:
    getBilledMonthComparativeDataFailure,
  [Types.GET_BILLED_MONTH_COMPARATIVE_FILTERS_REQUEST]:
    getBilledMonthComparativeFiltersRequest,
  [Types.GET_BILLED_MONTH_COMPARATIVE_FILTERS_FAILURE]:
    getBilledMonthComparativeFiltersFailure,
  [Types.GET_BILLED_MONTH_COMPARATIVE_FILTERS_SUCCESS]:
    getBilledMonthComparativeFiltersSuccess,
  [Types.SET_BILLED_MONTH_COMPARATIVE_FILTER]: setBilledMonthComparativeFilter,
  [Types.RESET_BILLED_MONTH_COMPARATIVE]: resetBilledMonthComparative,
})
