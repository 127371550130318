const getActions = (call, user, actionsData) => {
  let excludeActionsId = []
  if (call.requester_id === user?.id) {
    excludeActionsId = [2, 3, 4, 5, 8, 9, 10]
    switch (call.status_id) {
      case 5:
        excludeActionsId = excludeActionsId.filter((actionId) => actionId !== 3)
        excludeActionsId.push(6)
        break
      case 6:
        excludeActionsId.push(7)
        break
      default:
    }
  } else {
    excludeActionsId = [7, 9, 5]
    switch (call.status_id) {
      case 1:
      case 4:
        excludeActionsId.push(2, 3, 10, 1, 4)
        break
      case 3:
        excludeActionsId.push(3, 4, 8, 1, 2, 10)
        break
      case 5:
        excludeActionsId.push(2, 4, 5, 8, 10, 1, 3)
        break
      case 6:
        excludeActionsId.push(2, 3, 4, 5, 8, 10, 1)
        break
      case 8:
        excludeActionsId.push(2, 3, 5, 8, 10, 1, 4)
        break
      default:
        excludeActionsId.push(2, 3, 8, 10, 1, 4)
    }
  }
  return actionsData.filter((action) => !excludeActionsId.includes(action.id))
}

export { getActions }
