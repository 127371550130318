import { createActions, createReducer } from 'reduxsauce'
import { groupBy, sortBy } from 'lodash'

import { uniqueId } from '~/helpers/utils'

export const { Types, Creators } = createActions({
  getBiDashboardTableDataRequest: [],
  getBiDashboardTableDataFailure: [],
  getBiDashboardTableDataSuccess: ['newsData', 'semiNewsData', 'directData'],
  getBiDashboardDataRequest: [],
  getBiDashboardDataFailure: [],
  getBiDashboardDataSuccess: ['data'],
  getBiDashboardSalesRequest: ['dealershipId'],
  getBiDashboardSalesFailure: [],
  getBiDashboardSalesSuccess: ['data'],
  getBiDashboardGroupsRequest: [],
  getBiDashboardGroupsFailure: [],
  getBiDashboardGroupsSuccess: ['data'],
  setBiDashboardFilter: ['property', 'value'],
  setBiDashboardGroupFilter: ['value'],
  resetBiDashboard: [],
  setChangeShowHideColumnsBiDashboard: ['newTableColumns'],
  setChangeShowHideColumnsBiDashboardDialog: ['newTableColumnsDialog'],
})

const initialColumns = JSON.parse(
  localStorage.getItem('caid.columnsTableBiDashboard' || '{}'),
)

const initialColumnsDialog = JSON.parse(
  localStorage.getItem('caid.columnsTableBiDashboardDialog' || '{}'),
)

const INITIAL_STATE = {
  data: [],
  tableData: {
    newsData: [],
    semiNewsData: [],
    directData: [],
    loading: false,
  },
  loading: false,
  filters: {
    date: new Date(),
    group: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    loading: false,
  },
  sales: {
    data: [],
    loading: false,
  },
  showHideColumns: initialColumns || {
    group_name: true,
    totalSales: true,
    totalWallet: true,
    totalDailySales: true,
    totalGoals: true,
    totalDailyGoal: true,
    totalPreOrders: true,
    totalProposal: true,
    totalBilled: true,
    totalGeral: true,
  },
  showHideColumnsDialog: initialColumnsDialog || {
    segment: true,
    cod: true,
    version: true,
    yearModel: true,
    seller: true,
    client: true,
  },
}

const resetBiDashboard = () => ({
  ...INITIAL_STATE,
})

const getBiDashboardSalesRequest = (state = INITIAL_STATE) => ({
  ...state,
  sales: {
    ...state.sales,
    loading: true,
  },
})

const getBiDashboardSalesFailure = (state = INITIAL_STATE) => ({
  ...state,
  sales: {
    ...state.sales,
    loading: false,
  },
})

const getBiDashboardSalesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  sales: {
    ...state.sales,
    data,
    loading: false,
  },
})

const getBiDashboardDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getBiDashboardDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getBiDashboardDataSuccess = (state = INITIAL_STATE, { data }) => {
  const newsTotal = data
    ?.map(
      (item) =>
        item.pre_ordens_novos + item.propostas_novos + item.faturados_novos,
    )
    ?.reduce((total, item) => total + item, 0)

  const semiNewsTotal = data
    ?.map((item) => item.propostas_usados + item.faturados_usados)
    ?.reduce((total, item) => total + item, 0)
  const directTotal = data
    ?.map(
      (item) =>
        item.propostas_novos_vendas_diretas +
        item.faturados_novos_vendas_diretas,
    )
    ?.reduce((total, item) => total + item, 0)

  const allTotal = {
    nome: 'Total Geral',
    news: newsTotal,
    semiNews: semiNewsTotal,
    direct: directTotal,
    totalSales: newsTotal + semiNewsTotal + directTotal,
  }

  const formattedData = data?.map((item) => {
    const news =
      item.pre_ordens_novos + item.propostas_novos + item.faturados_novos
    const semiNews = item.propostas_usados + item.faturados_usados
    const direct =
      item.propostas_novos_vendas_diretas + item.faturados_novos_vendas_diretas
    const totalSales = news + semiNews + direct

    return {
      ...item,
      news,
      semiNews,
      direct,
      totalSales,
    }
  })

  return {
    ...state,
    data: formattedData.length > 0 ? [...formattedData, allTotal] : [],
    loading: false,
  }
}

const getBiDashboardTableDataRequest = (state = INITIAL_STATE) => ({
  ...state,
  tableData: {
    ...state.tableData,
    loading: true,
  },
})

const getBiDashboardTableDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  tableData: {
    ...state.tableData,
    loading: false,
  },
})

const getBiDashboardTableDataSuccess = (
  state = INITIAL_STATE,
  { newsData, semiNewsData, directData },
) => {
  const newsDataGrouped = groupBy(newsData, 'group_name')
  const semiNewsDataGrouped = groupBy(semiNewsData, 'group_name')
  const directDataGrouped = groupBy(directData, 'group_name')

  const formattedNewsData =
    newsData.length > 0
      ? Object.keys(newsDataGrouped)?.map((key) => {
          return {
            group_name: newsDataGrouped[key][0]?.group_name,
            id: uniqueId(),
            data: newsDataGrouped[key],
          }
        })
      : []

  const formattedSemiNewsData =
    semiNewsData.length > 0
      ? Object.keys(semiNewsDataGrouped)?.map((key) => {
          return {
            group_name: semiNewsDataGrouped[key][0]?.group_name,
            id: uniqueId(),
            data: semiNewsDataGrouped[key],
          }
        })
      : []

  const formattedDirectData =
    directData.length > 0
      ? Object.keys(directDataGrouped)?.map((key) => {
          return {
            group_name: directDataGrouped[key][0]?.group_name,
            id: uniqueId(),
            data: directDataGrouped[key],
          }
        })
      : []

  const getTotals = (data) => {
    return data.map((item) => ({
      ...item,
      totalSales: item.data.reduce(
        (total, sale) => Number(total + sale.sales),
        0,
      ),
      totalWallet: item.data.reduce(
        (total, sale) => Number(total + sale.wallet),
        0,
      ),
      totalDailySales: item.data.reduce(
        (total, sale) => Number(total + sale.daily_sales),
        0,
      ),
      totalGoals: item.data.reduce(
        (total, sale) => Number(total + sale.total_objetivos),
        0,
      ),
      totalDailyGoal: item.data.reduce(
        (total, sale) => Number(total + Number(sale.faturados_dia)),
        0,
      ),
      totalPreOrders: item.data.reduce(
        (total, sale) => Number(total + sale.pre_ordens),
        0,
      ),
      totalProposal: item.data.reduce(
        (total, sale) => Number(total + sale.total_propostas),
        0,
      ),
      totalBilled: item.data.reduce(
        (total, sale) => Number(total + sale.total_faturados),
        0,
      ),
      totalGeral: item.data.reduce(
        (total, sale) => Number(total + sale.total_geral),
        0,
      ),
    }))
  }
  return {
    ...state,
    tableData: {
      ...state.tableData,
      loading: false,
      newsData:
        formattedNewsData.length > 0 ? getTotals(formattedNewsData) : [],
      semiNewsData:
        formattedSemiNewsData.length > 0
          ? getTotals(formattedSemiNewsData)
          : [],
      directData:
        formattedDirectData.length > 0 ? getTotals(formattedDirectData) : [],
    },
  }
}

const getBiDashboardGroupsRequest = (state = INITIAL_STATE) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    loading: true,
  },
})

const getBiDashboardGroupsFailure = (state = INITIAL_STATE) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    loading: false,
  },
})

const getBiDashboardGroupsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  filtersData: {
    ...state.filtersData,
    groups: [
      { name: 'Todos os grupos', value: 0 },
      ...sortBy(data, (a) => a.name),
    ],
    loading: false,
  },
})

const setBiDashboardFilter = (state = INITIAL_STATE, { property, value }) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const setBiDashboardGroupFilter = (state = INITIAL_STATE, { value }) => ({
  ...state,
  filters: {
    ...state.filters,
    group: value,
  },
})

const setChangeShowHideColumnsBiDashboard = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableBiDashboard',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

const setChangeShowHideColumnsBiDashboardDialog = (
  state = INITIAL_STATE,
  { newTableColumnsDialog },
) => {
  localStorage.setItem(
    'caid.columnsTableBiDashboardDialog',
    JSON.stringify(newTableColumnsDialog),
  )
  return {
    ...state,
    showHideColumnsDialog: newTableColumnsDialog,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_BI_DASHBOARD_TABLE_DATA_REQUEST]: getBiDashboardTableDataRequest,
  [Types.GET_BI_DASHBOARD_TABLE_DATA_FAILURE]: getBiDashboardTableDataFailure,
  [Types.GET_BI_DASHBOARD_TABLE_DATA_SUCCESS]: getBiDashboardTableDataSuccess,
  [Types.GET_BI_DASHBOARD_DATA_REQUEST]: getBiDashboardDataRequest,
  [Types.GET_BI_DASHBOARD_DATA_FAILURE]: getBiDashboardDataFailure,
  [Types.GET_BI_DASHBOARD_DATA_SUCCESS]: getBiDashboardDataSuccess,
  [Types.GET_BI_DASHBOARD_SALES_REQUEST]: getBiDashboardSalesRequest,
  [Types.GET_BI_DASHBOARD_SALES_FAILURE]: getBiDashboardSalesFailure,
  [Types.GET_BI_DASHBOARD_SALES_SUCCESS]: getBiDashboardSalesSuccess,
  [Types.GET_BI_DASHBOARD_GROUPS_REQUEST]: getBiDashboardGroupsRequest,
  [Types.GET_BI_DASHBOARD_GROUPS_FAILURE]: getBiDashboardGroupsFailure,
  [Types.GET_BI_DASHBOARD_GROUPS_SUCCESS]: getBiDashboardGroupsSuccess,
  [Types.SET_BI_DASHBOARD_FILTER]: setBiDashboardFilter,
  [Types.SET_BI_DASHBOARD_GROUP_FILTER]: setBiDashboardGroupFilter,
  [Types.RESET_BI_DASHBOARD]: resetBiDashboard,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_BI_DASHBOARD]:
    setChangeShowHideColumnsBiDashboard,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_BI_DASHBOARD_DIALOG]:
    setChangeShowHideColumnsBiDashboardDialog,
})
