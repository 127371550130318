import React from 'react'
import PropTypes from 'prop-types'

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Title,
  Legend,
  LineSeries,
  Tooltip,
} from 'react-jsx-highcharts'

export default function RhMonthComparativeChart({
  datasets,
  labels,
  dealership: title,
}) {
  const plotOptions = {
    series: {
      marker: {
        enabled: true,
        radius: 3,
      },
    },
  }
  const colors = ['#1b1464', '#9d0068', '#ec4647', '#ffa600']

  return (
    <HighchartsChart plotOptions={plotOptions} colors={colors}>
      <Chart />

      <Title>{title}</Title>

      <Legend layout="horizontal" align="center" verticalAlign="bottom" />

      <Tooltip
        padding={10}
        hideDelay={250}
        shape="square"
        followPointer
        shared
      />
      <XAxis type="category" />

      <YAxis>
        {datasets.map(({ data, label }) => (
          <LineSeries
            key={label}
            name={label}
            data={data.map((y, i) => ({ name: labels[i], y }))}
            // color={backgroundColor}
          />
        ))}
      </YAxis>
    </HighchartsChart>
  )
}

RhMonthComparativeChart.propTypes = {
  datasets: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  ),
  dealership: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
}

RhMonthComparativeChart.defaultProps = {
  datasets: [],
  dealership: 'Title',
  labels: [],
}
