import { createActions, createReducer } from 'reduxsauce'
import moment from 'moment'
import { last } from 'lodash'

export const { Types, Creators } = createActions({
  getAnalitycalRequest: [],
  getAnalitycalFailure: [],
  getAnalitycalSuccess: ['data', 'dataPreviousMonth', 'total'],
  setAnalitycalSort: ['sort', 'direction'],
  setAnalitycalPage: ['page', 'limit'],
  setAnalitycalDate: ['start', 'end'],
  resetAnalitycal: [],
  setChangeShowHideColumnsAnalitycal: ['newTableColumns'],
  getFiltersAnalitycalRequest: [],
  getFiltersAnalitycalFailure: [],
  getFiltersAnalitycalSuccess: ['groups', 'dealerships'],
  setAnalitycalChangeFilter: ['property', 'value'],
  getAnalitycalDetailsRequest: ['segment'],
  getAnalitycalDetailsFailure: [],
  getAnalitycalDetailsSuccess: ['dataDetails'],
  setChangeShowHideColumnsAnalitycalDialog: ['newTableColumnsDialog'],
})

const INITIAL_STATE = {
  showHideColumns: {
    dealership: true,
    type: true,
    billing: true,
    quantity: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
  },
  showHideColumnsDialog: {
    family: true,
    amount: true,
    billing: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
  },
  options: {
    sort: 'nome',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  filters: {
    startDate: moment().subtract(30, 'days').format(),
    endDate: moment().format(),
    group: 0,
    dealership: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    dealerships: [
      {
        name: 'Todas as concessionárias',
        value: 0,
      },
    ],
  },
  data: [],
  dataPreviousMonth: {},
  loading: false,
  loadingFilters: false,
  dataDetails: [],
}

const getAnalitycalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalSuccess = (
  state = INITIAL_STATE,
  { data, dataPreviousMonth, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  dataPreviousMonth: last(dataPreviousMonth),
  loading: false,
})

const setAnalitycalSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setAnalitycalPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setAnalitycalDate = (state = INITIAL_STATE, { start, end }) => ({
  ...state,
  filters: {
    ...state.filters,
    startDate: start,
    endDate: end,
  },
})

const resetAnalitycal = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsAnalitycal = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycal',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}
const getFiltersAnalitycalRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getFiltersAnalitycalSuccess = (
  state = INITIAL_STATE,
  { groups, dealerships },
) => {
  return {
    ...state,
    filtersData: {
      ...state.filtersData,
      groups: [
        {
          name: 'Todas os grupos',
          value: 0,
        },
        ...groups,
      ],
      dealerships: [
        {
          name: 'Todas as concessionárias',
          value: 0,
        },
        ...dealerships,
      ],
    },
    loading: false,
  }
}

const getFiltersAnalitycalFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setAnalitycalChangeFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const getAnalitycalDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalDetailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalDetailsSuccess = (
  state = INITIAL_STATE,
  { dataDetails },
) => ({
  ...state,
  dataDetails,
  loading: false,
})

const setChangeShowHideColumnsAnalitycalDialog = (
  state = INITIAL_STATE,
  { newTableColumnsDialog },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycalDialog',
    JSON.stringify(newTableColumnsDialog),
  )
  return {
    ...state,
    showHideColumnsDialog: newTableColumnsDialog,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_ANALITYCAL_REQUEST]: getAnalitycalRequest,
  [Types.GET_ANALITYCAL_FAILURE]: getAnalitycalFailure,
  [Types.GET_ANALITYCAL_SUCCESS]: getAnalitycalSuccess,
  [Types.SET_ANALITYCAL_SORT]: setAnalitycalSort,
  [Types.SET_ANALITYCAL_PAGE]: setAnalitycalPage,
  [Types.SET_ANALITYCAL_DATE]: setAnalitycalDate,
  [Types.RESET_ANALITYCAL]: resetAnalitycal,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL]:
    setChangeShowHideColumnsAnalitycal,
  [Types.GET_FILTERS_ANALITYCAL_REQUEST]: getFiltersAnalitycalRequest,
  [Types.GET_FILTERS_ANALITYCAL_SUCCESS]: getFiltersAnalitycalSuccess,
  [Types.GET_FILTERS_ANALITYCAL_FAILURE]: getFiltersAnalitycalFailure,
  [Types.SET_ANALITYCAL_CHANGE_FILTER]: setAnalitycalChangeFilter,
  [Types.GET_ANALITYCAL_DETAILS_REQUEST]: getAnalitycalDetailsRequest,
  [Types.GET_ANALITYCAL_DETAILS_FAILURE]: getAnalitycalDetailsFailure,
  [Types.GET_ANALITYCAL_DETAILS_SUCCESS]: getAnalitycalDetailsSuccess,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL_DIALOG]:
    setChangeShowHideColumnsAnalitycalDialog,
})
