import React, { useEffect, useState } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as DepartmentActions } from '~/store/ducks/department'
import { Creators as DepartmentsActions } from '~/store/ducks/departments'

import TabPanel from '~/components/TabPanel'
import OverlayLoading from '~/components/OverlayLoading'
import { useMobileScreen } from '~/helpers/useMediaQuery'
import styles from './styles'
import SubjectFormTab from './SubjectFormTab'
import AttendantsFormTab from './AttendantsFormTab'

const useStyles = makeStyles(styles)

const departmentSelector = ({ department }) => department

const DepartmentDialog = ({ open, onClose, id }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, loading } = useSelector(departmentSelector)
  const [currentTab, setCurrentTab] = useState(0)
  const isMobileScreen = useMobileScreen()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()
  const isEdit = !!id

  useEffect(() => {
    if (isEdit) {
      dispatch(DepartmentActions.getDepartmentRequest(id))
      dispatch(DepartmentActions.getDepartmentSubjectsRequest(id))
    } else {
      reset({ name: '' })
    }
  }, [isEdit, id])

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }

  const reloadDepartmentsList = () => {
    dispatch(DepartmentsActions.getDepartmentsRequest())
  }

  const onSubmit = (formData) => {
    if (isEdit) {
      dispatch(
        DepartmentActions.updateDepartmentRequest(id, formData, () => {
          reloadDepartmentsList()
          onClose()
        }),
      )
    } else {
      dispatch(
        DepartmentActions.addDepartmentRequest(formData, () => {
          reloadDepartmentsList()
          onClose()
        }),
      )
    }
  }

  useEffect(() => {
    if (isEdit) {
      reset(data)
    }
  }, [data, isEdit, reset])

  const handleResetForm = () => {
    setCurrentTab(0)
    reset({ name: '' })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
      open={open}
      onClose={(onClose, handleResetForm)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          Departamento
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      <DialogContent className={classes.dialogContent}>
        {loading && <OverlayLoading />}
        {!loading && (
          <>
            {!isEdit ? (
              <>
                <Grid container className={classes.subtitleWrapper}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Departamento</Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.dividerBottom} />
              </>
            ) : (
              <Tabs
                variant="fullWidth"
                value={currentTab}
                onChange={handleTabsChange}
              >
                <Tab label="Departamento" />
                <Tab label="Assuntos" />
                <Tab label="Atendentes" />
              </Tabs>
            )}

            <TabPanel index={0} value={currentTab}>
              <form onSubmit={handleSubmit(onSubmit)} id="form-department">
                <Grid container spacing={2} className={classes.contentWrapper}>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          variant="outlined"
                          autoFocus
                          label="Nome"
                          type="text"
                          onChange={onChange}
                          value={value || ''}
                          error={!!errors.name}
                          helperText={!!errors.name && 'Campo obrigatório'}
                          fullWidth
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    color="primary"
                    type="submit"
                    form="form-department"
                    variant="contained"
                  >
                    SALVAR DEPARTAMENTO
                  </Button>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <SubjectFormTab id={id} />
            </TabPanel>
            <TabPanel index={2} value={currentTab}>
              <AttendantsFormTab id={id} />
            </TabPanel>
          </>
        )}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DepartmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

DepartmentDialog.defaultProps = {
  id: null,
}

export default DepartmentDialog
