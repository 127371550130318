import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactCrop from 'react-image-crop'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
} from '@material-ui/core'

import {
  Backup as UploadIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'

import OverlayLoading from '~/components/OverlayLoading'
import RhfTextField from '~/components/FormComponents/RhfTextField'
import history from '~/helpers/history'

import { Creators as UserActions } from '~/store/ducks/user'
import { Creators as FlashMessageActions } from '~/store/ducks/flashMessage'
import { generateBlobImage } from '~/helpers/images'

import { jsonUserToFormData } from '~/helpers/user'
import 'react-image-crop/dist/ReactCrop.css'

import styles from './styles'

const useStyles = makeStyles(styles)

const userDealershipSelector = ({ user }) => user.dealerships
const userRolesSelector = ({ user }) => user.roles
const userBanksSelector = ({ user }) => user.bank
const userSelector = ({ user }) => user

export default function UserDataFormTab({ id, mode }) {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(userSelector)
  const userDealerships = useSelector(userDealershipSelector)
  const userRoles = useSelector(userRolesSelector)
  const { data: dataBanks } = useSelector(userBanksSelector)
  const classes = useStyles()

  const isEdit = !!id
  const isView = !!id && mode === 'view'

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (isEdit) {
      reset(data)
    }
  }, [isEdit, data])

  const [imageUrl, setImageUrl] = useState('')
  const [croppedCompleted, setCroppedCompleted] = useState(null)
  const fileInput = useRef(null)

  const [cropProps, setCropProps] = useState({
    aspect: 1 / 1,
    unit: 'px', // default, can be 'px' or '%'
    width: 200,
    height: 200,
  })

  const handleUploadImage = (e) => {
    const allowedExtensions = {
      image: ['png', 'jpg', 'jpeg'],
    }
    const [file] = e.target.files
    if (!file) {
      return
    }
    const [fileType, ext] = file.type.split('/')

    if (
      !allowedExtensions[fileType] ||
      !allowedExtensions[fileType].includes(ext)
    ) {
      dispatch(
        FlashMessageActions.showMessage({
          id: 'extension_not_allowed',
          variant: 'error',
        }),
      )
      return
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => setImageUrl(reader.result))
    reader.readAsDataURL(file)
    e.target.value = null
  }

  const handleSubmitUserDataForm = async (userData) => {
    let formattedData
    if (imageUrl && croppedCompleted) {
      const image = await generateBlobImage(croppedCompleted, fileInput)
      formattedData = {
        ...userData,
        image,
        dt_primeiro_contrato:
          moment(userData.dt_primeiro_contrato).format('YYYY-MM-DD') || null,
        dt_birthday: moment(userData.dt_birthday).format('YYYY-MM-DD') || null,
        dealerships_id: userData.dealerships_id?.map((d) => d.id),
        roles_id: userData.roles_id?.map((r) => r.id),
        password: userData.password || null,
      }
    } else {
      formattedData = {
        ...userData,
        dt_primeiro_contrato:
          moment(userData.dt_primeiro_contrato).format('YYYY-MM-DD') || null,
        dt_birthday: moment(userData.dt_birthday).format('YYYY-MM-DD') || null,
        dealerships_id: userData.dealerships_id?.map((d) => d.id),
        roles_id: userData.roles_id?.map((r) => r.id),
        password: userData.password || null,
      }
    }

    const formData = jsonUserToFormData(formattedData)

    if (isEdit) {
      dispatch(
        UserActions.updateUserRequest(id, formData, () => {
          history.push('/users')
        }),
      )
    } else {
      dispatch(UserActions.addUserRequest(formData))
    }
  }

  const maritalStatus = [
    { id: 1, name: 'Casado(a)' },
    { id: 2, name: 'Solteiro(a)' },
    { id: 3, name: 'Divorciado(a)' },
    { id: 4, name: 'Viuvo(a)' },
    { id: 5, name: 'União Estável' },
    { id: 6, name: 'Concubinato' },
  ]

  const genders = [
    { value: 'F', name: 'Feminino' },
    { value: 'M', name: 'Masculino' },
  ]

  const schooling = [
    { id: 1, name: 'Ensino Fundamental Incompleto' },
    { id: 2, name: 'Ensino Fundamental Completo' },
    { id: 3, name: 'Ensino Médio Incompleto' },
    { id: 4, name: 'Ensino Médio Completo' },
    { id: 5, name: 'Ensino Superior Incompleto' },
    { id: 6, name: 'Ensino Superior Completo' },
  ]

  const onLoad = useCallback((img) => {
    fileInput.current = img
  }, [])

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      {!loading && (
        <form
          onSubmit={handleSubmit(handleSubmitUserDataForm)}
          id="user-data-form"
        >
          <Grid container spacing={2} justifyContent="center">
            {isEdit && !imageUrl && data.url_image && (
              <Avatar
                src={data.url_image}
                alt={data.name}
                className={classes.userImage}
              />
            )}
            {imageUrl && (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                className={classes.cropWrapper}
              >
                <ReactCrop
                  src={imageUrl}
                  crop={cropProps}
                  keepSelection
                  onImageLoaded={onLoad}
                  onChange={(c) => setCropProps(c)}
                  onComplete={(c) => setCroppedCompleted(c)}
                  minWidth={200}
                  minHeight={200}
                />
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  disabled={isView}
                  className={classes.uploadButton}
                  onClick={() => {
                    fileInput.current.click()
                  }}
                >
                  <UploadIcon className={classes.Icon} />
                  Selecionar imagem
                  <input
                    type="file"
                    multiple={false}
                    accept=".png,.jpg"
                    onClick={(e) => {
                      e.target.value = ''
                    }}
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={handleUploadImage}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <RhfTextField
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{ required: true }}
                  label="Nome *"
                  autoComplete="off"
                  disabled={isView}
                  type="text"
                  placeholder="Informe o nome"
                  error={!!errors.name}
                  helperText={!!errors.name && 'Campo obrigatório'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dt_primeiro_contrato"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        value={value || null}
                        label="Data do primeiro vínculo"
                        disabled={isView}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data inválida"
                        inputRef={ref}
                        onChange={(dateValue) => onChange(dateValue)}
                        {...field}
                        error={!!errors.dt_primeiro_contrato}
                        helperText={
                          !!errors.dt_primeiro_contrato && 'Campo obrigatório'
                        }
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="cpf"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <InputMask
                      mask="999.999.999-99"
                      onChange={onChange}
                      disabled={isView}
                      value={value || ''}
                      {...field}
                      error={!!errors.cpf}
                      helperText={!!errors.cpf && 'Campo obrigatório'}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          label="CPF *"
                          autoComplete="off"
                          fullWidth
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="rg"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="RG"
                      type="number"
                      placeholder="Informe o RG"
                      disabled={isView}
                      value={value || ''}
                      autoComplete="off"
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="pis"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="PIS*"
                      autoComplete="off"
                      placeholder="Informe o PIS *"
                      disabled={isView}
                      value={value || ''}
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.pis}
                      helperText={!!errors.pis && 'Campo obrigatório'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="gender"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      select
                      label="Gênero *"
                      disabled={isView}
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value || ''}
                      {...field}
                      error={!!errors.gender}
                      helperText={!!errors.gender && 'Campo obrigatório'}
                    >
                      {genders?.map((gender) => (
                        <MenuItem value={gender.value} key={gender.value}>
                          {gender.name}
                        </MenuItem>
                      ))}
                      {!genders?.length && (
                        <MenuItem disabled value="">
                          Sem opções
                        </MenuItem>
                      )}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dt_birthday"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        value={value || null}
                        label="Data de nascimento *"
                        disabled={isView}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data inválida"
                        inputRef={ref}
                        onChange={(dateValue) => onChange(dateValue)}
                        {...field}
                        error={!!errors.dt_birthday}
                        helperText={!!errors.dt_birthday && 'Campo obrigatório'}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="estado_civil"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      select
                      label="Selecione o estado civil *"
                      disabled={isView}
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value || ''}
                      {...field}
                      error={!!errors.estado_civil}
                      helperText={!!errors.estado_civil && 'Campo obrigatório'}
                    >
                      {maritalStatus.map((marital) => (
                        <MenuItem value={marital.id} key={marital.id}>
                          {marital.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="dealerships_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Autocomplete
                      fullWidth
                      disabled={isView}
                      multiple
                      value={value || []}
                      onChange={(event, v) => {
                        onChange(v)
                      }}
                      disableCloseOnSelect
                      filterSelectedOptions
                      options={userDealerships.data}
                      {...rest}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, val) => {
                        return option.id === val.id
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue?.map((option, index) => (
                          <Chip
                            label={option.name}
                            style={{
                              backgroundColor: '#ED1E79',
                              color: '#FFFFFF',
                              // eslint-disable-next-line no-nested-ternary
                            }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.dealerships_id}
                          helperText={
                            !!errors.dealerships_id && 'Campo obrigatório'
                          }
                          label="Selecione as concessionárias que o colaborador tem acesso *"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      type="email"
                      label="E-mail"
                      autoComplete="off"
                      placeholder="Informe o E-mail"
                      disabled={isView}
                      value={value || ''}
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <InputMask
                      mask="(99)99999-9999"
                      onChange={onChange}
                      value={value || ''}
                      {...field}
                      error={!!errors.phone}
                      helperText={!!errors.phone && 'Campo obrigatório'}
                      disabled={isView}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          label="Telefone *"
                          autoComplete="off"
                          fullWidth
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: !isEdit }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="Senha *"
                      placeholder="Informe a senha"
                      disabled={isView}
                      type="password"
                      value={value || ''}
                      autoComplete="off"
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      {...field}
                      error={!!errors.password}
                      helperText={!!errors.password && 'Campo obrigatório'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="address"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="Endereço"
                      placeholder="Informe o endereço"
                      disabled={isView}
                      value={value || ''}
                      onChange={onChange}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="roles_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Autocomplete
                      fullWidth
                      disabled={isView}
                      multiple
                      value={value || []}
                      onChange={(event, v) => {
                        onChange(v)
                      }}
                      disableCloseOnSelect
                      filterSelectedOptions
                      options={userRoles.data}
                      {...rest}
                      getOptionLabel={(option) => option.display_name}
                      getOptionSelected={(option, val) => {
                        return option.id === val.id
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.display_name}
                            style={{
                              backgroundColor: '#ED1E79',
                              color: '#FFFFFF',
                              // eslint-disable-next-line no-nested-ternary
                            }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.roles_id}
                          helperText={!!errors.roles_id && 'Campo obrigatório'}
                          label="Perfil de acesso *"
                          variant="outlined"
                          disabled={isView}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="cod_nbs"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="Código NBS"
                      placeholder="Informe o código NBS"
                      disabled={isView}
                      value={value || ''}
                      onChange={onChange}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="ramal_next_ip"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="Ramal Next Ip"
                      placeholder="Informe o Ramal Next Ip"
                      disabled={isView}
                      value={value || ''}
                      onChange={onChange}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="rh_code"
                  defaultValue=""
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      label="Código RH"
                      type="string"
                      placeholder="Informe o código do RH"
                      disabled={isView}
                      value={value || ''}
                      autoComplete="off"
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <div className={classes.detailsWrapper}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="caption">Dados avançados</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="ctps"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              label="CTPS"
                              autoComplete="off"
                              placeholder="Informe o CTPS"
                              disabled={isView}
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="serie"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              type="number"
                              autoComplete="off"
                              label="Série"
                              placeholder="Informe a Série"
                              disabled={isView}
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="titulo_eleitor"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              type="number"
                              label="Título de eleitor"
                              autoComplete="off"
                              placeholder="Informe o título de eleitor"
                              disabled={isView}
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="seccao"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              label="Seção"
                              placeholder="Informe a seção de votação"
                              disabled={isView}
                              autoComplete="off"
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          type="number"
                          name="zona"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              label="Zona"
                              placeholder="Informe a zona"
                              disabled={isView}
                              autoComplete="off"
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="grau_escolaridade"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              select
                              label="Selecione o grau de escolaridade"
                              disabled={isView}
                              variant="outlined"
                              fullWidth
                              onChange={onChange}
                              value={value || ''}
                              {...field}
                            >
                              {schooling.map((grau) => (
                                <MenuItem value={grau.id} key={grau.id}>
                                  {grau.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="bank"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              select
                              label="Selecione o banco"
                              disabled={isView}
                              variant="outlined"
                              fullWidth
                              onChange={onChange}
                              value={value || ''}
                              {...field}
                            >
                              {dataBanks.map((bank) => (
                                <MenuItem value={bank.id} key={bank.id}>
                                  {bank.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="agencia"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              label="Agência"
                              placeholder="Informe a agência"
                              disabled={isView}
                              value={value || ''}
                              autoComplete="off"
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name="conta"
                          defaultValue=""
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <TextField
                              label="Conta"
                              placeholder="Informe a conta"
                              disabled={isView}
                              autoComplete="off"
                              value={value || ''}
                              onChange={onChange}
                              variant="outlined"
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  )
}

UserDataFormTab.propTypes = {
  id: PropTypes.string,
  mode: PropTypes.string,
}

UserDataFormTab.defaultProps = {
  id: null,
  mode: null,
}
