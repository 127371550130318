import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSurveysRequest: ['id'],
  getSurveysSuccess: ['data'],
  getSurveysFailure: [],
  finishSurveyRequest: ['data'],
  finishSurveyFinish: [],
})

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const getSurveysRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSurveysSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getSurveysFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const finishSurveyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const finishSurveyFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SURVEYS_REQUEST]: getSurveysRequest,
  [Types.GET_SURVEYS_SUCCESS]: getSurveysSuccess,
  [Types.GET_SURVEYS_FAILURE]: getSurveysFailure,
  [Types.FINISH_SURVEY_REQUEST]: finishSurveyRequest,
  [Types.FINISH_SURVEY_FINISH]: finishSurveyFinish,
})
