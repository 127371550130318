import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'

import Autocomplete from '~/components/Autocomplete'

const callsSelector = ({ calls }) => calls

export default function CallsFilter({
  classes,
  handlePropertyFilterChange,
  getFilterValue,
  departmentSelected,
  subjects,
}) {
  const { dealerships, status, departments } = useSelector(callsSelector)

  const priorities = [
    { id: 1, name: 'Alta' },
    { id: 2, name: 'Normal' },
    { id: 3, name: 'Baixa' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      spacing={2}
      className={classes.filtersWrapper}
    >
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'dealership_id',
              option ? option.value : null,
            )
          }}
          options={sortBy(dealerships.data, (a) => a.name).map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="dealership_id"
          value={getFilterValue('dealership_id')}
          label="Concessionária"
          placeholder="Selecione uma concessionária"
          loading={dealerships.loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'department_id',
              option ? option.value : null,
            )
          }}
          options={departments.data?.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          loading={dealerships.loading}
          name="department_id"
          value={getFilterValue('department_id')}
          label="Departamento"
          placeholder="Selecione um departamento"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'subject_id',
              option ? option.value : null,
            )
          }}
          options={subjects?.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="subject_id"
          loading={dealerships.loading}
          disabled={!departmentSelected.value}
          value={getFilterValue('subject_id')}
          label="Assunto"
          placeholder="Selecione um assunto"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange('priority', option ? option.value : null)
          }}
          options={priorities?.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="priority"
          value={getFilterValue('priority')}
          label="Prioridade"
          loading={dealerships.loading}
          placeholder="Selecione um prioridade"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange(
              'status_id',
              option ? option.value : null,
            )
          }}
          options={status?.data?.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          loading={status.loading}
          name="status_id"
          value={getFilterValue('status_id')}
          label="Status"
          placeholder="Selecione um status"
        />
      </Grid>
    </Grid>
  )
}

CallsFilter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handlePropertyFilterChange: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
  departmentSelected: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  subjects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
}

CallsFilter.defaultProps = {}
