export default (theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    minWidth: 800,
  },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
    position: 'relative',
    minHeight: 400,
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
  },
  grow: {
    flex: 1,
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  gridContainerButton: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  iconAdd: {
    marginRight: 8,
  },
  buttonAdd: {
    minWidth: 259,
    minHeight: 40,
  },
  tableCellActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  filterWropper: {
    display: 'flex',
  },
  editIcon: {
    // marginLeft: 6,
    padding: 0,
  },
  deleteIcon: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  visibilityIcon: {
    marginLeft: theme.spacing(1),
    padding: 0,
  },
})
