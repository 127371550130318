import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getOpenTicketsRequest: [],
  getOpenTicketsFailure: [],
  getOpenTicketsSuccess: ['data'],
  getWaitingTicketsRequest: [],
  getWaitingTicketsFailure: [],
  getWaitingTicketsSuccess: ['data'],
  getAttendantsRequest: [],
  getAttendantsFailure: [],
  getAttendantsSuccess: ['data'],
  getTicketsPerHourRequest: [],
  getTicketsPerHourFailure: [],
  getTicketsPerHourSuccess: ['data'],
  getTicketMetricsRequest: [],
  getTicketMetricsFailure: [],
  getTicketMetricsSuccess: ['data'],
  getDealershipsBlipRequest: [],
  getDealershipsBlipSuccess: ['data'],
  getDealershipsBlipFailure: [],
  setBlipDealershipFilter: ['dealershipId'],
  getDepartmentsBlipRequest: [],
  getDepartmentsBlipSuccess: ['data'],
  getDepartmentsBlipFailure: [],
  setBlipDepartmentFilter: ['departmentId'],
})

const INITIAL_STATE = {
  options: {
    dealershipId: null,
    departmentId: null,
  },
  dealerships: {
    data: [],
    loading: false,
  },
  departments: {
    data: [],
    loading: false,
  },
  dataOpenTickets: [],
  dataWaitingTickets: [],
  dataAttendants: [],
  dataTicketsPerHour: [],
  dataTicketMetrics: [],
  loading: false,
}

const getOpenTicketsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getOpenTicketsFailure = (state = INITIAL_STATE) => ({
  ...state,

  loading: false,
})

const getOpenTicketsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataOpenTickets: data,
  loading: false,
})
const getWaitingTicketsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getWaitingTicketsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getWaitingTicketsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataWaitingTickets: data,
  loading: false,
})
const getAttendantsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAttendantsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAttendantsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataAttendants: data,
  loading: false,
})
const getTicketsPerHourRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getTicketsPerHourFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getTicketsPerHourSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataTicketsPerHour: data,
  loading: false,
})
const getTicketMetricsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getTicketMetricsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getTicketMetricsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataTicketMetrics: data,
  loading: false,
})

const getDealershipsBlipRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})
const getDealershipsBlipSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getDealershipsBlipFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const setBlipDealershipFilter = (state = INITIAL_STATE, { dealershipId }) => ({
  ...state,
  options: {
    ...state.options,
    dealershipId,
  },
})

const getDepartmentsBlipRequest = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: true,
  },
})
const getDepartmentsBlipSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  departments: {
    ...state.departments,
    data,
    loading: false,
  },
})

const getDepartmentsBlipFailure = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: false,
  },
})

const setBlipDepartmentFilter = (state = INITIAL_STATE, { departmentId }) => ({
  ...state,
  options: {
    ...state.options,
    departmentId,
  },
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_OPEN_TICKETS_REQUEST]: getOpenTicketsRequest,
  [Types.GET_OPEN_TICKETS_FAILURE]: getOpenTicketsFailure,
  [Types.GET_OPEN_TICKETS_SUCCESS]: getOpenTicketsSuccess,
  [Types.GET_WAITING_TICKETS_REQUEST]: getWaitingTicketsRequest,
  [Types.GET_WAITING_TICKETS_FAILURE]: getWaitingTicketsFailure,
  [Types.GET_WAITING_TICKETS_SUCCESS]: getWaitingTicketsSuccess,
  [Types.GET_ATTENDANTS_REQUEST]: getAttendantsRequest,
  [Types.GET_ATTENDANTS_FAILURE]: getAttendantsFailure,
  [Types.GET_ATTENDANTS_SUCCESS]: getAttendantsSuccess,
  [Types.GET_TICKETS_PER_HOUR_REQUEST]: getTicketsPerHourRequest,
  [Types.GET_TICKETS_PER_HOUR_FAILURE]: getTicketsPerHourFailure,
  [Types.GET_TICKETS_PER_HOUR_SUCCESS]: getTicketsPerHourSuccess,
  [Types.GET_TICKET_METRICS_REQUEST]: getTicketMetricsRequest,
  [Types.GET_TICKET_METRICS_FAILURE]: getTicketMetricsFailure,
  [Types.GET_TICKET_METRICS_SUCCESS]: getTicketMetricsSuccess,
  [Types.GET_DEALERSHIPS_BLIP_REQUEST]: getDealershipsBlipRequest,
  [Types.GET_DEALERSHIPS_BLIP_SUCCESS]: getDealershipsBlipSuccess,
  [Types.GET_DEALERSHIPS_BLIP_FAILURE]: getDealershipsBlipFailure,
  [Types.SET_BLIP_DEALERSHIP_FILTER]: setBlipDealershipFilter,
  [Types.GET_DEPARTMENTS_BLIP_REQUEST]: getDepartmentsBlipRequest,
  [Types.GET_DEPARTMENTS_BLIP_SUCCESS]: getDepartmentsBlipSuccess,
  [Types.GET_DEPARTMENTS_BLIP_FAILURE]: getDepartmentsBlipFailure,
  [Types.SET_BLIP_DEPARTMENT_FILTER]: setBlipDepartmentFilter,
})
