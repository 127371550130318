import React, { useEffect } from 'react'

import 'moment/locale/pt-br'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'

import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'

import { Creators as BilledMonthComparativeActions } from '~/store/ducks/billedMonthComparative'
import Portlet from '~/components/Portlet'
import OverlayLoading from '~/components/OverlayLoading'
import { useSmallScreen } from '~/helpers/useMediaQuery'
import BilledMonthComparativeFilters from './components/BilledMonthComparativeFilters/index'
import BilledMonthComparativeChart from './components/BilledMonthComparativeChart'

import styles from './styles'

const billedMonthComparativeSelector = ({ billedMonthComparative }) =>
  billedMonthComparative

const useStyles = makeStyles(styles)

function BilledMonthComparative() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { data, loading } = useSelector(billedMonthComparativeSelector)
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    dispatch(
      BilledMonthComparativeActions.getBilledMonthComparativeDataRequest(),
    )
    dispatch(
      BilledMonthComparativeActions.getBilledMonthComparativeFiltersRequest(),
    )
  }, [])

  return (
    <div className={classes.root}>
      {!isSmallScreen && <BilledMonthComparativeFilters />}
      {isSmallScreen && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.filterWropper}>
              <FilterIcon color="primary" className={classes.filterIcon} />
              <Typography variant="h5" color="primary">
                Filtros
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <BilledMonthComparativeFilters />
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {loading && <OverlayLoading />}
      {!loading && (
        <Grid container spacing={2}>
          {data.length > 0 &&
            data.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.dealership}>
                <div className={classes.wrapper}>
                  <Portlet>
                    <BilledMonthComparativeChart
                      datasets={item.datasets}
                      labels={item.labels}
                      dealership={item.dealership}
                    />
                  </Portlet>
                </div>
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  )
}

export default BilledMonthComparative
