export default (theme) => ({
  root: {
    height: 1000,
  },
  titleDivider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    alignContent: 'center',
    marginLeft: theme.spacing(3),
  },
  birthdayCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    marginRight: 20,
  },
  avatar: {
    width: 58,
    height: 58,
    marginRight: 16,
    border: '1px solid #ED1E79',
  },
  firstContainer: {
    padding: theme.spacing(4, 3),
  },
  accordionPassword: {
    margin: theme.spacing(0, 3, 3, 3),
  },
})
