import { call, takeLatest, put } from 'redux-saga/effects'
import { get, pickBy, keys } from 'lodash'
import configureApi from '~/helpers/api'
import history from '~/helpers/history'

import {
  Types as SurveysTypes,
  Creators as SurveyActions,
} from '../ducks/surveys'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

function* getSurveys({ id }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, `/quizzes/${id}`)

    yield put(SurveyActions.getSurveysSuccess(data))
  } catch (e) {
    yield put(SurveyActions.getSurveysFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

const getCorrectAnswer = (sortedQuestions, questionId) => {
  const currentQuestion = sortedQuestions?.find(
    (question) => question.id === questionId,
  )
  const correctAnswer = pickBy(currentQuestion, (item) => item === true)
  return keys(correctAnswer)[0].split('_')[1]
}

function* finishSurvey({ data }) {
  const formattedData = {
    ...data,
    questions: data?.questions?.map((question) => {
      return {
        ...question,
        quiz_id: data.id,
        user_id: data.user_id,
        answer: Number(getCorrectAnswer(data.questions, question.id)),
      }
    }),
  }

  try {
    const api = configureApi()
    yield call(api.post, 'answers', formattedData)

    yield put(SurveyActions.finishSurveyFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Obrigado por responder o questionário!',
        variant: 'success',
      }),
    )

    history.push('/')
  } catch (e) {
    yield put(SurveyActions.finishSurveyFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(SurveysTypes.GET_SURVEYS_REQUEST, getSurveys)
  yield takeLatest(SurveysTypes.FINISH_SURVEY_REQUEST, finishSurvey)
}
