import { call, takeLatest, put, select } from 'redux-saga/effects'

import moment from 'moment'
import { get } from 'lodash'

import configureApi from '~/helpers/api'
import jsonToFormData from '~/helpers/jsonToFormData'

import {
  Types as ReceiptsTypes,
  Creators as ReceiptsActions,
} from '../ducks/receipts'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const receiptsFilterSelector = ({ receipts }) => receipts.options

const api = configureApi()

function* reloadReceipts() {
  yield put(ReceiptsActions.getReceiptsRequest())
}

function* addDocument({ data, callback }) {
  const options = yield select(receiptsFilterSelector)

  const formattedData = {
    ...data,
    user_id: data.user_id,
    year: String(moment(options.filters[0].value).year()),
    month: String(moment(options.filters[0].value).month() + 1),
  }

  const formattedDataToJson = jsonToFormData(formattedData)
  try {
    yield call(api.post, 'rh-documents', formattedDataToJson)

    if (callback) {
      yield call(callback)
    }

    yield put(ReceiptsActions.addDocumentFinish())

    yield put(
      FlashMessageActions.showMessage({
        message: 'Documento cadastrado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(ReceiptsActions.addDocumentFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getReceipts() {
  const options = yield select(receiptsFilterSelector)

  try {
    const { data } = yield call(api.get, 'rh-documents', {
      params: {
        pagination: 1,
        total: options.total,
        limit: options.limit,
        page: options.page + 1,
        order: options.sort,
      },
    })
    yield put(ReceiptsActions.getReceiptsSuccess(data, data.total))
  } catch (e) {
    yield put(ReceiptsActions.getReceiptsFailure())
  }
}

function* receiptVisibilty({ id, callback }) {
  try {
    yield call(api.post, `rh-documents/${id}/visibility`, {})
    if (callback) {
      yield call(callback)
    }

    yield put(ReceiptsActions.getReceiptVisibilityFinish())
  } catch (e) {
    yield put(ReceiptsActions.getReceiptVisibilityFinish())
  }
}

function* removeDocument({ id, callback }) {
  try {
    yield call(api.delete, `rh-documents/${id}`)
    if (callback) {
      yield call(callback)
    }
    yield put(
      FlashMessageActions.showMessage({
        message: 'Documento excluído com sucesso!',
        variant: 'success',
      }),
    )
    yield put(ReceiptsActions.removeDocumentFinish())
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(ReceiptsActions.removeDocumentFinish())
  }
}

function* removeReceiptUser({ id, callback }) {
  try {
    yield call(api.delete, `user-receipts/${id}`)

    if (callback) {
      yield call(callback)
      yield put(
        FlashMessageActions.showMessage({
          message: 'Recibo excluído com sucesso!',
          variant: 'success',
        }),
      )
      yield put(ReceiptsActions.removeReceiptUserFinish())
    }
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
      yield put(ReceiptsActions.removeReceiptUserFinish()),
    )
  }
}

export default function* () {
  yield takeLatest(ReceiptsTypes.ADD_DOCUMENT_REQUEST, addDocument)
  yield takeLatest(ReceiptsTypes.GET_RECEIPTS_REQUEST, getReceipts)
  yield takeLatest(ReceiptsTypes.SET_RECEIPTS_PAGE, reloadReceipts)
  yield takeLatest(
    ReceiptsTypes.GET_RECEIPT_VISIBILITY_REQUEST,
    receiptVisibilty,
  )
  yield takeLatest(ReceiptsTypes.REMOVE_DOCUMENT_REQUEST, removeDocument)
  yield takeLatest(ReceiptsTypes.REMOVE_RECEIPT_USER_REQUEST, removeReceiptUser)
}
