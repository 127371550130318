import { call, takeLatest, put } from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as DailySalesTypes,
  Creators as DailySalesActions,
} from '../ducks/dailySales'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const api = configureApi()

function* getDailySales() {
  try {
    const { data } = yield call(api.get, 'daily_sales_config', {
      params: {
        pagination: 0,
        'where[year]': moment().year(),
        'where[month]': moment().month() + 1,
      },
    })
    yield put(DailySalesActions.getDailySalesSuccess(data))
  } catch (e) {
    yield put(DailySalesActions.getDailySalesFailure())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* updateDailySale({ id, data, callback }) {
  const formattedData = {
    daily_sales: data,
  }
  try {
    yield call(api.post, `update_daily_sales/${id}`, formattedData)
    if (callback) {
      yield call(callback)
    }
    yield put(DailySalesActions.updateDailySaleFinish())
    yield put(
      FlashMessageActions.showMessage({
        message: 'Atualizado com sucesso!',
        variant: 'success',
      }),
    )
  } catch (e) {
    yield put(DailySalesActions.updateDailySaleFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

export default function* () {
  yield takeLatest(DailySalesTypes.GET_DAILY_SALES_REQUEST, getDailySales)
  yield takeLatest(DailySalesTypes.UPDATE_DAILY_SALE_REQUEST, updateDailySale)
}
