import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  // IconButton,
} from '@material-ui/core'

// import { Edit as EditIcon } from '@material-ui/icons'

import Portlet from '~/components/Portlet'

import { Creators as DailyEmailsActions } from '~/store/ducks/dailyEmails'

import DailyEmailsDialog from '~/pages/DailyEmails/components/DailyEmailsDialog/index'
import OverlayLoading from '~/components/OverlayLoading'
import styles from './styles'

function DailyEmails() {
  const [openDailyEmailsDialog, setOpenDailyEmailsDialog] = useState(false)
  const [typeId, setTypeId] = useState(null)
  const [name, setName] = useState(null)
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const dispatch = useDispatch()

  const usersDailyEmailsTypesSelector = ({ dailyEmails }) => dailyEmails

  const { dataTypes, loadingTypes } = useSelector(usersDailyEmailsTypesSelector)

  useEffect(() => {
    dispatch(DailyEmailsActions.getTypesEmailRequest())
    dispatch(DailyEmailsActions.getUsersDailyEmailsRequest())
  }, [])

  const editUsersDailyEmails = (id, nameEmail) => {
    setTypeId(id)
    setName(nameEmail)
    setOpenDailyEmailsDialog(true)
  }

  const handleClose = () => {
    setOpenDailyEmailsDialog(false)
    setTypeId(null)
  }

  return (
    <>
      {loadingTypes && <OverlayLoading />}
      {!loadingTypes && (
        <div className={classes.root}>
          <Portlet className={classes.grow}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tipo de Relatório</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTypes?.data?.map((item) => {
                  return (
                    <TableRow
                      hover
                      key={item.id}
                      className={classes.tableRow}
                      onClick={() => editUsersDailyEmails(item.id, item.name)}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Portlet>
          <DailyEmailsDialog
            id={typeId}
            name={name}
            open={openDailyEmailsDialog}
            onClose={handleClose}
          />
        </div>
      )}
    </>
  )
}

export default DailyEmails
