export default (theme) => ({
  root: {},
  paper: {
    minWidth: 400,
    textAlign: 'center',
    alignItems: 'center',
  },
  variantHeader: {
    color: '#fff',
    width: '100%',
    backgroundColor: theme.palette.error.dark,
  },
  variantSaveHeader: {
    backgroundColor: theme.palette.success.dark,
  },
  variantWarningHeader: {
    backgroundColor: theme.palette.warning.main,
  },
  variantHeaderIcon: {
    boxSizing: 'content-box',
    width: 20,
    padding: 12,
    border: '1px solid white',
    borderRadius: '50%',
    margin: 12,
  },
  acceptButton: {
    marginRight: theme.spacing(2),
  },
  buttonContent: {
    marginBottom: theme.spacing(2),
  },
})
