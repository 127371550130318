import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
  InputOutlined as LogoutIcon,
  Person as UserIcon,

  // AccountCircleSharp as UserIconMenu,
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import history from '~/helpers/history'
import { Creators as SessionActions } from '~/store/ducks/session'

import styles from './styles'

const sessionSelector = ({ session }) => session
function AccountButton({ classes }) {
  const dispatch = useDispatch()
  const { user } = useSelector(sessionSelector)

  const [openAccountMenu, setOpenAccountMenu] = useState(null)

  const handleMenuClose = () => {
    setOpenAccountMenu(null)
  }

  const handleLocaleClick = (event) => {
    setOpenAccountMenu(event.currentTarget)
  }

  const handlePreferences = () => {
    setOpenAccountMenu(null)
    // history.push('/preferences')
  }

  const handleSignOut = () => {
    dispatch(SessionActions.logout())
  }

  const goProfile = () => {
    history.push('/profile')
  }

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.accountMenu}
        onClick={handleLocaleClick}
        disableRipple
        disableFocusRipple
      >
        <div className={classes.avatarWrapper} />
        {/* {user.url_image && ( */}
        <Avatar
          alt={user.name}
          src={user.url_image}
          className={classes.avatarImage}
        />
        {/* )} */}
        {/* {!user.url_image && <UserIconMenu className={classes.avatarMenu} />} */}
        {!openAccountMenu && <ArrowDown className={classes.arrowIcon} />}
        {openAccountMenu && <ArrowUp className={classes.arrowIcon} />}
      </IconButton>
      <Menu
        open={Boolean(openAccountMenu)}
        onClose={handleMenuClose}
        anchorEl={openAccountMenu}
        className={classes.accountMenu}
        PaperProps={{
          style: {
            left: '50%',
            transform: 'translateX(-5%) translateY(20%)',
          },
        }}
        classes={{ list: classes.menuList }}
      >
        <MenuItem component="button" disableRipple>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={2}>
              {user.url_image ? (
                <Avatar
                  alt={user.name}
                  className={classes.avatar}
                  src={user.url_image}
                />
              ) : (
                <Avatar
                  className={[classes.avatarIcon, classes.avatarIconUser].join(
                    ' ',
                  )}
                >
                  <UserIcon />
                </Avatar>
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.accountName} variant="body1">
                {user.name}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={handlePreferences}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={2}>
              <Avatar className={classes.avatarIcon}>
                <UserIcon />
              </Avatar>
            </Grid>
            <Grid item xs={10} onClick={goProfile}>
              <Typography className={classes.accountName} variant="body1">
                Meu Perfil
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={2}>
              <Avatar className={classes.avatarIcon}>
                <LogoutIcon />
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.accountName} variant="body1">
                Sair
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  )
}

AccountButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(AccountButton)
