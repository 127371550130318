import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useDispatch, useSelector } from 'react-redux'

import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import { Creators as SellersActions } from '~/store/ducks/sellers'
import Autocomplete from '~/components/Autocomplete'
import styles from './styles'

const dealershipsSelector = ({ sellers }) => sellers.dealerships

const useStyles = makeStyles(styles)
const sellersSelector = ({ sellers }) => sellers

function SellersFilters() {
  const { options } = useSelector(sellersSelector)
  const { dateValue } = options

  const classes = useStyles()

  const dispatch = useDispatch()
  const { data: dataDealerships, loading: loadingDealerships } = useSelector(
    dealershipsSelector,
  )

  const handleDateChange = (value) => {
    dispatch(SellersActions.setSellersDate(value))
  }

  const handleDealershipsFilterChange = (dealershipId) => {
    dispatch(SellersActions.setSellersDealershipFilter(dealershipId))
  }

  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.gridContainerButton}
      spacing={2}
    >
      <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
          onChange={(_, option) => {
            handleDealershipsFilterChange(option ? option.value : null)
          }}
          options={dataDealerships.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="dealership"
          value={options.dealershipId}
          className={classes.autocomplete}
          label="Filtrar por concessionária"
          placeholder="Selecione uma concessionária"
          loading={loadingDealerships}
          autoSelect={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <KeyboardDatePicker
            format="MM/YYYY"
            fullWidth
            inputVariant="outlined"
            views={['month', 'year']}
            label="Ano e mês"
            // helperText="With min and max"
            minDate={new Date('2001-01-01')}
            // maxDate={new Date('2018-06-01')}
            value={dateValue}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default SellersFilters
