import React from 'react'
import { useEffectOnce } from 'react-use'

import { useDispatch, useSelector } from 'react-redux'

import { Grid, makeStyles } from '@material-ui/core'
import { Creators as DailySalesActions } from '~/store/ducks/dailySales'

import OverlayLoading from '~/components/OverlayLoading'
import Portlet from '~/components/Portlet'
import EmptyList from '~/components/EmptyList'

import DailySalesCard from './DailySalesCard'

const dailySalesSelector = ({ dailySales }) => dailySales

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: 'relative',
    height: '100%',
  },
  emptyWrapper: {
    display: 'flex',
  },
  grow: {
    display: 'flex',
    height: '85vh',
    flex: 1,
    position: 'relative',
  },
}))

export default function DailySalesPage() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { data, loading } = useSelector(dailySalesSelector)

  useEffectOnce(() => {
    dispatch(DailySalesActions.getDailySalesRequest())
  })

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      <Grid container spacing={2}>
        {data.length > 0 &&
          !loading &&
          data.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item._id}>
              <DailySalesCard data={item.data} />
            </Grid>
          ))}
        {!loading && data.length === 0 && (
          <Grid item xs={12} className={classes.emptyWrapper}>
            <Portlet className={classes.grow}>
              <EmptyList message="Não há dados para serem visualizadas" />
            </Portlet>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
