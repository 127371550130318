import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  DialogTitle,
  Typography,
  Button,
  makeStyles,
  Divider,
  Grid,
} from '@material-ui/core'

import { useDispatch } from 'react-redux'

import { Creators as DealershipActions } from '~/store/ducks/dealership'

import { useMobileScreen } from '~/helpers/useMediaQuery'

import TabPanel from '~/components/TabPanel'

import DealershipFormTab from './DealershipFormTab'
import DepartmentFormTab from './DepartmentFormTab'
import styles from './styles'

const useStyles = makeStyles(styles)

const DealershipsDialog = ({ open, onClose, id }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(0)
  const isMobileScreen = useMobileScreen()
  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }
  const isEdit = !!id

  useEffect(() => {
    setCurrentTab(0)
    if (isEdit) {
      dispatch(DealershipActions.getDealershipRequest(id))
    }
  }, [isEdit, id])

  return (
    <Dialog
      fullScreen={isMobileScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {isEdit ? 'Editar ' : 'Adicionar '}
          concessionária
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />

      <DialogContent className={classes.dialogContent}>
        {!isEdit ? (
          <>
            <Grid container className={classes.subtitleWrapper}>
              <Grid item xs={12}>
                <Typography variant="h5">Concessionária</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.dividerBottom} />
          </>
        ) : (
          <Tabs
            variant="fullWidth"
            value={currentTab}
            onChange={handleTabsChange}
          >
            <Tab label="Concessionária" />
            <Tab label="Departamento" />
          </Tabs>
        )}
        <TabPanel index={0} value={currentTab}>
          <DealershipFormTab id={id} onCloseDialog={onClose} />
        </TabPanel>
        <TabPanel index={1} value={currentTab}>
          <DepartmentFormTab id={id} />
        </TabPanel>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.buttonForm}>
        <Button onClick={onClose} variant="contained">
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DealershipsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number,
}

DealershipsDialog.defaultProps = {
  id: null,
}

export default DealershipsDialog
