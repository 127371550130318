/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react'
import { useEffectOnce } from 'react-use'
import { makeStyles } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as OrganogramActions } from '~/store/ducks/organogram'
import OverlayLoading from '~/components/OverlayLoading'
import CustomOrganogram from './components/CustomOrganogram'

import styles from './styles'
import './organogram.css'

const organogramSelector = ({ organogram }) => organogram

const useStyles = makeStyles(styles)

export default function EmployeesOrganogramPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, loading } = useSelector(organogramSelector)

  useEffectOnce(() => {
    dispatch(OrganogramActions.getOrganogramDataRequest())
  })

  return (
    <div className={classes.root}>
      {loading && <OverlayLoading />}
      {data?.id && !loading && <CustomOrganogram data={data} />}
    </div>
  )
}
