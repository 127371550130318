import { createActions, createReducer } from 'reduxsauce'
import moment from 'moment'
import { last } from 'lodash'

export const { Types, Creators } = createActions({
  getAnalitycalPartsRequest: [],
  getAnalitycalPartsFailure: [],
  getAnalitycalPartsSuccess: ['data', 'dataPreviousMonthParts', 'total'],
  setAnalitycalPartsSort: ['sort', 'direction'],
  setAnalitycalPartsPage: ['page', 'limit'],
  setAnalitycalPartsDate: ['start', 'end'],
  resetAnalitycalParts: [],
  setChangeShowHideColumnsAnalitycalParts: ['newTableColumns'],
  getFiltersAnalitycalPartsRequest: [],
  getFiltersAnalitycalPartsFailure: [],
  getFiltersAnalitycalPartsSuccess: ['groups', 'dealerships'],
  setAnalitycalPartsChangeFilter: ['property', 'value'],
  getAnalitycalPartsDetailsRequest: ['segment'],
  getAnalitycalPartsDetailsFailure: [],
  getAnalitycalPartsDetailsSuccess: ['dataDetails'],
  setChangeShowHideColumnsAnalitycalPartsDialog: ['newTableColumnsDialog'],
})

const INITIAL_STATE = {
  showHideColumns: {
    type: true,
    billing: true,
    quantity: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
  },
  showHideColumnsDialog: {
    type: true,
    amount: true,
    billing: true,
    cost: true,
    liquidValue: true,
    porcentage: true,
    emissao: true,
  },
  options: {
    sort: 'nome',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  filters: {
    startDate: moment().subtract(30, 'days').format(),
    endDate: moment().format(),
    group: 0,
    dealership: 0,
  },
  filtersData: {
    groups: [
      {
        name: 'Todos os grupos',
        value: 0,
      },
    ],
    dealerships: [
      {
        name: 'Todas as concessionárias',
        value: 0,
      },
    ],
  },
  data: [],
  dataPreviousMonthParts: {},
  loading: false,
  loadingFilters: false,
  dataDetails: [],
}

const getAnalitycalPartsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalPartsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalPartsSuccess = (
  state = INITIAL_STATE,
  { data, dataPreviousMonthParts, total },
) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  dataPreviousMonthParts: last(dataPreviousMonthParts),
  loading: false,
})

const setAnalitycalPartsSort = (
  state = INITIAL_STATE,
  { sort, direction },
) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setAnalitycalPartsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setAnalitycalPartsDate = (state = INITIAL_STATE, { start, end }) => ({
  ...state,
  filters: {
    ...state.filters,
    startDate: start,
    endDate: end,
  },
})

const resetAnalitycalParts = () => ({
  ...INITIAL_STATE,
})

const setChangeShowHideColumnsAnalitycalParts = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycalParts',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}
const getFiltersAnalitycalPartsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getFiltersAnalitycalPartsSuccess = (
  state = INITIAL_STATE,
  { groups, dealerships },
) => {
  return {
    ...state,
    filtersData: {
      ...state.filtersData,
      groups: [
        {
          name: 'Todas os grupos',
          value: 0,
        },
        ...groups,
      ],
      dealerships: [
        {
          name: 'Todas as concessionárias',
          value: 0,
        },
        ...dealerships,
      ],
    },
    loading: false,
  }
}

const getFiltersAnalitycalPartsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setAnalitycalPartsChangeFilter = (
  state = INITIAL_STATE,
  { property, value },
) => ({
  ...state,
  filters: {
    ...state.filters,
    [property]: value,
  },
})

const getAnalitycalPartsDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getAnalitycalPartsDetailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getAnalitycalPartsDetailsSuccess = (
  state = INITIAL_STATE,
  { dataDetails },
) => ({
  ...state,
  dataDetails,
  loading: false,
})

const setChangeShowHideColumnsAnalitycalPartsDialog = (
  state = INITIAL_STATE,
  { newTableColumnsDialog },
) => {
  localStorage.setItem(
    'caid.columnsTableAnalitycalPartsDialog',
    JSON.stringify(newTableColumnsDialog),
  )
  return {
    ...state,
    showHideColumnsDialog: newTableColumnsDialog,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_ANALITYCAL_PARTS_REQUEST]: getAnalitycalPartsRequest,
  [Types.GET_ANALITYCAL_PARTS_FAILURE]: getAnalitycalPartsFailure,
  [Types.GET_ANALITYCAL_PARTS_SUCCESS]: getAnalitycalPartsSuccess,
  [Types.SET_ANALITYCAL_PARTS_SORT]: setAnalitycalPartsSort,
  [Types.SET_ANALITYCAL_PARTS_PAGE]: setAnalitycalPartsPage,
  [Types.SET_ANALITYCAL_PARTS_DATE]: setAnalitycalPartsDate,
  [Types.RESET_ANALITYCAL_PARTS]: resetAnalitycalParts,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL_PARTS]:
    setChangeShowHideColumnsAnalitycalParts,
  [Types.GET_FILTERS_ANALITYCAL_PARTS_REQUEST]:
    getFiltersAnalitycalPartsRequest,
  [Types.GET_FILTERS_ANALITYCAL_PARTS_SUCCESS]:
    getFiltersAnalitycalPartsSuccess,
  [Types.GET_FILTERS_ANALITYCAL_PARTS_FAILURE]:
    getFiltersAnalitycalPartsFailure,
  [Types.SET_ANALITYCAL_PARTS_CHANGE_FILTER]: setAnalitycalPartsChangeFilter,
  [Types.GET_ANALITYCAL_PARTS_DETAILS_REQUEST]:
    getAnalitycalPartsDetailsRequest,
  [Types.GET_ANALITYCAL_PARTS_DETAILS_FAILURE]:
    getAnalitycalPartsDetailsFailure,
  [Types.GET_ANALITYCAL_PARTS_DETAILS_SUCCESS]:
    getAnalitycalPartsDetailsSuccess,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_ANALITYCAL_PARTS_DIALOG]:
    setChangeShowHideColumnsAnalitycalPartsDialog,
})
