import React, { useState } from 'react'
import moment from 'moment'
import { useEffectOnce, useUnmount } from 'react-use'
import {
  makeStyles,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'

import SearchInput from '~/components/SearchInput'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import ConfirmDialog from '~/components/ConfirmDialog'

import EmptyList from '~/components/EmptyList'

import OverlayLoading from '~/components/OverlayLoading'

import history from '~/helpers/history'
import { useSmallScreen } from '~/helpers/useMediaQuery'

import { Creators as NewsBoardActions } from '~/store/ducks/newsBoard'

import NewsBoardListHeader from './components/NewsBoardListHeader'

import styles from './styles'

const useStyles = makeStyles(styles)

const newsBoardSelector = ({ newsBoard }) => newsBoard

export default function NewsBoardPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSmallScreen = useSmallScreen()
  const { data, loading, options } = useSelector(newsBoardSelector)
  const [newsId, setNewsId] = useState(null)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffectOnce(() => {
    dispatch(NewsBoardActions.getNewsBoardRequest('news', false))
  })

  useUnmount(() => {
    dispatch(NewsBoardActions.resetNews())
  })

  const handleSearchChange = (value) => {
    dispatch(NewsBoardActions.setNewsBoardSearch(value))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(NewsBoardActions.setNewsBoardPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(NewsBoardActions.setNewsBoardPage(page, options.limit))
  }

  const handleRemoveCancel = () => {
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      NewsBoardActions.removeNewsRequest(newsId, 'news', () => {
        setNewsId(null)
        setOpenConfirmDialog(false)
        dispatch(NewsBoardActions.getNewsBoardRequest('news', false))
      }),
    )
  }
  const handleDeleteNewBoard = (id) => {
    setNewsId(id)
    setOpenConfirmDialog(true)
  }

  const handleSwitchChange = (item) => {
    let formattedData
    if (item.text === null) {
      formattedData = {
        ...item,
        visible: Number(!item.visible),
        text: '',
      }
    } else {
      formattedData = {
        ...item,
        visible: Number(!item.visible),
      }
    }
    dispatch(
      NewsBoardActions.updateNewsRequest(item.id, formattedData, () => {
        dispatch(NewsBoardActions.getNewsBoardRequest('news', false))
      }),
    )
  }

  const getCurrentMention = (mention) => {
    // return `@[${mention.user.name}](${mention.user.id})`
    return `<span class="mention-text" data-id="mention-${mention.id}-user-${mention.user.id}" data-image=${mention.user.url_image}>@${mention.user.name}</span>`
  }

  const getText = (str, mentions) => {
    const text = str || ''
    const usersMentions = [...text?.matchAll(/@@\[(.*?)]\((\d+)\)/g)].map(
      (item) => ({
        previous: item[0],
        current: getCurrentMention(
          mentions?.find((mention) => mention.user_id === Number(item[2])),
        ),
      }),
    )
    return usersMentions.reduce(
      (acc, item) => acc.replace(item.previous, item.current),
      str,
    )
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.gridContainerButton}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <SearchInput
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise pela descrição..."
              />
            </Grid>
            <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAdd}
                onClick={() => history.push('/news-board/new')}
              >
                <AddIcon className={classes.iconAdd} /> Adicionar Notícia
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há notícias para serem visualizadas" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              <Table size="small">
                <NewsBoardListHeader />
                <TableBody>
                  {data.length > 0 &&
                    data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell align="center">
                          <div className={classes.avatar}>
                            <Avatar
                              alt={item.author.name}
                              src={item.author.url_image}
                            />
                          </div>
                        </TableCell>
                        <TableCell>{item.author.name}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell
                          style={{
                            maxWidth: 140,
                          }}
                        >
                          <div
                            className={classes.descriptionCell}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: getText(item.text, item.mentions),
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {moment(item.created_at).format('L')}
                          {' - '}
                          {moment(item.created_at).format('HH:mm')}
                        </TableCell>
                        <TableCell className={classes.actionsCell}>
                          <div className={classes.actionsWrapper}>
                            <IconButton
                              className={classes.editIcon}
                              onClick={() =>
                                history.push(`/news-board/${item.id}`)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              className={classes.icon}
                              onClick={() => handleDeleteNewBoard(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Tooltip
                              placement="top"
                              title={`${
                                item.visible ? 'Vísivel' : 'Não vísivel'
                              }`}
                            >
                              <Switch
                                size="small"
                                checked={item.visible}
                                color="primary"
                                onChange={() => handleSwitchChange(item)}
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>

      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir esta notícia?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </div>
  )
}
