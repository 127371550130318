import React, { useEffect, useState } from 'react'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { Creators as SuperiorActions } from '~/store/ducks/superior'
import { Creators as SuperiorsActions } from '~/store/ducks/superiors'

import OverlayLoading from '~/components/OverlayLoading'
import { useMobileScreen } from '~/helpers/useMediaQuery'

import Autocomplete from '~/components/Autocomplete'

import styles from './style'

const useStyles = makeStyles(styles)

const superiorSelector = ({ superior: { data } }) => data
const loadingSelector = ({ superior }) => superior.loading

const SuperiorDialog = ({ open, onClose, superior: currentSuperior }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const superiorsData = useSelector(superiorSelector)
  const loading = useSelector(loadingSelector)
  const [optionSelected, setOptionSelected] = useState(null)
  const isMobileScreen = useMobileScreen()

  useEffect(() => {
    if (currentSuperior?.id) {
      dispatch(SuperiorActions.getSuperiorRequest(currentSuperior.id))
    }
  }, [currentSuperior])

  const reloadSuperiors = () => {
    dispatch(SuperiorsActions.getSuperiorsRequest())
  }

  const onSubmit = (event) => {
    event.preventDefault()
    reloadSuperiors()
    const formattedData = {
      ...currentSuperior,
      parent_id: optionSelected,
      user_id: currentSuperior.id,
    }
    if (currentSuperior?.id) {
      dispatch(
        SuperiorActions.updateSuperiorRequest(formattedData, () => {
          reloadSuperiors()
          onClose()
        }),
      )
    }
  }

  const currentParent =
    superiorsData &&
    superiorsData?.find((s) => s.id === currentSuperior?.parent_id)

  useEffect(() => {
    if (currentParent) {
      setOptionSelected(currentParent.id)
    }

    return () => {
      setOptionSelected(null)
    }
  }, [currentParent])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        fullWidth
        maxWidth="md"
        fullScreen={isMobileScreen}
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">Editar superior</Typography>
        </DialogTitle>
        <Divider className={classes.divider} />

        <DialogContent className={classes.dialogContent}>
          {loading && <OverlayLoading />}
          {!loading && (
            <form onSubmit={onSubmit} id="form-superior">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    variant="outlined"
                    autoFocus
                    label="Nome"
                    type="text"
                    value={currentSuperior?.name || ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    variant="outlined"
                    label="Departamento"
                    type="text"
                    value={currentSuperior?.function_department_name || ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    variant="outlined"
                    label="Função"
                    type="text"
                    value={currentSuperior?.function_name || ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(_, option) => {
                      setOptionSelected(option ? option.value : null)
                    }}
                    options={superiorsData?.map((option) => ({
                      label: option.name,
                      value: option.id,
                    }))}
                    name="superior"
                    value={optionSelected}
                    className={classes.autocomplete}
                    label="Superior"
                    placeholder="Selecione um superior"
                    loading={loading}
                    autoSelect={false}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <Divider className={classes.divider} />
        <DialogActions className={classes.buttonForm}>
          <Button onClick={onClose} variant="contained">
            CANCELAR
          </Button>
          <Button
            color="primary"
            type="submit"
            form="form-superior"
            variant="contained"
          >
            SALVAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

SuperiorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  superior: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ),
}

SuperiorDialog.defaultProps = {
  superior: {},
}

export default SuperiorDialog
