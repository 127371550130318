import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import configureApi from '~/helpers/api'

import {
  Types as DepartmentsTypes,
  Creators as DepartmentsActions,
} from '../ducks/departments'

const optionsSelector = ({ departments }) => departments.options

function* getDepartments() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'departments', {
      params: {
        ...options,
        page: options.page + 1,
        order: `${options.sort},${options.direction}`,
        search: options.search ? `name,${options.search}` : null,
      },
    })

    yield put(DepartmentsActions.getDepartmentsSuccess(data.data, data.total))
  } catch (e) {
    yield put(DepartmentsActions.getDepartmentsFailure())
  }
}

function* reloadDepartments() {
  yield put(DepartmentsActions.getDepartmentsRequest())
}

export default function* () {
  yield takeLatest(DepartmentsTypes.GET_DEPARTMENTS_REQUEST, getDepartments)
  yield takeLatest(DepartmentsTypes.SET_DEPARTMENTS_SORT, reloadDepartments)
  yield takeLatest(DepartmentsTypes.SET_DEPARTMENTS_PAGE, reloadDepartments)
  yield debounce(
    1000,
    DepartmentsTypes.SET_DEPARTMENTS_SEARCH,
    reloadDepartments,
  )
}
