import React from 'react'
import moment from 'moment'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import {
  Typography,
  makeStyles,
  Avatar,
  Divider,
  IconButton,
} from '@material-ui/core'

import {
  ArrowBackIos as ArrowPrevIcon,
  ArrowForwardIos as ArrowNextIcon,
} from '@material-ui/icons'
import { useSelector } from 'react-redux'

import Portlet from '~/components/Portlet'

import styles from './styles'

const dashboardSelector = ({ dashboard }) => dashboard

const useStyles = makeStyles(styles)

function CustomPrevArrow(props) {
  const { onClick } = props
  const classes = useStyles()
  return (
    <IconButton
      disabled={!onClick}
      className={classes.arrowPrevIcon}
      onClick={onClick}
      color="secondary"
    >
      <ArrowPrevIcon />
    </IconButton>
  )
}
function CustomNextArrow(props) {
  const { onClick } = props
  const classes = useStyles()
  return (
    <IconButton
      disabled={!onClick}
      className={classes.arrowNextIcon}
      onClick={onClick}
      color="secondary"
    >
      <ArrowNextIcon />
    </IconButton>
  )
}

export default function BirthdaysSlider() {
  const {
    birthdays: { data },
  } = useSelector(dashboardSelector)

  const classes = useStyles()

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    pauseOnHover: true,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }

  const getDataMessage = (item) => {
    if (item.type === 'company' && item.years > 1) {
      return ` ${moment(item.dt_birthday).format('DD/MM')} - ${item.years} anos
      de empresa `
    }
    if (item.type === 'company' && item.years === '1') {
      return ` ${moment(item.dt_birthday).format('DD/MM')} - ${item.years} ano
      de empresa `
    }
    return ` ${moment(item.dt_birthday).format('DD/MM')}`
  }

  return (
    <>
      {data.length > 0 && (
        <div>
          <Portlet className={classes.portlet}>
            <div className={classes.sliderWrapper}>
              {data.length > 0 && (
                <Slider {...settings}>
                  {data?.map((item, index) => (
                    <div key={item.id} className={classes.container}>
                      <div className={classes.birthdayCard}>
                        <Avatar
                          className={classes.avatar}
                          alt={item.name}
                          src={item.url_image}
                        />
                        <div className={classes.birthdayCardContent}>
                          <Typography variant="h5" color="primary">
                            {getDataMessage(item)}
                          </Typography>
                          <Typography variant="body1">{item.name}</Typography>
                          <Typography
                            variant="caption"
                            style={{ color: '#3A3B3F' }}
                          >
                            {item.function_name} -{' '}
                            {item.function_dealership_name}
                          </Typography>
                        </div>
                        {index !== data.length - 1 &&
                          settings?.slidesToShow > 1 && (
                            <Divider
                              orientation="vertical"
                              className={classes.divider}
                            />
                          )}
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </Portlet>
        </div>
      )}
    </>
  )
}

BirthdaysSlider.defaultProps = {}

CustomPrevArrow.propTypes = {
  onClick: PropTypes.func,
}

CustomPrevArrow.defaultProps = {
  onClick: null,
}
CustomNextArrow.propTypes = {
  onClick: PropTypes.func,
}

CustomNextArrow.defaultProps = {
  onClick: null,
}
