export default (theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    '& .ReactCrop__image': {
      maxHeight: 500,
    },
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
  },
  Icon: {
    marginRight: theme.spacing(1),
  },
  cropWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),

    marginBottom: theme.spacing(15),
    maxHeight: 400,
    maxWidth: 400,
  },
  gridButton: {
    marginTop: theme.spacing(4),
  },
  acordeon: {
    display: 'block',
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  detailsWrapper: {
    width: '100%',
    padding: theme.spacing(1),
  },
  userImage: {
    margin: theme.spacing(1, 0, 2, 0),
    width: 200,
    height: 200,
  },
  uploadButton: {
    height: '42px',
  },
  buttons: {
    justifyContent: 'flex-end',
  },
})
