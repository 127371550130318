import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  showMessage: ['settings'],
  hideMessage: [],
})

const INITIAL_STATE = {
  message: null,
  open: false,
  variant: 'error',
}

const showMessage = (state = INITIAL_STATE, action) => ({
  ...state,
  message: action.settings.message || null,
  variant: action.settings.variant || 'error',
  open: true,
})

const hideMessage = (state = INITIAL_STATE) => ({
  ...state,
  open: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_MESSAGE]: showMessage,
  [Types.HIDE_MESSAGE]: hideMessage,
})
