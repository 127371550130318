import { call, takeLatest, put, select } from 'redux-saga/effects'
import moment from 'moment'
import configureApi from '~/helpers/api'

import { getRating } from '~/helpers/sellers'
import {
  Types as SellersTypes,
  Creators as SellersActions,
} from '../ducks/sellers'

const optionsSelector = ({ sellers }) => sellers.options

function* getSellers() {
  const options = yield select(optionsSelector)
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'sellers', {
      params: {
        // ...options,
        page: options.page + 1,
        limit: options.limit,
        order: `${options.sort},${options.direction}`,
        pagination: 1,
        month: moment(options.dateValue).format('MM'),
        year: moment(options.dateValue).format('YYYY'),
        'where[dealership_id]': options.dealershipId,
      },
    })
    const formattedData = data?.data?.map((seller) => ({
      ...seller,
      rating: getRating(seller.media),
    }))
    yield put(SellersActions.getSellersSuccess(formattedData, data.total))
  } catch (e) {
    yield put(SellersActions.getSellersFailure())
  }
}

function* reloadSellers() {
  yield put(SellersActions.getSellersRequest())
}

function* getDealershipsSellers() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        limit: 99999,
        order: 'name,asc',
      },
    })
    yield put(SellersActions.getDealershipsSellersSuccess(data.data))
  } catch (e) {
    yield put(SellersActions.getDealershipsSellersFailure())
  }
}

export default function* () {
  yield takeLatest(SellersTypes.GET_SELLERS_REQUEST, getSellers)
  yield takeLatest(SellersTypes.SET_SELLERS_SORT, reloadSellers)
  yield takeLatest(SellersTypes.SET_SELLERS_PAGE, reloadSellers)
  yield takeLatest(SellersTypes.SET_SELLERS_DATE, reloadSellers)
  yield takeLatest(SellersTypes.SET_SELLERS_DEALERSHIP_FILTER, reloadSellers)
  yield takeLatest(
    SellersTypes.GET_DEALERSHIPS_SELLERS_REQUEST,
    getDealershipsSellers,
  )
}
